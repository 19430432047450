import { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import { checkUserLiveStatus, getUserSummaryAsync, handleSessionRefreshAsync, handleUserLogoutAsync } from "../../../redux-store/auth-reducer";

const LiveCheckLoader = () => {
  // local state
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [logoutTime, setLogoutTime] = useState(30);
  const [countDownEnabled, setCountDown] = useState(false);
  // action dispatcher
  const dispatch = useDispatch();
  // default assignments
  let automaticTimeoutTimer = null;
  let intervalId;
  // state selectors
  const liveCheck = useSelector((state) => state.auth.liveCheck);
  const shouldCheck = useSelector((state) => state.auth.shouldCheckLive);
  const auth = useSelector((state) => state.auth);

  const checkTokenHasExpired = () => {
    if (auth.isSignedIn) {
      const exp = auth.exp;
      const expirationTime = exp * 1000;
      let crTime = new Date();
      crTime.setMinutes(crTime.getMinutes() + 1);

      if (crTime.getTime() >= expirationTime) {
        if (automaticTimeoutTimer == null) {
          // needs to show autologout box
          const currentTime = new Date().getTime();
          const loginTime = auth.loginTS * 1000;
          const diffInMilliseconds = Math.abs(currentTime - loginTime);
          const minutes = Math.floor(diffInMilliseconds / 60 / 1000);
          setMinutes(minutes);
          setSeconds(seconds);
          dispatch(checkUserLiveStatus(true));
          dispatch(getUserSummaryAsync());
          setCountDown(true);
        }
      }
    }
  };

  const handleStay = () => {
    clearInterval(automaticTimeoutTimer);
    automaticTimeoutTimer = null;
    setLogoutTime(30);
    setCountDown(false);
    dispatch(handleSessionRefreshAsync());
  };

  const handleLogout = () => {
    clearInterval(automaticTimeoutTimer);
    clearInterval(intervalId);
    automaticTimeoutTimer = null;
    intervalId = null;
    setLogoutTime(30);
    setCountDown(false);
    dispatch(handleUserLogoutAsync());
  };

  useEffect(() => {
    if (auth.isSignedIn) {
      intervalId = setInterval(checkTokenHasExpired, 5000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [auth.isSignedIn, auth.exp, auth.loginTS]);

  useEffect(() => {
    if (countDownEnabled) {
      automaticTimeoutTimer = setInterval(() => {
        if (logoutTime === 0) {
          clearInterval(automaticTimeoutTimer);
          clearInterval(intervalId);
          automaticTimeoutTimer = null;
          intervalId = null;
          setLogoutTime(0);
          dispatch(handleUserLogoutAsync());
        } else {
          const logTime = logoutTime - 1;
          setLogoutTime(logTime);
        }
      }, 1000);
    }
    return () => {
      if (automaticTimeoutTimer) clearInterval(automaticTimeoutTimer);
    };
  }, [countDownEnabled, automaticTimeoutTimer, logoutTime]);

  return (
    <Dialog open={shouldCheck} keepMounted aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
      <DialogTitle style={{ color: "var(--color-contrast)" }} id="alert-dialog-slide-title">
        <Translate value="labels.resp_game" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "var(--grey)" }} id="alert-dialog-slide-description" component={"span"}>
          <Translate value="labels.auto_logout_1" />
          {minutes}
          <Translate value="labels.auto_logout_2" />
          {seconds}
          <Translate value="labels.auto_logout_3" />
          <br></br>
          <br></br>
          <Typography style={{ textAlign: "center" }}>
            <Translate value="labels.auto_logout_stake" />: {liveCheck.totalCashBetAmount ? liveCheck.totalCashBetAmount : 0}
          </Typography>
          <Typography style={{ textAlign: "center", color: "var(--green)" }}>
            <Translate value="labels.auto_logout_win" />: {liveCheck.totalCashWinAmount ? liveCheck.totalCashWinAmount : 0}
          </Typography>
          <Typography style={{ textAlign: "center" }}>
            <Translate value="labels.auto_logout_profit" />: {liveCheck.totalPromoWinAmount ? liveCheck.totalPromoWinAmount : 0}
          </Typography>
          <br></br>
          <Translate value="labels.auto_logout_statement" />
          <br></br>
          <Translate value="labels.auto_logout_4" />
          {logoutTime}
          <Translate value="labels.auto_logout_5" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box mr={2}>
          <Button onClick={handleLogout} variant="contained" color="warning" size="small">
            <Translate value="labels.logout" />
          </Button>
        </Box>
        <Button
          onClick={handleStay}
          variant="contained"
          size="small"
          color="primary"
          style={{
            color: "var(--btn-text)",
            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
          }}
          autoFocus
        >
          <Translate value="labels.stay_loggedin" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LiveCheckLoader;
