import { Autoplay, Pagination, Navigation } from "swiper/modules";

const featuredPrecannedSliderSettings = {
  slidesPerView: 3,
  spaceBetween: 10,
  loop: true,
  freeMode: true,
  modules: [Autoplay, Pagination, Navigation],
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    },
    900: {
      slidesPerView: 2,
      spaceBetween: 0,
      slidesPerGroup: 2,
      autoplay: {
        delay: 15000,
        disableOnInteraction: false,
      },
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 0,
      slidesPerGroup: 3,
      autoplay: {
        delay: 15000,
        disableOnInteraction: false,
      },
    },
  },
};

export default featuredPrecannedSliderSettings;
