import { Alert, Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { checkTrustlyRegDepositStatusAsync, removeTrustlyRegPayload } from "../../../redux-store/auth-reducer";
import { StyledContainer } from "../@components/containers";
import Iconify from "../../iconify";

const TrustlyRegStatusPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // state selectors
  const auth = useSelector((state) => state.auth);
  const locale = useSelector((state) => state.i18n.locale);
  const regPayload = useSelector((state) => state.auth.trustlyRegPayloadCopy);
  const regStatus = useSelector((state) => state.auth.trustlyDepositStatus);
  const status = regStatus?.status;

  const handleDepositStatusCheck = () => {
    if (status !== "ok" && status !== "failed" && status !== "cancelled" && status !== "rejected" && status !== "voided") {
      dispatch(checkTrustlyRegDepositStatusAsync({ reference: regPayload.reference, token: regPayload.token, pathname: location.pathname, navigate }));
    }
  };

  const handleBacktoHome = () => {
    dispatch(removeTrustlyRegPayload());
    navigate(`/${locale}`);
  };

  useEffect(() => {
    let intervalId;
    if (regPayload?.token && regPayload?.reference && !auth.isSignedIn && status !== "ok") {
      intervalId = setInterval(handleDepositStatusCheck, 5000);
    }
    if (auth.isSignedIn) {
      navigate(`/${locale}/auth/request-limits`);
    }
    if (status === "init") {
      navigate("/sports");
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [regPayload?.token, status, auth.isSignedIn]);

  return (
    <Box py={3}>
      <StyledContainer>
        {(!status && typeof regStatus === "object") || status === "pending" || status === "waiting" ? (
          <Stack alignItems="center" gap={4} py={4} px={2}>
            <CircularProgress />
            <Typography variant="body1" color="text.secondary">
              Loading...
            </Typography>
          </Stack>
        ) : null}
        {typeof regStatus === "string" && (
          <Stack alignItems="center" gap={4} py={4} px={2}>
            {regStatus}
          </Stack>
        )}
        {status === "init" && (
          <Stack alignItems="center" gap={3} py={3} px={2}>
            <Typography variant="body2" color="warning">
              <Translate value="trustly.deposit_not_completed" />
            </Typography>
            <Stack direction="row" alignItems="center" gap={2}>
              <Link to={`/${locale}/sports?sportEventStatusSlug=all`}>
                <Button variant="contained" color="primary" onClick={handleBacktoHome}>
                  <Translate value="labels.cancel_operation" />
                </Button>
              </Link>
              <Button variant="contained" color="success" onClick={() => (window.location.href = regPayload?.redirectUrl)}>
                <Translate value="trustly.complete_deposit" />
              </Button>
            </Stack>
          </Stack>
        )}
        {status === "voided" || status === "cancelled" || status === "rejected" || status === "failed" ? (
          <Box py={4} px={2}>
            <Typography variant="body2" color="error" align="center">
              <Translate value="trustly.deposit_canceled" />
            </Typography>
          </Box>
        ) : null}
        {status === "ok" && (
          <Stack alignItems="center" gap={4} py={4} px={2}>
            <Iconify color="success.main" width={100} icon="icon-park-twotone:success" />
            <Typography variant="body2" align="center">
              <Translate value="labels.deposit_succeded" />
            </Typography>
          </Stack>
        )}
      </StyledContainer>
    </Box>
  );
};

export default TrustlyRegStatusPage;
