import { FormControl, FormHelperText, InputBase, Select, styled } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  height: 44,
  fontSize: 14,
  width: "100%",
  fontWeight: 500,
  padding: "0 0 0 1rem",
  borderRadius: "5px",
  background: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
  "&::placeholder": {
    color: theme.palette.grey[200],
  },
}));

const RHFSelectField = ({ name, helperText, type, textField, children, ...other }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <Select input={<StyledInputBase placeholder={other.label} />} labelId="select-input" id="select-input" {...field} {...other}>
            {children}
          </Select>
          <FormHelperText error={!!error} id="rhf-text-field">
            {error ? error?.message : helperText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default RHFSelectField;
