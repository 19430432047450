import { Box, Button, Paper, Stack, styled, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const StyledContainer = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.dbPaper,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    width: "60%",
    margin: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: "45%",
    margin: "auto",
  },
}));

const LimitsRequestPage = () => {
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  return (
    <Box py={3} px={2}>
      <StyledContainer>
        <Stack gap={2}>
          <Typography align="center" variant="h3">
            {translate.labels.resp_game}
          </Typography>
          <Typography variant="body1" color="text.secondary" align="center">
            {translate.labels.limits_infotext}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="center" gap={3} mt={3}>
          <Link to={`/${locale}/account/settings?interface=limits`}>
            <Button variant="contained" color="primary">
              {translate.selections.yes}
            </Button>
          </Link>
          <Link to={`/${locale}/sports?sportEventStatusSlug=all`}>
            <Button variant="contained" color="primary">
              {translate.selections.no}
            </Button>
          </Link>
        </Stack>
      </StyledContainer>
    </Box>
  );
};

export default LimitsRequestPage;
