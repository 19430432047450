import { LiveChatWidget } from "@livechat/widget-react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 1000,
  },
}));

const CustomLiveChatWidget = () => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const isChatOpen = useSelector((state) => state.settings.isLiveChatOpen);
  const authConfig = {
    customerName: "",
    customerEmail: auth.email,
  };

  if (!isChatOpen) return null;

  return (
    <div className={classes.container}>
      <LiveChatWidget license="14508951" visibility="maximized" {...(auth.isLoggedIn && authConfig)} />
    </div>
  );
};

export default CustomLiveChatWidget;
