import { useFormContext, Controller } from "react-hook-form";
// @mui
import { FormControl, FormHelperText, InputBase, styled } from "@mui/material";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  height: 39,
  fontSize: 14,
  width: "100%",
  fontWeight: 500,
  padding: "0 0 0 1rem",
  borderRadius: "5px",
  background: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
  "&::placeholder": {
    color: theme.palette.grey[200],
  },
}));

export default function RHFTextField({ name, helperText, type, textField, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <StyledInputBase
            {...field}
            type={type}
            value={type === "number" && field.value === 0 ? "" : field.value}
            onChange={(event) => {
              if (type === "number") {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            error={!!error}
            aria-describedby="rhf-text-field"
            {...other}
          />
          <FormHelperText error={!!error} id="rhf-text-field">
            {error ? error?.message : helperText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
