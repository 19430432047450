/**
 * TermsConditions - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Obfuscate from "react-obfuscate";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class TermsConditions extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM YLEISET KÄYTTÖEHDOT</h1>
            Suomenkielinen versio 1.4 Viimeisin päivitys 19.12.2024.<br></br>
            <br></br>
            <h3 id="General">1. YLEISET TIEDOT</h3>
            1.1. Nubet.com-verkkosivustoa (jäljempänä <b>"Verkkosivusto"</b>) ylläpitää Vana Lauri OÜ (jäljempänä <b>"Yritys"</b>, <b>"Vana Lauri"</b> tai <b>"Operaattori"</b>), joka on Virossa rekisteröity yritys, jonka
            yritysrekisteritunnus on 16427120 ja jonka rekisteröity osoite on Roseni 10-135, 10111 Tallinna, Viro.<br></br>
            <br></br>
            1.2. Vana Laurilla on Viron vero- ja tullihallituksen (<b>"EMTA"</b>) myöntämä toimilupa, ja sitä valvoo Viron vero- ja tullihallitus. Vana Laurilla on kaupallisten online-rahapelien lisenssit [HKT000066,
            HKT000067] urheiluvedonlyönnin ja kasinopelien tarjoamiseen Viron lain mukaisesti.<br></br>
            <br></br>
            1.3. Kaikki viittaukset peleihin Nubet.comin yleisissä käyttöehdoissa ("käyttöehdot") viittaavat vedonlyöntiin, kasinopeleihin ja muihin sivustolla saatavilla oleviin peleihin (jäljempänä yhdessä{" "}
            <b>"Pelit"</b> tai <b>"Palvelut"</b>). Näitä käyttöehtoja sovelletaan Verkkosivuston pelien käyttöön.<br></br>
            <br></br>
            1.4. Näihin ehtoihin sovelletaan Viron lakia, ja ne on esitetty Verkkosivustolla useammalla kuin yhdellä kielellä. Viron pelaajien osalta näiden ehtojen virallinen kieli on viro. Muiden kuin virolaisten pelaajien virallinen kieli näissä ehdoissa on englanti.<br></br>
            <br></br>
            1.5. Lakisääteisten velvoitteiden ja vastuiden muutosten, teknisten ja/tai liiketaloudellisten syiden vuoksi Yhtiö voi aika ajoin joutua muuttamaan näitä Ehtoja. Jos näihin Ehtoihin tehdään muutoksia,
            muutoksista ilmoitetaan aina erikseen verkkosivustolla.<br></br>
            <br></br>
            1.6. Pelaajille, jotka rekisteröityvät käyttämään Verkkosivuston palveluita (<b>"Rekisteröityneet pelaajat"</b> tai <b>"Rekisteröitynyt pelaaja"</b>), ilmoitetaan erikseen näihin Ehtoihin tehdyistä
            muutoksista, kun he kirjautuvat tililleen. Kirjautumisprosessin aikana näyttöön tulee ponnahdusikkuna, ja Rekisteröidyn pelaajan on hyväksyttävä muutokset rastittamalla ruutu "Hyväksyn täten päivitetyt
            käyttöehdot" jatkaakseen Verkkosivuston käyttöä.<br></br>
            <br></br>
            1.7. Kaikki muutokset näihin Ehtoihin ja edellytyksiin tulevat voimaan siitä hetkestä, kun ne ladataan ja julkaistaan Verkkosivustolla ja ne ovat yleisesti pelaajien nähtävissä Internetissä.<br></br>
            <br></br>
            1.8. Jos rekisteröitynyt pelaaja käyttää pelejä, jotka koostuvat erilaisista urheiluvedonlyöntipeleistä, kasinopeleistä ja muista Verkkosivustolla tarjottavista pelituotteista, sen jälkeen kun muutokset on
            julkaistu, tämä tulkitaan päivitettyjen ehtojen hyväksymiseksi.<br></br>
            <br></br>
            1.9. Jos Rekisteröitynyt pelaaja ei hyväksy Ehtoihin tehtyjä muutoksia, Rekisteröidyn pelaajan tulee välittömästi lopettaa Palveluiden käyttö. Jos Rekisteröitynyt pelaaja ei vahvista muutoksia rastittamalla
            edellä mainittua ruutua, Rekisteröitynyt pelaaja ei ole oikeutettu jatkamaan Palveluiden käyttöä.<br></br>
            <br></br>
            1.10. Verkkosivustolla julkaistu sisältö on tekijänoikeuslain alaista. Kaikki sivulta löytyvä materiaali on tarkoitettu vain ei-kaupalliseen henkilökohtaiseen käyttöön. Palveluiden käyttö ei anna
            Rekisteröityneelle Pelaajalle mitään immateriaalioikeuksia Sivustolla saatavilla olevaan sisältöön. Sivustolla olevien tietojen, logojen ja kuvien kopiointi ja käyttö on kielletty.<br></br>
            <br></br>
            1.11. Yhtiö pidättää oikeuden rajoittaa Verkkosivuston käyttöä milloin tahansa ilman selitystä, jos on viitteitä siitä, että asiakas on rikkonut näitä määräyksiä.<br></br>
            <br></br>
            <h3 id="Parties">2. SOPIMUKSEN OSAPUOLET</h3>
            2.1 Operaattori avaa kullekin rekisteröidylle pelaajalle henkilökohtaisen tilin (<b>"Pelitili"</b> tai <b>”Pelaajan tili”</b>) maksutta.<br></br>
            <br></br>
            2.2 Pelaajan tili on tarkoitettu Rekisteröidyn pelaajan pelikulujen ja/tai vedonlyönnin hallinnointiin sekä Operaattorin suorittamaan voittojen palautukseen ja maksuun.<br></br>
            <br></br>
            2.3 Operaattori hyväksyy Rekisteröityneiksi Pelaajiksi vain luonnolliset henkilöt, jotka ovat yli sen ikärajan, joka on vähimmäisikäraja, joka vaaditaan tarjottujen pelien lailliseen pelaamiseen.<br></br>
            <br></br>
            2.4 Rekisteröitynyttä pelaajaa ei voi rekisteröidä yrityksen nimiin.<br></br>
            <br></br>
            2.5 Operaattori tarkistaa aina, että rekisteröitynyt pelaaja on saavuttanut laillisen vähimmäisikärajan, joka vaaditaan verkkosivustolla pelaamiseen. Tarkastus suoritetaan sen varmistamiseksi, että alaikäiset
            eivät voi pelata. Ikäraja voi vaihdella maittain. Tyypillisesti ikärajaksi on asetettu 18 vuotta, mutta esimerkiksi Virossa rahapelien pelaamisen ikäraja on 21 vuotta. Virossa oleskeleva alle 21-vuotias
            henkilö ei saa pelata uhkapeliä, etäuhkapelinä järjestettyä uhkapeliä tai etäuhkapelinä järjestettyä taitopeliä.<br></br>
            <br></br>
            2.6 Operaattori voi evätä pelaajan rekisteröinnin tai estää Rekisteröitynyttä pelaajaa pelaamasta enää, jos todentaminen tai lisätodennuspyyntö ei ole riittävä. Jos rekisteröitynyt pelaaja ei noudata
            tällaista pyyntöä kohtuullisessa ajassa, pelaajan tili voidaan sulkea.<br></br>
            <br></br>
            2.7 Rekisteröitynyt pelaaja ymmärtää, että joillakin lainkäyttöalueilla nettipelaaminen voi olla laitonta. Jos epäiltyä laitonta tai petollista toimintaa havaitaan VPN- tai välityspalvelun käytön aikana,
            Yhtiöllä on oikeus estää kyseinen tili ja pidättää varat. Yhtiö ei aio tarjota Rekisteröidyille pelaajille palveluja, jotka voisivat olla ristiriidassa Rekisteröidyn pelaajan lainkäyttöalueen lainsäädännön
            kanssa. Rekisteröitynyt pelaaja käyttää palveluita oman valintansa mukaan. Rekisteröitynyt pelaaja vahvistaa, takaa ja hyväksyy, että palveluiden käyttö on hänen lainkäyttöalueensa lakien mukaista. Yhtiö ei
            ole vastuussa rekisteröidyn pelaajan harjoittamasta Palveluiden laittomasta käytöstä tai luvattomasta käytöstä.<br></br>
            <br></br>
            2.8 Virossa toimiluvan saanut nettikasino-operaattori ei saa hyväksyä asiakkaita seuraavista maista: Afganistan, Albania, Algeria, Amerikan Samoa, Angola, Anguilla, Antigua & Barbuda, Argentiina, Armenia,
            Aruba, Australia, Azerbaidžan, Bahama, Bangladesh, Barbados, Belgia, Belize, Benin, Bermuda, Bhutan, Bonaire (Sint Eustatius ja Saba), Bosnia ja Hertsegovina, Botswana, Bouvet-saari, Brittiläinen Intian
            valtameren alue, Bulgaria, Burkina Faso, Burundi, Kambodža, Kamerun, Kap Verde, Caymansaaret, Keski-Afrikan tasavalta, Tšad, Joulusaari, Kookossaaret, Kolumbia, Komorit, Kongo (demokraattinen tasavalta),
            Cookinsaaret, Costa Rica, Costa Rica, Norsunluurannikko, Kroatia, Kuuba, Curacao, Kypros, Tšekin tasavalta, Pohjois-Korea, Tanska, Djibouti, Dominica, Dominikaaninen tasavalta, Ecuador, Egypti, El Salvador,
            Päiväntasaajan Guinea, Eritrea, Etiopia, Falklandinsaaret, Färsaaret, Fidži, Ranska, Guayana, Ranskan Polynesia, Tšekin tasavalta, Ranskan eteläiset alueet, Gabon, Gambia, Ghana, Gibraltar, Kreikka,
            Grönlanti, Grenada, Guadeloupe, Guam, Guatemala, Guinea, Guinea-Bissau, Guyana, Haiti, Heard, Pyhä istuin (Vatikaanivaltio), Honduras, Hongkong, Unkari, Iran, Irak, Israel, Italia, Jamaika, Jordania,
            Kazakstan, Kuwait, Kirgizstan, Laos, Latvia, Lesotho, Liberia, Libya, Liettua, Madagaskar, Malawi, Malesia, Malediivit, Mali, Marshallinsaaret, Martinique, Mauritania, Mauritius, Mayotte, Meksiko, Mikronesia,
            Montenegro, Montserrat, Mosambik, Myanmar, Namibia, Nauru, Nepal, Alankomaat, Alankomaiden Antillit, Uusi-Kaledonia, Nicaragua, Niger, Nigeria, Niue, Norfolkin saari, Pohjois-Korea, Pohjois-Mariaanit, Oman,
            Pakistan, Puerto Rico, Réunion, Romania, Ruanda, Saint-Barthelemy, Saint-Helena, Saint-Helena-saaret, Ylösnousemus- ja Tristan da Cunha-saaret, Saint-Kitts ja Nevis, Saint-Lucia, Saint-Mart Ranskan osa,
            Saint-Pierre ja Miquelon, Saint Vincent ja Grenadiinit, Samoa, San Marino, Sao Tome ja Principe, Senegal, Seychellit, Sierra Leone, Singapore, Sint Maarten, Slovakia, Slovenia, Salomonsaaret, Somalia,
            Etelä-Georgia ja eteläiset Sandwichsaaret, Etelä-Sudan, Espanja, Sri Lanka, Palestiinan valtio, Sudan, Surinam, Huippuvuoret ja Jan Mayen, Swazimaa, Sveitsi, Syyria, Taiwan, Tadžikistan, Tansania, Färsaaret,
            Itä-Timor, Togo, Tokelau, Tonga, Trinidad ja Tobago, Tunisia, Turkki, Turkmenistan, Turks- ja Caicossaaret , Tuvalu, Valko-Venäjä, U. Yhdysvaltain Neitsytsaaret, Uganda, Yhdistynyt kuningaskunta, Yhdysvaltain
            pienet syrjäiset saaret, Yhdysvallat ja kaikki sen tukikohdat, sotilastukikohdat ja alueet, Uzbekistan, Vanuatu, Venezuela, Brittiläiset Neitsytsaaret, Wallis ja Futuna, Länsi-Sahara, Jemen, Antarktis,
            Makedonia, Moldova, Mongolia, Kenia, Kiribati ja Libanon.<br></br>
            <br></br>
            2.9 Jos rekisteröitynyt pelaaja rikkoo edellä lueteltua maakohtaista sääntöä, operaattori pidättää itsellään oikeuden sulkea rekisteröidyn pelaajan pelitilin välittömästi ja pidättää kaikki rahansiirrot
            pelitililtä ja pelitilille.<br></br>
            <br></br>
            2.10 Yhtiön rahanpesun vastaisten vaatimusten ja asiaankuuluvien vastuullisen pelaamisen velvoitteiden mukaisesti Yhtiö voi myös sulkea tai jäädyttää pelitilin.<br></br>
            <br></br>
            2.11 Kullakin rekisteröidyllä pelaajalla voi olla vain yksi pelitili. Tämän säännön rikkominen voi johtaa kaikkien rekisteröidyn pelaajan rekisteröimien pelitilien sulkemiseen, ja Yhtiö maksaa takaisin vain
            sen, mitä rekisteröidyn pelaajan tekemistä talletuksista on jäljellä.<br></br>
            <br></br>
            2.12 Operaattori pidättää oikeuden kieltäytyä rekisteröinnistä ilman selitystä.<br></br>
            <br></br>
            <h3 id="Playeraccount">3. PELITILIN KÄYTTÖ JA PELAAJAN VASTUU </h3>
            3.1. Hyväksymällä nämä säännöt ja ehdot rekisteröitynyt pelaaja vahvistaa täten, että hän ei toimi toisen henkilön nimissä.<br></br>
            <br></br>
            3.2. Kumpikin tämän sopimuksen osapuoli voi sulkea pelitilin välittömästi, väliaikaisesti tai pysyvästi, jos toinen osapuoli ei täytä näiden ehtojen mukaisia velvoitteitaan. Jäljellä olevan luoton maksamiseen
            sovelletaan näiden ehtojen määräyksiä. Pelitilin väliaikainen tai pysyvä sulkeminen ei vapauta osapuolia laillisista oikeuksistaan ja velvollisuuksistaan.<br></br>
            <br></br>
            3.3. Rekisteröitynyt pelaaja hyväksyy täten myös sen, että hänellä ei ole oikeutta sallia kolmansien osapuolien, olipa kyseessä sitten vahingossa tai tahallisesti rekisteröitynyt alaikäinen tai muu henkilö,
            kirjautua pelitililleen. Yhtiö ei ole vastuussa siitä, että Rekisteröitynyt pelaaja ei noudata tätä määräystä, mukaan lukien huolimattomuus ja/tai tahallinen laiminlyönti. Asiakastilille pääsy on mahdollista
            vain käyttäjätunnuksella ja salasanalla, ja Rekisteröitynyt pelaaja on vastuussa siitä, että ne ovat vain rekisteröidyn pelaajan tiedossa ja että niitä ei esimerkiksi tallenneta laitteelle, johon muilla on
            pääsy, tai kirjoiteta muistiin jonnekin, josta ne voivat päätyä toisen henkilön haltuun.<br></br>
            <br></br>
            3.4. Yhtiö voi pyytää Rekisteröitynyttä pelaajaa vaihtamaan salasanansa tai keskeyttää tilapäisesti Rekisteröidyn pelaajan pelitilin, jos on syytä epäillä, että Yhtiön tietoturvapolitiikkaa on rikottu tai
            että pelitilin turvallisuus on vaarantunut.<br></br>
            <br></br>
            3.5. Jollei pakottavista määräyksistä muuta johdu, ja lukuun ottamatta tapauksia, joissa Yhtiö on syyllistynyt törkeään huolimattomuuteen, tahalliseen väärinkäytökseen, petokseen tai vilpilliseen toimintaan,
            Yhtiön vastuu mistään vahingoista (suorista tai epäsuorista) tai menetyksistä, jotka johtuvat Rekisteröidyn pelaajan Palveluiden käytöstä, on suljettu pois.<br></br>
            <br></br>
            3.6. Varoja saa siirtää ja tallettaa vain samalta tililtä ja samalle tilille, joka ilmoitettiin rekisteröinti- ja vahvistusprosessin aikana. Digitaalisten apuvälineiden, kuten robottien, käyttö on kielletty.
            <br></br>
            <br></br>
            3.7. Rekisteröitynyt pelaaja ei saa siirtää varoja kolmannen osapuolen tilin kautta.<br></br>
            <br></br>
            3.8. Yhtiö ja sen yhteistyökumppanit, kuten tytäryhtiöt, eivät ole koskaan vastuussa Rekisteröidyn pelaajan Palveluissa tekemien tappioiden korvaamisesta. Yhtiö tarjoaa uhkapelejä, joissa tappioriski on
            kokonaan Rekisteröidyn pelaajan vastuulla, kun hän päättää pelata verkkosivustolla tarjottuja uhkapelejä.<br></br>
            <br></br>
            3.9. Yhtiö ei tee automaattisia ilmoituksia Rekisteröityjen pelaajien voitoista veroviranomaisille. Jos Rekisteröitynyt pelaaja on velvollinen ilmoittamaan voittonsa veroviranomaiselle, on Rekisteröityneen
            pelaajan yksinomaisella vastuulla noudattaa tällaista velvollisuutta.<br></br>
            <br></br>
            3.10. Yhtiö pitää kaikki olennaiset tiedot pelistä, mukaan lukien sen säännöt ja voiton todennäköisyys, helposti rekisteröityjen pelaajien saatavilla.<br></br>
            <br></br>
            3.11. Yhtiö pidättää oikeuden kieltäytyä vedoista tai panoksista milloin tahansa ilman selitystä.<br></br>
            <br></br>
            3.12. Jos rekisteröidyllä pelaajalla on kysyttävää, hän voi milloin tahansa ottaa yhteyttä operaattorin asiakaspalvelutiimiin osoitteessa <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate>.
            <br></br>
            <br></br>
            3.13. Yhtiö pidättää oikeuden poistaa tai muuttaa mitä tahansa rekisteröidylle pelaajalle tarjottua palvelua tai verkkosivustoaan milloin tahansa ja ilman ennakkoilmoitusta sekä lisätä tai poistaa tarjouksia
            verkkosivustoltaan.<br></br>
            <br></br>
            <h3 id="Harmful">4. HAITALLINEN TOIMINTA</h3>
            4.1. Yhtiö on velvollinen valvomaan rahanpesua ja muuta rikollisuuteen liittyvää rahoitusta. Rekisteröityneet pelaajat ovat velvollisia avustamaan operaattoria kaikissa heitä koskevissa kyselyissä ja
            tutkimuksissa.<br></br>
            <br></br>
            4.2. Jos havaitset epäilyttävää toimintaa verkkosivustolla, sinun on ilmoitettava siitä välittömästi operaattorille.<br></br>
            <br></br>
            <h3 id="Transactions">5. RAHALIIKENNE</h3>
            5.1. Yhtiö hyväksyy talletuksia euroina (EUR).<br></br>
            <br></br>
            5.2. Rekisteröitynyt pelaaja saa tiliotteen kaikesta rahaliikenteestä, kun hän on kirjautunut verkkosivustolle.<br></br>
            <br></br>
            5.3. Rekisteröidyn pelaajan on mahdollisuuksien mukaan käytettävä kotiutuksiin samaa maksutiliä, jolla hän on tehnyt talletuksen.<br></br>
            <br></br>
            5.4. Rekisteröitynyt pelaaja ei ole oikeutettu saamaan korkoa pelitilillään oleville varoille riippumatta siitä, kuinka kauan hän pitää rahaa pelitilillään.<br></br>
            <br></br>
            5.5. Yhtiö varmistaa pakollisten vaatimusten mukaisesti, että vedonlyöntiä ja maksuja, jotka on siirretty yhtiön tilille vedonlyöntiä varten, hyväksytään vain saman rekisteröidyn pelaajan selvitystililtä tai
            saman toiminnanharjoittajan pelipaikassa olevalta rekisteröidyltä pelaajalta, ja; jakojen tekeminen vain samalle selvitystilille, jolta rekisteröity pelaaja on siirtänyt maksun rahapelioperaattorin tilille
            vedonlyöntiä varten rahapeleissä.<br></br>
            <br></br>
            5.6. Operaattori hyvittää talletetut varat pelitilille, kun operaattori on saanut maksupalveluntarjoajalta/luottokorttiyhtiöltä/pankilta luvan siihen, että rekisteröity pelaaja on tallettanut varat.<br></br>
            <br></br>
            5.7. Yhtiön periaatteena on hyvittää voitot rekisteröidyn pelaajan pelitilille lähes välittömästi. Jos epäillään rikollista toimintaa tai muuta luvatonta toimintaa, Yhtiö pidättää oikeuden keskeyttää
            voittojen hyvittämisen tutkinnan tulokseen asti.<br></br>
            <br></br>
            5.8. Maksuaika voi vaihdella muutamasta minuutista 24 tuntiin sen jälkeen, kun nostopyyntö on käsitelty.<br></br>
            <br></br>
            5.9. Yli 20 000 euron suuruisista voitoista voidaan neuvotella erikseen kohtuullinen maksuaikataulu rekisteröidyn pelaajan kanssa. Tällaisena voidaan pitää esimerkiksi 100 000 euron maksua 20 000 euron osissa
            viiden päivän aikana.<br></br>
            <br></br>
            5.10. Maksimi voittosumma per vedonlyöntilippu on 100 000 euroa.<br></br>
            <br></br>
            5.11. Rekisteröityä pelaajaa kohden voidaan voittaa yhteensä enintään 200 000 euroa viikossa (maanantai 00:00 EET - sunnuntai 23:59 EET).<br></br>
            <br></br>
            5.12. Kaikkiin vetovalintoihin sovelletaan ennalta asetettuja rajoja, jotka voivat olla edellä mainittuja rajoja alhaisempia.<br></br>
            <br></br>
            5.13. Yhtiö ei veloita rekisteröityjä pelaajiaan rahansiirroista. Rekisteröidyn pelaajan vastuulla on selvittää, periikö hänen käyttämänsä palveluntarjoaja häneltä rahansiirtojen yhteydessä siirtomaksun.
            <br></br>
            <br></br>
            5.14. Yhtiö vaatii talletuksen kierrättämistä vähintään yhden kerran (talletussumma pitää riskeerata aikankin kerran) ja voi hylätä nostot, jos tätä ei ole tehty.<br></br>
            <br></br>
            5.15. Rekisteröidyn pelaajan varoja säilytetään erillisellä tilillä yhtiön omista varoista, ja näin ollen pelitilillä olevat varat ovat suojattuja, jos yhtiö tulee maksukyvyttömäksi.<br></br>
            <br></br>
            5.16. Yhtiöllä on oikeus keskeyttää rekisteröidyn pelaajan talletukset ja kotiutukset, jos pelitilin käyttö edellyttää rekisteröidyn pelaajan henkilöllisyyden, iän tai muiden tietojen todentamista (
            <b>"Tarkastukset"</b>).<br></br>
            <br></br>
            5.17. Yhtiö päättää itse, milloin tarkastukset suoritetaan.<br></br>
            <br></br>
            5.18. Rahanpesun vastaisten vaatimusten mukaisesti Yhtiö on velvollinen varmistamaan, että rekisteröity pelaaja todistaa ikänsä, henkilöllisyytensä, henkilötunnuksensa (sosiaaliturvatunnuksensa), osoitteensa
            ja varojensa alkuperän.<br></br>
            <br></br>
            5.19. Yhtiö pidättää oikeuden tehdä tarkistuksia AML-käytännön mukaisesti i) liikesuhteen perustamisen yhteydessä; ii) voitonmaksun, vedonlyönnin tai molempien yhteydessä, kun rekisteröidyn pelaajan antama tai saama summa on
            vähintään 2 000 euroa tai vastaava summa muussa valuutassa, riippumatta siitä, suoritetaanko rahallinen velvoite kertamaksuna vai useina toisiinsa liittyvinä maksuina enintään yhden kuukauden aikana; iii)
            tarkistettaessa tietoja, jotka on kerätty due diligence -toimenpiteiden soveltamisen yhteydessä, tai epäiltäessä aiemmin kerättyjen asiakirjojen tai tietojen riittävyyttä tai totuudenmukaisuutta
            päivitettäessä asiaankuuluvia tietoja, ja iv) epäiltäessä rahanpesua tai terrorismin rahoittamista.<br></br>
            <br></br>
            5.20. Jos tarkistus vaatii yhteydenottoa rekisteröityyn pelaajaan ja lisäselvityksiä, pelitili voidaan jäädyttää tarkistuksen ajaksi. Yleisin syy lisätarkistuspyyntöön ja AML-lisätoimenpiteisiin on
            poikkeuksellisen suuri pelimäärä.<br></br>
            <br></br>
            5.21. Henkilöstä voi tulla rekisteröity pelaaja vain, jos hän on käyttämiensä varojen laillinen omistaja, eivätkä varat ole peräisin rikollisesta toiminnasta.<br></br>
            <br></br>
            5.22. Yhtiö käsittelee peruuttamispyynnöt yhdestä kuuteen (1-6) työpäivän kuluessa pyynnön vastaanottamisesta. On mahdollista, että määräaikaa pidennetään esimerkiksi KYC-menettelyjen tai nostopyynnön
            lisätarkastuksen tai muun tarvittavan tarkastuksen vuoksi ennen nostopyynnön käsittelyä.<br></br>
            <br></br>
            5.23. Pelatessaan peliä rekisteröidyn pelaajan on tiedostettava, että tuotteet kulutetaan välittömästi, eikä yhtiö voi palauttaa, peruuttaa tai hyvittää tuotteita pelin aikana tai sen jälkeen.<br></br>
            <br></br>
            5.24. Varojen siirtäminen rekisteröidyn pelaajan pelitililtä toiselle henkilön pelitilille on kielletty.<br></br>
            <br></br>
            Yhtiö pidättää oikeuden keskeyttää kotiutukset, takavarikoida pelitilin saldon, mitätöidä vedot, rajoittaa rekisteröidyn pelaajan pelitiliä, jos Yhtiöllä on perusteltu syy epäillä, että:<br></br>
            <br></br>
            Rekisteröity pelaaja on osallisena petoksessa; ottelun/tapahtuman integriteetti on vaarantunut; rekisteröity pelaaja on osallisena ottelumanipunoinnissa; rekisteröity pelaaja on ollut osallisena sellaisen
            teknisen järjestelmän käytössä, jonka tarkoituksena on manipuloida tai vaikuttaa tuotteen (pelin, pelikierroksen, vedon) lopputulokseen; rekisteröity pelaaja toimii osana ryhmää, joka asettaa yhdessä sarjan
            vetoja saadakseen taloudellista hyötyä; muu laiton tai rikollinen toiminta; tai toiminta, joka on vastoin näitä käyttöehtoja.<br></br>
            <br></br>
            5.26. Kaikesta petollisesta, rikollisesta tai epäilyttävästä toiminnasta ilmoitetaan asianomaisille viranomaisille.<br></br>
            <br></br>
            5.26. Veto vahvistetaan vedonlyöntikupongin tunnisteella. Kupongin tunniste on saatavilla kirjautumalla sisään rekisteröidyn pelaajan pelitilille, valikosta "Oma profiili" kohdasta "Tiliote".<br></br>
            <br></br>
            5.27. Pelatut kasinopelit, pelikierrokset ja asetetut panokset ovat saatavilla kirjautumalla sisään rekisteröidyn pelaajan pelitilille "Oma profiili" -valikon "Tiliote" -kohdassa.<br></br>
            <br></br>
            <h3 id="Duty">6. HUOLELENPITOVELVOITE, PELAAMISEN RAJOITTAMINEN JA PELIRAJAT</h3>
            6.1. Rekisteröityneet pelaajat voivat rajoittaa pelaamistaan tai sulkea pelitilinsä itsenäisesti käyttämällä pelitilillään tarjottuja rajoitustyökaluja tai ottamalla yhteyttä Yhtiöön sähköpostin välityksellä.
            Yhtiö tarjoaa rekisteröidyille pelaajilleen pelirajoja, rajoituksia ja peliesto-mahdollisuuden voimassa olevien säännösten mukaisesti.<br></br>
            <br></br>
            6.2. Rekisteröityneet pelaajat voivat milloin tahansa sulkea itsensä pois kaikista peleistä, jotka edellyttävät rekisteröitymistä rahapeliyhtiöihin, joilla on EMTA:n rekisterin myöntämä lisenssi rahapelien
            järjestämiseen Virossa. Rekisteröitynyt pelaaja voi hakea itsensä poissulkemista osoitteessa https://www.emta.ee/en/private-client/e-services-tax-literacy/registers-inquiries/setting-restrictions-gambling.
            <br></br>
            <br></br>
            6.3. Rahapeliviranomainen pitää rekisteriä pelaajista, jotka ovat asettaneet itselleen pelieston, ja Yhtiö sulkee rekisterissä olevat pelaajat rahapelitarjontansa ulkopuolelle. Tämän seurauksena on
            mahdollista, että Vana Lauri evää pelaajan rekisteröitymisen tai ei anna Rekisteröityjen pelaajien enää pelata.<br></br>
            <br></br>
            6.4. Rekisteröityjen pelaajien on aktiivisesti määriteltävä ja vahvistettava panoksensa pelaamisen yhteydessä.<br></br>
            <br></br>
            6.5. Yhtiö tai mikään Yhtiön puolesta työskentelevä toimija ei tarjoa tai anna luottoa rahapelipanoksista missään olosuhteissa<br></br>
            <br></br>
            6.6. Rekisteröityneet pelaajat voivat asettaa erilaisia rajoja pelitililleen.<br></br>
            <br></br>
            6.7. Rekisteröitymisen jälkeen verkkosivusto tarjoaa mahdollisuuden asettaa tappiorajoja. Rajoitukset löytyvät myös sivuston valikosta Asetukset - Pelirajat -välilehdeltä.<br></br>
            <br></br>
            <h3 id="Disputes">7. ERIMIELISYYDET</h3>
            7.1. Jos rekisteröidyllä pelaajalla on valituksia tai hän haluaa esittää Yhtiötä vastaan Palveluihin liittyvän vaatimuksen, rekisteröity pelaaja voi esittää tällaisen vaatimuksen millä tahansa tavalla, missä
            tahansa muodossa ja/tai muodossa. Suosittelemme kuitenkin, että tällaiset asiat toimitetaan chatin kautta tai sähköpostitse osoitteeseen support@nubet.com. Yhtiö pyrkii käsittelemään valitukset mahdollisimman
            nopeasti, joka tapauksessa 15 päivän kuluessa.<br></br>
            <br></br>
            7.2. Jos verkkosivustolla saatavilla olevissa peleissä on ohjelmistovirheitä, Yhtiö voi harkita arvontojen peruuttamista siltä osin kuin virhe on vaikuttanut tuloksiin. Kaikki manipulointiyritykset,
            onnistuneet tai epäonnistuneet, keskeyttävät rekisteröidyn pelaajan pelaamisen ja johtavat todennäköisesti oikeustoimiin.<br></br>
            <br></br>
            7.3. Jos Palvelut keskeytyvät tilapäisesti, kaikki Palveluihin tilapäisen keskeytyksen aikana asetetut vedot, panokset ja muut panokset palautetaan täysimääräisesti Rekisteröidyille Pelaajille. Jos
            Rekisteröitynyt pelaaja keskeyttää pelaamisen, keskeneräinen peli jatkuu, kun Rekisteröitynyt pelaaja palaa pelaamaan.<br></br>
            <br></br>
            7.4. Jos et pääse yhteisymmärrykseen kanssamme tai et ole tyytyväinen asiasi ratkaisuun, voit kääntyä vaihtoehtoisen riidanratkaisuelimen ("ADR") puoleen. Yhtiö suosittelee seuraavia vaihtoehtoisia
            riidanratkaisuelimiä: Euroopan verkkovälitteinen riidanratkaisu ("ODR"), joka on foorumi, jonka tarjoaa Euroopan komission tarjoama riidanratkaisujärjestelmä. Yksityiskohtaiset tiedot ja pääsy Euroopan
            komission tarjoamaan ODR-foorumiin löytyvät täältä: https://ec.europa.eu/consumers/odr/main/?event=main.complaints.screeningphase.<br></br>
            <br></br>
            <h3 id="Limitation">8. VASTUUN RAJOITTAMINEN</h3>
            8.1. Lukuun ottamatta tapauksia, joissa on kyse törkeästä huolimattomuudesta tai tahallisesta väärinkäytöksestä tai pakollisista säännöistä johtuvista syistä, Yhtiö ei ole vastuussa ohjelmistoon liittyvistä
            ongelmista ja virheistä, jotka ovat estäneet rekisteröitynyttä pelaajaa pääsemästä verkkosivustolle tai keskeyttäneet pelaamisen. Viestintävirheen sattuessa, jos asetettua vetoa ei vastaanoteta verkkosivuston
            palvelimilla, sitä ei huomioida, eikä vedon määrää myöskään vähennetä pelitililtä. Jos ohjelmistossa tai satunnaislukugeneraattorissa on jokin muu virhe, panos mitätöidään ja panoksen määrä palautetaan
            rekisteröidylle pelaajalle.<br></br>
            <br></br>
            8.2. Jos Yhtiö maksaa virheellisesti voitot rekisteröidylle pelaajalle virheen vuoksi, rahojen katsotaan edelleen kuuluvan Yhtiölle, ja näin ollen ne vähennetään Pelaajan tililtä. Jos Rekisteröitynyt pelaaja
            on jo nostanut rahat, Rekisteröidyn pelaajan on ilmoitettava asiasta välittömästi operaattorin asiakaspalveluun asian selvittämiseksi ja jatkoseurausten välttämiseksi.<br></br>
            <br></br>
            8.3. Yhtiö ja sen työntekijät ja muu Yhtiöön liittyvä henkilöstö eivät takaa, että Yhtiön ohjelmisto toimii aina moitteettomasti.<br></br>
            <br></br>
            8.4. Rekisteröitynyt pelaaja vahvistaa olevansa henkilö, joka on saavuttanut laillisen vähimmäisikärajan verkkosivustolla tapahtuvaan rahapelitoimintaan ja joka on täysin oikeustoimikelpoinen sen maan
            mukaisesti, jossa hän asuu tai elää pysyvästi. Lisäksi Rekisteröitynyt pelaaja ymmärtää tekojensa merkityksen, hyväksyy, että palveluiden käytön valinta on yksinomaan Rekisteröidyn pelaajan henkilökohtainen
            valinta ja ymmärtää, että Rekisteröitynyt pelaaja hallinnoi pelitiliinsä sisältyviä varoja.<br></br>
            <br></br>
            8.5. Yhtiö ei ole vastuussa sellaisten Internet-sivustojen sisällöstä, joihin pääsee verkkosivuston kautta.<br></br>
            <br></br>
            8.6. Näissä käyttöehdoissa olevia vastuun rajoituksia ja poissulkemisia ei sovelleta siltä osin kuin tällaisia rajoituksia tai poissulkemisia ei sovelleta sovellettavan lain mukaan.<br></br>
            <br></br>
            <h3 id="Protection">9. TIETOSUOJA</h3>
            9.1. Yhtiö arvostaa rekisteröityjen pelaajien henkilötietojen koskemattomuutta ja luottamuksellisuutta, kuten Nubet.comin tietosuojakäytännössä ("Tietosuojakäytäntö") on määritelty. Yhtiö kerää, käsittelee ja
            jakaa vain sellaisia tietoja, jotka ovat välttämättömiä Palveluiden tarjoamiseksi. Yhtiö käsittelee rekisteröidyn pelaajan antamia henkilötietoja tiukasti Tietosuojakäytännönsä ja sovellettavien
            tietosuojalakien ja -määräysten mukaisesti.<br></br>
            <br></br>
            9.2. Hyväksymällä Yhtiön tietosuojakäytännön ja antamalla Yhtiölle henkilötietonsa Rekisteröitynyt pelaaja hyväksyy henkilötietojensa käsittelyn tietosuojakäytännössä kuvattuihin tarkoituksiin.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(TermsConditions));
