import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { checkTrustlyLoginStatusAsync } from "../../../redux-store/auth-reducer";

const TrustlyLoginStatusPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locale = useSelector((state) => state.i18n.locale);
  const loginPayload = useSelector((state) => state.auth.trustlyLoginPayload);
  const loginStatus = useSelector((state) => state.auth.trustlyLoginStatus);
  const status = loginStatus?.payload?.status;

  const handleCheckLoginStatus = () => {
    dispatch(checkTrustlyLoginStatusAsync(loginPayload?.token));
  };

  useEffect(() => {
    let intervalId;
    if (loginPayload?.token && status !== "success") {
      intervalId = setInterval(handleCheckLoginStatus, 2000);
    }
    if (status === "success") {
      navigate(`/${locale}/auth/autoLogin`);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [loginPayload?.token, status]);

  return (
    <Box>
      {!status || status === "waiting" ? (
        <Stack alignItems="center" gap={4} py={4} px={2}>
          <CircularProgress />
          <Typography variant="body1" color="text.secondary">
            Loading...
          </Typography>
        </Stack>
      ) : null}
      {status === "init" || status === "pending" ? (
        <Stack alignItems="center" gap={3} py={3} px={2}>
          <Typography variant="body2" color="error" align="center">
            <Translate value="labels.login_failed" />
          </Typography>
        </Stack>
      ) : null}
      {status === "voided" || status === "cancelled" || status === "rejected" || status === "failed" ? (
        <Box py={4} px={2}>
          <Typography variant="body2" color="error" align="center">
            <Translate value="labels.login_failed" />
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default TrustlyLoginStatusPage;
