import { Box, Grid2 } from "@mui/material";
import Slip from "../slip";
import { useSelector } from "react-redux";
import TicketsList from "../../user/tickets/TicketsList";
import MobileSlip from "../slip/MobileSlip";
import { useLocation } from "react-router-dom";

const SportsLayout = ({ children }) => {
  const location = useLocation();
  const showMyTicketsPanel = useSelector((state) => state.settings.showMyTicketsPanel);
  const auth = useSelector((state) => state.auth);
  const layout = useSelector((state) => state.settings.layout);
  const slip = useSelector((state) => state.slips[0]);
  const bets = Object.values(slip.settings.bets);
  return (
    <Box px={{ xs: 0, md: 2 }}>
      <Grid2 container spacing={1}>
        <Grid2 item size={layout === "mouse" ? 9 : 12}>
          {children}
        </Grid2>
        {layout === "mouse" && (
          <Grid2 item size={3}>
            {showMyTicketsPanel && auth.isSignedIn ? <TicketsList /> : <Slip />}
          </Grid2>
        )}
      </Grid2>
      {!location.pathname.includes("/betbuilder/") || bets.length ? <MobileSlip /> : null}
    </Box>
  );
};

export default SportsLayout;
