import React, { useState } from "react";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputBase, styled } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Iconify from "../iconify";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  height: 44,
  fontSize: 14,
  width: "100%",
  fontWeight: 500,
  padding: "0 0.5rem 0 1rem",
  borderRadius: "5px",
  background: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
  "& ::placeholder": {
    color: theme.palette.grey[200],
  },
}));

const RHFPasswordField = ({ name, helperText, type, ...other }) => {
  const [visibility, setVisibility] = useState(false);

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <StyledInputBase
            {...field}
            type={visibility ? "string" : "password"}
            value={type === "number" && field.value === 0 ? "" : field.value}
            onChange={(event) => {
              if (type === "number") {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            error={!!error}
            aria-describedby="rhf-editable-field"
            endAdornment={
              <InputAdornment>
                <IconButton onClick={handleVisibility}>
                  <Iconify icon={visibility ? "iconamoon:eye" : "iconamoon:eye-off-thin"} />
                </IconButton>
              </InputAdornment>
            }
            {...other}
          />
          <FormHelperText error={!!error} id="rhf-editable-field">
            {error ? error?.message : helperText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default RHFPasswordField;
