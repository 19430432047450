import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { getWPContent, getWPNewsContent } from "../../actions";
import fi from "date-fns/locale/fi";
import enAU from "date-fns/locale/en-AU";
import et from "date-fns/locale/et";
import ReactHtmlParser from "react-html-parser";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { newsConfigs } from "../../config/newsConfigs";
import withRouter from "../../helpers/withRouter";

const locales = {
  fi: fi,
  et: et,
  en: enAU,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const styles = (theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontSize: "1.1em !important",
    },
    "& p": {
      margin: "1em",
      lineHeight: "1.2",
    },
    "& ol": {
      margin: "1em 1em 1em 0",
      lineHeight: "1.2",
    },
    "& li": {
      margin: "1em 0",
      lineHeight: "1.2",
    },
    "& .MuiDivider-root": {
      backgroundColor: "var(--color-contrast)",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  postDetail: {
    margin: "1em 1em 1em ",
    backgroundColor: "var(--bg-promo)",
    borderRadius: "1em",
    flex: 1,
  },
  postContent: {
    textAlign: "justify",
    color: "var(--color-main-featured)",
    "& img": {
      maxWidth: "100%",
      height: "auto",
      margin: "0 auto 1em",
      borderTopLeftRadius: "1em",
      borderTopRightRadius: "1em",
      display: "block",
      "@media (hover:hover)": {
        maxWidth: "100%",
        width: "100%",
      },
    },
    "& figure": {
      margin: "1em",
    },
    "& li": {
      margin: "0.5em 1em 0 0",
      textAlign: "left",
    },
    "& ::marker": {
      color: "var(--color-contrast)",
      fontWeight: "bold",
    },
    "& summary": {
      margin: "1em",
      color: "var(--grey)",
      textAlign: "left",
      fontWeight: "bold",
    },
    "& thead": {
      color: "var(--color-contrast)",
      backgroundColor: "var(--color-main))"
    },
    "& td": {
      width: "content",
      color: "var(--grey)",
    },
    "& .wp-block-image": {
      margin: "0",
    },
    "& .wp-block-table": {
      display: "grid",
      textAlign: "center",
    },
    "& .wp-block-heading": {
      margin: "0 1em",
      color: "var(--grey)",
      fontSize: "1.1em",
      textAlign: "left",
    },
    "& .wp-block-button": {
      border: "1px solid var(--color-contrast)",
      padding: "0.5em",
      margin: "2em",
      borderRadius: "0.3em",
      backgroundColor: "var(--color-contrast)",
      fontSize: "0.9em !important",
      fontWeight: "bold",
      justifySelf: "center",
      color: "var(--btn-text)",
      textTransform: "uppercase !important",
      "&:hover": {
        backgroundColor: "var(--hover-color)",
        color: "var(--grey)",
      },
    },
    "& table": {
      width: "100%",
      borderCollapse: "collapse",
      margin: "1em 0",
      backgroundColor: "var(--color-main)",
      borderRadius: "0.5em"
    },
    "& tr": {
      borderTop: "1px solid var(--bg-promo)",
    },
  },
  newsTabs: {
    "& .MuiTab-root": {
      paddingTop: "0",
      "@media (hover:hover)": {
        fontSize: "1.2em !important",
      },
    },
    "& .MuiTabs-root": {
      padding: "0 1em 0",
    },
  },
  tabSelection: {
    "&.MuiButtonBase-root.MuiTab-root": {
      fontSize: "0.9em !important",
      fontWeight: "400",
      textTransform: "uppercase !important"
    },
    "&.MuiButtonBase-root.Mui-selected": {
      color: "var(--grey)",
    },
  },
});

class WPPromotions extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { locale, search } = this.props;
    let tabActiveValue = new URLSearchParams(search).get("news");
    if (!tabActiveValue) tabActiveValue = 0;
    this.props.getWPNewsContent(newsConfigs[locale][tabActiveValue]["id"], this.constructor.name);
  }

  handleTabChange = (event, newValue) => {
    const { locale } = this.props;
    this.props.getWPNewsContent(newsConfigs[locale][newValue]["id"], this.constructor.name);
    this.props.navigate(`/${locale}/promotions?news=${newValue}`)
  }

  render() {
    log(this.constructor.name, "render");
    const { classes, wpContent, locale, search, host } = this.props;

    let tabActiveValue = new URLSearchParams(search).get("news");
    if (!tabActiveValue) tabActiveValue = 0;

    return (
      <div className={classes.root}>
        <div className={classes.newsTabs}>
          {console.log('Category Labels:', newsConfigs[locale]?.map(category => category.label))}
          <Tabs
            value={parseInt(tabActiveValue)}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            aria-label="full width tabs example"
            scrollButtons="auto"
          >
            {newsConfigs[locale]
              ?.filter((category, indx) => {
                // Filter out the "Test" tab (index 4) if host.test_post is false
                if (indx === 4 && !host?.test_post) {
                  return false;
                }
                return indx < 5; // Include only the first 5 tabs after filtering
              })
              .map((category, indx) => (
                <Tab
                  key={category.id}
                  label={category.label} // Use original labels from `newsConfigs`
                  {...a11yProps(indx)}
                  className={classes.tabSelection}
                />
              ))}
          </Tabs>



        </div>
        <Grid container style={{ padding: "0 0 1em 0", display: "flex", alignItems: "stretch" }}>
          {wpContent?.map((item, index) => {
            const itemDate = new Date(item?.date);
            return (
              <Grid item xs={12} sm={6} md={3} style={{ display: "flex" }} key={`post-${item.id}-${index}`}>
                <div key={item.id} className={classes.postDetail}>
                  <Typography
                    className={classes.postContent}
                    component={"span"}
                  >
                    {ReactHtmlParser(item.content?.rendered)}
                  </Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selectedLocale = props?.match?.params?.lang;
  return {
    locale: selectedLocale ? selectedLocale : state.i18n.locale,
    wpContent: state.wpContent.news,
    host: state.settings.host,
    selectedTheme: state.settings.theme,
    search: props.location.search
  };
};

export default withRouter(connect(mapStateToProps, { getWPContent, getWPNewsContent })(
  withStyles(styles)(WPPromotions)
));
