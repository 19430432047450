/**
 * BetMarketLabel - Show betting market label
 */

import { Typography } from "@mui/material";
import { log } from "../config/log";
import React from "react";
import { connect } from "react-redux";

class BetMarketLabel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Check if market has hc or not
   */

  marketHasHc(marketId) {
    const { marketSpecifiers } = this.props;
    let specifiers = marketSpecifiers[marketId]?.split("|") ? marketSpecifiers[marketId]?.split("|") : [];
    let allowedSpecifiers = ["hcp", "total"];
    let hasHc = false;

    specifiers?.forEach((specifier, key) => {
      // $.each(specifiers, function (k, specifier) {
      if (allowedSpecifiers.includes(specifier)) {
        // if ($.inArray(specifier, allowedSpecifiers) > -1) {
        hasHc = true;
        return false;
      }
    });

    return hasHc;
  }

  /**
   * Get all specifiers
   */

  getAllSpecifiers(hcStr) {
    if (hcStr === "") {
      return {};
    }

    let specifiers = hcStr.split("|");
    let hc = {};

    specifiers?.forEach((specifier, key) => {
      // $.each(specifiers, function (k, specifier) {
      let specArr = specifier.split("=");
      hc[specArr[0]] = specArr[1];
    });

    return hc;
  }

  /**
   * Get hc from column
   */

  getHcForColumn(hcStr) {
    let specifiers = this.getAllSpecifiers(hcStr);
    let allowedSpecifiers = ["hcp", "total"];
    let data = { normal: "", opposite: "", real: "" };

    // console.log(specifiers);

    for (let key in specifiers) {
      // Object.keys(specifiers)?.forEach((specifier, key) => {
      // console.log(key + ':' + specifiers[key]);
      // $.each(specifiers, function (specifier, value) {
      if (allowedSpecifiers.includes(key)) {
        // if ($.inArray(key, allowedSpecifiers) > -1) {
        // console.log('ok:' + specifiers[key]);
        let hc = specifiers[key];
        data.normal = hc;

        if (!isNaN(specifiers[key] % 1)) {
          hc = parseFloat(hc);
          data.real = hc;

          if (key === "hcp") {
            let normalHC = hc;
            normalHC = normalHC > 0 ? "+" + normalHC : normalHC;

            let oppositeHC = -1 * hc;
            oppositeHC = oppositeHC > 0 ? "+" + oppositeHC : oppositeHC;

            if (hc === 0) {
              normalHC = oppositeHC = 0;
            }

            data.normal = normalHC;
            data.opposite = oppositeHC;
          }
        } else if (specifiers[key].indexOf(":") > -1) {
          let parts = specifiers[key].split(":");
          data.real = parseInt(parts[0]) - parseInt(parts[1]);
        }
        // console.log('hc:' + hc);
        return data;
      }
    }

    // console.log(data);
    return data;
  }

  /**
   * calculate Market label and return
   */

  calcMarketLabel = () => {
    const { bet, marketViewerLabels, marketSpecifiers } = this.props;
    // var marketText = getMarketName(marketID, hc);
    // let bet = bet;
    // let game = bet.game;

    let marketLabels = marketViewerLabels;
    // let marketSpecifiers = marketSpecifiers;

    let specifiers = marketSpecifiers[bet.marketId]?.split("|") ? marketSpecifiers[bet.marketId]?.split("|") : [];
    let oddSpecifiers = bet.hc?.split("|");
    let marketLabel = marketLabels[bet.marketId];
    if (bet?.isPlayerProps) {
      marketLabel = marketLabel ? marketLabel?.replace("{%player}", bet?.sn?.split(",")[0]) : "";
    }

    if (bet?.marketName && marketLabel?.includes("{%player}")) {
      marketLabel = bet?.marketName;
    }

    specifiers?.forEach((specifier, key) => {
      // $.each(specifiers, function (k, specifier) {
      let oddSpec = oddSpecifiers && oddSpecifiers[key] ? oddSpecifiers[key] : "";
      let oddSpecValues = oddSpec?.split("=");
      let specValue = oddSpecValues ? oddSpecValues[1] : "";

      marketLabel = marketLabel?.replace("{" + specifier + "}", specValue);

      if (marketLabel?.indexOf("{!" + specifier + "}") > -1) {
        marketLabel = marketLabel.replace("{!" + specifier + "}", specValue + ".");
      }
    });

    if (bet?.isPlayerProps) {
      marketLabel = bet?.marketName;
      if (this.props.isInTicket) {
        marketLabel = bet?.selectionName[0]?.split("-")[0];
      }
    }

    // betText = betText + '<br><b>' + selectionText + '</b>';

    return marketLabel;
  };

  render() {
    log(this.constructor.name, "render");
    return (
      <Typography variant="h6" component="p" pt={0.3}>
        {this.calcMarketLabel()}
      </Typography>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    marketViewerLabels: state.labels.market?.viewer ? state.labels.market?.viewer : {},
    marketSpecifiers: state.labels.market?.specifiers ? state.labels.market.specifiers : {},
    translate: state.i18n.translations[state.i18n.locale],
    bet: ownProps.bet,
  };
};

export default connect(mapStateToProps)(BetMarketLabel);
