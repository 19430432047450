import { Box, Button, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import Iconify from "../../iconify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { setAlert, emptySlipTemplate } from "../../../actions";
import { removePostedTicketState } from "../../../redux-store/tickets-reducer";
import * as htmlToImage from "html-to-image";
import { LoadingButton } from "@mui/lab";
import BetMarketLabel from "../../BetMarketLabel";
import BetSelectionLabel from "../../BetSelectionLabel";
import iconDark from "../../../media/nubet_bluedark.svg";
import iconLight from "../../../media/nubet_bluelight.svg";
import { Localize, Translate } from "react-redux-i18n";

const BetPlaced = () => {
  const [isCopyLoading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  // redux action dispatcher
  const dispatch = useDispatch();
  // dom reference
  const copyRef = useRef();
  // select tickets
  const postedTicket = useSelector((state) => state.tickets.postedTicket);
  // select settings
  const settings = useSelector((state) => state.settings);
  const lang = useSelector((state) => state.i18n.locale);
  const translations = useSelector((state) => state.i18n.translations[lang]);

  // handle clear tickets state
  const handleRemoveTicketState = () => {
    dispatch(removePostedTicketState());
    dispatch(emptySlipTemplate(0));
  };
  // handle save to image
  const copyTheReceipt = async () => {
    setLoading(true);
    htmlToImage
      .toPng(copyRef.current, { cacheBust: true })
      .then(
        async function (dataUrl) {
          var link = document.createElement("a");
          link.download = "my-bet" + "-" + postedTicket.ticketCode + ".png";
          link.href = dataUrl;
          link.click();
          const response = await fetch(dataUrl);
          const blob = await response.blob();
          navigator.clipboard
            .write([
              new ClipboardItem({
                [blob.type]: blob,
              }),
            ])
            .then(() => {
              dispatch(
                setAlert(
                  {
                    type: "success",
                    payload: translations.labels.receipt_copied,
                  },
                  "BetPlaced.js"
                )
              );
            })
            .catch((error) => {
              console.error("Error copying image:", error);
            });
          setLoading(false);
        },
        [copyRef]
      )
      .catch(() => {
        setLoading(false);
      });
  };

  // handle ticket shown

  const handleRecieptView = () => {
    setOpen(!isOpen);
  };

  const combinePostedBets = () => {
    let combinedBets = {};
    const bets = postedTicket?.bets ? postedTicket.bets : [];
    bets.forEach((bet) => {
      if (!combinedBets[bet?.game?.gameid]) {
        combinedBets[bet.game.gameid] = {
          game: bet.game,
          bets: bets.filter((nBet) => nBet.game.gameid === bet.game.gameid),
        };
      }
    });
    return Object.values(combinedBets);
  };

  const combinedBets = combinePostedBets();

  useEffect(() => {
    dispatch(emptySlipTemplate(0));
  }, [dispatch]);

  return (
    <Box mt={2}>
      <Stack alignItems="center" mb={1} position="relative">
        <Iconify width={70} icon="prime:check-circle" color="primary.main" />
        <Typography variant="body2" mt={1}>
          <Translate value="labels.place_ticket_success" />
        </Typography>
        <Box position="absolute" right={18}>
          <IconButton onClick={handleRemoveTicketState}>
            <Iconify icon="line-md:close-small" />
          </IconButton>
        </Box>
      </Stack>
      <Stack alignItems="center" my={2}>
        <Button endIcon={<Iconify icon={isOpen ? "fluent:chevron-up-24-filled" : "fluent:chevron-down-20-filled"} />} variant="outlined" size="small" color="primary" onClick={handleRecieptView}>
          {isOpen ? <Translate value="labels.hide_receipt" /> : <Translate value="labels.show_receipt" />}
        </Button>
      </Stack>
      {isOpen && (
        <Stack width={"90%"} margin="auto">
          <Paper sx={{ background: "var(--bg-footer)" }} ref={copyRef}>
            <Box p={2}>
              <Stack spacing={0.5} mb={2}>
                {combinedBets.map((group, indx) => {
                  return (
                    <Stack key={indx}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <div className={`game-divider-time font-time-2 color-time  vertical-center`} style={{ fontSize: "14px", width: "40px" }}>
                            <Localize value={group?.game?.isOutright ? group?.game?.nextBetStop : group?.game?.startTime} dateFormat="date.game1" />
                          </div>

                          {group.bets[0]?.isOutright ? (
                            <Typography variant="subtitle1" fontWeight={500} width="100%">
                              {group.game.game_title}
                            </Typography>
                          ) : (
                            <Stack>
                              <Typography variant="subtitle1" fontWeight={500} align="left">
                                {group.game.team1}
                              </Typography>
                              <Typography variant="subtitle1" fontWeight={500} width="max-content" align="left" mt={0.3}>
                                {group.game.team2}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                      {group.bets.map((bet, indx) => (
                        <Stack key={indx}>
                          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" py={0.5}>
                            <Stack>
                              <Typography mb={0.2} variant="string" align="left" width="max-content">
                                {group?.game?.sport_code === "TR" && indx !== 0 ? <Box /> : <BetMarketLabel bet={bet} />}
                              </Typography>
                              <Typography color="text.odds" variant="string" align="left">
                                <BetSelectionLabel bet={bet} />
                              </Typography>
                            </Stack>
                            <Typography align="right" variant="subtitle2" color="text.odds">
                              {Number(bet.price)?.toFixed(2)}
                            </Typography>
                          </Stack>
                          <Divider />
                        </Stack>
                      ))}
                    </Stack>
                  );
                })}
              </Stack>
              <Stack spacing={0.2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle2" fontWeight={500} width={100} align="left">
                    {translations.labels.bet_type}
                  </Typography>
                  {postedTicket?.ticketType === "System" ? (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography align="right" variant="h6">
                        {translations.slip[postedTicket?.ticketType?.toLowerCase()]}
                      </Typography>
                      <Typography align="right" variant="h6">
                        {postedTicket?.betTypeLabel}
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography align="right" variant="h6">
                      {translations.slip[postedTicket?.ticketType?.toLowerCase()]}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle2" fontWeight={500} align="left">
                    {translations.labels.slip_stake}
                  </Typography>
                  <Typography align="right" color="text.odds" variant="h6">
                    {Number(postedTicket?.stake)?.toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle2" fontWeight={500} align="left">
                    {translations.labels.total_odds}
                  </Typography>
                  <Typography align="right" color="text.odds" variant="h6">
                    {Number(postedTicket?.totalOdd)?.toFixed(2)}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle2" fontWeight={500} align="left">
                    {translations.labels.bett_possiwin}
                  </Typography>
                  <Typography align="right" variant="h6" color="text.odds">
                    {postedTicket?.totalWon?.toFixed(2)}
                  </Typography>
                </Stack>
              </Stack>
              <Stack mt={1} alignItems="center">
                <Box component="img" alt="nubet.com logo" src={settings.theme === "bluelight" ? iconLight : iconDark} height={50} />
              </Stack>
            </Box>
          </Paper>
          <Stack my={2} direction="row" spacing={2} alignItems="center">
            <LoadingButton loading={isCopyLoading} startIcon={<Iconify icon="mynaui:copy" />} size="small" variant="contained" color="primary" onClick={copyTheReceipt}>
              <Translate value="labels.copy_receipt" />
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default BetPlaced;
