import React from "react";
import { Box, Paper, styled, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import NotificationsInterface from "./notifications";
import ChangePasswordInterface from "./change-password";
import BlockAccountInterface from "./block-account";
import LimitsInterface from "./limits";

const StyledContainer = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.dbPaper,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    width: "70%",
    margin: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: "60%",
    margin: "auto",
  },
  "& .MuiTab-root": {
    textTransform: "uppercase !important",
    fontSize: "1.1em !important",
  },
  "& .MuiTabPanel-root": {
    padding: "0 !important",
  },
}));

const SettingsPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const loocation = useLocation();

  // route selectros
  const tabValue = searchParams.get("interface") || "change-password";

  // state selectors
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);

  const handleTabChange = (e, newValue) => {
    searchParams.set("interface", newValue);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <Box px={1} py={2}>
      <StyledContainer>
        <TabContext value={tabValue}>
          <Box>
            <TabList indicatorColor="none" onChange={handleTabChange} aria-label="settings-tabs">
              <Tab label={translate.labels.settings_change_pw} value="change-password" />
              <Tab label={translate.labels.settings_limits} value="limits" />
              <Tab label={translate.labels.settings_blocks} value="block-account" />
              <Tab label={translate.labels.settings_notes} value="notifications" />
            </TabList>
          </Box>
          <TabPanel value="change-password">
            <ChangePasswordInterface />
          </TabPanel>
          <TabPanel value="limits">
            <LimitsInterface />
          </TabPanel>
          <TabPanel value="block-account">
            <BlockAccountInterface />
          </TabPanel>
          <TabPanel value="notifications">
            <NotificationsInterface />
          </TabPanel>
        </TabContext>
      </StyledContainer>
    </Box>
  );
};

export default SettingsPage;
