/**
 * Get API base domain from host and set it as request base url for all API requests
 */

import axios from "axios";
import hostConfig from "../config/hostConfig";

let host = window.location.hostname ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_") : "betv3_frontend_herokuapp_com";
let source;

switch (hostConfig[host]?.api) {
  default:
  case "nubet_test":
    source = "https://dev-api.nubet.com";
    break;

  case "nubet_prod":
    source = "https://api.nubet.com";
    break;
}

export default axios.create({
  baseURL: source,
});
