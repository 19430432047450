/**
 * Alert notification component
 */
import { log } from "../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import withStyles from "@mui/styles/withStyles";
import { clearAlert } from "../actions/index";
import { handleUserLogoutAsync } from "../redux-store/auth-reducer";
import { toggleMyTicketsPanel } from "../redux-store/settings-reducer";
import { Box } from "@mui/material";

function AlertBox({ children, ...rest }) {
  return (
    <MuiAlert elevation={0} variant="filled" {...rest}>
      {children}
    </MuiAlert>
  );
}

const styles = (theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: "theme.spacing(8)",
    },
    position: "absolute",
    top: "70px",
  },
});

class Alert extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  /**
   * Close alert notification
   */

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.clearAlert(this.constructor.name);
    this.setState({
      open: false,
    });
  };

  /**
   * Show alert notification
   */

  openAlert = () => {
    this.setState({
      open: true,
    });
  };

  /**
   * Show custom alert notification based on error type
   */

  customError = () => {
    const { alert, translate, stake } = this.props;

    let error = alert.payload[0];
    let title, message, options, val;
    let APP_LOCALE = "de-DE"; //maksym

    switch (error.type) {
      default:
        return "Custom Errror";

      case "inactive_game":
      case "event_out_of_date":
      case "inactive_market":
        // apply logic from btg instead of alert
        // setBetState(item.eventID, item.marketID, item.selectionID, item.hc, 0);
        // enableSlip();
        title = translate.labels.error;
        message = translate.acceptance[error.type];
        return this.renderCustomError(title, message);

      case "invalid_price":
        // apply logic from btg instead of alert:
        // updateSlipErrorPriceDropped(item.eventID, item.eventName, item.marketID, item.selectionID, item.hc, item.price, item.newPrice, false);
        // enableSlip();
        title = translate.labels.error;
        message = translate.acceptance[error.type];
        return this.renderCustomError(title, message);

      case "no_user_found":
        title = translate.labels.error;
        message = translate.labels.please_log_in;
        return this.renderCustomError(title, message);

      case "ticket_awaiting_approval":
        title = translate.labels.attention;
        message = translate.acceptance.ticket_awaiting_approval;
        return this.renderCustomError(title, message);

      case "deposit_succes":
        title = translate.labels.attention;
        message = translate.labels.deposit_succes;
        return this.renderCustomError(title, message);

      case "deposit_fail":
        title = translate.labels.attention;
        message = translate.labels.deposit_fail;
        return this.renderCustomError(title, message);

      case "not_enough_credit":
        title = translate.labels.error;
        message = translate.acceptance.not_enough_credit.replace("%%1%%", error.currentAmountReadable);
        return this.renderCustomError(title, message);

      case "maxCashBet_limit_exceeded":
        title = translate.labels.error;
        message = translate.acceptance.cash_bet_limit_exceeded;
        return this.renderCustomError(title, message);

      case "maxStake_limit_exceeded":
        title = translate.labels.error;
        message = translate.acceptance.maxstake_limit_exceeded;
        return this.renderCustomError(title, message);

      case "ticket_uniqueness":
        title = translate.labels.error;
        message = translate.acceptance.ticket_uniqueness;
        return this.renderCustomError(title, message);

      case "coupons_per_event":
        title = translate.labels.error;
        message = translate.acceptance.coupons_per_event.replace("%%1%%", error.ruleValue);
        return this.renderCustomError(title, message);

      case "stake_limits":
        title = translate.labels.error;
        message = translate.acceptance.stake_limits.replace("%%1%%", Number(error.userMinStake)).replace("%%2%%", Number(error.userMaxStake)).replace("%%3%%", error.userCurrency);
        return this.renderCustomError(title, message);

      case "bet_risk":
        title = error.eventName;
        message = translate.acceptance.error_bet_risk_1 + " " + error.maxStake + " " + translate.labels.selected_currency + " " + translate.acceptance.error_bet_risk_2;
        return this.renderCustomError(title, message);

      case "invalid_system_combi":
        title = translate.labels.error;
        message = translate.acceptance.invalid_system_combi;
        return this.renderCustomError(title, message);

      case "limits_exceeded_wager":
        options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        val = parseFloat(error.value).toLocaleString(APP_LOCALE, options);

        title = translate.labels.error;
        message = translate.acceptance.limits_exceeded_wager.replace("%%1%%", val);
        return this.renderCustomError(title, message);

      case "limits_exceeded_loss":
        options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
        val = parseFloat(error.value).toLocaleString(APP_LOCALE, options);

        title = translate.labels.error;
        message = translate.acceptance.limits_exceeded_loss.replace("%%1%%", val);
        return this.renderCustomError(title, message);

      case "data_error":
        title = translate.labels.error;
        message = translate.labels.error;
        return this.renderCustomError(title, message);

      case "min_unique_events":
        title = translate.labels.error;
        message = translate.acceptance.min_unique_events.replace("%%1%%", Number(error.minEvents));
        return this.renderCustomError(title, message);

      case "ticket_not_valid":

      case "soap_authentication_not_successful":
        title = translate.labels.error;
        message = translate.acceptance.internal_error;
        return this.renderCustomError(title, message);

      case "no_language_defined":
        title = translate.labels.error;
        message = translate.acceptance.internal_error;
        return this.renderCustomError(title, message);

      case "user_not_active":
        title = translate.labels.error;
        message = translate.acceptance.internal_error;
        return this.renderCustomError(title, message);

      case "user_acceptance_disabled":
        title = translate.labels.error;
        message = translate.acceptance.internal_error;
        return this.renderCustomError(title, message);

      case "incorrect_bet_type":
        title = translate.labels.error;
        message = translate.acceptance.internal_error;
        return this.renderCustomError(title, message);

      case "incorrect_game_engine":
        title = translate.labels.error;
        message = translate.acceptance.internal_error;
        return this.renderCustomError(title, message);

      case "finnplay_wallet_api_problem":
        title = translate.labels.error;
        message = translate.acceptance.internal_error;
        return this.renderCustomError(title, message);

      case "no_system_combi":
        title = translate.labels.error;
        message = translate.acceptance.internal_error;
        return this.renderCustomError(title, message);

      case "insufficient_min_bets":
      case "insufficient_min_combi_type":
      case "event_unique":

      case "no_stake":
        title = translate.labels.error;
        message = translate.acceptance.no_stake;
        return this.renderCustomError(title, message);

      case "wager_limit_exceeded":
        title = translate.labels.error;
        message = translate.acceptance.wager_limit_exceeded;
        return this.renderCustomError(title, message);

      case "loss_limit_exceeded":
        title = translate.labels.error;
        message = translate.acceptance.loss_limit_exceeded;
        return this.renderCustomError(title, message);

      case "over_max_stake":
      case "invalid_hc":
      case "total_stake":
      case "total_win":
      case "total_bets":
      case "ticket_stake":
      case "ticket_win":
      case "ticket_bets":
      case "no_wallet_configured":
        title = translate.labels.error;
        message = translate.acceptance.internal_error;
        return this.renderCustomError(title, message);

      case "ticket_cooldown":
        if (error.eventName) title = error.eventName;

        message = translate.acceptance[error.type];

        if (error.ruleOperator) {
          let oppositeOperator = parseInt(error.ruleOperator);

          switch (error.ruleOperator) {
            case "1":
              oppositeOperator = 2;
              break;

            case "2":
              oppositeOperator = 1;
              break;

            case "4":
              oppositeOperator = 7;
              break;

            case "5":
              oppositeOperator = 6;
              break;

            case "6":
              oppositeOperator = 5;
              break;

            case "7":
              oppositeOperator = 4;
              break;

            default:
              break;
          }

          message += " " + translate.acceptance["rule_operator_" + oppositeOperator] + " " + error.ruleValue;
        }

        if (error.type === "ticket_win") {
          message += host.brand === "nubet_se" ? "SEK" : "EUR";
        }

        return this.renderCustomError(title, message);
    }
  };

  /**
   * Return custom alert notification with title and message
   */

  renderCustomError = (title, message) => {
    return (
      <div>
        <Box mb={2}>
          <div>{title}</div>
        </Box>
        <div className="alert-custom-error-body">{message}</div>
      </div>
    );
  };

  /**
   * Show multiple errors
   */

  showMultipleErrors = (collection, payloads) => {
    const { alert } = this.props;

    var field_required_options = [];
    for (let key in payloads) {
      if (payloads[key] === "field_required") {
        const word = key ? key : "No message";
        const firstLetter = word.charAt(0);
        const firstLetterCap = firstLetter.toUpperCase();
        const remainingLetters = word.slice(1);
        const capitalizedWord = firstLetterCap + remainingLetters;
        field_required_options.push({ fieldName: capitalizedWord, payload: "field_required" });
      }
    }

    var newPayloads = Object.values(payloads);

    return (
      <div>
        {newPayloads.map(
          (payload, key) =>
            payload !== "field_required" && (
              <div key={key}>{alert.extraField ? <Translate value={`${collection}.${payload}`} dangerousHTML extraField={alert.extraField} /> : <Translate value={`${collection}.${payload}`} />}</div>
            )
        )}

        {field_required_options.map((payload, key) => (
          <div key={key}>
            {payload.fieldName} <Translate value={`${collection}.${payload.payload}`} />
          </div>
        ))}
      </div>
    );
  };


  render() {
    log(this.constructor.name, "render");
    const { alert, classes, slipAutoUpdateStatus } = this.props;

    if (alert.type && !this.state.open) {
      this.setState({ open: true });
      return null;
    }
    if (
      (alert.type === "error" && alert.payload?.error === "NullPointerException") ||
      (alert.type === "error" && alert.payload?.error === "token_expired") ||
      (alert.type === "error" && alert.payload?.error === "token_invalid") ||
      (alert.type === "error" && alert?.payload?.error === "NoAvailableSessionException")
    ) {
      // this means token is expired
      // we should log out and go to login page
      // console.log("__________TOKEN EXPIRED__________");
      // this.props.handleUserLogoutAsync();
      alert["payload"] = { error: "token_expired" };
      alert["collection"] = "acceptance";
    }

    if (!alert.type) return null;
    if (alert.from && slipAutoUpdateStatus) return null;

    // let collection = alert.collection ? alert.collection : 'labels';

    return (
      <div className={classes.root}>
        <Snackbar open={this.state.open} autoHideDuration={10000} onClose={this.handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <div>
            <AlertBox onClose={this.handleClose} severity={alert.type}>
              {typeof alert.payload === "string" ? (
                <Translate value={`${alert.collection}.${alert.payload}`} />
              ) : alert.payload[0] && alert.payload[0].type ? (
                this.customError()
              ) : (
                this.showMultipleErrors(alert.collection, alert.payload)
              )}
            </AlertBox>
          </div>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const stake = state.slips[0]["stake"];
  return {
    alert: state.alert,
    slipAutoUpdateStatus: state.slips[0].slipAutoUpdateStatus,
    translate: state.i18n.translations[state.i18n.locale],
    stake: stake,
    locale: state.i18n.locale,
  };
};

export default connect(mapStateToProps, { clearAlert, handleUserLogoutAsync, toggleMyTicketsPanel })(withStyles(styles)(Alert));
