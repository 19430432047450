/**
 * Game Score and states information component
 * Show game score, game state and additional information
 * For Columns view mode
 */
import "./@css/GameBox.css";
import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Localize, Translate } from "react-redux-i18n";
import GameBoxCompactAvatar from "./@avatars/GameBoxCompactAvatar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { addStateItem, getGameStatus } from "../../../helpers/gameFunctions";
import RedCardIcon from "../../../media/img/card_red.svg";
import YellowCardIcon from "../../../media/img/card_yellow.svg";
import CornerIcon from "../../../media/img/corner.svg";
import { Box } from "@mui/material";

class GameBoxColumn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameId: "",
      sportCode: "",
      openDetail: false,

      currentTime: "",
      increasingTime: "",
    };

    this.stateList = [];
    this.liveTimer = null;
  }

  UNSAFE_componentWillMount() {
    // const increasingTime = localStorage.getItem(`${this.props.game.gameid}_live_time`) ? localStorage.getItem(`${this.props.game.gameid}_live_time`) : '';

    // this.setState({ increasingTime }, () => {
    //   this.runLiveGameTimer(this.props.game);
    // });

    this.runLiveGameTimer(this.props.game);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.runLiveGameTimer(nextProps.game);
  }

  shouldComponentUpdate(nextProps, nextState) {
    var flag = false;
    if (nextProps.isInViewport === true) {
      flag = true;
    }

    if (this.state.openDetail !== nextState.openDetail) {
      flag = true;
    }

    return flag;
  }

  /**
   * Open game details ( show game odds information )
   */

  openGameDetails(game) {
    this.setState(
      {
        gameId: game.gameid,
        sportCode: game.sport_code,
        openDetail: !this.state.openDetail,
      },
      () => {
        this.openDrawer();
      }
    );
  }

  /**
   * Show game state item ( home / away )
   */

  renderStateItem(stateInfoItem, key) {
    let className = "";
    // let stateTeamColor1 = '';
    // let stateTeamColor2 = '';

    switch (stateInfoItem.stateTeam) {
      default:
      case "home":
        // stateTeamColor1 = stateInfoItem.team1Color1;
        // stateTeamColor2 = stateInfoItem.team1Color2;
        className = "game-box-detailed-additional-state-info-item-left bg-light-gradient-detailed-left vertical-center delay fade-in content-left grey-permanent";
        break;
      case "away":
        // stateTeamColor1 = stateInfoItem.team2Color1;
        // stateTeamColor2 = stateInfoItem.team2Color2;
        className = "game-box-detailed-additional-state-info-item-right bg-light-gradient-detailed-right vertical-center delay fade-in content-right grey-permanent";
        break;
    }
    if (key === Object.keys(this.stateList)?.length) {
      className = "game-box-detailed-additional-state-info-item content-center vertical-center font-l bg-light-gradient-center delay fade-in";
    }
    return (
      <div className={className} key={key}>
        <div className={stateInfoItem.stateIcon.replace("home-", "").replace("away-", "") + "-white"} style={{ width: "1em", margin: "0.35em 0.4em 0.35em 0.2em" }}>
          &nbsp;
        </div>
        {/* <div className="content-center" style={{ margin: "0.2em 0 0 0" }}><GameBoxCompactAvatar pattern="1" color1={stateTeamColor1} color2={stateTeamColor2} /></div> */}
        {/* <div className="white-permanent spacer-right">{key === 0 ? stateInfoItem.stateTeamName + ': ' : ''}<Translate value={`stateInfo.${stateInfoItem.stateText}`} /></div> */}
        <div className="white-permanent spacer-right">
          <Translate value={`stateInfo.${stateInfoItem.stateText}`} />
        </div>
      </div>
    );
  }

  /**
   * Check if game stateTime is one week later or not
   */

  checkOneWeekLater(timeStamp, content, game) {
    let today = new Date();
    let time = new Date(timeStamp);
    let difference = Math.floor((Date.UTC(time.getFullYear(), time.getMonth(), time.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) / (1000 * 60 * 60 * 24));

    if (difference > 7) return true;
    // if (difference > 7 || content !== 'selectedGames') return true;

    return false;
  }

  /**
   * Show game live time counter
   */

  runLiveGameTimer = (game) => {
    let self = this;
    let { time } = game;

    if (time) {
      if (self.state.currentTime !== time) {
        if (time === "HT" || time === "End" || (time && time?.length !== 5)) {
          self.setState({ increasingTime: time }, () => {
            clearInterval(self.liveTimer);
            self.liveTimer = null;
            // localStorage.removeItem(`${game.gameid}_live_time`);
          });
        } else {
          self.setState({ currentTime: time, increasingTime: time }, () => {
            if (self.state.increasingTime === "NaN:NaN" || !self.state.increasingTime) {
              self.setState({ increasingTime: time }, () => {
                if (!self.liveTimer && self.state.increasingTime) {
                  self.liveTimer = setInterval(() => {
                    let minutes = parseInt(self.state.increasingTime.toString().split(":")[0], 10);
                    let seconds = parseInt(self.state.increasingTime.toString().split(":")[1], 10);

                    if (seconds >= 59) {
                      seconds = 0;
                      minutes++;
                    } else {
                      seconds++;
                    }

                    const formattedTime = `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
                    // self.setState({ increasingTime: formattedTime }, () => {
                    //   localStorage.setItem(`${game.gameid}_live_time`, formattedTime);
                    // });
                    self.setState({ increasingTime: formattedTime });
                  }, 1000);
                }
              });
            } else {
              if (!self.liveTimer && self.state.increasingTime) {
                self.setState({ increasingTime: time }, () => {
                  self.liveTimer = setInterval(() => {
                    let minutes = parseInt(self.state.increasingTime.toString().split(":")[0], 10);
                    let seconds = parseInt(self.state.increasingTime.toString().split(":")[1], 10);

                    if (seconds >= 59) {
                      seconds = 0;
                      minutes++;
                    } else {
                      seconds++;
                    }

                    const formattedTime = `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
                    // self.setState({ increasingTime: formattedTime }, () => {
                    //   localStorage.setItem(`${game.gameid}_live_time`, formattedTime);
                    // });
                    self.setState({ increasingTime: formattedTime });
                  }, 1000);
                });
              }
            }
          });
        }
      }
    }
  };

  goNextGame = (game, e) => {
    e.stopPropagation();
    this.props.goNextGame(game);
  };

  /**
   * Show game time ( static and live time) and game odds information
   */

  renderGameBox() {
    const { game, mode, content, showCompetitorAvatar, marketsLayout, gameIndex } = this.props;

    switch (mode) {
      default:
        return null;

      case "compact":
        let gridTemplateColumns = ["3.7em", "1em"];
        if (showCompetitorAvatar) gridTemplateColumns.push("2em");
        // window.innerWidth > 768 && content === 'selectedGames' ? gridTemplateColumns.push('15em', '1em', '1.5em') : gridTemplateColumns.push('16em', '1em', '1.8em');
        game.isOutright ? gridTemplateColumns.push("26em", "0", "0") : gridTemplateColumns.push("15em", "1em", "1.5em");

        Object.keys(game.score_set_calculated?.home || {})?.forEach((key) => {
          gridTemplateColumns.push("1.8em");
        });

        game.isOutright ? gridTemplateColumns.push("0") : gridTemplateColumns.push("auto");
        if (marketsLayout === "horizontal") gridTemplateColumns.push("auto");
        gridTemplateColumns.push(content === "selectedGamesTicket" ? "0em" : "2em");

        gridTemplateColumns = gridTemplateColumns.join(" ");

        let totalOddsAvatarStyle;

        if (game.total_odds > 99) {
          totalOddsAvatarStyle = {
            backgroundColor: "var(--color-contrast)",
            color: "var(--color-main)",
            width: "1.8em",
            height: "1.5em",
            fontSize: "1em",
            lineHeight: "inherit",
            fontWeight: "500",
          };
        } else {
          totalOddsAvatarStyle = {
            backgroundColor: "var(--color-contrast)",
            color: "var(--color-main)",
            width: "1.5em",
            height: "1.5em",
            fontSize: "1em",
            lineHeight: "inherit",
            fontWeight: "500",
          };
        }

        return (
          // <div className={this.props.from === 'conference' ? 'game-compact-column-conference' : `game-compact-column ${game.stateBackground} `} style={{ gridTemplateColumns: gridTemplateColumns }} key={game.gameid} onClick={this.props.from === 'conference' ? this.goNextGame.bind(this, game) : marketsLayout === 'vertical' ? this.openGameDetails.bind(this, game) : null}>
          // <div className={this.props.from === 'conference' ? 'game-compact-column-conference' : `game-compact-column-column ${game.stateBackground} `} style={{ gridTemplateColumns: gridTemplateColumns }} key={game.gameid}>
          <div className={game.isOutright ? "game-compact-column-column" : `game-compact-column ${game.stateBackground} `} style={{ gridTemplateColumns: gridTemplateColumns }} key={game.gameid}>
            {game.time ? (
              // <div className="game-divider-time color-time font-bold vertical-center spacer-left font-time">
              <div className={`game-divider-time ${game.isLive === 1 ? "color-time-live" : "color-time"} font-bold vertical-center spacer-left font-time-1 cursor-pointer`} onClick={this.openGameDetails.bind(this, game)}>
                <span className="">
                  {getGameStatus(game.state, game?.sport_code)}{" "}
                  {game.isLive === 0 || !game.isLive || (game.state === "FT" && content !== "selectedGames") ? (
                    this.checkOneWeekLater(game.time) ? (
                      <Localize value={game.time !== "FT" ? game.time : game.startTime} dateFormat="date.game1" />
                    ) : content === "selectedGamesTicket" ? (
                      <Localize value={game.time !== "FT" ? game.time : game.startTime} dateFormat="date.game1" />
                    ) : (
                      <Localize value={game.time !== "FT" ? game.time : game.startTime} dateFormat="date.game2" />
                    )
                  ) : (
                    <span>
                      {game.time} <span style={{ color: "var(--red)" }}>{game.stoppageTime ? `[${game.stoppageTime}]` : null}</span>
                    </span>
                  )}
                </span>
              </div>
            ) : game.startTime ? (
              <div className={`game-divider-time ${game.isLive === 1 ? "color-time-live" : "color-time"} font-bold vertical-center spacer-left font-time-1`}>
                <span className="">
                  <Localize value={game?.isOutright ? game?.nextBetStop : game.startTime} dateFormat="date.game1" />
                </span>
              </div>
            ) : game.isLive === 1 ? (
              <div className="game-divider-time color-time font-bold vertical-center spacer-left font-time-1">
                <span className="text-wrap">Pause</span>
              </div>
            ) : (
              <div className="game-divider-time color-time font-bold vertical-center spacer-left font-time-1">
                <span className="text-wrap">
                  {game.isLive === 0 || (game.state === "FT" && content !== "selectedGames") ? (
                    this.checkOneWeekLater(game.startTime) ? (
                      <Localize value={game.startTime} dateFormat="date.game1" />
                    ) : (
                      <Localize value={game.startTime} dateFormat="date.game2" />
                    )
                  ) : (
                    <Localize value={game.startTime} dateFormat="date.game1" />
                  )}
                </span>
              </div>
            )}
            <div className={"vertical-center content-center " + (game.stateIcon?.includes("home") ? game.stateIcon.replace("home-", "") : "")} style={{ width: "1.1em", margin: "0 0.3em 0 0" }}></div>
            {showCompetitorAvatar ? (
              <div className="vertical-center content-center">
                <GameBoxCompactAvatar pattern="1" color1={game.team1Color1} color2={game.team1Color2} />
              </div>
            ) : null}
            {/* <div className="color-team font-team vertical-center font-bold-2"> */}
            <div className="color-team font-team vertical-center">
              <span className="text-wrap">{game.isOutright ? game.leagueName : game.team1}</span>
            </div>
            <div className="vertical-center content-center">{game.red_home > 0 ? <img src={RedCardIcon} style={{ height: "0.8em" }} alt="redCard" /> : null}</div>
            <div className={`color-contrast vertical-center font-bold-2 font-standing content-center ${game.score_home_updated ? "animate-flicker" : ""}`}>
              {/* {game.state !== 'not_started' && !game.isOutright && game?.state === "FT" ? game.score_home : game?.state === "FT" ? game.score_home : null} */}
              {game.state !== "not_started" && !game.isOutright && game?.state === "FT" ? game.score_home : null}

              {/* PSML TASK 24 - RESULTS MUST BE SPLITTED AND SHOWN HERE IF game.state IS FT */}
              {/* {game.state === 'FT' ? 'result_home' : null} */}
            </div>

            {game?.sportCode === "TR" &&
              Object.values(game.score_set_calculated.home).map((scoreSet, key1) => {
                return (
                  <div key={key1} className="grey-3 vertical-center font-standing content-center">
                    {game.state !== "1p" && game.state === "FT" ? scoreSet : null}
                  </div>
                );
              })}
            {/* Genius Danger State updates remove  */}
            <div></div>

            {content === "selectedGames" && marketsLayout === "horizontal" ? <div>{/* <Odds game={game} odds={odds} marketSetKey={marketSetKey} isInViewport={isInViewport} /> */}</div> : null}

            <div className={`game-divider-badge color-contrast font-m vertical-center content-right cursor-pointer ${content === "selectedGamesTicket" ? null : "spacer-right"}`}>
              {/* {content === 'selectedGames' ? <Avatar style={totalOddsAvatarStyle} onClick={this.openGameDetails.bind(this, game)}>{game.total_odds}</Avatar> : null} */}
            </div>

            {content === "selectedGamesTicket" && marketsLayout === "horizontal" ? <div></div> : null}

            <div className={"vertical-center content-center " + (game.stateIcon?.includes("away") ? game.stateIcon.replace("away-", "") : "")} style={{ width: "1.1em", margin: "0.1em 0.3em 0 0" }}></div>
            {showCompetitorAvatar ? (
              <div className="vertical-center content-center">
                <GameBoxCompactAvatar pattern="1" color1={game.team2Color1} color2={game.team2Color2} />
              </div>
            ) : null}
            {/* <div className="color-team font-team vertical-center font-bold-2"><span className="text-wrap">{game.isOutright ? game.game_title : game.team2}</span></div> */}
            {/* <div className="color-team font-team vertical-center font-bold-2"> */}
            <div className="color-team font-team vertical-center">
              <span className={game.isOutright ? "" : "text-wrap"}>{game.isOutright ? game.game_title : game.team2}</span>
            </div>

            <div className="vertical-center content-center">{game.red_away > 0 ? <img src={RedCardIcon} style={{ height: "0.8em" }} alt="redCard" /> : null}</div>
            <div className={`color-contrast font-bold-2 vertical-center font-standing content-center ${game.score_away_updated ? "animate-flicker" : ""}`}>
              {game.state !== "not_started" ? game.score_away : null}

              {/* PSML TASK 24 - RESULTS MUST BE SPLITTED AND SHOWN HERE WHERE I PUT THE PLACEHOLDER "result_home and result_away" */}
              {/* {game.state === 'FT' ? 'result_away' : null} */}
            </div>
            {Object.values(game.score_set_calculated.away).map((scoreSet, key2) => {
              return (
                <div key={key2} className="grey-3 vertical-center font-standing content-center">
                  {game.state !== "1p" ? scoreSet : null}
                </div>
              );
            })}

            {/* <div className="text-wrap vertical-center"> */}

            {/* Genius Danger State updates remove  */}
            {/* <div className="text-wrap vertical-center font-s">
              {
                game.time === 'HT' || game.time === 'End' ? null : (
                  game.infoIcon ? (
                    <span className="text-wrap vertical-center">{game?.infoTeam?.replace('home', game.team1).replace('away', game.team2)}</span>
                  ) : (
                    <span className="text-wrap vertical-center">{game?.stateTeam?.replace('home', game.team1).replace('away', game.team2)}</span>
                  )
                )
              }TEST
            </div> */}
            <div></div>
            <Box display={{ xs: "none", md: "block" }} />
            {game.state !== "not_started" && game.isOutright && game.state === "FT" && game.sport_code === "TR"
              ? [
                  <div key={1} />,
                  <div key={2} />,
                  <div className="color-contrast font-xs text-wrap-outright-result spacer-left" key="outright-result-ticket">
                    <Translate value="labels.result" />: {game.score_home}
                  </div>,
                ]
              : null}
          </div>
        );

      case "detailed":
        addStateItem(game, this.stateList);
        // this.addStateItem(game);

        return (
          <div className={`game-box-detailed-bg bg-${game.sport_code}`} key={game.gameid}>
            <div className="game-box-detailed-title">
              <div className="game-box-detailed-team1 content-center vertical-center" style={{ margin: "0 0 0 -3em" }}>
                {/* <GameBoxDetailedAvatar pattern="1" color1={game.team1Color1} color2={game.team1Color2} /> */}
              </div>
              {/* {
                game.time ? (
                  <div className="white-permanent text-shadow font-xxxxl font-bold text-wrap vertical-center content-center">
                    {
                      game.isLive === 0 || (game.state === 'FT' && content !== 'selectedGames') ?
                        this.checkOneWeekLater(game.time) ? (<Localize value={game.time} dateFormat="date.game1" />) : (<Localize value={game.time} dateFormat="date.game2" />) : <span>{game.time} <span style={{ color: 'var(--red)' }}>{game.stoppageTime ? `[${game.stoppageTime}]` : null}</span></span>
                    }
                  </div>
                ) : (
                    <div className="white-permanent text-shadow font-xxxxl font-bold text-wrap vertical-center content-center">
                    {getGameStatus(game.state, game?.sport_code)}{" "}
                    {
                      game.isLive === 0 || (game.state === 'FT' && content !== 'selectedGames') ?
                        this.checkOneWeekLater(game.startTime) ? (<Localize value={game.startTime} dateFormat="date.game1" />) : (<Localize value={game.startTime} dateFormat="date.game2" />) : game.startTime
                    }
                  </div>
                )
              } */}
              <div className="white-permanent text-shadow font-xxxxxl font-bold-2 text-wrap vertical-center content-center">
                {game.score_home} - {game.score_away}
              </div>
              <div className="game-box-detailed-team2 content-center vertical-center">{/* <GameBoxDetailedAvatar pattern="1" color1={game.team2Color1} color2={game.team2Color2} /> */}</div>
              <div className="white-permanent font-m content-left vertical-center text-wrap spacer-left">
                {game.sport_code === "FB"
                  ? [
                      <div key={0}>
                        {game.yellow_home} <img className="spacer-right" src={YellowCardIcon} alt="yellow" />
                        {game.red_home} <img className="spacer-right" src={RedCardIcon} alt="red" />
                        {game.corners_home} <img className="spacer-right" src={CornerIcon} alt="corner" />
                      </div>,
                    ]
                  : null}
              </div>
              <div></div>
              <div className="white-permanent font-m content-right vertical-center text-wrap spacer-right">
                {game.sport_code === "FB"
                  ? [
                      <div key={0}>
                        {game.yellow_away} <img className="spacer-right" src={YellowCardIcon} alt="yellow" />
                        {game.red_away} <img className="spacer-right" src={RedCardIcon} alt="red" />
                        {game.corners_away} <img className="spacer-right" src={CornerIcon} alt="corner" />
                      </div>,
                    ]
                  : null}
              </div>
            </div>
            <div className="game-box-detailed-title1">
              <div className="white-permanent bg-color-contrast-gradient-left font-xl spacer-left vertical-center content-left">{game.team1}</div>
              <div className="white-permanent bg-color-contrast-gradient-right font-xl spacer-right vertical-center content-right">{game.team2}</div>
            </div>
            <div className="game-box-detailed-additional-info">
              {Object.values(this.stateList).map((stateInfoItem, key) => {
                // if (key > 4) return;
                // return this.renderStateItem(stateInfoItem, key, 'detailed');
                return game.isLive === 1 && game.sport_code === "FB" ? this.renderStateItem(stateInfoItem, key, "detailed") : null;
              })}
            </div>
          </div>
        );
    }
    // });
  }

  render() {
    log(this.constructor.name + ":" + this.props.game.team1, "render");
    const { game, content } = this.props;

    // if (game.state === 'FT' && content !== 'selectedLeagueResults') {
    if (game.state === "FT" && content !== "selectedLeagueResults" && content !== "selectedGamesTicket" && new Date().getTime() > game.endTime) {
      return null;
    }

    return <div>{this.renderGameBox()}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    game: ownProps.game[0],
    content: ownProps.content,
    from: ownProps.from,
    mode: ownProps.mode ? ownProps.mode : "compact",
    showCompetitorAvatar: state.settings.showCompetitorAvatar,
    marketsLayout: state.settings.marketsLayout,
    isInViewport: ownProps.isInViewport,
    odds: ownProps.odds,
    marketSetKey: ownProps.marketSetKey,
    gameIndex: ownProps.gameIndex,
  };
};

export default connect(mapStateToProps)(GameBoxColumn);
