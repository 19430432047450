import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UnAuthorizedRedirect = ({ children }) => {
  const navigate = useNavigate();
  const locale = useSelector((state) => state.i18n.locale);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth.isSignedIn) {
      navigate(`/${locale}/sports?sportEventStatusSlug=all`);
    }
  }, [auth.isSignedIn]);

  return children;
};

export default UnAuthorizedRedirect;
