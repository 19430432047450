import { useFormContext, Controller } from "react-hook-form";
// @mui
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel, { formControlLabelClasses } from "@mui/material/FormControlLabel";
import { Grid2 } from "@mui/material";
import RHFAutoComplete from "./rhf-autocomplete";

export function RHFCheckbox({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel control={<Checkbox {...field} checked={field.value} />} {...other} />
          {(!!error || helperText) && <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>}
        </div>
      )}
    />
  );
}

export function RHFMultiCheckbox({
  row = false, // Default to false for row
  name,
  label,
  options = [],
  displayLabel = "label", // Default to "label" if not provided
  valueLabel = "value", // Default to "value" if not provided
  spacing = 0, // Default to 0 if not provided
  helperText,
  sx = {}, // Default to empty object if not provided
  showSelectAll = false,
  showSearch = false,
  gridCount = 12,
  showErrorMessage = true,
  ...other
}) {
  const { control } = useFormContext();

  const getSelected = (selectedItems = [], item) => {
    const isSelected = selectedItems.some((selected) => selected[valueLabel] === item[valueLabel]);
    return isSelected ? selectedItems.filter((selected) => selected[valueLabel] !== item[valueLabel]) : [...selectedItems, item];
  };

  const selectAllOptions = options; // Store entire objects instead of just values

  const isAllSelected = (selectedItems) => selectedItems.length === options.length;

  const handleSelectAllChange = (field) => {
    if (isAllSelected(field.value)) {
      field.onChange([]); // Unselect all
    } else {
      field.onChange(selectAllOptions); // Select all
    }
  };

  const handleOptionClick = (field, option) => {
    field.onChange(getSelected(field.value, option));
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset" error={!!error} sx={sx}>
          {label && (
            <FormLabel component="legend" sx={{ typography: "body2" }}>
              {label}
            </FormLabel>
          )}

          <FormGroup
            sx={{
              flexDirection: row ? "row" : "column",
              [`& .${formControlLabelClasses.root}`]: {
                "&:not(:last-of-type)": {
                  mb: row ? 0 : spacing,
                  mr: row ? spacing : 0,
                },
              },
            }}
          >
            {showSearch && <RHFAutoComplete name={name} displayLabel="title" valueLabel="_id" options={options} />}
            {showSelectAll && <FormControlLabel control={<Checkbox checked={isAllSelected(field?.value || [])} onChange={() => handleSelectAllChange(field)} />} label="Select All" {...other} />}
            <Grid2 container p={0}>
              {options?.map((option) => (
                <Grid2 item size={gridCount}>
                  <FormControlLabel
                    key={option?.[valueLabel]}
                    control={<Checkbox checked={field.value?.some((item) => item[valueLabel] === option?.[valueLabel]) || false} onChange={() => handleOptionClick(field, option)} />}
                    label={option?.[displayLabel]}
                    {...other}
                  />
                </Grid2>
              ))}
            </Grid2>
          </FormGroup>

          {showErrorMessage && (!!error || helperText) && <FormHelperText sx={{ mx: 0 }}>{error ? error.message : helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
