/**
 * TermsConditions - Estonian version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Obfuscate from "react-obfuscate";

const styles = (theme) => ({
  root: {
    padding: "1em 0",
    fontSize: "var(--content)",
  },
});

class TermsConditions extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)" }} maxWidth={false}>
        <Typography component="span">
          <div id="legalcontent" className={classes.root}>
            <h1>NUBET.COM-i ÜLDTINGIMUSED</h1>
            Eestikeelne versioon 1.4, viimati uuendatud 19.12.2024.<br></br>
            <br></br>
            <h3 id="General">1. ÜLDTEAVE</h3>
            1.1. Veebilehte nubet.com (edaspidi: <b>veebileht</b>) haldab Eestis registreeritud ettevõte Vana Lauri OÜ (edaspidi: <b>ettevõte</b>, <b>Vana Lauri</b> või <b>käitaja</b>), registrikood 16427120,
            registrijärgne aadress Roseni 10–135, 10111 Tallinn, Eesti.<br></br>
            <br></br>
            1.2. Vana Lauri tegutseb Eesti Maksu- ja Tolliameti (edaspidi: <b>MTA</b>) tegevusloa alusel ning nende järelevalve all. Vana Lauril on kommertslike veebihasartmängude jaoks tegevusload (HKT000066,
            HKT000067), et pakkuda spordiennustusteenust ja kasiinomänge kooskõlas Eesti seadustega.<br></br>
            <br></br>
            1.3. Nubet.com-i üldtingimustes (edaspidi: <b>tingimused</b>) nimetatud mängud viitavad kihlvedudele, kasiinomängudele ja muudele veebisaidil kättesaadavatele mängudele (edaspidi ühiselt: <b>mängud</b> või{" "}
            <b>teenused</b>). Siinseid tingimusi kohaldatakse siis, kui mängite veebisaidil mänge.<br></br>
            <br></br>
            1.4. Tingimused on reguleeritud Eesti õigusega ja need on ettevõtte veebilehel esitatud rohkem kui ühes keeles. Eesti mängijate jaoks on käesolevate tingimuste ametlik keel eesti keel. Käesolevate tingimuste ametlik keel on inglise keel, mis ei ole eesti keel.<br></br>
            <br></br>
            1.5. Juriidiliste kohustuste ja vastutuse muutumise, tehniliste ja/või äriliste põhjuste tõttu võib ettevõttel aeg-ajalt tekkida vajadus tingimusi muuta. Kui tingimusi muudetakse, teavitatakse muudatustest
            alati veebisaidil.<br></br>
            <br></br>
            1.6. Mängijaid, kes registreeruvad veebisaidi teenuste kasutamiseks (edaspidi: <b>registreeritud mängija</b>), teavitatakse eraldi kõigist tingimuste muudatustest, kui nad logivad oma kontole sisse.
            Sisselogimise ajal ilmub hüpikaken, kus registreeritud mängija peab veebisaidi kasutamise jätkamiseks muudatustega nõustuma, märgistades kasti „I hereby agree on the updated Terms and Conditions“ („Nõustun
            uuendatud tingimustega“).<br></br>
            <br></br>
            1.7. Kõik tingimuste muudatused jõustuvad hetkel, mil need laaditakse üles ja avaldatakse veebisaidil ning on mängijatele internetis üldiselt nähtavad.<br></br>
            <br></br>
            1.8. Kui registreeritud mängija mängib mänge (spordiennustus-, kasiino- ja muid veebisaidil pakutavaid mängutooteid) pärast muudatuste avaldamist, loetakse mängija muudetud tingimustega nõustunuks.<br></br>
            <br></br>
            1.9. Kui registreeritud mängija ei nõustu tingimuste muudatustega, peab ta kohe lõpetama teenuste kasutamise. Kui registreeritud mängija ei nõustu muudatustega eespool nimetatud kasti märgistamise kaudu, ei
            ole tal õigust teenuste kasutamist jätkata.<br></br>
            <br></br>
            1.10. Veebisaidil avaldatud sisu on kaitstud autoriõigusega. Kogu lehel olev materjal on mõeldud ainult mitteäriliseks isiklikuks kasutamiseks. Teenuste kasutamine ei anna registreeritud mängijale veebisaidi
            sisu üle ühtegi intellektuaalse omandi õigust. Veebisaidil olevat teavet, logosid ja pilte on keelatud kopeerida ja kasutada.<br></br>
            <br></br>
            1.11. Ettevõte jätab endale õiguse piirata veebilehe kasutamist igal ajal ilma selgitusteta, kui tundub, et klient on neid sätteid rikkunud.<br></br>
            <br></br>
            <h3 id="Parties">2. LEPINGUPOOLED</h3>
            2.1. Käitaja avab igale registreeritud mängijale tasuta isikliku konto (edaspidi:<b> mängijakonto</b>).<br></br>
            <br></br>
            2.2. Mängijakonto on mõeldud registreeritud mängija mängukulude ja/või panuste haldamiseks, sissemakstud raha tagastamiseks ja võitude väljamaksmiseks.<br></br>
            <br></br>
            2.3. Käitaja aktsepteerib registreeritud mängijatena ainult füüsilisi isikuid, kes on üle vanusepiirangu, mis on sätestatud mängude seaduslikuks mängimiseks.<br></br>
            <br></br>
            2.4. Registreeritud mängijaid ei saa registreerida ettevõtte nimel.<br></br>
            <br></br>
            2.5. Käitaja kontrollib alati, ega registreeritud mängija ei ole veebisaidil mängimiseks nõutavast seadusliku vanuse alampiirist noorem. Seda kontrollitakse seetõttu, et alaealised ei saaks mänge mängida.
            Vanusepiirang võib riigiti erineda. Tavaliselt on vanusepiirang 18 aastat, kuid näiteks Eestis on hasartmängude mängimise vanusepiirang 21 aastat. Eestis asuv alla 21-aastane isik ei tohi mängida hasartmänge,
            kaughasartmänge ega kaugosavusmänge.<br></br>
            <br></br>
            2.6. Käitaja võib keelduda mängija registreerimisest või keelata registreeritud mängijal mängimast, kui kontrollimise või lisakontrollimise nõude tulemus ei ole piisav. Kui registreeritud mängija ei täida
            sellist nõuet mõistliku aja jooksul, võidakse tema konto sulgeda.<br></br>
            <br></br>
            2.7. Registreeritud mängija mõistab, et mõnes jurisdiktsioonis võivad kaughasartmängud olla ebaseaduslikud. Kui VPN- või proksiserveriteenuse kasutamise ajal tuvastatakse võimalik ebaseaduslik tegevus või
            pettus, on ettevõttel õigus asjaomane konto blokeerida ja raha kinni pidada. Ettevõte ei kavatse pakkuda registreeritud mängijatele teenuseid, mis võivad olla vastuolus registreeritud mängija jurisdiktsiooni
            õigusaktidega. Registreeritud mängija kasutab teenuseid omal valikul. Registreeritud mängija kinnitab ja nõustub, et teenuste kasutamine on kooskõlas tema jurisdiktsiooni seadustega. Ettevõte ei vastuta
            ühelgi viisil, kui registreeritud mängija kasutab teenuseid ebaseaduslikult või loata.<br></br>
            <br></br>
            2.8. Eesti tegevusloaga hasartmängukorraldajad ei võta vastu kliente järgmistest riikidest: Afganistan, Albaania, Alžeeria, Ameerika Samoa, Angola, Anguilla, Antigua ja Barbuda, Argentina, Armeenia, Aruba,
            Austraalia, Aserbaidžaan, Bahama, Bangladesh, Barbados, Belgia, Belize, Benin, Bermuda, Bhutan, Bonaire (Sint Eustatius ja Saba), Bosnia ja Hertsegoviina, Botswana, Bouvet’ saar, Briti India ookeani ala,
            Bulgaaria, Burkina Faso, Burundi, Kambodža, Kamerun, Cabo Verde, Kaimanisaared, Kesk-Aafrika Vabariik, Tšaad, Jõulusaar, Kookossaared, Colombia, Komoorid, Kongo (Demokraatlik Vabariik), Cooki saared, Costa
            Rica, Elevandiluurannik, Horvaatia, Kuuba, Curaçao, Küpros, Tšehhi Vabariik, Põhja-Korea, Taani, Djibouti, Dominica, Dominikaani Vabariik, Ecuador, Egiptus, El Salvador, Ekvatoriaal-Guinea, Eritrea, Etioopia,
            Falklandi saared, Fääri saared, Fidži, Prantsusmaa, Guajaana, Prantsuse Polüneesia, Prantsuse Lõunaalad, Gabon, Gambia, Ghana, Gibraltar, Kreeka, Gröönimaa, Grenada, Guadeloupe, Guam, Guatemala, Guinea,
            Guinea-Bissau, Guyana, Haiti, Heard, Püha Tool (Vatikani Linnriik), Honduras, Hongkong, Ungari, Iraan, Iraak, Iisrael, Itaalia, Jamaica, Jordaania, Kasahstan, Kuveit, Kõrgõzstan, Laos, Läti, Lesotho,
            Libeeria, Liibüa, Leedu, Madagaskar, Malawi, Malaisia, Maldiivid, Mali, Marshalli saared, Martinique, Mauritaania, Mauritius, Mayotte, Mehhiko, Mikroneesia, Montenegro, Montserrat, Mosambiik, Myanmar,
            Namiibia, Nauru, Nepal, Holland, Hollandi Antillid, Uus-Kaledoonia, Nicaragua, Niger, Nigeeria, Niue, Norfolki saar, Põhja-Korea, Põhja-Mariana Saared, Omaan, Pakistan, Puerto Rico, Réunion, Rumeenia, Rwanda,
            Saint-Barthelemy, Saint Helena, Ascension ja Tristan da Cunha, Saint Kitts ja Nevis, Saint Lucia, Saint-Martini territoriaalühendus, Saint-Pierre ja Miquelon, Saint Vincent ja Grenadiinid, Samoa, San Marino,
            São Tomé ja Príncipe, Senegal, Seišellid, Sierra Leone, Singapur, Sint Maarten, Slovakkia, Sloveenia, Saalomoni Saared, Somaalia, Lõuna-Georgia ja Lõuna-Sandwichi saared, Lõuna-Sudaan, Hispaania, Sri Lanka,
            Palestiina riik, Sudaan, Surinaame, Svalbard ja Jan Mayen, Svaasimaa, Šveits, Süüria, Taiwan, Tadžikistan, Tansaania, Ida-Timor, Togo, Tokelau, Tonga, Trinidad ja Tobago, Tuneesia, Türgi, Türkmenistan, Turks
            ja Caicos, Tuvalu, Valgevene, USA Neitsisaared, Uganda, Ühendkuningriik, Ühendriikide hajasaared, Ameerika Ühendriigid ja kõik selle baasid, sõjaväebaasid ja territooriumid, Usbekistan, Vanuatu, Venezuela,
            Briti Neitsisaared, Wallis ja Futuna, Lääne-Sahara, Jeemen, Antarktika, Makedoonia, Moldova, Mongoolia, Kenya, Kiribati ja Liibanon.<br></br>
            <br></br>
            2.9. Kui registreeritud mängija rikub eespool nimetatud riigipõhist reeglit, jätab käitaja endale õiguse peatada kohe registreeritud mängija mängijakonto ning pidada kinni kõik rahaülekanded mängijakontolt ja
            mängijakontole.<br></br>
            <br></br>
            2.10. Ettevõtte rahapesuvastaste nõuete ja asjakohaste vastutustundliku hasartmängimise kohustustega kooskõlas võib ettevõte mängijakonto ka sulgeda või peatada.<br></br>
            <br></br>
            2.11. Igal registreeritud mängijal võib olla ainult üks mängijakonto. Selle reegli rikkumise korral võidakse sulgeda kõik registreeritud mängija mängijakontod, kusjuures ettevõte maksab tagasi ainult raha,
            mis on registreeritud mängija tehtud sissemaksetest alles.<br></br>
            <br></br>
            2.12. Käitaja jätab endale õiguse keelduda registreerimisest ilma selgitusteta.<br></br>
            <br></br>
            <h3 id="Playeraccount">3. MÄNGIJAKONTO KASUTAMINE JA MÄNGIJA VASTUTUS </h3>
            3.1. Tingimustega nõustudes kinnitab registreeritud mängija, et ta ei tegutse teise isiku nimel.<br></br>
            <br></br>
            3.2. Mõlemad lepingupooled võivad mängijakonto kohe ajutiselt või lõplikult sulgeda, kui teine pool ei täida siinsetest tingimustest tulenevaid kohustusi. Allesjäänud krediidi väljamaksmist reguleerivad
            siinsed tingimused. Mängijakonto ajutine või lõplik sulgemine ei vabasta pooli nende juriidilistest õigustest ja kohustustest.<br></br>
            <br></br>
            3.3. Registreeritud mängija nõustub ka sellega, et tal ei ole õigust lubada kolmandatel isikutel, olgu siis juhuslikult või tahtlikult, alaealistel või muudel isikutel, oma mängijakontole sisse logida.
            Ettevõte ei vastuta selle eest, kui registreeritud mängija ei täida seda sätet, sealhulgas hooletuse ja/või tahtliku rikkumise tõttu. Kliendi kontole on võimalik pääseda juurde ainult kasutajanime ja
            salasõnaga ning registreeritud mängija vastutab selle eest, et need on teada ainult talle ja et need ei ole näiteks salvestatud seadmesse, millele pääsevad juurde ka teised isikud, või kirjutatud üles kuhugi,
            kus need võivad sattuda teise isiku valdusesse.<br></br>
            <br></br>
            3.4. Ettevõte võib paluda registreeritud mängijal muuta oma salasõna või peatada tema mängijakonto ajutiselt, kui ettevõttel on alust kahtlustada oma infoturbepõhimõtete rikkumist või kui mängijakonto
            turvalisus on ohus.<br></br>
            <br></br>
            3.5. Ettevõte ei vastuta eeskirjadega kooskõlas registreeritud kasutaja teenuste kasutamisest tulenevate (nii otseste kui ka kaudsete) kahjude eest, välja arvatud juhul, kui tegemist on raske hooletuse,
            tahtliku väärkäitumise või pettusega.<br></br>
            <br></br>
            3.6. Raha tohib üle kanda ainult samalt kontolt ja samale kontole, mis nimetati registreerimise ja kontrollimise käigus. Digitaalsete abivahendite, näiteks robotite kasutamine on keelatud.<br></br>
            <br></br>
            3.7. Registreeritud mängijal ei ole lubatud kanda raha üle kolmanda isiku konto kaudu.<br></br>
            <br></br>
            3.8. Ettevõte ja selle partnerid, näiteks sidusettevõtjad, ei vastuta kunagi selliste kahjude hüvitamise eest, mida registreeritud mängija on kandnud teenuseid kasutades. Ettevõte pakub õnnemänge, kus kaotuse
            risk on täielikult registreeritud mängija kanda, kui ta otsustab mängida veebisaidil pakutavaid õnnemänge.<br></br>
            <br></br>
            3.9. Ettevõte ei esita maksuhalduritele automaatseid aruandeid registreeritud mängijate võitude kohta. Kui registreeritud mängija on kohustatud oma sissetulekud maksuhaldurile deklareerima, vastutab ta
            sellise kohustuse täitmise eest ainuisikuliselt.<br></br>
            <br></br>
            3.10. Ettevõte tagab, et kogu teave mängu kohta, sealhulgas selle tingimused ja võidutõenäosused.<br></br>
            <br></br>
            3.11. Ettevõte jätab endale õiguse lükata panuseid tagasi igal ajal ilma selgituseta.<br></br>
            <br></br>
            3.12. Kui registreeritud mängijal on küsimusi, võib ta igal ajal võtta ühendust käitaja klienditeenindusega aadressil <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate>.<br></br>
            <br></br>
            3.13. Ettevõte jätab endale õiguse igal ajal ja ilma ette teatamata eemaldada või muuta registreeritud mängijale pakutavaid teenuseid või oma veebisaiti ning lisada pakkumisi veebisaidile ja neid sealt
            eemaldada.<br></br>
            <br></br>
            <h3 id="Harmful">4. KAHJULIK TEGEVUS</h3>
            4.1. Käitaja on kohustatud jälgima rahapesu ja muud kuritegevusega seotud rahastamist. Registreeritud mängijad on kohustatud abistama käitajat kõigi ülalnimetatuga seotud päringute ja küsitluste puhul.
            <br></br>
            <br></br>
            4.2. Kui avastate veebisaidil mis tahes kahtlase tegevuse, peate sellest kohe käitajale teatama.<br></br>
            <br></br>
            <h3 id="Transactions">5. FINANTSTEHINGUD</h3>
            5.1. Ettevõte võtab sissemakseid vastu eurodes (EUR).<br></br>
            <br></br>
            5.2. Pärast veebisaidile sisselogimist on registreeritud mängijale kättesaadav konto väljavõte koos kõigi finantstehingutega.<br></br>
            <br></br>
            5.3. Võimaluse korral peab registreeritud mängija kasutama väljamaksete tegemiseks sama maksekontot, millelt ta tegi sissemakse.<br></br>
            <br></br>
            5.4. Registreeritud mängijal ei ole õigust saada intressi oma mängijakontol oleva raha pealt, olenemata sellest, kui kaua ta raha oma mängijakontol hoiab.<br></br>
            <br></br>
            5.5. Kohustuslike nõuetega kooskõlas tagab käitaja, et panused ja maksed, mis kantakse käitaja kontole panuste tegemiseks, pärinevad ainult sama registreeritud mängija arvelduskontolt või sama käitaja
            mängukohas registreeritud mängijalt ning väljamaksed tehakse samale arvelduskontole, millelt registreeritud mängija on teinud panuste tegemise eesmärgil hasartmängukorraldaja kontole sissemakseid.<br></br>
            <br></br>
            5.6. Käitaja kannab sissemakstud raha mängijate kontole, kui käitaja on saanud makseteenuse osutajalt / krediitkaardifirmalt / pangalt kinnituse, et registreeritud mängija on sissemakse teinud.<br></br>
            <br></br>
            5.7. Ettevõtte põhimõte on kanda võidud peaaegu kohe registreeritud mängija mängijakontole. Kuritegevuse või muu volitamata tegevuse kahtluse korral jätab ettevõte endale õiguse peatada võitude krediteerimine
            kuni uurimise tulemuste selgumiseni.<br></br>
            <br></br>
            5.8. Maksetähtaeg võib ulatuda mõnest minutist kuni 24 tunnini pärast väljamaksetaotluse töötlemist.<br></br>
            <br></br>
            5.9. Üle 20 000 euro suuruste võitude puhul võidakse registreeritud mängijaga leppida kokku mõistlik maksegraafik. Näiteks võib mõistlikuks lugeda 100 000 euro maksmist 20 000 euro suuruste osadena viie päeva
            jooksul.<br></br>
            <br></br>
            5.10. Maksimaalne väljamaksesumma ühe kihlveosedeli kohta on 100 000 eurot.<br></br>
            <br></br>
            5.11. Ühe registreeritud mängija maksimaalse koguvõidu piirang on 200 000 eurot nädalas (esmaspäevast kella 00.00 EET kuni pühapäevani kella 23.59 EET).<br></br>
            <br></br>
            5.12. Kõikide panustevalikute kohta kehtivad varem kehtestatud piirangud, mis võivad olla eespool toodud piirangutest madalamad.<br></br>
            <br></br>
            5.13. Ettevõte ei võta oma registreeritud mängijatelt rahaülekannete eest tasu. Registreeritud mängija vastutab selle eest, et ta uuriks, kas tema kasutatav teenuseosutaja võtab temalt rahaülekannete tegemise
            eest tasu.<br></br>
            <br></br>
            5.14. Ettevõte nõuab, et mis tahes sissemakset mängitaks läbi vähemalt üks kord (sissemakset riskitaks vähemalt üks kord), ja võib keelduda väljamaksetest, kui seda ei tehta.<br></br>
            <br></br>
            5.15. Registreeritud mängija raha hoitakse ettevõtte kapitalist eraldi ja seega on mängijakontol olev raha kaitstud, kui ettevõte peaks muutuma maksejõuetuks.<br></br>
            <br></br>
            5.16. Ettevõttel on õigus registreeritud mängija sisse- ja väljamaksed peatada, kui mängijakonto kasutamiseks on vaja kontrollida või uuesti kontrollida registreeritud mängija isikusamasust, vanust või muud
            teavet (edaspidi: <b>kontrollimine</b>).<br></br>
            <br></br>
            5.17. Kontrollimise üle otsustab üksnes käitaja.<br></br>
            <br></br>
            5.18. Ettevõte on kooskõlas asjakohaste rahapesuvastaste nõuetega kohustatud veenduma, et registreeritud mängija oleks tõendanud oma vanust, isikusamasust, isikukoodi (sotsiaalkindlustusnumbrit), aadressi ja
            raha päritolu.<br></br><br></br>
            5.19. Vastavalt rahapesu tõkestamise poliitikale jätab ettevõte endale õiguse teha kontrolle i) ärisuhte loomisel; ii) võidu väljamaksmisel, panuse tegemisel või mõlemal juhul, kui registreeritud mängija
            sissemakstud või talle makstav summa on vähemalt 2000 eurot või samaväärne summa muus valuutas, olenemata sellest, kas raha makstakse ühekordse maksena või mitme seotud maksena kuni ühe kuu jooksul; iii)
            hoolsusmeetmete rakendamisel kogutud teabe kontrollimisel või juhul, kui asjakohaste andmete uuendamisel tekib kahtlus kogutud dokumentide või andmete piisavuses või tõesuses; iv) rahapesu või terrorismi
            rahastamise kahtluse korral.<br></br>
            <br></br>
            5.20. Kui kontrollimiseks on vaja registreeritud mängijaga suhelda ja temalt lisaselgitusi küsida, võidakse mängijakonto kuni ülevaatamiseni peatada. Kõige tavalisem põhjus lisakontrollimiseks ja rahapesu
            tõkestamise lisameetmete võtmiseks on erakordselt suur mängude arv.<br></br>
            <br></br>
            5.21. Isik saab registreeritud mängija olla ainult siis, kui ta on kasutatava raha seaduslik omanik ja raha ei ole omandatud kuritegelikul teel.<br></br>
            <br></br>
            5.22. Ettevõte töötleb kõiki väljamaksetaotlusi ühe kuni kuue (1–6) tööpäeva jooksul alates taotluse kättesaamisest. On võimalik, et tähtaega pikendatakse näiteks KYC toimingute, väljamaksetaotluse
            lisakontrollimise või mis tahes muu vajaliku kontrollimise tõttu enne väljamakse töötlemist.<br></br>
            <br></br>
            5.23. Mängides peab registreeritud mängija tunnistama, et tooteid tarbitakse kohe ning ettevõte ei saa mängu ajal või pärast seda tooteid tagastada, tühistada ega nende eest raha tagasi maksta.<br></br>
            <br></br>
            5.24. Keelatud on kanda raha ühe registreeritud mängija mängijakontolt teise mängija kontole.<br></br>
            <br></br>
            5.25. Ettevõte jätab endale õiguse peatada väljamaksed, konfiskeerida mängijakonto jäägi, tühistada panused, piirata registreeritud mängija mängijakonto, kui ettevõttel on mõistlik põhjus kahtlustada, et:
            registreeritud mängija on seotud pettusega; mängu/sündmuse ausus on ohustatud; registreeritud mängija on seotud tulemuste kokkuleppimisega; registreeritud mängija on seotud tehnilise süsteemi kasutamisega,
            mille eesmärk on manipuleerida või mõjutada toote (mängu, mänguraundi, panuse) tulemust; registreeritud mängija kuulub rühmitusse, mis teeb rahalise kasu eesmärgil ühiselt palju panuseid; registreeritud
            mängija on seotud mis tahes muu ebaseadusliku, kuritegeliku või tingimustega vastuolus oleva tegevusega.<br></br>
            <br></br>
            5.26. Kõigist pettustest, kuritegelikest või kahtlastest tegevustest teatatakse asjakohastele asutustele.<br></br>
            <br></br>
            5.27. Kehtiv panus kinnitatakse kihlveosedeli numbri kaudu. Kihlveosedeli number on saadaval mängijakontole sisse logides menüüs „My Bets“ („Minu kihlveod“).<br></br>
            <br></br>
            5.28. Mängitud kasiinomängud, mänguraundid ja tehtud panused on saadaval mängijakontole sisse logides menüüs „Statement“ („Väljavõte“).<br></br>
            <br></br>
            <h3 id="Duty">6. HOOLSUSKOHUSTUS, ENESEPIIRANGUD JA LIMIIDID</h3>
            6.1. Registreeritud mängijad võivad piirata ise oma mängimist või sulgeda oma mängijakonto, kasutades mängijakontol toodud piiranguvahendeid või võttes ettevõttega ühendust klienditoe või e-posti teel.
            Ettevõte pakub oma registreeritud mängijatele piiranguid, limiite ja enesevälistamise võimalust kooskõlas kehtivate eeskirjadega.<br></br>
            <br></br>
            6.2. Registreeritud mängijad võivad end igal ajal lisada MTA riiklikku enesevälistusregistrisse, misjärel ei saa registreeritud mängija mängida mänge, mida pakuvad Eestis tegevusluba vajavad
            hasartmängukorraldajad. Registreeritud mängija võib taotleda enesevälistust aadressil https://www.emta.ee/eraklient/e-teenused-maksutarkus/registrid-paringud/hasartmangu-piirangu-registreerimine.<br></br>
            <br></br>
            6.3. Registrit mängijatest, kellel on hasartmängimise keeld, peab MTA ning ettevõte jätab registrisse kantud mängijad oma hasartmängupakkumisest välja. Selle tulemusena on võimalik, et Vana Lauri keeldub
            mängija registreerimisest või ei luba registreeritud mängijatel enam mängida.<br></br>
            <br></br>
            6.4. Registreeritud mängijad peavad hasartmänge mängides oma panuseid aktiivselt täpsustama ja kinnitama.<br></br>
            <br></br>
            6.5. Ettevõte ega ükski ettevõtte nimel töötav hasartmänguagent ei paku ega anna mitte mingil juhul panuste tegemiseks krediiti.<br></br>
            <br></br>
            6.6. Registreeritud mängijad võivad oma mängijakontole seada eri tüüpi limiite.<br></br>
            <br></br>
            6.7. Pärast registreerimist pakutakse veebisaidil võimalust määrata kuine kaotuslimiidid. Koatuslimiidid on saadaval ka seadete vahekaardil „Limiidid“.<br></br>
            <br></br>
            <h3 id="Disputes">7. VAIDLUSED</h3>
            7.1. Kui registreeritud mängijal on kaebus või ta soovib esitada ettevõtte vastu teenustega seotud nõude, võib registreeritud mängija esitada nõude mis tahes viisil, kujul ja/või vormis. Soovitame siiski
            esitada kõik kaebused ja nõuded vestluse teel või e-posti aadressil <Obfuscate email={"support@nubet.com"}>support@nubet.com</Obfuscate>. Ettevõte püüab kaebusi menetleda võimalikult kiiresti, igal juhul 15
            päeva jooksul.<br></br>
            <br></br>
            7.2. Kui veebisaidi mängudes esineb tarkvaravigu, võib ettevõte kaaluda tulemuste tühistamist ulatuses, milles viga on neid mõjutanud. Registreeritud mängija mängimine katkestatakse igasuguse nii õnnestunud
            kui ka ebaõnnestunud manipuleerimiskatse puhul, mille järel rakendatakse tõenäoliselt õiguslikke meetmeid.<br></br>
            <br></br>
            7.3. Teenuste ajutise katkestuse korral makstakse kõik teenuste ajutise katkestuse ajal tehtud panused ja kulutatud raha registreeritud mängijatele täielikult tagasi. Kui registreeritud mängija lõpetab
            mängimise, jätkub lõpetamata mäng siis, kui registreeritud mängija hakkab mängu uuesti mängima.<br></br>
            <br></br>
            7.4. Kui te ei jõua meiega kokkuleppele või ei ole rahul oma probleemi lahendusega, võite üritada lahendada vaidlust alternatiivsel viisil. Ettevõte soovitab järgmisi alternatiivseid viise:<br></br> Euroopa
            veebipõhine vaidluste lahendamise platvorm, mida pakub Euroopa Komisjon. Üksikasjaliku teabe ja Euroopa Komisjoni pakutava veebipõhise vaidluste lahendamise platvormi leiate aadressil
            https://ec.europa.eu/consumers/odr/main/?event=main.complaints.screeningphase.<br></br>
            <br></br>
            <h3 id="Limitation">8. VASTUTUSE PIIRAMINE</h3>
            8.1. Ettevõte ei vastuta tarkvaraga seotud probleemide ja vigade eest, mis on takistanud registreeritud mängijal veebisaiti kasutada või peatanud mängu, välja arvatud raske hooletuse, tahtliku väärkasutamise
            korral või kohustuslike reeglite rakendamise korral. Kui ilmneb andmeedastusviga, mille tõttu ei saa veebisaidi serverid tehtud panust kätte, siis jäetakse panus tegemata ja selle summat ei võeta
            mängijakontolt maha. Kui tarkvaras või juhuslike arvude generaatoris esineb mõni muu viga, tühistatakse panus ja panusesumma tagastatakse registreeritud mängijale.<br></br>
            <br></br>
            8.2. Kui ettevõte maksab registreeritud mängijale vea tõttu ekslikult võidud välja, loetakse raha endiselt ettevõttele kuuluvaks ja seega arvatakse see mängija kontolt maha. Kui registreeritud mängija on raha
            juba välja võtnud, peab ta kohe teavitama käitaja kliendituge, et asi lahendada ja vältida edasisi tagajärgi.<br></br>
            <br></br>
            8.3. Ettevõte ja selle töötajad ning muu ettevõttega seotud personal ei saa tagada, et ettevõtte tarkvara töötab alati laitmatult.<br></br>
            <br></br>
            8.4. Registreeritud mängija kinnitab, et ta on isik, kes on veebisaidil mängimiseks piisavalt vana ja täie teovõimega kooskõlas selle riigi seadustega, kus ta asub või elab alaliselt. Peale selle mõistab
            registreeritud mängija oma tegevuste tähtsust, nõustub, et teenuse kasutamise valiku teeb ta ise, ja mõistab, et ta haldab oma kontol olevat raha.<br></br>
            <br></br>
            8.5. Ettevõte ei vastuta selliste internetisaitide sisu eest, millele pääseb ettevõtte veebisaidi kaudu.<br></br>
            <br></br>
            8.6. Siinsetes tingimustes sätestatud vastutuse piirangud ja välistused ei kehti ulatuses, milles need piirangud või välistused ei ole rakendatavad kohaldatava õiguse alusel.<br></br>
            <br></br>
            <h3 id="Protection">9. ANDMEKAITSE</h3>
            9.1. Ettevõte väärtustab registreeritud mängijate isikuandmete puutumatust ja konfidentsiaalsust, nagu on määratletud nubet.com-i privaatsuspõhimõtetes (edaspidi: <b>privaatsuspõhimõtted</b>). Ettevõte kogub,
            töötleb ja jagab ainult sellist teavet, mis on vajalik teenuste osutamiseks. Ettevõte töötleb registreeritud mängija esitatud isikuandmeid rangelt kooskõlas oma privaatsuspõhimõtete ning kehtivate
            andmekaitseseaduste ja -määrustega.<br></br>
            <br></br>
            9.2. Nõustudes ettevõtte privaatsuspõhimõtetega ja andes ettevõttele oma isikuandmed, nõustub registreeritud mängija oma isikuandmete töötlemisega privaatsuspõhimõtetes kirjeldatud eesmärkidel.<br></br>
            <br></br>
          </div>
        </Typography>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(TermsConditions));
