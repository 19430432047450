import { Paper } from "@mui/material";
import { styled } from "@mui/system";

export const StyledContainer = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.dbPaper,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    width: "60%",
    margin: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: "45%",
    margin: "auto",
  },
}));
