import { Box, Grid2, Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import SportsLayout from "../@components/SportsLayout";
import SportsHeader from "../@components/SportsHeader";
import React, { useEffect } from "react";
import { getRacingGamesAsync, getRacingLeaguesAsync } from "../../../redux-store/sports-reducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PaperContainer } from "../@components/containers";
import { Translate } from "react-redux-i18n";
import RacingRegularLayout from "./regular-layout";
import TrottingSystemLayout from "./system-layout";
import RacingResults from "./results";
import RacingVerticalBanner from "../../banners/racing-vertical";

const RacingGamesPage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [tabValue, setTabValue] = React.useState("regular-layout");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const lang = useSelector((state) => state.i18n.locale);
  const racingLeagues = useSelector((state) => state.sports.racing.list);
  const games = useSelector((state) => state.sports.racing.games);
  const leagueId = Number(params.leagueId);
  const selectedLeague = racingLeagues?.find((lg) => lg.leagueId === leagueId);

  useEffect(() => {
    dispatch(getRacingLeaguesAsync(lang));
    dispatch(getRacingGamesAsync({ lang, leagueId }));
  }, [params.leagueId]);

  return (
    <SportsLayout>
      <Box pb={{ xs: 0, md: 2 }}>
        <Grid2 container spacing={1}>
          <Grid2 item size={2.5} sx={{ display: { xs: "none", md: "block" } }}>
            <RacingVerticalBanner />
          </Grid2>
          <Grid2 item size={{ xs: 12, md: 9.5 }}>
            <Stack spacing={1}>
              <SportsHeader collection="horseracing" league={selectedLeague} sportCode={"TR"} />
              <PaperContainer>
                <Box sx={{ "& .MuiTabPanel-root": { padding: 0 } }}>
                  <TabContext value={tabValue}>
                    <Box
                      pl={1}
                      pb={1}
                      sx={{
                        "& .MuiTab-root": {
                          fontWeight: "400",
                        },
                        "& .Mui-selected": {
                          color: "primary.menuTopActive",
                          fontWeight: "400",
                        },
                      }}
                    >
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label={<Translate value="marketSets.outrights" />} value="regular-layout" />
                        <Tab label={<Translate value="slip.system" />} value="system-layout" />
                        <Tab label={<Translate value="menu.results" />} value="results" />
                      </TabList>
                    </Box>
                    <TabPanel value="regular-layout">
                      <RacingRegularLayout leagueId={leagueId} gamesList={games || []} />
                    </TabPanel>
                    <TabPanel value="system-layout">
                      <TrottingSystemLayout leagueId={leagueId} gamesList={games || []} />
                    </TabPanel>
                    <TabPanel value="results">
                      <RacingResults leagueId={leagueId} results={selectedLeague?.results || {}} />
                    </TabPanel>
                  </TabContext>
                </Box>
              </PaperContainer>
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </SportsLayout>
  );
};

export default RacingGamesPage;
