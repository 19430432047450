// Racing main page
import { useEffect } from "react";
// @MUI
import { Box, Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { Grid, Paper, Typography } from "@mui/material";
// @Redux utils
import { useDispatch, useSelector } from "react-redux";
import { getRacingLeaguesAsync } from "../../../redux-store/sports-reducer";
// external components
import FeaturedOutrightsBanner from "../../banners/featured-outright";
import { Translate } from "react-redux-i18n";
import PageLoader from "../../PageLoader";
import SportsLayout from "../@components/SportsLayout";
import RacingLeague from "../@components/RacingLeague";
import { PaperContainer } from "../@components/containers";

const RacingPage = () => {
  // redux action dispatcher
  const dispatch = useDispatch();

  // selectors
  const isPageLoading = useSelector((state) => state.sports.racing.isRacingLoading);
  const lang = useSelector((state) => state.i18n.locale);
  const racingLeagues = useSelector((state) => state.sports.racing.list);
  const layout = useSelector((state) => state.settings.layout);

  // functions
  useEffect(() => {
    dispatch(getRacingLeaguesAsync(lang));
  }, []);

  return (
    <SportsLayout>
      <Box>
        {layout === "mouse" && (
          <Box pb={0.5}>
            <FeaturedOutrightsBanner />
          </Box>
        )}
        {isPageLoading && !racingLeagues.length ? (
          <PageLoader />
        ) : !racingLeagues.length && !isPageLoading ? (
          <Container
            style={{
              backgroundImage: "url('https://storage.googleapis.com/nubet-prod-content-news/bg_norace_2.png')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100%",
            }}
            maxWidth={false}
          >
            {/* <EmptyScreen displayBackground message={<Translate value="labels.racing_empty_screen_message" />} /> */}
            <Grid container spacing={2} style={{ textAlign: "center", padding: "2em", height: "55Vh" }} alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={12}>
                <Typography variant="h7">
                  <Translate value="labels.racing_empty_screen_message" />
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={12}><Typography variant="h7"><Translate value="labels.racing_empty_screen_message_1" /></Typography></Grid>
              <Grid item xs={12} sm={12}>
              <Typography variant="body">
                23.12. Oulu<br></br>
                23.12. Eskilstuna<br></br>
                26.12. Lahti<br></br>
                26.12. Solvalla<br></br>
                27.12. Seinäjoki<br></br>
                28.12. Kuopio<br></br>
                28.12. Halmstad<br></br>
                30.12. Jyväskylä<br></br>
                31.12. Teivo<br></br>
                31.12. Axevalla<br></br>
                1.1. Oulu
                </Typography>
              </Grid> */}
            </Grid>
          </Container>
        ) : (
          <Stack gap={0.5}>
            {racingLeagues.map((league) => (
              <PaperContainer key={league.leagueId}>
                <RacingLeague {...league} />
              </PaperContainer>
            ))}
          </Stack>
        )}
      </Box>
    </SportsLayout>
  );
};

export default RacingPage;
