// @MUI components
import { Box, Divider, Stack, styled, Typography } from "@mui/material";
// components
import CountryAvatar from "./CountryAvatar";
import Iconify from "../../iconify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const StyledRow = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const LeagueRow = ({ labelCategory, label, leagueId, countryCode, gameCount, leaguesCount, rowIndex }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${location.pathname}/league/${leagueId}?${searchParams.toString()}`);
  };

  return (
    <Stack>
      <StyledRow onClick={handleClick}>
        <Stack direction="row" alignItems="center">
          <Stack direction="row" alignItems="center" gap={1}>
            <CountryAvatar countryCode={countryCode} />
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="subtitle1" component="p">
                {labelCategory}
              </Typography>
              <Typography variant="body2" color="text.secondary" component="p" noWrap>
                {label}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" gap={1}>
            <Typography align="end" variant="subtitle1" color="primary">
              {gameCount}
            </Typography>
            <Iconify icon="ic:outline-chevron-right" />
          </Stack>
        </Stack>
      </StyledRow>
      {leaguesCount !== rowIndex && <Divider />}
    </Stack>
  );
};

export default LeagueRow;
