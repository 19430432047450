import { Box, IconButton, Paper, Stack, styled, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import CountryAvatar from "./CountryAvatar";
import Iconify from "../../iconify";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { getCatalogTime } from "../../../helpers/utilities";
import { Translate } from "react-redux-i18n";

const bgImages = {
  FB: require("../../../media/img/bg_gamebox_large_FB.jpg"),
  BA: require("../../../media/img/bg_gamebox_large_BA.jpg"),
  AF: require("../../../media/img/bg_gamebox_large_AF.jpg"),
  IH: require("../../../media/img/bg_gamebox_large_IH.jpg"),
  TR: require("../../../media/img/bg_gamebox_large_TR.jpg"),
};

const StyledContainer = styled(Paper)(({ theme, sportCode }) => ({
  backgroundImage: `url(${bgImages[sportCode]})`,
  backgroundSize: "cover",
  borderRadius: 10,
  [theme.breakpoints.down("md")]: {
    borderRadius: 0,
  },
}));

const ContainerOverLay = styled(Box)(({ theme }) => ({
  backgroundImage: "linear-gradient(180deg, rgba(33,40,60,0) 0%, rgba(33,40,60,0.979411833092612) 80%)",
  minHeight: 100,
  [theme.breakpoints.down("md")]: {
    borderRadius: 0,
  },
}));

const SportsHeader = ({ sportCode, league, game, collection, ...other }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sportsLabels = useSelector((state) => state.labels.sports);
  const locale = useSelector((state) => state.i18n.locale);
  const getSportName = () => {
    if (sportsLabels) return sportsLabels.default[sportCode];
  };

  const gameTime = game?.game_time ? new Date(game?.game_time) : new Date(game?.startTime);
  const displayTime = isNaN(gameTime) ? new Date() : gameTime;

  const handleNavBack = () => {
    if (location.pathname.includes("/betbuilder/game")) {
      navigate(`/${locale}/betbuilder/league/${league?.leagueId}?selected-sport=${sportCode}`);
    } else if (location.pathname.includes("/betbuilder/league")) {
      navigate(`/${locale}/betbuilder?selected-sport=${sportCode}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <StyledContainer sportCode={sportCode} {...other}>
      <ContainerOverLay>
        <Stack px={2} pt={1} gap={1}>
          <Stack direction="row" alignItems="center" gap={1}>
            {league || game ? (
              <IconButton size="small" onClick={handleNavBack}>
                <Iconify icon="ic:round-arrow-back" />
              </IconButton>
            ) : null}
            <Typography variant="h5" fontSize={22} component="p">
              {getSportName()}
            </Typography>
            <Typography variant="body2" component="p" color="text.secondary">
              {collection === "player-props" ? <Translate value={`marketSets.player`} /> : <Translate value={`marketSets.betbuilder`} />}
            </Typography>
          </Stack>
          {league && (
            <Stack direction="row" alignItems="center" gap={1}>
              <CountryAvatar countryCode={league?.countryCode} />
              <Typography variant="h6" component="p">
                {league?.label}
              </Typography>
            </Stack>
          )}
        </Stack>
        {game && (
          <Stack direction="row" alignItems="center" justifyContent="space-around" p={1} gap={1}>
            <Stack width={"35%"}>
              <Typography variant="h5" component="p" align="center">
                {game?.game_title ? game?.game_title.split(" v ")[0] : game?.team1}
              </Typography>
            </Stack>
            <Stack width={"30%"}>
              <Typography align="center" variant="h5">
                {format(displayTime, "dd.MM. HH:mm")}
              </Typography>
            </Stack>
            <Stack width={"35%"}>
              <Typography variant="h5" component="p" align="center">
                {game?.game_title ? game?.game_title.split(" v ")[1] : game?.team2}
              </Typography>
            </Stack>
          </Stack>
        )}
      </ContainerOverLay>
    </StyledContainer>
  );
};

export default SportsHeader;
