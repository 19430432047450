import { FormControl, FormHelperText, InputAdornment, styled, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Iconify from "../iconify";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-input": {
    color: "var(--color-input-amount)",
    fontSize: "1.5em",
    textAlign: "center",
    width: "100%",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  "& .MuiFormHelperText-root": {
    color: `${theme.palette.text.secondary} !important`,
    fontSize: "13px",
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: 0,
    marginLeft: 0,
  },
}));

const RHFAmountField = ({ name, helperText, type, ...other }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledFormControl fullWidth>
          <TextField
            {...field}
            variant="outlined"
            type={type}
            value={type === "number" && field.value === 0 ? "" : field.value}
            onChange={(event) => {
              if (type === "number") {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
            }}
            error={!!error}
            aria-describedby="rhf-text-field"
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="ic:twotone-euro" />
                  </InputAdornment>
                ),
              },
            }}
            {...other}
          />
          <FormHelperText error={!!error} id="rhf-text-field">
            {error ? error?.message : helperText}
          </FormHelperText>
        </StyledFormControl>
      )}
    />
  );
};

export default RHFAmountField;
