/**
 * Main App component - define routing and other configurations for whole application casino
 */
import "./@global-css/App.css";
import "./@global-css/Theme.css";
import { log } from "../config/log";
import hostConfig from "../config/hostConfig";
import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { CookieBanner } from "@palmabit/react-cookie-law";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Input from "@mui/material/Input";
import { Translate } from "react-redux-i18n";
import { getLabelsMarkets, getComboBoost, getPrecannedBets } from "../actions/index";
import { setLayout, setHost, setIsMobile, getMaintananceStatusAsync, toggleMyTicketsPanel } from "../redux-store/settings-reducer";
import { getTicketsAsync, getTicketGamesAsync } from "../redux-store/tickets-reducer";
import { setLocaleWithFallback } from "../actions/i18n";
import MenuTop from "./MenuTop";
import MenuBottom from "./MenuBottom";

import FooterDE from "./info/de/Footer";
import FooterEN from "./info/en/Footer";
import OutdatedBrowser_EN from "./info/en/OutdatedBrowser";

import FooterET from "./info/et/Footer";
import FooterFI from "./info/fi/Footer";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import { Helmet } from "react-helmet";
//Widgtes pages

import AppRouter from "../Router";
import withRouter from "../helpers/withRouter";
import AppStateLoader from "./user/AppStateLoader";

const styles = (theme) => ({
  root: {
    "& .MuiGrid-spacing-xs-2 > .MuiGrid-item": {
      // opacity: "0.97",
    },
    "& .MuiGrid-spacing-xs-2": {
      margin: "0",
    },
    "& .MuiGrid-spacing-xs-1 > .MuiGrid-item": {
      // opacity: "0.96",
      padding: "2px 4px 0 0",
    },
    "& .MuiGrid-spacing-xs-1": {
      margin: "0",
      width: "100%",
    },
    fontSize: "var(--content)",
  },
  overlay: {
    width: "100%",
    height: "100vh",
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "999999999",
    background: "rgba(0,0,0,0.5)",
  },
  maintenanceWrapper: {
    width: "100%",
    minHeight: "100vh",
    fontSize: "48px",
    color: "var(--color-maintenance)",
    position: "relative",
    backgroundImage: "var(--bg-sportsbetting)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    "@media only screen and (max-width: 903px)": {
      fontSize: "32px",
    },

    "@media only screen and (max-width: 603px)": {
      fontSize: "24px",
    },
  },
  maintenanceContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
});

/**
 * Get Parameter by name from URL query
 */

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Get Browser information
 */

function getBrowserInformation() {
  var ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }

  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return {
    name: M[0],
    version: M[1],
  };
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.intervalId = null;
    this.automaticTimeoutTimer = null;
    this.isOutdatedBrowser = false;

    let dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 365);

    this.state = {
      dateFrom: dateFrom,
      dateTo: Date.now(),
      open: false,
      minutes: "",
      logoutTime: 30,
      password: "",
      securityModal: true,
      cookieModal: true,
    };
  }

  /**
   * update view mode ( mouse / mobile )
   */

  updateViewMode = () => {
    let self = this;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      if (self.props.layout !== "touch") {
        // Cookies.set('layout', "touch", { path: '/' });
        localStorage.setItem("layout", "touch");
        self.props.setLayout("touch");
      }
    }
  };

  /**
   * Format Date to YYYY-MM-DD
   */

  formatDateToApi(date) {
    const ye = new Intl.DateTimeFormat("de", { year: "numeric" }).format(date);
    const mo = new Intl.DateTimeFormat("de", { month: "2-digit" }).format(date);
    const da = new Intl.DateTimeFormat("de", { day: "2-digit" }).format(date);

    // return (`${da}.${mo}.${ye}`);
    return `${ye}-${mo}-${da}`;
  }

  /**
   * Redirect to v2 if current browser and os is not supporting v3
   */

  UNSAFE_componentWillMount() {
    let browser = getBrowserInformation();
    let OS = navigator.appVersion;

    let host = window.location.hostname ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_") : "betv3_frontend_herokuapp_com";
    let redirectURL;

    switch (hostConfig[host]?.api) {
      default:
      case "nubet_test":
        redirectURL = "/info/outdated_browser";
        break;

      case "nubet_prod":
        redirectURL = "/info/outdated_browser";
        break;
    }

    if (OS.indexOf("Mac") > -1) {
      // this means mac computer
      if (browser.name === "Chrome") {
        if (browser.version < 60) {
          this.isOutdatedBrowser = true;
          window.document.location.href = redirectURL;
          return false;
        }
      }

      if (browser.name === "Safari") {
        if (browser.version < 11) {
          window.document.location.href = redirectURL;
          return false;
        }
      }

      if (browser.name === "Firefox") {
        if (browser.version < 64) {
          window.document.location.href = redirectURL;
          return false;
        }
      }

      if (browser.name === "Opera") {
        if (browser.version < 47) {
          window.document.location.href = redirectURL;
          return false;
        }
      }
    }

    if (OS.indexOf("Windows") > -1) {
      // this means Windows computer
      if (browser.name === "Chrome") {
        // check chrome and edge both
        if (browser.version < 60) {
          window.document.location.href = redirectURL;
          return false;
        }
      }

      if (browser.name === "Firefox") {
        if (browser.version < 64) {
          window.document.location.href = redirectURL;
          return false;
        }
      }

      if (browser.name === "Opera") {
        if (browser.version < 47) {
          window.document.location.href = redirectURL;
          return false;
        }
      }

      if (browser.name === "IE") {
        if (browser.version < 11) {
          window.document.location.href = redirectURL;
          return false;
        }
      }
    }

    const password = localStorage.getItem("nuBet23_security_password") ? localStorage.getItem("nuBet23_security_password") : "";
    const expiredTime = localStorage.getItem("nuBet23_security_expired") ? parseInt(localStorage.getItem("nuBet23_security_expired")) : 0;

    if (password && new Date().getTime() - expiredTime <= 3600000) {
      this.setState({ password, securityModal: false });
    } else {
      this.setState({ password: "", securityModal: true }, () => {
        localStorage.removeItem("nuBet23_security_password");
        localStorage.removeItem("nuBet23_security_expired");
      });
    }
  }

  /**
   * Do some configurations
   * Define listener for casino game status
   * Set Host, get Ticket and balance information if user is already signed in
   * Get Market labels and Locale
   * Set appropriate logo , manifest
   */

  componentDidUpdate() {
    const { trustlyDepositStatus, navigate } = this.props;

    if (!window.location.href.includes("/register/trustly") && !window.location.href.includes("/trustly/deposit/waiting") && !window.location.href.includes("/interfaces/message?type=deposit")) {
      if (trustlyDepositStatus?.payload && trustlyDepositStatus?.payload?.status === "ok") {
        navigate(`/${this.props.lang}/register/trustly`);
      } else if (trustlyDepositStatus?.payload && trustlyDepositStatus?.payload?.status === "pending") {
        navigate(`/${this.props.lang}/trustly/deposit/waiting`);
      }
    }
  }

  componentDidMount() {
    const { precannedLength, boostersLength, isFreeSpinsLoading, auth } = this.props;

    if (!boostersLength || boostersLength < 1) {
      this.props.getComboBoost(this.constructor.name);
    }
    if (precannedLength < 1) {
      this.props.getPrecannedBets(this.constructor.name);
    }

    this.props.getMaintananceStatusAsync(this.props.lang);

    let host = window.location.hostname ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_") : "betv3_frontend_herokuapp_com";
    this.props.setHost(hostConfig[host]);
    let isMobile = false;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // true for mobile device
      isMobile = true;
    } else {
      // false for not mobile device
      isMobile = false;
    }

    this.props.setIsMobile(isMobile);
    window.addEventListener("resize", this.updateViewMode.bind(this));

    if (this.props.auth.isSignedIn) {
      const getTicketsPayload = {
        startDate: this.formatDateToApi(this.state.dateFrom),
        endDate: this.formatDateToApi(this.state.dateTo),
        status: "pending",
        start: 0,
        limit: this.props.ticketsLimit,
        lang: this.props.lang,
        callbacks: { getTicketGames: (props) => this.props.getTicketGamesAsync(props), getTicket: () => {} },
      };
      this.props.getTicketsAsync(getTicketsPayload);
    }

    const getLanguage = () => navigator.language.split("-")[0];

    // Start Time calculation timer for auto logout box
    this.intervalId = setInterval(this.checkTokenHasExpired, 1000);

    // set Favicon dynamically
    let faviconElement = document.getElementById("favicon");
    faviconElement.href = `/${hostConfig[host]["favicon"]}.ico`;

    // set Appicon dynamically
    let appiconElement = document.getElementById("appicon");
    appiconElement.href = `/${hostConfig[host]["appicon"]}.png`;

    // set robots tag dynamically
    let robotsElement = document.getElementById("page_robots");
    robotsElement.setAttribute("content", hostConfig[host]["robots"] ? hostConfig[host]["robots"] : "");

    if (getLanguage() === "de") {
      let titleElement = document.getElementById("page_title");
      titleElement.innerHTML = hostConfig[host][`title_${this.props.lang ? this.props.lang : "fi"}`] ? hostConfig[host][`title_${this.props.lang ? this.props.lang : "fi"}`] : "";
    } else {
      let titleElement = document.getElementById("page_title");
      titleElement.innerHTML = hostConfig[host][`title_${this.props.lang ? this.props.lang : "fi"}`] ? hostConfig[host][`title_${this.props.lang ? this.props.lang : "fi"}`] : "";
    }
    if (getLanguage() === "de") {
      let descriptionElement = document.getElementById("page_description");
      descriptionElement.setAttribute("content", hostConfig[host]["description"] ? hostConfig[host]["description"] : "");
    } else {
      let descriptionElement = document.getElementById("page_description");
      descriptionElement.setAttribute("content", hostConfig[host][`description_${this.props.lang ? this.props.lang : "fi"}`] ? hostConfig[host][`description_${this.props.lang ? this.props.lang : "fi"}`] : "");
    }
    if (getLanguage() === "de") {
      let keywordsElement = document.getElementById("page_keywords");
      keywordsElement.setAttribute("content", hostConfig[host][`keywords_${this.props.lang ? this.props.lang : "fi"}`] ? hostConfig[host][`keywords_${this.props.lang ? this.props.lang : "fi"}`] : "");
    } else {
      let keywordsElement = document.getElementById("page_keywords");
      keywordsElement.setAttribute("content", hostConfig[host][`keywords_${this.props.lang ? this.props.lang : "fi"}`] ? hostConfig[host][`keywords_${this.props.lang ? this.props.lang : "fi"}`] : "");
    }
    if (getLanguage() === "fi") {
      let manifestElement = document.getElementById("pageManifest");
      manifestElement.href = `/${hostConfig[host]["pageManifest"]}.json`;
    } else {
      let manifestElement = document.getElementById("pageManifest");
      manifestElement.href = `/${hostConfig[host]["pageManifest_en"]}.json`;
    }
    // set locale from url
    const lang = getParameterByName("lang");
    if (lang) {
      this.props.getLabelsMarkets(lang, this.constructor.name);
      this.props.setLocaleWithFallback(lang, this.constructor.name);
      // Cookies.set('lang', lang, { path: '/' });
      localStorage.setItem("lang", lang);
      localStorage.setItem("lang", lang);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewMode.bind(this));
  }

  /**
   * Calculate width function - currently not being used
   ** if it's currently casino page, width is 9/12 , otherwise width is 7/12
   */

  calcWidth(isCasinoView) {
    if (isCasinoView) return 9;
    else return 7;
  }

  /**
   * Enter security password
   */

  enterPassword() {
    if (this.state.password === "nuBet23") {
      this.setState({ securityModal: false }, () => {
        localStorage.setItem("nuBet23_security_password", this.state.password);
        localStorage.setItem("nuBet23_security_expired", new Date().getTime());
      });
    }
  }

  /**
   * Generate Cookie banner content
   */

  generateCookieBannerContent() {
    const { lang } = this.props;

    if (lang === "en") {
      var element = (
        <div className="medium">
          <div className="medium">
            <h2 className="vertical-center medium">
              <FingerprintIcon
                style={{
                  width: "2em",
                  height: "2em",
                  margin: "0 0.5em 0 -0.1em",
                }}
              />
              This website uses cookies
            </h2>
            <p>
              We use cookies to personalise content and ads, to provide social media features and to analyse our traffic.<br></br>
              <br></br>
              We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve
              collected from your use of their services.
            </p>
          </div>
        </div>
      );
    } else if (lang === "et") {
      var element = (
        <div className="medium">
          <div className="medium">
            <h2 className="vertical-center medium">
              <FingerprintIcon
                style={{
                  width: "2em",
                  height: "2em",
                  margin: "0 0.5em 0 -0.1em",
                }}
              />
              See veebisait kasutab küpsiseid
            </h2>
            <p>
              Kasutame küpsiseid sisu ja reklaamide isikupärastamiseks, sotsiaalmeedia funktsioonide pakkumiseks ja liikluse analüüsimiseks.<br></br>
              <br></br>
              Samuti jagame teavet meie saidi kasutamise kohta oma sotsiaalmeedia-, reklaami- ja analüüsipartneritega, kes võivad seda kombineerida muu teabega, mille olete neile esitanud või mille nad on kogunud teie
              teenuste kasutamisest.
            </p>
          </div>
        </div>
      );
    } else {
      element = (
        <div className="medium">
          <div className="medium">
            <h2 className="vertical-center medium">
              <FingerprintIcon
                style={{
                  width: "2em",
                  height: "2em",
                  margin: "0 0.5em 0 -0.1em",
                }}
              />
              Tämä sivusto käyttää evästeitä
            </h2>
            <p>
              Käytämme evästeitä personoidaksemme sisältöä ja mainoksia, tarjotaksemme sosiaalisen median palveluita ja analysoidaksemme sivuston liikennettä.<br></br>
              <br></br>
              Jaamme myös tietoa käyttäytymisestäsi sivustollamme sosiaalisen median, mainonnan ja analytiikan kumppaneittemme kanssa, jotka voivat yhdistää tietoja muihin heidän palvelussa valtuuttamiisi sinusta
              kerättyihin tietoihin.
            </p>
          </div>
        </div>
      );
    }

    return element;
  }

  /**
   * Accept Cookie
   */

  acceptCookie() {
    this.setState({ cookieModal: false });
  }

  /**
   * Define routings, define themes
   */

  render() {
    if (this.isOutdatedBrowser) return <OutdatedBrowser_EN />;

    const shouldByPass = localStorage.getItem("bypass_maintanance");

    log(this.constructor.name, "render");
    const { classes, layout, url, host, lang, liveCheck, translate, notificationEnabled, notificationContent } = this.props;

    if (!host) return null;

    return (
      <AppStateLoader>
        <Helmet>
          {/* open graph meta tags */}
          <meta property="og:title" content={translate?.seo?.card_title} />
          <meta property="og:description" content={translate?.seo?.card_description} />
          <meta property="og:image" content={`/seo/card_${lang ? lang : "fi"}.jpg`} />
          <meta property="og:url" content="https://www.facebook.com/nubetcom" />
          <meta property="og:type" content="website" />
          <meta name="twitter:site" content="@NUBETCOM" />
          <meta name="twitter:title" content={translate?.seo?.card_title} />
          <meta name="twitter:description" content={translate?.seo?.card_description} />
          <meta name="twitter:image" content={`/seo/card_${lang ? lang : "fi"}.jpg`} />
        </Helmet>
        {!this.props.maintenanceMode || shouldByPass ? (
          (this.state.password === "nuBet23" && this.state.securityModal === false) || host?.isPasswordOn === false ? (
            <div className={`${layout === "mouse" ? classes.root : ""} ui container`}>
              {layout === "mouse" ? (
                <div id="betv3_wrapper" className={`${!url?.includes("/casino") && host.bet ? "bg-web-img" : ""}`}>
                  <div id="app_header">
                    {notificationEnabled && (
                      <div className="infobox-container">
                        <div className="infobox-text">{notificationContent}</div>
                      </div>
                    )}
                    <MenuTop />
                  </div>
                  <div>
                    <AppRouter />
                    {lang === "en" ? <FooterEN /> : lang === "fi" ? <FooterFI /> : lang === "et" ? <FooterET /> : <FooterDE />}
                  </div>
                </div>
              ) : (
                <div id="betv3_wrapper1" style={{ paddingBottom: "102px" }} className={classes.root}>
                  <div id="app_header">
                    {/* <PanicButtonMobile/> */}
                    {notificationEnabled && (
                      <div className="infobox-container">
                        <div className="infobox-text">{notificationContent}</div>
                      </div>
                    )}
                    <MenuTop />
                  </div>
                  <AppRouter />
                  {lang === "en" ? <FooterEN /> : lang === "fi" ? <FooterFI /> : lang === "et" ? <FooterET /> : <FooterDE />}
                  <MenuBottom />
                </div>
              )}
            </div>
          ) : null
        ) : (
          <div className={classes.maintenanceWrapper}>
            <div className={classes.maintenanceContent}>
              <img style={{ width: "100%", height: "auto", margin: "0.3em 0" }} src={`https://api.nubet.com/media/logos/${host.logo}_dark.svg`} alt="logo" />
              <div style={{ fontSize: "1em" }}>
                <Translate value={`labels.maintenance`} />
              </div>
              <div style={{ fontSize: "0.8em" }}>
                <Translate value={`labels.try_again_later`} />
              </div>
            </div>
          </div>
        )}

        {this.state.cookieModal ? (
          <div className={classes.overlay}>
            <CookieBanner
              message={this.generateCookieBannerContent()}
              wholeDomain={true}
              onAccept={this.acceptCookie.bind(this)}
              necessaryOptionText={<Translate value={`cookieConsent.option1`} />}
              preferencesOptionText={<Translate value={`cookieConsent.option2`} />}
              statisticsOptionText={<Translate value={`cookieConsent.option3`} />}
              marketingOptionText={<Translate value={`cookieConsent.option4`} />}
              acceptButtonText={<Translate value={`cookieConsent.button2`} />}
              managePreferencesButtonText={<Translate value={`cookieConsent.button1`} />}
              savePreferencesButtonText={<Translate value={`cookieConsent.button3`} />}
              onAcceptPreferences={() => {}}
              onAcceptStatistics={() => {}}
              onAcceptMarketing={() => {}}
            />
          </div>
        ) : null}
        {host?.isPasswordOn ? (
          <Dialog open={this.state.securityModal && this.props.maintenanceMode === false} keepMounted fullScreen>
            <DialogContent component={"span"}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <h1>Enter Password</h1>
                <DialogContentText id="alert-dialog-slide-description" component={"span"}>
                  <Input
                    className={classes.input}
                    value={this.state.password}
                    label={host.brand === "nubet_se" ? "SEK" : "EUR"}
                    fullWidth
                    onChange={(e) => this.setState({ password: e.target.value })}
                    id="nubetPasswordInput"
                  />
                </DialogContentText>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <Button onClick={this.enterPassword.bind(this)} variant="outlined" color="primary" size="medium" id="nubetLoginButton">
                    Login
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        ) : null}
      </AppStateLoader>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const precannedList = state.banner && state.banner?.precanned && typeof state.banner?.precanned === "object" ? state.banner?.precanned : {};
  return {
    theme: state.settings.theme,
    layout: state.settings.layout,
    showMyTicketsPanel: state.settings.showMyTicketsPanel,
    tokenExpire: state.auth?.exp,
    url: state.settings.url,
    host: state.settings.host,
    leagueFilter: ownProps.params && ownProps.params.leagueFilter ? ownProps.params.leagueFilter : state.settings.leagueFilter,
    sportCode: ownProps.params && ownProps.params.sportCode ? ownProps.params.sportCode : state.settings.sportCode,
    navMode: state.settings.navMode,
    auth: state.auth,
    balance: state.account.balanceDetails.balance,
    lang: state.i18n.locale,
    translate: state.i18n.translations[state.i18n.locale],
    maintenanceMode: state.settings.maintenanceMode,
    notificationEnabled: state.settings.notificationEnabled,
    notificationContent: state.settings.notificationContent,
    ticketsLimit: state.tickets.ticketsLimit,
    isFreeSpinsLoading: state.casino.isFreeSpinsLoading,
    precannedLength: Object.keys(precannedList).length,
    boostersLength: state.comboboost?.bonuses?.length,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getMaintananceStatusAsync,
    setHost,
    setIsMobile,
    toggleMyTicketsPanel,
    setLayout,
    getTicketsAsync,
    getTicketGamesAsync,
    setLocaleWithFallback,
    getLabelsMarkets,
    getComboBoost,
    getPrecannedBets,
  })(withStyles(styles)(App))
);
