/**
 * SlipTemplate component
 * Handle all placing bet operations
 */
import "./@css/SlipTemplate.css";
import "./@css/Odds.css";
import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar, Box, Grid2, InputAdornment, Paper, Stack, styled, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";

import { updateBetWithLowerPrice, saveAutoSlipUpdateStatus } from "../../../actions";
import { getWalletBalanceAsync } from "../../../redux-store/account-reducer";
import { getTicketsAsync, getTicketGamesAsync, postTicketAsync } from "../../../redux-store/tickets-reducer";
import { emptySlipTemplate, saveSlipTemplate, saveBet, getMaxStake } from "../../../actions";
import GameBoxColumn from "../@components/GameBoxColumn";
import StakePad from "../../StakePad";
import BetSelectionLabel from "../../BetSelectionLabel";
import BetMarketLabel from "../../BetMarketLabel";
import OddsFormat from "../../OddsFormat";
import OddsChart from "../@components/OddsChart";
import PriceDropIcon from "@mui/icons-material/ArrowDropDown";
import PriceUpIcon from "@mui/icons-material/ArrowDropUp";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockIcon from "@mui/icons-material/Lock";

import BetBuilderUpdateAlert from "../../sports/betbuilder/OddsUpdateAlert";
import { expandedLocales } from "../../../helpers/datefns-locales";
import withRouter from "../../../helpers/withRouter";
import { calculateComboBoost } from "../../../helpers/slipComboBoostCalculator";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../iconify";
import _ from "lodash";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.results,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "50vh",
}));

const styles = (theme) => ({
  root: {
    "& .MuiTabs-root": {
      minHeight: "3.2em",
    },
    "& .MuiTab-root": {
      minHeight: "1em",
      color: "var(--grey)",
      "@media (hover:hover)": {
        minHeight: "3em",
      },
    },
    "& .MuiAppBar-root": {
      "@media (hover:hover)": {
        minHeight: "3em",
        position: "sticky",
        top: "0",
        zIndex: "999",
      },
    },
    "& .MuiInputBase-root": {
      fontSize: "1em",
    },
    "& .MuiButton-containedSizeSmall": {
      padding: "10em",
    },
    flexGrow: 1,
    width: "100%",
  },
  subTabs: {
    backgroundColor: "var(--color-main)",
    "& .MuiTab-root": {
      minHeight: "1.95rem !important",
      padding: "0.35em 0 !important",
      color: "var(--grey)",
      "@media (hover:hover)": {
        // minHeight: "2.3em !important",
        padding: "0.44em 0 !important",
        fontSize: "1.2em !important",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  slipTemplates: {
    flexGrow: 1,
    width: "100%",
    position: "fixed",
    bottom: "66px",
    zIndex: 1,
  },
  stakeInfoBox: {
    flexGrow: 1,
    width: "100%",
    position: "fixed",
    bottom: "66px",
    zIndex: 1,
  },
  textField: {
    "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "1em",
      color: "var(--color-stake)",
      fontWeight: "bold",
      textAlignLast: "right",
    },

    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "0.5em 0.5em 0.5em 0",
      textAlign: "right",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0.5em 1em 0.5em 0",
    },
    "& .MuiOutlinedInput-inputMarginDense::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiOutlinedInput-inputMarginDense::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  textFieldStake: {
    "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "1em",
      color: "var(--color-stake)",
      fontWeight: "bold",
      textAlignLast: "right",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0.5em 1em 0.5em 0",
    },

    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "0.5em 0.5em 0.5em 0",
      textAlign: "right",
    },
    "& .MuiOutlinedInput-inputMarginDense::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiOutlinedInput-inputMarginDense::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  initialText: {
    textTransform: "initial!important",
  },
  dialog: {
    position: "absolute !important",
    margin: "0 !important",
    padding: "0 !important",
    bottom: 0,
    height: "35%",
    textAlign: "center",
    backgroundColor: "var(--bg-bet-accepted)",
    "& .MuiTypography-h6": {
      fontSize: "2em",
    },
    "@media (hover:hover)": {
      width: "25%",
      height: "30%",
      borderTopLeftRadius: "0.5em",
      borderTopRightRadius: "0.5em",
    },
    "@media (hover:none) and (orientation:landscape)": {
      height: "70%",
    },
    "@media   (max-width: 480px)": {
      width: "90% !important",
    },
  },
  tabSelection: {
    "&.MuiButtonBase-root.MuiTab-root": {
      // backgroundColor: "var(--color-slip-tabs)",
      // borderRadius: "0.4em",
      // height: "1em",
      // color: "var(--grey)",
      fontSize: "0.9em !important",
      // margin: "0 0.3em",
      // background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
      background: "var(--bg-slip)",
      textTransform: "uppercase !important",
      borderTop: "1px solid var(--color-main)",
    },
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function getRoundedValue(value) {
  return Math.ceil(Number(value) * 100) / 100;
}

let timer = null;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SlipTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    const { slipTemplates, thisSlipTemplate } = this.props;
    props.forwardRef(() => this.slipTemplateSave(slipTemplates[thisSlipTemplate]?.settings));

    this.slipTemplate = thisSlipTemplate;
    this.betType = slipTemplates[thisSlipTemplate]?.betType ? slipTemplates[thisSlipTemplate].betType : "single";
    this.stake = slipTemplates[thisSlipTemplate]?.stake;
    this.settings = _.cloneDeep(slipTemplates[thisSlipTemplate]?.settings);
    this.outrightExceeded = slipTemplates[thisSlipTemplate]?.outrightExceeded;
    this.timer = null;
    this.removerInterval = null;

    let dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 365);

    this.state = {
      loading: true,
      stakePadOpen: false,
      stakePadValueReceiver: null,
      isPlacingBet: false,
      remainingSeconds: 5,
      oddsChartsMarketId: null,
      dateFrom: dateFrom,
      dateTo: Date.now(),
      open: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.domReferences = {};
  }

  /**
   * If placing a bet is failed because of odd price changing, post ticket again until it succeed
   */

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.alert !== this.props.alert) {
      if (newProps.alert.from) {
        /// this means postTicket was failed
        /// we need to check auto update slip is checked
        /// if it was checked, we should rePostTicket
        if (newProps.slipAutoUpdateStatus) {
          let self = this;

          clearInterval(timer);
          this.timer = null;
          setTimeout(() => {
            self.postTicket(true);
          }, 1000);
        }
      }
    }
  }

  componentWillUnmount() {
    this.slipTemplateSave(this.settings);
    this.domReferences = {};
    const { slipsLength } = this.props;
    // if (!slipsLength) {
    clearInterval(this.removerInterval);
    // }
  }

  /**
   * If bet type is changing, save slip template and calculate price again
   ** Bet type can be one of these values ( Single, Combi and System )
   */

  betTypeChange = (event, newValue) => {
    this.betType = newValue;
    this.slipTemplateSave(this.settings);
  };

  /**
   * Save slip template and calculate price again
   */

  slipTemplateSave = (settings) => {
    const { disabledActions, hiddenTab, outrightExceeded, multipleOutright } = this.props;

    let payload = {
      slipTemplate: this.slipTemplate,
      betType: this.betType,
      stake: this.stake,
      settings: settings || { ...this.settings },
      disabledActions: disabledActions,
      hiddenTab: hiddenTab,
      outrightExceeded: outrightExceeded,
      multipleOutright: multipleOutright,
    };

    this.props.saveSlipTemplate(payload);
  };

  /**
   * Format date to YYYY-MM-DD
   */

  formatDateToApi(date) {
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

    // return (`${da}.${mo}.${ye}`);
    return `${ye}-${mo}-${da}`;
  }

  /**
   * Trigger a request for placing a bet
   ** Show countdown time from 5.
   ** if time is 0, reset it to 5.
   ** This will be repeated until placing a bet is done
   */

  postTicketCallback = () => {
    const getTicketsPayload = {
      startDate: this.formatDateToApi(this.state.dateFrom),
      endDate: this.formatDateToApi(this.state.dateTo),
      status: this.props.ticketListStatus,
      start: 0,
      limit: this.props.ticketsLimit,
      lang: this.props.locale,
      callbacks: { getTicketGames: (props) => this.props.getTicketGamesAsync(props), getTicket: () => {} },
    };
    this.props.getWalletBalanceAsync();
    this.props.getTicketsAsync(getTicketsPayload);
  };

  postTicket = (priceChangeAccepted) => {
    const { slipTemplates, thisSlipTemplate, locale, boosted } = this.props;
    let payload = {};
    let receiptPayload = {};
    let receiptBets = [];
    let slipBets = [];

    for (var key in slipTemplates) {
      if (slipTemplates[key].slipTemplate === thisSlipTemplate) {
        let slipTemplate = slipTemplates[key];

        Object.values(slipTemplate.settings.bets)?.forEach((bet, index) => {
          let item = {
            eventType: "game",
            eventID: bet.gameId,
            marketID: bet.marketId,
            selectionID: bet.selectionId,
            price: bet.price,
            hc: bet.hc,
            isBank: bet.isBank,
          };

          if (bet?.isOutright) {
            item["isOutright"] = bet.isOutright;
          }

          if (bet?.isBetBuilder) {
            const expandedLocale = expandedLocales[locale];
            const selectedDesc = bet?.translations?.find((item) => item.Culture === expandedLocale);
            item["fixtureId"] = String(bet.consumerFixtureId);
            item["isBetBuilder"] = bet.isBetBuilder;
            item["betslipUid"] = bet.betslipUid;
            item["identifier"] = bet.identifier;
            item["description"] = selectedDesc?.Description;
            item["marketDescription"] = bet.marketDescription;
            delete item["selectionID"];
          }

          if (bet?.isPlayerProps) {
            item["fixtureId"] = bet.fixtureId;
            item["isPlayerProps"] = bet.isPlayerProps;
            item["betslipUid"] = bet.betslipUid;
            item["identifier"] = bet.identifier;
            item["description"] = bet.description;
            item["isBetBuilder"] = true;
            item["marketDescription"] = bet.marketDescription;
            delete item["marketID"];
            delete item["selectionID"];
            delete item["isOutright"];
          }

          if (bet?.isPrecannedBet) {
            item["isPrecannedBet"] = bet.isPrecannedBet;
            item["description"] = bet.description;
          }
          receiptBets.push(bet);
          slipBets.push(item);
        });

        payload.acceptOdds = this.props.slipAutoUpdateStatus || priceChangeAccepted ? 1 : 0;
        payload.ticket = {
          betType: slipTemplate.betType,
          totalBets: slipTemplate.settings.totalBetsPerBetType,
          specialLabel: slipTemplate.settings.hasMulti ? "multibet" : "",
          stake: Number(slipTemplate.stake),
          combiTypes: slipTemplate.settings.combiTypes.toString(),
          combiStakes: slipTemplate.settings.combiStakes.join(","),
          bets: slipBets,
        };

        if (slipTemplate.betType === "system" && boosted?.isSystemBoosted) {
          payload.ticket.totalOdds = Number(boosted.price);
          payload.ticket.potentialWin = Number(boosted.winMax);
        }

        if (slipTemplate.settings.cntMultiBets > 0) {
          payload.ticket.betType = "system";
          payload.ticket.specialLabel = "multibet";
        } else if (slipTemplate.betType === "single" && slipBets?.length > 1) {
          payload.ticket.betType = "system";
          payload.ticket.specialLabel = "singles";
          payload.ticket.combiTypes = 1;
          payload.ticket.combiStakes = slipTemplate.stake / slipBets?.length;
        }
        const combiTypes = slipTemplate.settings.combiTypes;
        let betTypeLabel = String(combiTypes.sort((a, b) => a - b)) + "/" + slipTemplate.settings.availableCombiTypes.length;
        receiptPayload = { ...payload, bets: receiptBets, boosted: boosted, betTypeLabel };
        break;
      }
    }

    if (slipBets?.length === 0) return;
    this.props.postTicketAsync({ data: payload, receiptPayload, callback: this.postTicketCallback });
  };

  /**
   * Open odds history.
   */

  openOddsHistory = (marketId) => {
    this.setState({ oddsChartsMarketId: marketId });
  };

  /**
   * Remove bet from current slip
   */

  betDelete = (thisBet) => {
    console.log(thisBet);

    let payload = {};
    payload = Object.values(_.cloneDeep(this.settings.bets)).find(function (bet) {
      if (thisBet?.isBetBuilder) {
        return Number(bet.gameId) === Number(thisBet.gameId) && bet?.identifier === thisBet?.identifier && bet.selectionId === thisBet.selectionId;
      }
      return Number(bet.gameId) === Number(thisBet.gameId) && bet.marketId === thisBet.marketId && bet.selectionId === Number(thisBet.selectionId) && bet.hc === thisBet.hc;
    });

    if (payload) {
      this.props.saveBet(payload, 0);
    }
  };

  /**
   * Apply bank checkbox changing
   */

  bankCheckboxChange = (bet, bets) => {
    const filteredBets = bets.filter((item) => item.gameId === bet.gameId && bet.selectionId !== item.selectionId);
    bet.isBank = bet.isBank === 0 ? 1 : 0;
    if (bet.isBank === 1) {
      filteredBets?.forEach((lead) => {
        lead.isBank = 0;
      });
    }
    this.slipTemplateSave(this.settings);
  };

  /**
   * Apply combi type checkbox changing
   */

  combiTypeCheckboxChange = (combiType) => {
    const settingsCopy = { ...this.settings };
    if (this.getIndexFromCombiTypes(combiType) === -1) {
      const allCombiTypes = [...settingsCopy.combiTypes];
      const allCombiStakes = [...settingsCopy.combiStakes];
      allCombiTypes.push(Number(combiType));
      allCombiStakes.push(0);
      settingsCopy.combiTypes = allCombiTypes;
      settingsCopy.combiStakes = allCombiStakes;
    } else {
      const savedCombiTypes = [...settingsCopy.combiTypes];
      const savedCombiStakes = [...settingsCopy.combiStakes];
      settingsCopy.combiTypes = savedCombiTypes.filter((item) => item !== Number(combiType));
      settingsCopy.combiStakes = savedCombiStakes.splice(this.getIndexFromCombiTypes(combiType), 1);
    }

    settingsCopy.keepSystemTypeSettings = true;
    settingsCopy.stakeCalculationType = "thisBetFromTotal";
    this.slipTemplateSave(settingsCopy);
  };

  getIndexFromCombiTypes(combiType) {
    return this.settings.combiTypes.findIndex((value) => value === Number(combiType));
  }

  getIndexFromAvailableCombiTypes(combiType) {
    return this.settings.availableCombiTypes.findIndex((value) => value === Number(combiType));
  }

  renderAddSlipTemplate() {
    return <div>New Slip Banner</div>;
  }

  /**
   * Check if element is in current viewport
   ** This is for speed and performance optimization
   ** Don't re-render elements if it's not in viewport
   */

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      (rect.top >= 150 && rect.left >= 0 && rect.top <= (window.innerHeight - 66 || document.documentElement.clientHeight - 66) && rect.left <= (window.innerWidth || document.documentElement.clientWidth)) ||
      (rect.bottom >= 150 && rect.right >= 0 && rect.bottom <= (window.innerHeight - 66 || document.documentElement.clientHeight - 66) && rect.right <= (window.innerWidth || document.documentElement.clientWidth))
    );
  }

  saveRefs(key, ref) {
    this.domReferences[key] = ref;
  }

  /**
   * Render bets
   * Show game information for selected bet / odd
   */

  renderBets() {
    let games = [];
    this.settings.bets &&
      Object.values(this.settings.bets)?.forEach((bet, index) => {
        games.push({
          ...bet.game,
          hn: bet?.hn,
          isOutright: bet?.isOutright,
          isBetBuilder: bet?.isBetBuilder,
        });
      });
    games = Array.from(new Set(games));
    const filteredGames = [];
    for (let i = 0; i < games?.length; i++) {
      const findIfThere = filteredGames.find((doc) => doc.gameid === games[i].gameid);
      if (!findIfThere) {
        filteredGames.push(games[i]);
      }
    }
    // this.settings.hasMulti = games?.length < this.props.bets?.length ? true : false;

    return filteredGames.map((game, key) => {
      let isInViewport = true;
      if (this.domReferences[key]) isInViewport = this.isInViewport(this.domReferences[key]);
      return (
        <div key={key}>
          <div
            ref={(ref) => {
              this.saveRefs(key, ref);
            }}
          >
            {/* <GameBox game={[game]} content="selectedGames" isInViewport={isInViewport} /> */}
            <div className="betslip_header">
              <GameBoxColumn game={[game]} gameIndex={key} content="selectedGames" isInViewport={isInViewport} />
            </div>
            {this.renderSelectionInfo(game.gameid)}
          </div>
          {this.betType === "system" && key === filteredGames?.length - 1 ? this.renderSystemTypesInfo() : null}
        </div>
      );
    });
  }

  /**
   * Show game's information
   * Price changing, price changing state icon, show odds
   */

  renderSelectionInfo(gameId) {
    const { selectionDefaultLabels, slipTemplate } = this.props;

    let hasMulti = false;
    let multiLeagues = {};
    Object.values(this.settings.bets)?.forEach((betGame) => {
      if (!multiLeagues[betGame.gameId]) {
        multiLeagues[betGame.gameId] = Object.values(this.settings.bets).filter((item) => item.gameId === betGame.gameId);
      }
    });
    Object.values(multiLeagues)?.forEach((lgItem) => {
      if (lgItem?.length > 1) {
        hasMulti = true;
      }
    });

    return Object.values(this.settings.bets).map((bet, key) => {
      if (bet.gameId !== gameId) return false;
      let isPriceLowered = false;
      let isPriceUppered = false;

      if (bet.isPriceUppered) {
        isPriceUppered = true;
      } else {
        if (bet.tempPrice) isPriceLowered = true;
      }

      // const hc = bet.hc.split('=');

      if (this.props.slipAutoUpdateStatus && isPriceLowered === true) {
        this.updateBet(bet);
      }

      switch (this.betType) {
        case "single":
        case "combi":
          return (
            <div key={key}>
              {/* <div className="slip-selection"> */}
              <div key={key} className={`${bet?.isBetBuilder || this.betType != "system" ? "slip-selection-betbuilder" : "slip-selection"}`}>
                <div></div>
                {/* <div className="color-contrast-slip text-wrap vertical-center font-detail-1 spacer-left"> */}
                <div className="vertical-center font-detail-1 font-bold-2 spacer-left">
                  {/* <Translate value="markets.label" count={`${bet.marketId}`} />&nbsp;{hc[hc?.length - 1]} */}
                  {bet?.isBetBuilder ? (
                    <span className="color-team font-detail-1 font-bold-2" style={{ margin: "0.5em 0" }}>
                      {" "}
                      <Translate value="marketSets.betbuilder" />
                      {" :"}
                    </span>
                  ) : null}
                  <BetMarketLabel bet={bet} />
                </div>

                <div className={`slip-selection-divider flex-end font-bold ${!this.props.slipAutoUpdateStatus && isPriceLowered ? "" : "invisible"}`}>
                  <i className="material-icons slip-odd-update cursor-pointer" onClick={() => this.updateBet(bet)}>
                    check
                  </i>
                </div>

                <div
                  className={`slip-selection-divider font-detail-1 font-bold vertical-center content-right ${
                    !this.props.slipAutoUpdateStatus && isPriceLowered ? "red" : !this.props.slipAutoUpdateStatus && isPriceUppered ? "grey-3" : "invisible"
                  }`}
                >
                  {bet.tempPrice ? <OddsFormat price={bet.tempPrice} isActive="1" /> : null}
                </div>

                <div
                  className={`slip-selection-divider vertical-center spacer-left slip-odd-changes-symbol ${
                    (!this.props.slipAutoUpdateStatus && isPriceLowered) || (!this.props.slipAutoUpdateStatus && isPriceUppered) ? "" : "invisible"
                  }`}
                >
                  {!this.props.slipAutoUpdateStatus && isPriceLowered ? <PriceDropIcon style={{ color: "var(--red)", margin: "0 0 0.1em 0" }} /> : null}

                  {!this.props.slipAutoUpdateStatus && isPriceUppered ? <PriceUpIcon style={{ color: "var(--green)", margin: "0 0 0.1em 0" }} /> : null}
                </div>

                <div className="color-odds slip-selection-divider font-detail-1 text-wrap vertical-center content-right spacer-right">
                  <OddsFormat price={bet.price} isActive={bet.isActive} />
                </div>
                <div className="slip-selection-divider vertical-center content-right spacer-right">
                  <i className="material-icons font-slip-close cursor-pointer" onClick={() => this.betDelete(bet)}>
                    close
                  </i>
                </div>
                <div></div>
                {/* <div className="slip-selection-divider odds-history-button vertical-center content-center spacer-right">
                  <div>
                    <OddsHistoryIcon style={{ margin: '0 0 0.1em 0', fontSize: '1.9em' }} key={'icon-' + key} onClick={() => { this.state.oddsChartsMarketId !== bet.marketId ? this.openOddsHistory(bet.marketId) : this.setState({ oddsChartsMarketId: null }) }} />
                  </div>
                </div> */}
                {!bet?.isBetBuilder && (
                  <div className={` color-selection-slip font-detail-1 ${bet?.isBetBuilder ? "" : "text-wrap"} spacer-left`}>
                    <BetSelectionLabel bet={bet} />
                  </div>
                )}

                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                {/* <div></div> */}
              </div>
              {bet?.isBetBuilder && (
                <Stack pb={1}>
                  <div className={` color-selection-slip font-detail-1 ${bet?.isBetBuilder ? "" : "text-wrap"} spacer-left`}>
                    <BetSelectionLabel bet={bet} />
                  </div>
                </Stack>
              )}
              {this.state.oddsChartsMarketId === bet.marketId ? (
                <div>
                  <OddsChart leagueId={bet.game.leagueID} gameId={bet.game.gameid} marketId={bet.marketId} hc={bet.hc} selectionIds={selectionDefaultLabels[bet.marketId].split(",")} />
                </div>
              ) : null}
            </div>
          );

        case "system":
          // const { classes } = this.props;
          return (
            <div key={key}>
              {/* <div className="slip-selection-system"> */}
              <div key={key} className={`${bet?.isBetBuilder === true ? "slip-selection-system-betbuilder" : "slip-selection-system"}`}>
                <div className="grid-divider-system slip-bank-button vertical-center content-center"></div>
                <div
                  // className="color-contrast-slip text-wrap vertical-center font-detail-1"
                  className={`color-selection-slip vertical-center font-detail-1`}
                  style={{ paddingLeft: "1.2em" }}
                >
                  {bet?.game?.sport_code !== "TR" && <BetMarketLabel bet={bet} />}
                </div>
                <div className={`slip-selection-divider flex-end font-bold ${!this.props.slipAutoUpdateStatus && isPriceLowered ? "" : "invisible"}`}>
                  <i className="material-icons slip-odd-update cursor-pointer" onClick={() => this.updateBet(bet)}>
                    check
                  </i>
                </div>

                <div
                  className={`slip-selection-divider font-detail-1 font-bold vertical-center content-right ${
                    !this.props.slipAutoUpdateStatus && isPriceLowered ? "red" : !this.props.slipAutoUpdateStatus && isPriceUppered ? "" : "invisible"
                  }`}
                >
                  {bet.tempPrice ? <OddsFormat price={bet.tempPrice} isActive="1" /> : null}
                </div>

                <div className={`slip-selection-divider vertical-center spacer-left ${(!this.props.slipAutoUpdateStatus && isPriceLowered) || (!this.props.slipAutoUpdateStatus && isPriceUppered) ? "" : "invisible"}`}>
                  {!this.props.slipAutoUpdateStatus && isPriceLowered ? (
                    <PriceDropIcon
                      style={{
                        color: "var(--red)",
                        margin: "0 0 0.1em 0",
                        fontSize: "2.5em",
                      }}
                    />
                  ) : null}

                  {!this.props.slipAutoUpdateStatus && isPriceUppered ? (
                    <PriceUpIcon
                      style={{
                        color: "var(--green)",
                        margin: "0 0 0.1em 0",
                        fontSize: "2.5em",
                      }}
                    />
                  ) : null}
                </div>

                <div className="color-odds slip-selection-divider font-detail-1 text-wrap vertical-center content-right spacer-right">
                  {/* {Number(bet.price).toFixed(2)} */}
                  <OddsFormat price={bet.price} isActive={bet.isActive} />
                </div>
                <div className="slip-selection-divider vertical-center content-right spacer-right">
                  <i className="material-icons font-slip-close cursor-pointer" onClick={() => this.betDelete(bet)}>
                    close
                  </i>
                </div>
                {/* <div className="slip-selection-divider odds-history-button vertical-center content-center spacer-right">
                  <div>
                    <OddsHistoryIcon style={{ margin: '0 0 0.1em 0', fontSize: '1.9em' }} key={'icon-' + key} onClick={() => { this.state.oddsChartsMarketId !== bet.marketId ? this.openOddsHistory(bet.marketId) : this.setState({ oddsChartsMarketId: null }) }} />
                  </div>
                </div> */}
                <div className="color-selection-slip font-detail-1" style={{ paddingLeft: "1.2em" }}>
                  <BetSelectionLabel bet={bet} />
                </div>

                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                {/* <div></div> */}
              </div>
              {this.state.oddsChartsMarketId === bet.marketId ? (
                <div>
                  <OddsChart leagueId={bet.game.leagueID} gameId={bet.game.gameid} marketId={bet.marketId} hc={bet.hc} selectionIds={selectionDefaultLabels[bet.marketId].split(",")} />
                </div>
              ) : null}
            </div>
          );

        default:
          return null;
      }
    });
  }

  /**
   * Format the value to 2 decimal points
   * E.g, 1.234 -> 1.23
   */

  formatValue(value) {
    if (value) {
      let newValue = parseFloat(Number(value).toFixed(2));
      return newValue;
    } else {
      return 0;
    }
  }

  /**
   * Return System Type info ( checkbox, price input box )
   * Update / Re-calculate price when checkbox or price input is updated
   */

  renderSystemTypesInfo() {
    const { classes, outrightLeaguesCount, boosted } = this.props;
    let allCombiTypes = this.settings.availableCombiTypes;
    if (outrightLeaguesCount > 0) {
      allCombiTypes = this.settings.availableCombiTypes.slice(0, 1);
    }
    return allCombiTypes.map((combiType, index) => {
      return (
        <div className="slip-stake-system" key={index} id="pad_scroller">
          <div className="slip-check-system stake-system-select vertical-center content-center">
            <Checkbox
              color="primary"
              style={{
                padding: "0 0 0 0",
                fontSize: "1.1em",
                // color: '#ff0000',
              }}
              className="nubet_system_tests"
              checked={this.settings.combiTypes.includes(this.settings.availableCombiTypes[this.getIndexFromAvailableCombiTypes(combiType)])}
              onChange={(e) => this.combiTypeCheckboxChange(this.settings.availableCombiTypes[this.getIndexFromAvailableCombiTypes(combiType)])}
              // onClick={e => this.selectCombiType(activeCombiTypes[key])}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          {/* <!-- when inactive "bg-white white font-s font-bold text-center slip-check-system border-radius input-box object-right stake-system-select"  --> */}
          <div className="font-detail-1 font-bold text-wrap vertical-center system-spacer">{this.settings.availableCombiTexts[this.getIndexFromAvailableCombiTypes(combiType)]}</div>
          <div className="font-detail-1 font-bold text-wrap vertical-center content-right system-spacer">{this.settings.availableCombiCounts[this.getIndexFromAvailableCombiTypes(combiType)]} x</div>
          <div className="vertical-center content-right spacer-right" style={{ margin: "1.7em 0 0 1em" }}>
            {this.props.layout === "mouse" ? (
              <TextField
                className={classes.textField}
                size="small"
                color="primary"
                variant="outlined"
                disabled
                value={getRoundedValue(this.getIndexFromCombiTypes(combiType) !== -1 ? this.settings.combiStakes[this.getIndexFromCombiTypes(combiType)] : 0)}
                onChange={this.updateStake.bind(this, this.settings.availableCombiTypes[this.getIndexFromAvailableCombiTypes(combiType)])}
              />
            ) : (
              <div className="mobile_stake_input_container">
                <div
                  // onClick={(e) => {
                  //   this.openStakePad(this.settings.availableCombiTypes[this.getIndexFromAvailableCombiTypes(combiType)]);
                  // }}
                  className="mobile_stake_input_overlay"
                ></div>
                <TextField
                  className={classes.textField}
                  size="small"
                  color="primary"
                  variant="outlined"
                  disabled
                  value={getRoundedValue(this.getIndexFromCombiTypes(combiType) !== -1 ? this.settings.combiStakes[this.getIndexFromCombiTypes(combiType)] : 0)}
                />
              </div>
            )}
          </div>
          <div className="color-contrast font-detail-1 font-bold-2 text-wrap vertical-center">
            <Translate value={`labels.slip_win_chance`} />
          </div>
          <div className="color-contrast font-detail-1 font-bold-2 text-wrap vertical-center content-right">
            {/* <Localize
              value={
                this.settings.availableCombiWinMin[
                  this.getIndexFromAvailableCombiTypes(combiType)
                ]
              }
              options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            /> */}
            {boosted?.isSystemBoosted && boosted.payload[combiType] ? boosted.payload[combiType].winMin : this.settings.availableCombiWinMin[this.getIndexFromAvailableCombiTypes(combiType)]?.toFixed(2)}
            &nbsp;-&nbsp;
            {/* <Localize
              value={
                this.settings.availableCombiWinMax[
                  this.getIndexFromAvailableCombiTypes(combiType)
                ]
              }
              options={{ minimumFractionDigits: 2, maximumFractionDigits: 2 }}
            /> */}
            {boosted?.isSystemBoosted && boosted.payload[combiType] ? boosted.payload[combiType].winMax : this.settings.availableCombiWinMax[this.getIndexFromAvailableCombiTypes(combiType)]?.toFixed(2)}
          </div>
        </div>
      );
    });
  }

  /**
   * Show number pad dialog for inputing stake value
   */

  openStakePad = (type) => {
    const settingsCopy = { ...this.settings };
    switch (type) {
      case "stake":
        this.stake = 0;
        settingsCopy.keepSystemTypeSettings = true;
        settingsCopy.stakeCalculationType = "total";
        this.slipTemplateSave(settingsCopy);
        break;

      default:
        settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = "";
        settingsCopy.keepSystemTypeSettings = true;
        settingsCopy.stakeCalculationType = "thisBet";

        this.stake = 0;
        settingsCopy.combiStakes?.forEach((combiStake, key) => {
          let value = Number(combiStake).toFixed(2) * settingsCopy.availableCombiCounts[key];
          value = Number(value.toFixed(2));

          this.stake += value;
        });
        this.stake = Number(this.stake.toFixed(2));
        this.slipTemplateSave(settingsCopy);
        break;
    }

    this.setState({ stakePadOpen: true, stakePadValueReceiver: type }, () => {
      const viewArea = window.document.getElementById("pad_scroller");
      if (viewArea) {
        viewArea.scrollIntoView(true);
      }
    });
  };

  /**
   * Get value from pad and set it as stake value
   */

  setStake = (type, value, isMaxChanging) => {
    let stake;
    let betValue;
    const settingsCopy = { ...this.settings };

    if (this.props.layout === "mouse") {
      switch (type) {
        case "stake":
          if (value.split(".")?.length - 1 < 2) {
            if (value.indexOf(".") > -1) {
              this.stake = value;
            } else {
              this.stake = Number(value);
            }

            settingsCopy.keepSystemTypeSettings = true;
            settingsCopy.stakeCalculationType = "total";
            settingsCopy.isMaxChanging = isMaxChanging;
            this.slipTemplateSave(settingsCopy);
          }
          break;

        default:
          if (this.getIndexFromCombiTypes(type) === -1) {
            settingsCopy.combiTypes.push(Number(type));
            settingsCopy.combiStakes.push(0);
          }

          switch (value) {
            default:
              // settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = Number(value);
              settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = value;
              break;

            case "decimal":
            case "delete":
              break;

            case "set":
              this.setState({ stakePadOpen: false });
              break;
          }

          settingsCopy.keepSystemTypeSettings = true;
          settingsCopy.stakeCalculationType = "thisBet";
          settingsCopy.isMaxChanging = isMaxChanging;
          this.stake = 0;
          settingsCopy.combiStakes?.forEach((combiStake, key) => {
            let value = Number(combiStake).toFixed(2) * settingsCopy.availableCombiCounts[key];
            value = Number(value.toFixed(2));

            this.stake += value;
          });

          this.stake = Number(this.stake.toFixed(2));

          this.slipTemplateSave(settingsCopy);
          break;
      }
    } else {
      switch (type) {
        case "stake":
          stake = String(this.stake);
          switch (value) {
            default:
              if ([10, 25, 50].includes(value)) {
                this.stake = value;
              } else {
                this.stake = stake + value;
              }
              break;

            case "decimal":
              if (stake.indexOf(".") === -1) {
                this.stake = stake + ".";
              }
              break;

            case "delete":
              this.stake = stake.slice(0, -1);
              break;

            case "set":
              if (this.stake.toString().indexOf(".") === -1) {
                this.stake = this.stake + ".00";
              } else {
                this.stake = parseFloat(this.stake);
              }

              this.setState({ stakePadOpen: false });
              break;
          }

          settingsCopy.keepSystemTypeSettings = true;
          settingsCopy.stakeCalculationType = "total";
          settingsCopy.isMaxChanging = isMaxChanging;
          this.slipTemplateSave(settingsCopy);
          break;

        default:
          betValue = settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] ? String(settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)]) : "";

          if (this.getIndexFromCombiTypes(type) === -1) {
            settingsCopy.combiTypes.push(Number(type));
            settingsCopy.combiStakes.push(0);
          }

          switch (value) {
            default:
              if ([10, 25, 50].includes(value)) {
                settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = value;
              } else {
                settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = betValue + value;
              }

              // settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = Number(value);
              break;

            case "decimal":
              if (betValue.indexOf(".") === -1) {
                settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = betValue + ".";
              }
              break;

            case "delete":
              settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = betValue.slice(0, -1);
              break;

            case "set":
              if (settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)].toString().indexOf(".") === -1) {
                settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] + ".00";
              } else {
                settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)] = parseFloat(settingsCopy.combiStakes[this.getIndexFromCombiTypes(type)]);
              }

              this.setState({ stakePadOpen: false });
              break;
          }

          settingsCopy.keepSystemTypeSettings = true;
          settingsCopy.stakeCalculationType = "thisBet";
          settingsCopy.isMaxChanging = isMaxChanging;

          this.stake = 0;
          settingsCopy.combiStakes?.forEach((combiStake, key) => {
            let value = Number(combiStake).toFixed(2) * settingsCopy.availableCombiCounts[key];
            value = Number(value.toFixed(2));

            this.stake += value;
          });

          this.stake = Number(this.stake.toFixed(2));

          this.slipTemplateSave(settingsCopy);
          break;
      }
    }
  };

  /**
   * Get Max Stake value from API and set it as stake value.
   */

  changeMaxStake = () => {
    if (this.props.auth.isSignedIn) {
      this.props.getMaxStake(this.props.slipTemplates, this.props.thisSlipTemplate, this.props.boosted);
    } else {
      this.props.navigate(`/${this.props.locale}/auth/login`);
    }
  };
  /**
   * Render Stake info parts ( button, checkbox and input box )
   */

  renderStakeInfo() {
    const { classes, translate } = this.props;
    let totalBets = this.settings.totalBetsPerBetType;
    let oddsText = totalBets === 1 ? totalBets + " " + translate.labels.combination : totalBets + " " + translate.labels.combinations;

    return (
      <Grid2 container spacing={1} paddingTop={1.3} paddingRight={0.7}>
        <Grid2 item size={7}>
          {totalBets > 1 && (
            <>
              <span style={{ width: "100%", padding: "0 0 0 1em" }}>{oddsText}</span>
              <Translate value={`labels.slip_stake`} style={{ margin: "0 0 0 1em" }} />
            </>
          )}
        </Grid2>
        <Grid2 item size={5}>
          {this.props.layout === "mouse" ? (
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <Box onClick={this.changeMaxStake.bind(this)} sx={{ cursor: "pointer" }}>
                      <Typography color="primary" variant="subtitle2">
                        MAX
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
              className={classes.textFieldStake}
              size="small"
              id="stake"
              variant="outlined"
              value={getRoundedValue(this.stake)}
              onChange={this.updateMaxStake.bind(this, "stake")}
              type="number"
            />
          ) : (
            <TextField
              className={classes.textField}
              onClick={() => this.openStakePad("stake")}
              size="small"
              id="stake"
              variant="outlined"
              value={getRoundedValue(this.stake)}
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <Box onClick={this.changeMaxStake.bind(this)} sx={{ cursor: "pointer" }}>
                      <Typography color="primary" variant="subtitle2">
                        MAX
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Grid2>
      </Grid2>
    );
  }

  /**
   * Update stake value
   */

  updateStake(type, e) {
    this.setStake(type, e.target.value);
  }
  /**
   * Update stake value
   */

  updateMaxStake(type, e) {
    this.setStake(type, e.target.value, true);
  }
  // get old price
  getOldPrice = (price) => {
    const currentPrice = parseFloat(price);
    let currentFloor = currentPrice * 0.95;
    if (currentFloor) {
      currentFloor = currentFloor?.toFixed(1);
      // currentFloor = currentFloor?.toFixed(2);
    }

    return currentFloor;
  };

  /**
   * Render Odds information
   */

  renderOddsInfo() {
    const { oddsFormat, host, selectedTheme, boosted } = this.props;

    return (
      <Box pt={2}>
        <Grid2 container spacing={1}>
          <Grid2 item size={5} display="flex" alignItems="center" justifyContent="center">
            <img
              style={{
                height: "2.5em",
                cursor: "pointer",
                marginBottom: "-30px",
              }}
              src={`https://api.nubet.com/media/logos/${host.logo}_${selectedTheme}.svg`}
              alt="logo"
            />
          </Grid2>
          <Grid2 item size={7} container>
            <Grid2 item size={6}>
              <div className="color-odds font-detail-1 font-bold-2  vertical-center">
                {/* {oddsText}&nbsp;-&nbsp; */}
                <Translate value={`labels.total_odds`} />
              </div>
            </Grid2>
            <Grid2 item size={6}>
              <div
                style={
                  boosted.isBoosted || boosted?.isSystemBoosted
                    ? {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }
                    : {}
                }
                className={` ${boosted.isBoosted || boosted?.isSystemBoosted ? "" : "vertical-center content-right"}`}
              >
                <div className={`color-odds font-detail-1 font-bold-2 flex-end  ${boosted.isBoosted || boosted?.isSystemBoosted ? "line-through" : "spacer-right-slip "}`}>
                  {oddsFormat === "decimal" ? (
                    boosted.isBoosted && boosted.isBetBuilder ? (
                      <OddsFormat isActive={true} price={this.getOldPrice(this.settings.maxOdds)} />
                    ) : (
                      getRoundedValue(this.settings.maxOdds)
                    )
                  ) : (
                    <OddsFormat price={boosted.isBoosted && boosted.isBetBuilder ? this.getOldPrice(this.settings.maxOdds) : this.settings.maxOdds} isActive="1" />
                  )}
                </div>
                {boosted.isBoosted || boosted?.isSystemBoosted ? (
                  <>
                    <KeyboardDoubleArrowRightIcon fontSize="small" style={{ color: "var(--grey)" }} />
                    <div className="color-odds font-detail-1 font-bold-2 flex-end spacer-right">{oddsFormat === "decimal" ? getRoundedValue(boosted.price) : <OddsFormat price={boosted.price} isActive="1" />}</div>
                  </>
                ) : null}
              </div>
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>
    );
  }

  /**
   * Render WIN information for bet type
   */

  renderWinInfo() {
    const { host, oddsFormat, boosted } = this.props;
    switch (this.betType) {
      case "single":
      case "combi":
        return (
          <Grid2 container spacing={1} key="render-win-info" marginTop={2}>
            <Grid2 item size={5} />
            <Grid2 item size={7}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <div className="color-contrast font-detail-1 font-bold-2  vertical-center">
                  <Translate value={`labels.slip_win_chance`} />
                </div>
                <div className="color-contrast font-detail-1 font-bold-2 flex-end spacer-right-slip">
                  <OddsFormat price={boosted?.isBoosted ? boosted.maxWin : this.settings.winMax} isActive="1" />
                  &nbsp; {host.brand === "nubet_se" ? "SEK" : "EUR"}
                </div>
              </Stack>
            </Grid2>
          </Grid2>
        );

      case "system":
        return (
          <Grid2 container spacing={1} key="render-win-info" marginTop={2}>
            <Grid2 item size={5} />
            <Grid2 item size={7}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <div className="color-contrast font-detail-1 font-bold-2  vertical-center">
                  <Translate value={`labels.slip_win_chance`} />
                </div>
                <div className="color-contrast font-detail-1 font-bold-2 flex-end spacer-right-slip">
                  {this.settings.winMin?.toFixed(2)}
                  &nbsp;-&nbsp;
                  {boosted?.isSystemBoosted ? boosted.winMax : this.settings.winMax?.toFixed(2)}&nbsp;
                  {host.brand === "nubet_se" ? "SEK" : "EUR"}
                </div>
              </Stack>
            </Grid2>
          </Grid2>
        );

      default:
        break;
    }
  }

  /**
   * Accept odds price changing automatically
   */

  acceptOddsChange(e) {
    this.props.saveAutoSlipUpdateStatus(e.target.checked);
  }

  /**
   * Render checkbox for Accept Odds Changes
   */

  renderAcceptOddsChange() {
    return (
      <div className="slip-oddchanges" key="render-accept-odds-change">
        <div className="vertical-center content-center slip-check-oddchanges">
          <Checkbox
            color="primary"
            style={{
              padding: "0",
              fontSize: "1.1em",
            }}
            checked={this.props.slipAutoUpdateStatus}
            onChange={this.acceptOddsChange.bind(this)}
          />
        </div>
        <div className="font-detail-1 text-wrap vertical-center">
          <Translate value={`labels.accept_oddchanges`} />
        </div>
        <div className="vertical-center content-right spacer-right cursor-pointer" onClick={this.emptySlipTemplate.bind(this)}>
          <DeleteIcon style={{ margin: "0 0 0.1em 0", fontSize: "1.9em" }} />
        </div>
      </div>
    );
  }

  /**
   * Update bet
   */

  updateBet = (bet) => {
    const { thisSlipTemplate } = this.props;
    this.props.updateBetWithLowerPrice(bet, thisSlipTemplate, this.constructor.name);
    // this.slipTemplateSave();
  };

  /**
   * Check if placing a bet is possible
   ** Placing a bet can be disabled when any bet is disabled or there is a price changing in any bet
   */

  checkPostTicketIsDisabled() {
    let flag = false;
    const { outrightExceeded } = this.props;

    Object.values(this.settings.bets)?.forEach((bet) => {
      if (bet.isActive === 0) flag = true;
      if (bet.tempPrice && !bet.isPriceUppered) flag = true;
    });

    if (this.betType === "system") {
      let flag1 = false;

      this.settings.availableCombiTypes?.forEach((combiType) => {
        if (this.settings.combiTypes.includes(this.settings.availableCombiTypes[this.getIndexFromAvailableCombiTypes(combiType)])) {
          flag1 = true;
        }
      });

      flag = !flag1;
    } else if (this.betType === "combi" && outrightExceeded) {
      flag = true;
    }

    return flag;
  }

  /**
   * Render Odds status in notification
   ** When price is invalid, odd is disabled, etc
   */

  renderErrorOdds() {
    if (!this.settings.bets) return null;
    if (this.props.slipAutoUpdateStatus) return null;

    return Object.values(this.settings.bets).map((bet, key) => {
      if (bet.tempPrice && !bet.isPriceUppered) {
        return (
          <div className="slip-odd-error-frame">
            <div className="slip-odd-error" key={key}>
              <div className="slip-odd-error-content">
                {bet?.isOutright ? (
                  <div>
                    {bet.game.game_title}: {bet.sn}
                  </div>
                ) : (
                  <div>
                    {bet.game.team1} - {bet.game.team2}:
                  </div>
                )}
                <div className="slip-odd-error-note">
                  <span>
                    <Translate value="acceptance.invalid_price" />
                  </span>
                  <span className="slip-odd-error-price">
                    {bet.price} <i className="material-icons">fast_forward</i> {bet.tempPrice}
                  </span>
                </div>
              </div>
              <div className="slip-odd-error-action spacer-right cursor-pointer">
                <i className="material-icons" title="Accept Odds" onClick={() => this.updateBet(bet)}>
                  check
                </i>
              </div>
              <div className="slip-odd-error-action spacer-right cursor-pointer">
                <i className="material-icons" title="Accept Odds" onClick={() => this.betDelete(bet)}>
                  close
                </i>
              </div>
            </div>
          </div>
        );
      }

      if (bet.isActive === 0) {
        return (
          <div className="slip-odd-error-frame">
            <div className="slip-odd-error" key={key}>
              <div className="slip-odd-error-content disabled">
                {bet?.isOutright ? (
                  <div>
                    {bet.game.game_title}: {bet.sn}
                  </div>
                ) : (
                  <div>
                    {bet.game.team1} - {bet.game.team1}:
                  </div>
                )}
                <div className="slip-odd-error-note">
                  <span>
                    <Translate value="acceptance.inactive_market" />
                  </span>
                  <i className="material-icons">lock</i>
                </div>
              </div>
              <div className="slip-odd-error-action spacer-right cursor-pointer">
                <i className="material-icons" title="Accept Odds" onClick={() => this.betDelete(bet)}>
                  close
                </i>
              </div>
            </div>
          </div>
        );
      }

      return null;
    });
  }

  /**
   * Render maximum payout warning notification
   ** The maximum payout per ticket is 100000.00
   */

  renderMaximunPayout() {
    const winMax = this.settings.winMax;
    if (winMax < 100000) return null;
    return (
      <div className="win-max-notification" key="win-max">
        <div className="vertical-center content-center">
          <i className="red material-icons spacer-right">info</i> <Translate value="slip.maximum" />
        </div>
        {/* <div className="red spacer-left font-m"></div> */}
      </div>
    );
  }
  /**
   * Render outrightsExceeded
   ** Combi can have only one outrights
   */

  renderNonRunner() {
    const { hasNrBet } = this.props;
    if (hasNrBet) {
      return (
        <div className="win-max-notification" key="non-runner">
          <div className="vertical-center content-center">
            <i className="red material-icons spacer-right">info</i> <Translate value="slip.nonrunner" />
          </div>
          {/* <div className="red spacer-left font-m"></div> */}
        </div>
      );
    } else {
      return null;
    }
  }
  /**
   * Render tr bets Exceeded
   ** TR outrights can have upto 512 combis in system
   */

  renderTrLimitsExceeded() {
    const { hasCombiLimitExceeded } = this.props;
    if (hasCombiLimitExceeded) {
      return (
        <div className="win-max-notification" key="non-runner">
          <div className="vertical-center content-center">
            <i className="red material-icons spacer-right">info</i> <Translate value="labels.tr_bets_exceeded" />
          </div>
          {/* <div className="red spacer-left font-m"></div> */}
        </div>
      );
    } else {
      return null;
    }
  }
  /**
   * Render bet selections exceeded
   ** Bet can have up to 15 selections
   */

  renderSelectionsExceeded() {
    const { maxSelections, marketsCount } = this.props;
    // console.log(maxSelections, slipsLength);
    if (marketsCount > maxSelections) {
      return (
        <div className="win-max-notification" key="max-selection">
          <div className="vertical-center content-center">
            <i className="red material-icons spacer-right">info</i> <Translate value="slip.maxselections" />
          </div>
          {/* <div className="red spacer-left font-m"></div> */}
        </div>
      );
    } else {
      return null;
    }
  }
  /**
   * Render bet selections exceeded
   ** Bet can have up to 15 selections
   */

  renderHasMultipleBB() {
    const { hasMultipleBB } = this.props;
    // console.log(maxSelections, slipsLength);
    if (hasMultipleBB) {
      return (
        <div className="win-max-notification" key="max-selection">
          <div className="vertical-center content-center">
            <i className="red material-icons spacer-right">info</i> {/* <Translate value="slip.maxselections" /> */}
            You can select only one baseball bet.
          </div>
          {/* <div className="red spacer-left font-m"></div> */}
        </div>
      );
    } else {
      return null;
    }
  }

  renderMinstakeLimit() {
    const { maxSelections, slipsLength } = this.props;
    // console.log(maxSelections, slipsLength);
    if (this.stake < 1) {
      return (
        <div className="win-max-notification" key="minimum-stake" style={{ border: "none" }}>
          <div className="vertical-center content-center">
            <i className="red material-icons spacer-right">info</i> <Translate value="labels.minimum_stake" />
          </div>
          {/* <div className="red spacer-left font-m"></div> */}
        </div>
      );
    } else {
      return null;
    }
  }

  renderBuilderBetMixed() {
    const { hasMixedBets, hasBetBuilderBet, betBuilderBets } = this.props;
    // console.log(maxSelections, slipsLength);
    if (hasMixedBets && hasBetBuilderBet) {
      return (
        <div className="win-max-notification" key="minimum-stake">
          <div className="vertical-center content-center">
            <i className="red material-icons spacer-right">info</i> {/* <Translate value="labels.minimum_stake" /> */}
            Combination of bet builder and platform bets is currently not possible.
          </div>
          {/* <div className="red spacer-left font-m"></div> */}
        </div>
      );
    } else if (hasBetBuilderBet && betBuilderBets?.length > 1 && !hasMixedBets) {
      return (
        <div className="win-max-notification" key="minimum-stake">
          <div className="vertical-center content-center">
            <i className="red material-icons spacer-right">info</i> {/* <Translate value="labels.minimum_stake" /> */}
            Combination of bet builder bets is currently not possible.
          </div>
          {/* <div className="red spacer-left font-m"></div> */}
        </div>
      );
    } else {
      return null;
    }
  }
  /**
   * Render when there are both outright and mainmarket selections from the the league
   */

  renderOutrightsCombined() {
    const { multipleOutright, betType } = this.props;
    if (multipleOutright && betType === "combi") {
      return (
        <div className="win-max-notification" key="multiple-outright-combi">
          <div className="vertical-center content-center">
            <i className="red material-icons spacer-right">info</i> <Translate value="slip.outrightcombi" />
          </div>
          {/* <div className="red spacer-left font-m"></div> */}
        </div>
      );
    }
  }

  /**
   * Render `Place a bet` button
   ** When logged in, it is `Place a bet`, otherwise it is `Login or Register`
   */

  renderPlaceBet() {
    const { host, multipleOutright, maxSelections, marketsCount, hasCombiLimitExceeded, hasNrBet, locale, hasMixedBets, hasBetBuilderBet, betType, hasMultipleBB, layout, betBuilderBets, isPostTicketLoading } =
      this.props;
    const winMax = this.settings.winMax;
    const isBetBuilderBlocked = hasBetBuilderBet && betBuilderBets?.length > 1 && !hasMixedBets;
    return (
      <div className="grid-divider-place text-center spacer-right spacer-left slip-place-bet" key="render-place-bet" id="bet_place_container">
        {this.props.auth.isSignedIn ? (
          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            loading={isPostTicketLoading}
            style={{ margin: "0.4em 0 0.5em", fontSize: "1em" }}
            disabled={
              this.checkPostTicketIsDisabled() ||
              (multipleOutright && betType === "combi") ||
              hasNrBet ||
              hasMultipleBB ||
              hasCombiLimitExceeded ||
              this.stake < 1 ||
              (hasMixedBets && hasBetBuilderBet) ||
              winMax >= 100000 ||
              isBetBuilderBlocked ||
              marketsCount > maxSelections
            }
            id="bet_placement_btn"
            onClick={() => {
              this.postTicket(false);
            }}
          >
            {this.checkPostTicketIsDisabled() || (multipleOutright && betType === "combi") || hasNrBet || hasCombiLimitExceeded || marketsCount > maxSelections || this.stake < 1 ? (
              <LockIcon style={{ margin: "0 0.5em" }} />
            ) : null}{" "}
            <Translate value="slip.post_ticket_place_bet" />
            &nbsp;{getRoundedValue(this.stake)}&nbsp;
            {host.brand === "nubet_se" ? "SEK" : "EUR"}
          </LoadingButton>
        ) : (
          <Button
            className="green-button"
            variant="contained"
            fullWidth
            size="small"
            style={{ margin: "0.4em 0 0.5em", fontSize: "1em" }}
            onClick={() => {
              this.props.navigate(`/${locale}/auth/login?redirect_to=${layout === "mouse" ? "/" : `slip`}`);
            }}
          >
            <Translate value="labels.slip_login" />
          </Button>
        )}
      </div>
    );
  }

  renderStakePadSpacer() {
    // console.log(this.state.stakePadOpen)
    if (!this.state.stakePadOpen) return null;

    return (
      <div key="spacer-numpad" className="bg-light" style={{ height: "17.5em" }}>
        {/* Placeholder */}
      </div>
    );
  }
  // getCalculatedSlipData = (slipData) => {

  //   this.slipTemplate = slipData.slipTemplate;
  //   this.betType = slipData.betType;
  //   this.stake = slipData.stake;
  //   this.settings = slipData.settings;
  //   this.setState({ loading: false });
  // }

  // calculateSlip() {
  //   const { thisSlipTemplate } = this.props;
  //   return (<SlipCalculator slipTemplate={thisSlipTemplate} parentCallBack={this.getCalculatedSlipData} />);
  // }

  /**
   * Render if slip is empty
   ** if there are no bets, show `Slip is empty` message.
   */

  renderSlipIsEmpty() {
    const { locale, host, layout, logo, siteTheme } = this.props;
    // return <div className="color-contrast vertical-center content-center font-xl" style={{ padding: '3.3em 0', backgroundColor: 'var(--bg-primary)' }}><Translate value="slip.slip_empty" /></div>
    return (
      <div>
        {layout === "mouse" ? (
          <div>
            {/* {host.gaming ?
                [
                  <CasinoBannerSmall key={0} />

                ] : null
              } */}
            {host.bet
              ? [
                  // <Link key="link-casino" to={`/register`}><img className="slip-banner" style={{ width: "100%", height: "auto", margin: "0 0 -0.2em 0" }} src={`https://api.nubet.com/media/banners/banner_betting_${locale}_${logo}.jpg`} alt="banner_slots" /></Link>
                  <Link key="link-casino" to={`/${locale}/auth/registration`}>
                    {/* <Link key="link-casino" to={`/${locale}/leagues/all/AF/31/46791617`}> */}
                    <div>
                      {/* <div className="slip-banner-text">
                        <Translate value="labels.slip_banner_1" />
                        <br></br>
                        <Translate value="labels.slip_banner_2" />
                      </div> */}
                      <img
                        className="slip-banner"
                        style={{
                          width: "100%",
                          height: "auto",
                          margin: "0 0 -0.2em 0",
                          borderRadius: "0.5em 0 0 0.5em",
                        }}
                        src={`https://storage.googleapis.com/nubet-prod-content-news/banner_betting_${logo}_${locale}_${siteTheme}.jpg`}
                        // src={`https://storage.googleapis.com/nubet-prod-content-news/banner_superbowl_${logo}_${locale}.jpg`}
                        alt="banner_nubet"
                      />
                    </div>
                  </Link>,
                ]
              : null}
          </div>
        ) : (
          <div className="color-contrast-slip vertical-center content-center font-xl" style={{ padding: "3.3em 0", backgroundColor: "var(--bg-primary)" }} key={0}>
            <Translate value="slip.slip_empty" />
          </div>
        )}
      </div>
    );
  }

  /**
   * Action for clearing current slip
   */

  emptySlipTemplate() {
    this.props.emptySlipTemplate(0);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    const { layout } = this.props;

    this.setState({ open: false });

    if (layout === "touch") {
      this.props.navigate("/");
    }
  };

  findGamesLength = (bets) => {
    const gamesList = [];
    bets?.forEach((bet) => {
      if (!gamesList.includes(bet.gameId)) {
        gamesList.push(bet.gameId);
      }
    });
    return gamesList?.length > 1;
  };

  /**
   * Main view part for component
   */

  render() {
    log(this.constructor.name, "render");
    const { classes, slipTemplates, thisSlipTemplate, disabledActions, hiddenTab, multipleOutright, layout } = this.props;
    const betTypeMenuItems = ["single", "combi", "system"];

    let bets = slipTemplates[thisSlipTemplate].settings?.bets;

    this.betType = slipTemplates[thisSlipTemplate]?.betType ? slipTemplates[thisSlipTemplate].betType : "single";
    this.stake = slipTemplates[thisSlipTemplate]?.stake;
    this.settings = slipTemplates[thisSlipTemplate]?.settings;
    const lengthValue = this.findGamesLength(Object.values(bets));

    if (!Object.keys(bets).length)
      return (
        <StyledPaper>
          <Box mb={1}>
            <Iconify width={50} icon="majesticons:receipt-text" color="primary.featured2" />
          </Box>
          <Typography variant="slipinfo" color="primary.featured2">
            <Translate value="slip.slip_empty" />
          </Typography>
        </StyledPaper>
      );

    return (
      <div>
        {/* <div className={classes.subTabs}> */}
        <BetBuilderUpdateAlert handleConfirm={() => this.postTicket(true)} />
        <div className={`${Object.keys(bets)?.length > 0 ? classes.subTabs : "hidden"}`}>
          <AppBar position="static" style={{ backgroundColor: "var(--bg-tabs-2)" }} elevation={0}>
            <Tabs value={this.betType} onChange={this.betTypeChange.bind(this)} variant="fullWidth" indicatorColor="primary" scrollButtons="auto" aria-label="scrollable auto tabs example">
              {betTypeMenuItems.map((item, key) =>
                item !== hiddenTab ? (
                  <Tab key={key} value={item} className={`nubet_test_${item}  ${classes.tabSelection}`} disabled={disabledActions?.includes(item)} label={<Translate value={`slip.${item}`} />} {...a11yProps(key)} />
                ) : null
              )}
            </Tabs>
          </AppBar>
        </div>
        {Object.keys(bets)?.length
          ? [
              this.renderBets(),
              this.renderStakeInfo(),
              this.renderOddsInfo(),
              this.renderWinInfo(lengthValue),
              this.renderErrorOdds(),
              this.renderMinstakeLimit(),
              this.renderBuilderBetMixed(),
              this.renderSelectionsExceeded(),
              this.renderHasMultipleBB(),
              this.renderMaximunPayout(),
              this.renderNonRunner(),
              this.renderTrLimitsExceeded(),
              this.renderOutrightsCombined(),
              this.renderAcceptOddsChange(),
              this.renderPlaceBet(),
              this.renderStakePadSpacer(),
              <div key="stakepad">
                <StakePad
                  stakePadValueReceiver={this.state.stakePadValueReceiver}
                  stakePadOpen={this.state.stakePadOpen}
                  parentCallBack={this.setStake}
                  slipTemplate={this.props.thisSlipTemplate}
                  forwardRef={(c) => {
                    this.openDrawer = c;
                  }}
                />
              </div>,
            ]
          : this.renderSlipIsEmpty()}
        <Dialog classes={{ paper: classes.dialog }} open={this.state.open} TransitionComponent={Transition} keepMounted onClose={this.handleClose}>
          <DialogTitle id="alert-dialog-slide-title">
            <Translate value="labels.place_ticket_success" />
          </DialogTitle>
          <DialogContent>
            <CheckCircleOutlineIcon
              style={{
                color: "var(--color-contrast)",
                fontSize: "4.5em",
                margin: "0.2em 0 0.5em 0",
              }}
            />
            {/* <DialogContentText style={{ margin: "0 0 1.9em 0" }} id="alert-dialog-slide-description">
              <Translate value="acceptance.ticket_successful" />
            </DialogContentText> */}
            <Button
              onClick={this.handleClose}
              variant="contained"
              color="primary"
              id="bet_placed_modal"
              fullWidth
              style={{
                fontSize: "1.5em",
                color: "var(--btn-text)",
                background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
              }}
            >
              <Translate value="labels.next" />
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let markets = {};
  const slipsLength = Object.keys(state.slips[0].settings?.bets || {})?.length;
  const bets = state.slips[0].settings?.bets || [];
  const settings = state.slips[0].settings;
  const betsValues = Object.values(bets);
  const hasNrBet = betsValues.find((item) => item?.nr === 1);
  const hasBetBuilderBet = betsValues.find((item) => item?.isBetBuilder);
  const betBuilderBets = betsValues.filter((item) => item?.isBetBuilder);
  const hasMixedBets = betsValues.find((item) => !item?.isBetBuilder);
  const bbBets = betsValues.filter((item) => item.isBetBuilder && item?.game?.sport_code === "BB");

  const combiesSum = settings.availableCombiCounts?.reduce((a, b) => a + b, 0);

  betsValues?.forEach((market) => {
    if (!markets[market.gameId]) {
      const allSameMarkets = betsValues.filter((item) => item.gameId === market.gameId);
      markets[market.gameId] = allSameMarkets;
    }
  });

  const marketsCount = Object.keys(markets)?.length;

  return {
    hasNrBet: hasNrBet ? true : false,
    disabledActions: state.slips[0].disabledActions,
    outrightExceeded: state.slips[0].outrightExceeded,
    multipleOutright: state.slips[0].multipleOutright,
    betType: state.slips[0]?.betType,
    hiddenTab: state.slips[0].hiddenTab,
    outrightLeaguesCount: state.slips[0].settings.outrightLeaguesCount,
    auth: state.auth,
    layout: state.settings.layout,
    siteTheme: state.settings.theme,
    thisSlipTemplate: 0,
    // selectedSlipTemplate: state.selected.slipTemplate,
    slipTemplates: _.cloneDeep(state.slips),
    selectionPrintLabels: state.labels.selection ? state.labels.selection.print : null,
    selectionDefaultLabels: state.labels.selection ? state.labels.selection.default : null,
    oddsFormat: state.settings.oddsFormat,
    locale: state.i18n.locale,
    translate: state.i18n.translations[state.i18n.locale],
    host: state.settings.host,
    slipAutoUpdateStatus: state.slips[0].slipAutoUpdateStatus,
    logo: state.settings.host.logo,
    alert: state.alert,
    maxSelections: state.slips[0]?.settings?.maxSelections,
    slipsLength: slipsLength,
    ticketListStatus: state.settings.ticketListStatus ? state.settings.ticketListStatus : "pending",
    hasBetBuilderBet: Boolean(hasBetBuilderBet),
    hasMixedBets: Boolean(hasMixedBets),
    hasMultipleBB: bbBets?.length > 1,
    url: state.settings.url,
    betBuilderBets: betBuilderBets,
    ticketsLimit: state.tickets.limit,
    selectedTheme: state.settings.theme,
    hasCombiLimitExceeded: combiesSum > settings.maxCombinations,
    marketsCount: marketsCount,
    boosted: calculateComboBoost(state.slips[0], state.comboboost),
    isPostTicketLoading: state.tickets.isPostTicketLoading,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveSlipTemplate,
    postTicketAsync,
    updateBetWithLowerPrice,
    getMaxStake,
    getTicketsAsync,
    getWalletBalanceAsync,
    emptySlipTemplate,
    saveAutoSlipUpdateStatus,
    saveBet,
    getTicketGamesAsync,
  })(withStyles(styles)(SlipTemplate))
);
