import {  Box, Divider, Grid2, Stack, styled, Typography } from "@mui/material";
import RHFormProvider from "../../hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import RHFTextField from "../../hook-form/rhf-text-field";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import RHFPasswordField from "../../hook-form/rhf-password-field";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../iconify";
import { Link } from "react-router-dom";
import { handleUserLoginAsync, trustlyLoginAsync } from "../../../redux-store/auth-reducer";
import ConfirmTermsAndConditions from "./confirm-terms";

const StyledContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const UserLogin = () => {
  // hooks
  const dispatch = useDispatch();
  // translations
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const isLoginLoading = useSelector((state) => state.auth.isLoginLoading);
  const shouldConfirmTerms = useSelector((state) => state.settings.shouldConfirmTerms);
  // validation scheme
  const signinValidationScheme = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Wrong email type"),
    userPass: Yup.string().required("Password is required"),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(signinValidationScheme),
    defaultValues: {
      email: null,
      userPass: null,
    },
  });
  // handle TRUSTLY LOGIN
  const handleTrustlyLogin = () => {
    dispatch(trustlyLoginAsync(locale));
  };

  // Handle signin
  const onSubmit = async (formValues) => {
    const data = { ...formValues };
    if (shouldConfirmTerms) {
      data["policyChecked"] = true;
    }
    dispatch(handleUserLoginAsync({ params: data }));
  };

  return (
    <StyledContainer>
      <Stack margin="auto" width={{ xs: "100%", sm: "90%", md: "450px" }} px={2}>
        <Typography variant="h2" component="h2" color="primary" my={2}>
          {translate.labels.account_login}
        </Typography>
        <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid2 container spacing={1}>
            <Grid2 item size={12}>
              <RHFTextField type="email" name="email" placeholder={translate.labels.email} sx={{ backgroundColor: "var(--db-secondary-color)" }} />
            </Grid2>
            <Grid2 item size={12}>
              <RHFPasswordField name="userPass" placeholder={translate.labels.password} type="password" sx={{ backgroundColor: "var(--db-secondary-color)" }} />
            </Grid2>
            <Grid2 item size={12}>
              <Stack py={1}>
                <LoadingButton loading={isLoginLoading} fullWidth startIcon={<Iconify icon="cuida:login-outline" />} type="submit" variant="contained" color="warning">
                  {translate.labels.account_login}
                </LoadingButton>
              </Stack>
            </Grid2>
            <Grid2 item size={12}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                <Stack width="45%">
                  <Divider />
                </Stack>
                <Typography>OR</Typography>
                <Stack width="45%">
                  <Divider />
                </Stack>
              </Stack>
            </Grid2>
            <Grid2 item size={12}>
              <LoadingButton
                loading={isLoginLoading}
                onClick={handleTrustlyLogin}
                endIcon={<Box component="img" alt="nubet.com & trustly payments" src={require("../../../media/trustly-white.png")} />}
                variant="contained"
                color="primary"
                fullWidth
              >
                {translate.labels.login_trustly_2}
              </LoadingButton>
            </Grid2>
            <Grid2 item size={12}>
              <Stack py={2}>
                <Link to={`/${locale}/auth/forgot-password`}>
                  <Typography color="text.secondary" variant="body1" align="center">
                    {translate.labels.forget_pass}
                  </Typography>
                </Link>
              </Stack>
            </Grid2>
          </Grid2>
          <ConfirmTermsAndConditions onConfirm={methods.handleSubmit(onSubmit)} />
        </RHFormProvider>
      </Stack>
    </StyledContainer>
  );
};

export default UserLogin;
