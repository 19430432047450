/**
 * Ticket component
 */
import "./Ticket.css";
import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AppBar } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import TicketBox from "./TicketBox";
import GameBoxColumn from "../../sports/@components/GameBoxColumn";
import BetSelectionLabel from "../../BetSelectionLabel";
import BetMarketLabel from "../../BetMarketLabel";
import PriceDropIcon from "@mui/icons-material/ArrowDropDown";
import PriceUpIcon from "@mui/icons-material/ArrowDropUp";

import { formatCommasAndDots } from "../../../helpers/utilities";
import withRouter from "../../../helpers/withRouter";
import Iconify from "../../iconify";

const styles = (theme) => ({
  root: {
    "& .MuiAppBar-root": {
      backgroundColor: "var(--bg-tabs-2)",
      "@media (hover:hover)": {
        minHeight: "3.3em",
      },
    },
    "& .MuiTabs-root": {
      minHeight: "2.6em",
    },
    "& .MuiTab-root": {
      minHeight: "2.6em !important",
      padding: "0 1.2em !important",
      "@media (hover:hover)": {
        fontSize: "1.2em !important",
      },
    },
    "& .MuiChip-root": {
      height: "10em",
    },
    flexGrow: 1,
    width: "100%",
    position: "sticky",
    top: "0",
    zIndex: "999",
  },
  back_btn_container: {
    position: "absolute",
    paddingLeft: "10px",
    top: 7,
  },
  bank: {
    height: "1em",
    width: "1em",
    margin: "0 0 0 0.3em",
    color: "var(--btn-text)",
    "& .MuiChip-label": {
      fontSize: "0.7em",
      padding: "0",
      fontWeight: "bold",
      "@media (hover:none)": {
        fontSize: "0.8em",
      },
    },
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Ticket extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      topMenuIndex: 0,
    };
  }

  componentDidMount() {
    const { auth, locale } = this.props;
    if (!auth.isSignedIn) this.props.navigate(`/${locale}`);
  }

  componentDidUpdate() {
    const { auth, locale } = this.props;
    if (!auth.isSignedIn) this.props.navigate(`/${locale}`);
  }

  /**
   * Show bets which are in current ticket
   * Show bet information ( price changes , price, active / disabled )
   */
  renderBets() {
    const { ticket, classes, translate } = this.props;
    const groupedTickets = ticket.bets.reduce((result, obj) => {
      (result[obj["gameId"]] = result[obj["gameId"]] || []).push(obj);
      return result;
    }, {});
    return Object.values(groupedTickets).map((betsGroup, key) => {
      const game = betsGroup[0]["game"];
      return (
        <div key={key}>
          {/* <GameBoxColumn game={[bet.game]} content="selectedGames" isInViewport={true} /> */}
          <GameBoxColumn game={[game]} content="selectedGamesTicket" isInViewport={true} />
          {betsGroup.map((bet, key) => {
            // console.log(bet);
            let infoText = translate?.labels?.nonerunner_info;
            if (bet?.deadHeatFactor) {
              infoText = translate?.labels?.dead_heat_info;
            }
            infoText = infoText?.replace("{placed_price}", formatCommasAndDots(bet?.placed_price, this.props.locale));
            infoText = infoText?.replace("{price}", bet?.price?.toFixed(2));

            return (
              // <div key={key} className="ticket-selection">
              <div key={key} className={`${bet?.isBetBuilder === true ? "ticket-selection-betbuilder" : "ticket-selection"}`}>
                {/* <div className="color-contrast-slip text-wrap vertical-center font-detail-1 spacer-left"> */}
                <div className="vertical-center font-detail-1 font-bold-2 spacer-left">
                  {bet?.isBetBuilder ? (
                    <span className="color-team  font-detail-1 font-bold-2 ">
                      <Translate value="marketSets.betbuilder" /> {":"}
                    </span>
                  ) : null}
                  {game?.sport_code === "TR" && key !== 0 ? <div /> : <BetMarketLabel isInTicket bet={bet} />}
                  {bet.isBank === 1 ? <Chip label="B" color="primary" className={classes.bank} /> : null}
                </div>
                <div className="font-xs font-bold vertical-center content-right">{key === 0 && <Translate value="tickets.placed" />}</div>
                {bet.currentIsActive === 1 ? <div className="color-contrast font-xs font-bold vertical-center content-right">{key === 0 && <Translate value="tickets.now" />}</div> : <div></div>}
                <div></div>
                <div></div>
                {/* <div className="color-contrast-slip font-detail-1 font-bold text-wrap spacer-left"> */}
                <div className="color-selection-slip font-detail-1 font-bold-2 spacer-left">
                  <BetSelectionLabel isInTicket bet={bet} />
                </div>
                {/* <div className={`${bet.currentIsActive !== 1 && bet.isWon === 1 ? 'green' : ''} ${bet.currentIsActive !== 1 && bet.isWon < 1 ? 'red' : ''} font-detail-1 font-bold vertical-center content-right ${bet?.game?.sport_code === "TR" && bet.basicPrice !== bet.currentPrice && !bet?.isNonRunner ? "tr-ticket-nonrunner" : ""}`}> */}
                <div
                  className={`${bet.currentIsActive !== 1 && bet.isWon === 1 ? "green" : ""} ${
                    bet.currentIsActive !== 1 && bet.isWon < 1 && !bet?.isBetBuilder ? "red" : ""
                  } font-detail-1 color-odds font-bold vertical-center content-right ${bet?.game?.sport_code === "TR" && bet?.placed_price !== bet?.price && !bet?.isNonRunner ? "tr-ticket-nonrunner" : ""}`}
                >
                  {formatCommasAndDots(bet.basicPrice, this.props.locale)}
                  {/* <div className="tr-nonrunner-info-box">
                      <p className='nonrunner-info-text'>
                        {infoText}
                      </p>
                      <a href='/' className='nonrunner-info-text green'>{translate?.labels?.see_rules}</a>
                    </div> */}
                </div>

                {/* PSML TASK 24 - RESULTS HERE JUST TO SHOW WHAT TO USE (PLEASE REMOVE {bet.result} AFTER FIXING TASK - MUST BE SPLITTED AND SHOWN IN THE GAMEBOX WHERE I PUT THE PLACEHOLDER "result_home and result_away" */}
                {/* <div className={`${bet.currentIsActive != 1 && bet.isWon === 1 ? 'green' : ''} ${bet.currentIsActive != 1 && bet.isWon < 1 ? 'red' : ''} font-m font-bold vertical-center content-right`}>{formatCommasAndDots(bet.basicPrice, this.props.locale)}{bet.result}</div> */}

                {bet.currentIsActive === 1 ? (
                  <div className={`color-contrast font-detail-1 font-bold text-wrap vertical-center content-right`}>{formatCommasAndDots(bet.currentPrice, this.props.locale)}</div>
                ) : (
                  <div></div>
                )}

                {bet.basicPrice === bet.currentPrice || bet.currentIsActive !== 1 ? (
                  <div></div>
                ) : bet.basicPrice > bet.currentPrice ? (
                  <div>
                    <PriceDropIcon
                      style={{
                        color: "var(--red)",
                        margin: "0 0 0.1em 0",
                        fontSize: "2em",
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <PriceUpIcon
                      style={{
                        color: "var(--green)",
                        margin: "0 0 0.1em 0",
                        fontSize: "2em",
                      }}
                    />
                  </div>
                )}

                <div className="text-wrap vertical-center font-bold font-detail-1 spacer-right">{bet.isLive === 1 ? <span>@ {bet.score}</span> : null}</div>

                <div
                  className={`${
                    (bet?.game?.sport_code === "TR" && bet?.placed_price !== bet?.price && !bet?.isNonRunner) || bet?.deadHeatFactor ? "color-contrast-slip font-xs spacer-left tr-ticket-info-divider" : "hidden"
                  }`}
                >
                  <div className="tr-ticket-info-box">
                    {infoText}
                    <br></br>
                    <a href={`/${this.props.locale}/info/betting-rules`} className="green">
                      {translate?.labels?.see_rules}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  }

  renderSupportForm() {
    return <div>Support und Feedback</div>;
  }

  renderTabContent() {
    const { ticket } = this.props;
    switch (this.state.topMenuIndex) {
      case 0:
        return (
          <div style={{ pointerEvents: "none" }}>
            {this.renderBets()}
            <TicketBox ticketId={ticket.meta.ticketID} teamsDisplayOff={this.props.teamsDisplayOff} content="ticketDetail" />
          </div>
        );

      case 1:
        return this.renderSupportForm();
      default:
        break;
    }
  }

  topMenuChange = (event, newValue) => {
    this.setState({ topMenuIndex: newValue }, () => {});
  };

  topMenuChangeIndex = (newValue) => {
    this.setState({ topMenuIndex: newValue }, () => {});
  };

  render() {
    log(this.constructor.name, "render");
    const { ticket, classes } = this.props;

    if (!ticket) return <div>Loading...</div>;

    // let topMenuItems = ['Ticket', 'Support-Center'];
    let topMenuItems = [ticket.meta.ticketCode];

    return (
      <div>
        <div className={classes.root}>
          <AppBar position="relative" elevation={0}>
            <Tabs value={this.state.topMenuIndex} onChange={this.topMenuChange.bind(this)} variant="fullWidth" indicatorColor="primary" scrollButtons="auto" aria-label="scrollable auto tabs example">
              {topMenuItems.map((item, key) => (
                <Tab key={key} value={key} label={item} {...a11yProps(key)} />
              ))}
            </Tabs>
            <div className={classes.back_btn_container}>
              <IconButton onClick={this.props.backToTickets} aria-label="back-button" size="small">
                <Iconify icon="material-symbols:arrow-back" />
              </IconButton>
            </div>
          </AppBar>
        </div>
        {this.renderTabContent()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    tickets: state.tickets,
    auth: state.auth,
    ticket: state.tickets.selected ? Object.values(state.tickets.selected)[0] : null,
    translate: state.i18n.translations[state.i18n.locale],
    teamsDisplayOff: ownProps.teamsDisplayOff,
    locale: state.i18n.locale,
    // ticketId: state.selectedTicket.meta.ticketID
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Ticket)));
