import React from "react";
import { Box, Paper, styled, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DepositInterface from "./deposit";
import WithdrawInterface from "./withdraw";

const StyledContainer = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.dbPaper,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    width: "70%",
    margin: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: "60%",
    margin: "auto",
  },
}));

const UserPaymentsPage = () => {
  const [value, setValue] = React.useState("deposit");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box px={1} py={2}>
      <StyledContainer>
        <TabContext value={value}>
          <Box pb={3}>
            <TabList indicatorColor="none" onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="DEPOSIT" value="deposit" />
              <Tab label="WITHDRAW" value="withdraw" />
            </TabList>
          </Box>
          <TabPanel value="deposit">
            <DepositInterface />
          </TabPanel>
          <TabPanel value="withdraw">
            <WithdrawInterface />
          </TabPanel>
        </TabContext>
      </StyledContainer>
    </Box>
  );
};

export default UserPaymentsPage;
