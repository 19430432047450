// Casino navigation tab
import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSelector, useDispatch } from "react-redux";
import { Translate } from "react-redux-i18n";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import { getCasinoGamesGroupNamesAsync, resetCasinoFeatureGames } from "../../../redux-store/casino-reducer";
import { Grid2 } from "@mui/material";
import CasinoSearchBox from "./CasinoSearch";
// casino tab icons
import CategoryBonusIcon from "./icons/CategoryBonus";
import CategoryLiveIcon from "./icons/CategoryLiveIcon";
import CategoryNewIcon from "./icons/CategoryNewIcon";
import CategoryPopularIcon from "./icons/CategoryPopularIcon";
import CategoryHomeIcon from "./icons/CategoryHome";
import CategoryFavouriteIcon from "./icons/CategoryFavouriteIcon";
import CategoryWinOftenIcon from "./icons/CategoryWinOftenIcon";
import CategoryDropsWinsIcon from "./icons/CategoryDropsWinsIcon";
import CategoryWinALotIcon from "./icons/CategoryWinALotIcon";
import CategoryMegawaysIcon from "./icons/CategoryMegawaysIcon";
import CategoryJackpotsIcon from "./icons/CategoryJackpotsIcon";
import CategoryHotGamesIcon from "./icons/CategoryHotGamesIcon";
import CategoryClassicsIcon from "./icons/CategoryClassicsIcon";
import CategoryBonusBuyIcon from "./icons/CategoryBonusBuyIcon";
import CategoryTournament from "./icons/CategoryTournament";

const cat_icons = {
  all: <CategoryBonusIcon />,
  classics: <CategoryClassicsIcon />,
  jackpots: <CategoryJackpotsIcon />,
  livecasino: <CategoryLiveIcon />,
  megaways: <CategoryMegawaysIcon />,
  new: <CategoryNewIcon />,
  popular: <CategoryPopularIcon />,
  bonusbuy: <CategoryBonusBuyIcon />,
  hotgames: <CategoryHotGamesIcon />,
  dropsandwins: <CategoryDropsWinsIcon />,
  winalot: <CategoryWinALotIcon />,
  winoften: <CategoryWinOftenIcon />,
  tournament: <CategoryTournament />,
};

const tabStyles = {
  fontSize: "0.8em",
  "@media (hover:hover)": {
    fontSize: "1.1em",
  },
  "& .MuiTab-iconWrapper": {
    marginBottom: "0.2em",
    fontSize: "4em",
    "@media (hover:hover)": {
      fontSize: "2.7em",
    },
    border: "1.5px solid var(--grey)",
    borderRadius: "1em",
    padding: "0.15em",
  },
  "&.Mui-selected": {
    // fontWeight: "bold",
    color: "var(--grey)",
  },
  "& .MuiTabScrollButton-root.Mui-disabled": {
    display: "none",
  },
};
const tabsStyles = {
  "& .MuiTabScrollButton-root.Mui-disabled": {
    display: "none",
  },
};

const CasinoFilterTabs = () => {
  //Redux action dispatcher
  const dispatch = useDispatch();
  const location = useLocation();
  // Selectors
  const navigationList = useSelector((state) => state.casino.gameGroups);
  const layout = useSelector((state) => state.settings.layout);
  const locale = useSelector((state) => state.i18n.locale);
  const auth = useSelector((state) => state.auth);
  const favGamesIds = useSelector((state) => state.casino.favGames.ids);
  //router hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  //get filter from search params
  const tabValue = searchParams.get("filter");

  //Handle tab menu change
  const handleChange = (event, newValue) => {
    if (newValue === "home") {
      navigate(`/${locale}/casino/dashboard`);
    } else if (newValue === "my_games") {
      navigate(`/${locale}/casino/my-games`);
    } else {
      dispatch(resetCasinoFeatureGames(newValue));
      navigate(`/${locale}/casino/featured-games?filter=${newValue}`);
    }
  };

  useEffect(() => {
    dispatch(getCasinoGamesGroupNamesAsync());
  }, [locale]);

  return (
    <Grid2 container sx={{ width: "100%" }}>
      <Grid2 item size={{ xs: 12, md: 9 }} sx={{ width: "100%", padding: 0 }}>
        <Tabs
          value={tabValue ? tabValue : location.pathname.includes("casino/dashboard") ? "home" : location.pathname.includes("/casino/my-games") ? "my_games" : "null"}
          onChange={handleChange}
          aria-label="casino-filter-tabs"
          role="navigation"
          orientation={"horizontal"}
          variant={layout === "mouse" ? "scrollable" : "scrollable"}
          // variant="scrollable"
          // variant="fullWidth"
          scrollButtons="auto"
          TabIndicatorProps={{
            sx: {
              display: "none",
            },
          }}
          sx={tabsStyles}
        >
          <Tab key="home" value="home" label={<Translate value={`slots.home`} />} icon={<CategoryHomeIcon />} iconPosition="top" sx={tabStyles} />
          {auth.isSignedIn && favGamesIds?.length && <Tab key="my_games" value="my_games" label={<Translate value={`slots.mygames`} />} icon={<CategoryFavouriteIcon />} iconPosition="top" sx={tabStyles} />}
          {navigationList?.map((item, indx) => (
            <Tab key={indx} value={item.toLowerCase()} iconPosition="top" label={<Translate value={`slots.${item?.replace(/\s/g, "").toLowerCase()}`} />} icon={cat_icons[item.toLowerCase()]} sx={tabStyles} />
          ))}

          <Tab key="null-tab" value="none" sx={{ display: "none" }} />
        </Tabs>
      </Grid2>
      <Grid2 item size={0} />
      <Grid2
        item
        size={{ xs: 12, md: 3 }}
        sx={{
          margin: layout === "mouse" ? "1em 0 0 0" : "1em 1em 0",
        }}
      >
        <CasinoSearchBox />
      </Grid2>
    </Grid2>
  );
};

export default CasinoFilterTabs;
