import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthenticatedRedirect = ({ children }) => {
  const navigate = useNavigate();
  const locale = useSelector((state) => state.i18n.locale);
  const isSignedIn = useSelector((state) => state.auth.isSignedIn);
  useEffect(() => {
    if (isSignedIn) {
      navigate(`/${locale}/sports?sportEventStatusSlug=all`);
    }
  }, [isSignedIn]);
  return children;
};

export default AuthenticatedRedirect;
