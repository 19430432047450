/**
 * LanguageSwitcher component - currently not being used
 */
import hostConfig from "../config/hostConfig";
import { log } from "../config/log";
import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { withStyles } from "@mui/styles";
import { supportedLocales } from "../config/i18n";
import { getLabelsMarkets, emptySlipTemplate } from "../actions";
import { setLocaleWithFallback } from "../actions/i18n";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import withRouter from "../helpers/withRouter";
import { generateDatabetTokenAsync } from "../redux-store/auth-reducer";

const styles = (theme) => ({
  root: {
    "& .MuiButton-root": {
      textTransform: "none",
      fontSize: "0.92rem",
      color: "var(--grey)",
      fontWeight: "400",
      "@media (hover:hover)": {
        fontSize: "0.75rem",
      },
    },
    "& .MuiButton-startIcon": {
      marginRight: "0.8rem",
      "@media (hover:hover)": {
        marginRight: "0.8rem",
      },
    },
    "& .MuiButtonGroup-groupedTextHorizontal:not(:last-child)": {
      border: "0",
    },
    "& .MuiListItemAvatar-root": {
      minWidth: "30px",
    },
    flexDirection: "column",
    alignItems: "left",
    "& > *": {
      zIndex: 9999,
    },
  },
  avatarSmall: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
});

class LanguageSwitcher extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      selectedKey: "fi",
    };
  }

  UNSAFE_componentWillMount() {
    let defaultLocale = localStorage.getItem("lang") ? localStorage.getItem("lang") : "fi";
    if (defaultLocale === "") {
      // get browser LOCALE
      let language =
        (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
        navigator.language || // All browsers
        navigator.userLanguage; // IE <= 10

      if (language.indexOf("en") > -1) {
        defaultLocale = "en";
      } else if (language.indexOf("et") > -1) {
        defaultLocale = "et";
      } else {
        defaultLocale = "fi";
      }
    }

    this.setState({ selectedKey: defaultLocale });
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = (event) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleLanguageLinkClick = (event, lang) => {
    event.preventDefault();
    let host = window.location.hostname ? window.location.hostname.replace(/\./g, "_").replace(/\-/g, "_") : "betv3_frontend_herokuapp_com";

    let titleElement = document.getElementById("page_title");
    titleElement.innerHTML = hostConfig[host][`title_${lang ? lang : "fi"}`] ? hostConfig[host][`title_${lang ? lang : "fi"}`] : "";

    let descriptionElement = document.getElementById("page_description");
    descriptionElement.setAttribute("content", hostConfig[host][`description_${lang ? lang : "fi"}`] ? hostConfig[host][`description_${lang ? lang : "fi"}`] : "");

    let keywordsElement = document.getElementById("page_keywords");
    keywordsElement.setAttribute("content", hostConfig[host][`keywords_${lang ? lang : "fi"}`] ? hostConfig[host][`keywords_${lang ? lang : "fi"}`] : "");

    const { url, navigate, searchParams, auth } = this.props;

    this.setState({ selectedKey: lang, open: false }, () => {
      this.props.getLabelsMarkets(lang, this.constructor.name);
      window.document.querySelector("html").lang = lang;
      this.props.setLocaleWithFallback(lang, this.constructor.name);
    });
    this.props.emptySlipTemplate(0, this.constructor.name);
    let newRedirectUrl = url;
    if (url?.slice(1, 3) === "en") {
      newRedirectUrl = url?.replace("en", lang);
      navigate(`${newRedirectUrl}?${searchParams.toString()}`);
    } else if (url?.slice(1, 3) === "fi") {
      newRedirectUrl = url?.replace("fi", lang);
      navigate(`${newRedirectUrl}?${searchParams.toString()}`);
    } else if (url?.slice(1, 3) === "et") {
      newRedirectUrl = url?.replace("et", lang);
      navigate(`${newRedirectUrl}?${searchParams.toString()}`);
    }
    localStorage.setItem("lang", lang);
    this.props.generateDatabetTokenAsync(lang);
  };

  setIcon = (lang) => {
    let iconPath = "https://api.nubet.com/media/flags/";
    let icon;
    switch (lang) {
      case "en":
        icon = "UK.png";
        break;
      case "et":
        icon = "EST.png";
        break;
      case "fi":
        icon = "FIN.png";
        break;
      default:
        break;
    }

    return iconPath + icon;
  };

  render() {
    log(this.constructor.name, "render");
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <ButtonGroup variant="text" size="small" color="primary" ref={(ref) => (this.anchorRef = ref)} aria-label="split button">
          <Button sx={{ paddingLeft: "3px" }} startIcon={<Avatar src={this.setIcon(this.state.selectedKey)} sx={{ width: "20px", height: "20px" }} />}>
            {supportedLocales[this.state.selectedKey]}
          </Button>
          <Button
            variant="text"
            color="primary"
            size="small"
            aria-controls={this.state.open ? "split-button-menu" : undefined}
            aria-expanded={this.state.open ? "true" : undefined}
            aria-haspopup="menu"
            onClick={this.handleToggle.bind(this)}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper open={this.state.open} anchorEl={this.anchorRef} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose.bind(this)}>
                  <MenuList id="split-button-menu">
                    {Object.entries(supportedLocales).map(([key, value]) => (
                      <MenuItem key={key} selected={key === this.state.selectedKey} onClick={(event) => this.handleLanguageLinkClick(event, key)}>
                        <ListItemAvatar>
                          <Avatar src={this.setIcon(key)} sx={{ width: "20px", height: "20px" }} />
                        </ListItemAvatar>
                        {value}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

LanguageSwitcher.propTypes = {
  locale: PropTypes.string.isRequired,
  getLabelsMarkets: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    locale: state.i18n.locale,
    url: state.settings.url,
    auth: state.auth,
  };
};
const mapDispatchToProps = {
  getLabelsMarkets,
  setLocaleWithFallback,
  emptySlipTemplate,
  generateDatabetTokenAsync,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LanguageSwitcher)));
