/**
 * Slip component (consists of 2 menu items)
 ** My templates and slip menu items and components for those menu items
 ** currenly My Template menu item is disabled
 ** only Slip menu item is active
 ** Correct component for Slip menu item is SlipTemplate
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { selectSlipTemplate, createSlipTemplate, importSlipTemplate, setAlert } from "../../../actions/index";
import { Translate } from "react-redux-i18n";
import withStyles from "@mui/styles/withStyles";
import { AppBar } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import BannerSlip2 from "../../../media/img/banner_fb_1080x1920_team_.jpg";
import SlipNewIcon from "@mui/icons-material/AddCircle";
import SlipTemplatesIcon from "@mui/icons-material/ViewList";
import SlipIcon from "@mui/icons-material/AssignmentOutlined";
import InfoIcon from "@mui/icons-material/Info";
import SlipListBox from "./SlipListBox";
import SlipTemplate from "./SlipTemplate";
import { toggleMyTicketsPanel } from "../../../redux-store/settings-reducer";
import BetPlaced from "./BetPlaced";

const styles = (theme) => ({
  root: {
    "& .MuiTab-wrapper": {
      display: "inline-flex !important",
      textTransform: "uppercase",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.3em",
      "@media (hover: hover)": {
        fontSize: "1.7em",
      },
    },
    "& .MuiTab-wrapper > *:first-child": {
      marginBottom: 0,
    },
    // "& .MuiTabs-root": {
    //   minHeight: "3.3em",
    // },
    "& .MuiTab-root": {
      minHeight: "2em",
      minWidth: 0,
      fontSize: "1.3em",
      "@media (hover:hover)": {
        // fontSize: "1.2em",
        fontSize: "1em",
        fontWeight: "500 !important",
        minHeight: "2.6em",
      },
    },
    "& .MuiTab-labelIcon": {
      minHeight: 0,
    },
    flexGrow: 1,
    width: "100%",
    // "& .MuiAppBar-root": {
    //   "@media (hover:hover)": {
    //     minHeight: "3em",
    //   },
    // },
    "@media (hover:hover)": {
      position: "sticky",
    },
    zIndex: "999",
    top: "0",
    backgroundColor: "var(--bg-slip)",
    borderRadius: "0.5em",
  },

  topTabs: {
    "& .MuiTab-root": {
      textTransform: "uppercase !important",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      // color: "var(--color-slip-top)",
      // color: "var(--grey)",
    },
    "@media (hover:hover)": {
      // background: "var(--bg-slip-top)",
    },
  },
  subTabs: {
    "& .MuiTab-root": {
      minHeight: "1.95rem !important",
      padding: "0.35em 0 !important",
      "@media (hover:hover)": {
        minHeight: "2.3em !important",
        padding: "0.5em 0 !important",
        fontSize: "1.2em !important",
      },
    },
  },
  button: {
    "& .MuiButton-root": {
      width: "95%",
      padding: "0.2em 0.2em",
      fontSize: "1.1em",
      textTransform: "none",
    },
  },
  scroll: {
    "@media (hover:hover)": {
      maxHeight: "90vH",
      overflowY: "auto",
      overflowX: "hidden",
      margin: 0,
      padding: 0,
      listStyle: "none",
      height: "100%",
    },
  },
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

class Slip extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      topMenuIndex: 0,
      listMenuIndex: 0,
      importSlipTemplateData: "",
    };
  }

  componentDidUpdate() {
    const { slipTemplates, selectedSlipTemplate } = this.props;
    if (!slipTemplates[selectedSlipTemplate]) {
      this.props.selectSlipTemplate(Object.values(slipTemplates)[0].slipTemplate, this.constructor.name);
    }
  }

  // componentDidMount() {
  //   const { slipTemplates, thisSlipTemplate } = this.props;
  //   if (!slipTemplates[thisSlipTemplate]) this.props.selectSlipTemplate(thisSlipTemplate, this.constructor.name);
  // }

  importSlipTemplate = () => {
    let importSlipTemplateData;
    try {
      importSlipTemplateData = JSON.parse(atob(this.state.importSlipTemplateData));
      this.props.importSlipTemplate(importSlipTemplateData, this.constructor.name);
      this.setState({ importSlipTemplateData: "" }, () =>
        this.props.setAlert({
          type: "success",
          payload: "operation_successful",
        })
      );
    } catch (e) {
      this.setState({ importSlipTemplateData: "" }, () => this.props.setAlert({ type: "error", payload: "invalid_slip_template" }));
    }
  };

  slipTemplateOpen = (slipTemplate) => {
    this.slipTemplateSave();
    this.setState({
      topMenuIndex: 1,
    });
  };

  slipTemplateCreate = () => {
    const { slipTemplates } = this.props;
    this.slipTemplateSave();
    let newSlipTemplate = Object.values(slipTemplates)?.length;
    this.props.selectSlipTemplate(newSlipTemplate, this.constructor.name);
    this.props.createSlipTemplate(newSlipTemplate, this.constructor.name);
    this.setState({
      listMenuIndex: 0,
      topMenuIndex: 1,
    });
  };

  topMenuChange = (event, newValue) => {
    // this.slipTemplateSave();
    this.setState({ topMenuIndex: newValue }, () => {});
  };

  topMenuChangeIndex = (newValue) => {
    // this.slipTemplateSave();
    this.setState({ topMenuIndex: newValue }, () => {});
  };

  listMenuChange = (event, newValue) => {
    this.setState({ listMenuIndex: newValue }, () => {});
  };

  renderAddSlipTemplate() {
    return (
      <div className="spacer-left spacer-right">
        <Button
          onClick={(e) => this.slipTemplateCreate()}
          variant="contained"
          fullWidth
          size="small"
          style={{
            backgroundColor: "var(--green)",
            color: "var(--white)",
            width: "100%",
            margin: "0.5em 0",
          }}
        >
          <Translate value={`slip.new_template`} />
        </Button>
      </div>
    );
  }

  renderImportSlipTemplate() {
    return (
      <div className="spacer-left spacer-right">
        <TextField
          id="standard-multiline-static"
          label="Slip-Hash"
          multiline
          value={this.state.importSlipTemplateData}
          onChange={(e) => this.setState({ importSlipTemplateData: e.target.value })}
          rows={4}
          // defaultValue="Default Value"
          style={{
            // backgroundColor: "var(--green)",
            color: "var(--white)",
            width: "100%",
            margin: "0.5em 0",
          }}
        />
        <Button
          onClick={(e) => this.importSlipTemplate()}
          variant="contained"
          fullWidth
          size="small"
          style={{
            backgroundColor: "var(--green)",
            color: "var(--white)",
            width: "100%",
            margin: "0.5em 0",
          }}
        >
          Importieren
        </Button>
      </div>
    );
  }

  renderSlipTemplateInfo() {
    return (
      <div>
        <img style={{ width: "100%", height: "100%", margin: "0 0 0 0" }} src={BannerSlip2} alt="banner"></img>
      </div>
    );
  }

  renderListTabContent = () => {
    switch (this.state.listMenuIndex) {
      default:
      case 0:
        return this.renderList();
      case 1:
        return this.renderAddSlipTemplate();
      // case 2:
      //   return this.renderImportSlipTemplate()
      case 2:
        return this.renderSlipTemplateInfo();
    }
  };

  renderList() {
    const { slipTemplates } = this.props;
    return Object.values(slipTemplates).map((slipTemplate, key) => {
      return (
        <div key={key}>
          <SlipListBox slipTemplate={slipTemplate.slipTemplate} parentCallBack={this.slipTemplateOpen} />
        </div>
      );
    });
  }

  renderListMenu() {
    const { classes } = this.props;

    let listMenuItems = [];
    listMenuItems.push(
      {
        value: "templates_all",
        icon: <SlipTemplatesIcon />,
        text: <Translate value={`slip.templates_all`} />,
      },
      {
        value: "templates_new",
        icon: <SlipNewIcon />,
        text: <Translate value={`slip.templates_new`} />,
      },
      // {
      //   value: 'templates_import',
      //   icon: <SlipNewIcon />,
      //   text: <Translate value={`slip.templates_import`} />
      // },
      {
        value: "templates_info",
        icon: <InfoIcon />,
        text: <Translate value={`slip.templates_info`} />,
      }
    );

    return (
      <div className={classes.subTabs} key="slip-sub-tabs">
        <AppBar
          position="static"
          // style={{ backgroundColor: 'var(--bg-tabs-1)' }}
        >
          <Tabs value={this.state.listMenuIndex} onChange={this.listMenuChange.bind(this)} variant="fullWidth" indicatorColor="primary" scrollButtons="auto" aria-label="scrollable auto tabs example">
            {listMenuItems.map((item, key) => (
              <Tab key={key} value={key} label={item.text} {...a11yProps(key)} />
            ))}
          </Tabs>
        </AppBar>
        {this.renderListTabContent()}
      </div>
    );
  }

  renderMenuContent(menuIndex) {
    const { selectedSlipTemplate, classes, isTicketPosted } = this.props;
    if (menuIndex !== this.state.topMenuIndex) return <div key={menuIndex}></div>;

    switch (menuIndex) {
      case 0:
        //   return this.renderListMenu()
        // default:
        return (
          <div key="slip-template" className={classes.scroll}>
            {isTicketPosted ? (
              <BetPlaced />
            ) : (
              <SlipTemplate
                slipTemplate={selectedSlipTemplate}
                forwardRef={(c) => {
                  this.slipTemplateSave = c;
                }}
              />
            )}
          </div>
        );

      default:
        break;
    }
  }

  render() {
    log(this.constructor.name, "render");
    // const { classes, slipTemplates, selectedSlipTemplate, mode } = this.props;
    const { classes, slipTemplates, layout, translate, host, auth, url } = this.props;

    let topMenuItems = [];
    topMenuItems.push(
      // {
      //   value: 'my_templates',
      //   icon: <SlipTemplatesIcon />,
      //   text: <Translate value={`slip.my_templates`} />
      // },
      {
        value: "slip",
        icon: <SlipIcon />,
        // text: <Translate value={'slip.slip'} className={`${layout === 'mouse' ? classes.root : 'hidden'}`} style={{ backgroundColor: "var(--bg-menu-top)" }}/>
        text: <Translate value={"slip.slip"} className={`${layout === "mouse" ? classes.root : "hidden"}`} />,
      }
    );

    if (!slipTemplates) return <div>Loading...</div>;

    return (
      <div className={classes.root}>
        {/* <div className={`${layout === 'mouse' ? classes.root : 'hidden'}`}> */}
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs className={classes.topTabs} value={this.state.topMenuIndex} onChange={this.topMenuChange.bind(this)} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
            {topMenuItems.map((item, key) => (
              <Tab
                key={key}
                value={key}
                // icon={mode === 'standard' ? item.icon : null}
                label={item.text}
                {...a11yProps(key)}
              />
            ))}
            {host.bet && auth.isSignedIn && layout === "mouse" && !url?.includes("/casino") ? (
              <Tab
                key=""
                value="www"
                className={classes.tabSelection}
                label={translate.labels.show_tickets}
                onClick={() => {
                  this.props.toggleMyTicketsPanel(true);
                }}
              />
            ) : null}
          </Tabs>
        </AppBar>
        <SwipeableViews axis={"x"} index={this.state.topMenuIndex} onChangeIndex={this.topMenuChangeIndex.bind(this)}>
          {topMenuItems.map((item, key) => {
            return this.renderMenuContent(key);
          })}
        </SwipeableViews>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedSlipTemplate: state.selected.slipTemplate,
    slipTemplates: state.slips,
    mode: state.settings.mode,
    layout: state.settings.layout,
    translate: state.i18n.translations[state.i18n.locale],
    host: state.settings.host,
    auth: state.auth,
    url: state.settings.url,
    isTicketPosted: state.tickets.isTicketPosted,
  };
};

export default connect(mapStateToProps, {
  selectSlipTemplate,
  createSlipTemplate,
  importSlipTemplate,
  setAlert,
  toggleMyTicketsPanel,
})(withStyles(styles)(Slip));
