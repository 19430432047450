import { Box, Grid2, MenuItem, Stack, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import RHFormProvider from "../../hook-form/form-provider";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import RHFPasswordField from "../../hook-form/rhf-password-field";
import RHFSelectField from "../../hook-form/rhf-select-field";
import { LoadingButton } from "@mui/lab";
import { blockUserAsync, getWalletBalanceAsync } from "../../../redux-store/account-reducer";

const blockDays = [1, 3, 7];

const BlockAccountInterface = () => {
  const dispatch = useDispatch();
  // state selectros
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const isLoading = useSelector((state) => state.account.details.isBlockUserLoading);
  // scheme
  const blockUserScheme = Yup.object().shape({
    password: Yup.string().required(translate.labels.field_required),
    coolOffDays: Yup.number().required(translate.labels.field_required),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(blockUserScheme),
    defaultValues: {
      password: "",
      coolOffDays: 1,
    },
  });

  const getWalletBalance = () => {
    dispatch(getWalletBalanceAsync());
  };

  const onSubmit = (formValues) => {
    dispatch(blockUserAsync({ params: formValues, callback: getWalletBalance }));
  };

  return (
    <Box py={3} px={{ xs: 0, md: 2 }}>
      <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid2 container spacing={3}>
          <Grid2 item size={12}>
            <Typography align="center" variant="body2">
              <Translate value="labels.account_block" />
            </Typography>
          </Grid2>
          <Grid2 item size={{ xs: 12, md: 6 }}>
            <RHFPasswordField name="password" placeholder={translate.labels.password} />
          </Grid2>
          <Grid2 item size={{ xs: 12, md: 6 }}>
            <RHFSelectField name="coolOffDays" label={translate.labels.period}>
              <MenuItem value="" disabled>
                {translate.labels.please_select}&hellip;
              </MenuItem>
              {blockDays.map((key, index) => {
                let days = key === 1 ? translate.labels.days_singular : translate.labels.days_plural;
                return (
                  <MenuItem value={key} key={index}>
                    {key} {days}
                  </MenuItem>
                );
              })}
            </RHFSelectField>
          </Grid2>
          <Grid2 item size={12}>
            <Stack alignItems="center">
              <LoadingButton type="submit" loading={isLoading} variant="contained" color="primary">
                <Translate value="labels.block_acct_btn" />
              </LoadingButton>
            </Stack>
          </Grid2>
        </Grid2>
      </RHFormProvider>
      <Stack alignItems="center" pt={4}>
        <Typography variant="h4" color="primary">
          {translate.labels.close_account}
        </Typography>
        <Stack pt={2}>
          {locale === "et" ? (
            <Typography variant="">
              Kui soovite oma mängukonto jäädavalt sulgeda, palun pea meeles, et mängukonto sulgemist ei saa tühistada mis tahes asjaoludel ja te ei tohi hiljem uut mängukontot avada. Samuti saate esmalt oma mängimist
              juhtida, kasutades näiteks mängupiiranguid. Kui tunned, et sul on probleeme mängimise juhtimisega siis sulgege oma konto jäädavalt ja võtke meiega viivitamatult ühendust aadressil support@nubet.com.
            </Typography>
          ) : locale === "fi" ? (
            <Typography>
              Jos haluat sulkea pelitilisi pysyvästi niin huomioi, että pelitilin sulkemista ei voida peruuttaa eikä uutta pelitiliä avata myöhemmin. Voit hallita pelaamistasi ensisijaisesti käyttämällä vastuullisia
              pelityökaluja, kuten pelirajoja. Kuitenkin, jos sinulla on ongelmia pelaamisen hallinnassa ja haluat sulkea tilisi pysyvästi, ota meihin yhteyttä välittömästi osoitteessa support@nubet.com.
            </Typography>
          ) : (
            <Typography variant="body2" color="warning" align="center">
              If you want to close your game account permanently, please remember that closing the game account cannot be canceled under any circumstances and you may not open a new game account later. You can also
              control your gaming first by making use of responsible gaming tools, such as game limits. If you feel that you have problems with the control of playing and you want to close your account permanently,
              please contact us immediately at support@nubet.com.
            </Typography>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default BlockAccountInterface;
