/**
 * BetSelectionLabel - Show betting market label
 */

import { log } from "../config/log";
import React from "react";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import marketSetConfig from "../config/marketSetConfig";
import CommitIcon from "@mui/icons-material/Commit";
import { Box, Stack, Typography } from "@mui/material";

const styles = (theme) => ({
  // root: {
  //       "@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation:landscape)": {
  //         fontSize: '0.6em',
  //         color: 'var(--red)',
  //       }
  // },
});

class BetSelectionLabel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  //  Get if market has a hc specifier
  marketHasHc(marketId) {
    const { marketSpecifiers } = this.props;
    let specifiers = marketSpecifiers[marketId]?.split("|") ? marketSpecifiers[marketId]?.split("|") : [];
    let allowedSpecifiers = ["hcp", "total"];
    let hasHc = false;

    specifiers?.forEach((specifier, key) => {
      // $.each(specifiers, function (k, specifier) {
      if (allowedSpecifiers.includes(specifier)) {
        // if ($.inArray(specifier, allowedSpecifiers) > -1) {
        hasHc = true;
        return false;
      }
    });

    return hasHc;
  }

  /**
   * Get all specifiers
   */

  getAllSpecifiers(hcStr) {
    if (hcStr === "") {
      return {};
    }

    let specifiers = hcStr.split("|");
    let hc = {};

    specifiers?.forEach((specifier, key) => {
      // $.each(specifiers, function (k, specifier) {
      let specArr = specifier.split("=");
      hc[specArr[0]] = specArr[1];
    });

    return hc;
  }

  /**
   * Get hc from column
   */

  getHcForColumn(hcStr) {
    let specifiers = this.getAllSpecifiers(hcStr);
    let allowedSpecifiers = ["hcp", "total"];
    let data = { normal: "", opposite: "", real: "" };

    // console.log(specifiers);

    for (let key in specifiers) {
      // Object.keys(specifiers)?.forEach((specifier, key) => {
      // console.log(key + ':' + specifiers[key]);
      // $.each(specifiers, function (specifier, value) {
      if (allowedSpecifiers.includes(key)) {
        // if ($.inArray(key, allowedSpecifiers) > -1) {
        // console.log('ok:' + specifiers[key]);
        let hc = specifiers[key];
        data.normal = hc;

        if (!isNaN(specifiers[key] % 1)) {
          hc = parseFloat(hc);
          data.real = hc;

          if (key === "hcp") {
            let normalHC = hc;
            normalHC = normalHC > 0 ? "+" + normalHC : normalHC;

            let oppositeHC = -1 * hc;
            oppositeHC = oppositeHC > 0 ? "+" + oppositeHC : oppositeHC;

            if (hc === 0) {
              normalHC = oppositeHC = 0;
            }

            data.normal = normalHC;
            data.opposite = oppositeHC;
          }
        } else if (specifiers[key].indexOf(":") > -1) {
          let parts = specifiers[key].split(":");
          data.real = parseInt(parts[0]) - parseInt(parts[1]);
        }
        // console.log('hc:' + hc);
        return data;
      }
    }

    // console.log(data);
    return data;
  }

  /**
   * Calculate selection label and return
   */

  getSelectionLabel = (id, game) => {
    const { selectionPrintLabels } = this.props;
    if (selectionPrintLabels && selectionPrintLabels[id]) {
      if (selectionPrintLabels[id].indexOf("{$competitor1}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor1}", "1");
      } else if (selectionPrintLabels[id].indexOf("{$competitor2}") > -1) {
        return selectionPrintLabels[id].replace("{$competitor2}", "2");
      } else {
        return selectionPrintLabels[id];
      }
    }
    return "";
  };

  calcSelectionLabel = () => {
    const { bet, selectionPrintLabels, translate, marketViewerLabels } = this.props;
    const playerMarketsGroup =
      bet && bet?.game && bet?.game?.sport_code && marketSetConfig[bet?.game?.sport_code] && marketSetConfig[bet?.game?.sport_code]["sidebets"] && marketSetConfig[bet?.game?.sport_code]["sidebets"]["player"]
        ? marketSetConfig[bet?.game?.sport_code]["sidebets"]["player"]
        : [];

    // let bet = bet;
    let game = bet.game;

    let selectionLabels = selectionPrintLabels;
    // let translate = translate.selections;

    let selectionLabel = "";

    if (game["isOutright"] || bet?.isOutright || bet?.marketName?.toLowerCase()?.includes("goalscorer")) {
      selectionLabel = bet["sn"] ? bet["sn"] : bet["selectionName"] ? bet["selectionName"] : "";
    } else {
      selectionLabel = selectionLabels[bet.selectionId]?.replace("{$competitor1}", game.team1)?.replace("{$competitor2}", game.team2)?.trim();
    }
    let marketLabel = marketViewerLabels[bet.marketId];
    if (bet?.isPlayerProps || marketLabel?.includes("{%player}")) {
      const hc = bet.hc ? bet.hc?.split("=") : [""];
      selectionLabel = selectionLabels && selectionLabels[bet.selectionId] ? selectionLabels[bet.selectionId] : "";
      if (!this.props.hcDisabledMarkets.includes(bet.marketId)) {
        selectionLabel = selectionLabel + " " + hc[hc?.length - 1];
      }
    }
    if (bet?.isSinglePlayerProps || playerMarketsGroup.includes(bet?.marketId)) {
      const hc = bet.hc ? bet.hc?.split(":") : [""];
      const selectionPrintLabel = this.getSelectionLabel(bet?.selectionId, game);
      selectionLabel = bet["sn"] ? bet["sn"] : bet["selectionName"] ? bet["selectionName"] : "";
      if (selectionPrintLabel?.trim() === selectionLabel?.trim()) {
        selectionLabel = selectionPrintLabel;
      }
      if (!this.props.hcDisabledMarkets.includes(bet.marketId)) {
        selectionLabel = selectionPrintLabel ? selectionLabel + "\n " + selectionPrintLabel : selectionLabel + "\n " + hc[hc?.length - 1] + "+";
      }
    }

    // if (bet?.marketId === 910) {
    //     selectionLabel = bet?.selectionName;
    // }

    switch (selectionLabel) {
      case "1":
        selectionLabel = game.team1;
        break;
      case "X":
        if (bet.marketId === 1 || bet.marketId === 60 || bet.marketId === 7) {
          selectionLabel = translate.selections.draw;
        } else if (bet.marketId === 8 || bet.marketId === 62) {
          selectionLabel = translate.selections.no_goal;
        }
        break;
      case "2":
        selectionLabel = game.team2;
        break;
      case "+":
        selectionLabel = translate.selections.over;
        break;
      case "-":
        selectionLabel = translate.selections.under;
        break;
      case "U":
        selectionLabel = translate.selections.odd;
        break;
      case "G":
        selectionLabel = translate.selections.even;
        break;
      case "Y":
      case "J":
        selectionLabel = translate.selections.yes;
        break;
      case "N":
        selectionLabel = translate.selections.no;
        break;
      case "1/J":
        selectionLabel = game.team1 + " & " + translate.selections.yes;
        break;
      case "1/N":
        selectionLabel = game.team1 + " & " + translate.selections.no;
        break;
      case "X/J":
        selectionLabel = translate.selections.draw + " & " + translate.selections.yes;
        break;
      case "X/N":
        selectionLabel = translate.selections.draw + " & " + translate.selections.no;
        break;
      case "2/J":
        selectionLabel = game.team2 + " & " + translate.selections.yes;
        break;
      case "2/N":
        selectionLabel = game.team2 + "& " + translate.selections.no;
        break;
      case "J/+":
        selectionLabel = translate.selections.yes + "/" + translate.selections.over;
        break;
      case "J/-":
        selectionLabel = translate.selections.yes + "/" + translate.selections.under;
        break;
      case "N/+":
        selectionLabel = translate.selections.no + "/" + translate.selections.over;
        break;
      case "N/-":
        selectionLabel = translate.selections.no + "/" + translate.selections.under;
        break;
      case "1/-":
        selectionLabel = game.team1 + " & " + translate.selections.under;
        break;
      case "1/+":
        selectionLabel = game.team1 + " & " + translate.selections.over;
        break;
      case "X/-":
        selectionLabel = translate.selections.draw + " & " + translate.selections.under;
        break;
      case "X/+":
        selectionLabel = translate.selections.draw + " & " + translate.selections.over;
        break;
      case "2/-":
        selectionLabel = game.team2 + " & " + translate.selections.under;
        break;
      case "2/+":
        selectionLabel = game.team2 + " & " + translate.selections.over;
        break;
      case "1X/-":
        selectionLabel = "1X & " + translate.selections.under;
        break;
      case "1X/+":
        selectionLabel = "1X & " + translate.selections.over;
        break;
      case "X2/-":
        selectionLabel = "X2 & " + translate.selections.under;
        break;
      case "X2/+":
        selectionLabel = "X2 & " + translate.selections.over;
        break;
      case "12/-":
        selectionLabel = "12 & " + translate.selections.under;
        break;
      case "12/+":
        selectionLabel = "12 & " + translate.selections.over;
        break;
      case "1X/N":
        selectionLabel = "1X & " + translate.selections.no;
        break;
      case "1X/J":
        selectionLabel = "1X & " + translate.selections.yes;
        break;
      case "X2/N":
        selectionLabel = "X2 & " + translate.selections.no;
        break;
      case "X2/J":
        selectionLabel = "X2 & " + translate.selections.yes;
        break;
      case "12/N":
        selectionLabel = "12 & " + translate.selections.no;
        break;
      case "12/J":
        selectionLabel = "12 & " + translate.selections.yes;
        break;
      default:
        break;
    }

    if (selectionLabel && selectionLabel[0] && selectionLabel[0] === ".") {
      selectionLabel = selectionLabel.slice(1, selectionLabel?.length);
    }

    if (this.marketHasHc(bet.marketId)) {
      if (bet.selectionId === 1715) {
        selectionLabel += " " + this.getHcForColumn(bet.hc).opposite;
      } else {
        selectionLabel += " " + this.getHcForColumn(bet.hc).normal;
      }
    }
    if (game?.sport_code === "TR") {
      selectionLabel = selectionLabel?.replace(".", " ");
    }

    if (bet?.isPlayerProps) {
      selectionLabel = bet.marketGroupName + " - " + bet.sn;
      if (this.props.isInTicket) {
        selectionLabel = bet?.selectionName[0]?.split("-")[1];
      }
    }

    return selectionLabel;
  };

  render() {
    const { bet, locale } = this.props;
    log(this.constructor.name, "render");
    if (bet && bet?.isBetBuilder) {
      let translation = bet?.translations?.find((item) => item?.Culture?.includes(locale));
      const titles = translation ? translation?.Description : bet?.description;
      return (
        <Stack>
          {titles
            ? titles?.map((item, indx) => (
                <Stack key={indx} direction="row" alignItems="center" gap={1} mb={"-0.3em"}>
                  <CommitIcon sx={{ fontSize: "1.5em" }} className="rotated" />
                  <div className="font-xm" style={{ color: "var(--color-main-featured" }}>
                    {item}
                  </div>
                </Stack>
              ))
            : bet?.selectionName?.map((item, indx) => (
                <Stack key={indx} direction="row" alignItems="center" gap={1} mb={"-0.3em"}>
                  <CommitIcon sx={{ fontSize: "1.5em" }} className="rotated" />
                  <div className="font-xm" style={{ color: "var(--color-main-featured" }}>
                    {item}
                  </div>
                </Stack>
              ))}
        </Stack>
      );
    }
    return (
      <Box className="text-wrap">
        <Typography variant="h6" color="text.secondary">{this.calcSelectionLabel()}</Typography>
      </Box>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectionPrintLabels: state.labels.selection?.print ? state.labels.selection?.print : {},
    marketViewerLabels: state.labels.market?.viewer ? state.labels.market?.viewer : {},
    translate: state.i18n.translations[state.i18n.locale],
    bet: ownProps.bet,
    locale: state.i18n.locale,
    hcDisabledMarkets: state.settings.playerProps.hcDisabledList,
    marketSpecifiers: state.labels.market?.specifiers ? state.labels.market.specifiers : {},
  };
};

export default connect(mapStateToProps)(withStyles(styles)(BetSelectionLabel));
