import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTermsStatus } from "../../../redux-store/settings-reducer";

const ConfirmTermsAndConditions = ({ onConfirm }) => {
  const dispatch = useDispatch();
  // state selectors
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const isOpen = useSelector((state) => state.settings.shouldConfirmTerms);

  const handleDecline = () => {
    dispatch(setTermsStatus(false));
  };

  const handleConfirm = () => {
    dispatch(setTermsStatus(false));
    onConfirm();
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle id="terms-title">
        <Typography variant="h6" component="h5" color="primary" align="center">
          {translate.legal.terms_conditions_link}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {locale == "en" ? (
          <Typography variant="body1" color="warning" align="center">
            Nubet General Terms & Conditions have been updated. By clicking <strong>"Accept"</strong> you will accept the new versions of the T&C and/or other related documents. Accepting new document versions is
            mandatory in order to use the services.
          </Typography>
        ) : locale === "fi" ? (
          <Typography variant="body1" color="warning" align="center">
            Nubetin yleiset käyttöehdot on päivitetty. Klikkaamalla
            <strong>"Hyväksy"</strong> hyväksyt uudet versiot käyttöehdoista ja/tai muista niihin liittyvistä asiakirjoista. Uusien versioiden hyväksyminen on pakollista palvelujen käyttämiseksi.
          </Typography>
        ) : (
          <Typography variant="body1" color="warning" align="center">
            Nubeti üldtingimused on uuendatud. Vajutades <strong>"Nõustun"</strong>
            nõustute uute T&C ja/või muude seotud dokumentide versioonidega. Uue dokumendiversiooni aktsepteerimine on teenuste kasutamiseks kohustuslik.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="warning" onClick={handleDecline}>
          {translate.labels.button_decline}
        </Button>
        <Stack pl={2}>
          <LoadingButton onClick={handleConfirm} variant="contained" color="primary" autoFocus>
            {translate.labels.button_accept}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmTermsAndConditions;
