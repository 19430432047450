import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { i18nReducer } from "react-redux-i18n";
import { reducer as formReducer } from "redux-form";
//list of reducers
import alertReducer from "../reducers/alertReducer";
import selectReducer from "../reducers/selectReducer";
import labelsReducer from "../reducers/labelsReducer";
import favoriteCasinoGamesReducer from "../reducers/favoriteCasinoGamesReducer";
import slipAutoUpdateStatusReducer from "../reducers/slipAutoUpdateStatusReducer";

import confirmReducer from "../reducers/confirmReducer";
import wpReducer from "../reducers/wpReducer";
import updateAlertReducer from "../reducers/oddsUpdateAlertReducer";
import comboboostReducer from "../reducers/comboboostReducer";
import slipsReducer from "../reducers/slipsReducer";

// Updated reducers
import sportsReducer from "./sports-reducer";
import bannersReducer from "./banners-reducer";
import accountReducer from "./account-reducer";
import settingsReducer from "./settings-reducer";
import casinoReducer from "./casino-reducer";
import ticketsReducer from "./tickets-reducer";
import authReducer from "./auth-reducer";

const persistConfig = {
  key: "nubetRoot",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["settings", "slips", "auth", "databet"],
};

const rootReducer = combineReducers({
  i18n: i18nReducer,
  form: formReducer,
  settings: settingsReducer,
  selected: selectReducer,
  alert: alertReducer,
  labels: labelsReducer,
  tickets: ticketsReducer,
  slips: slipsReducer,
  casino: casinoReducer,
  favoriteCasinoGames: favoriteCasinoGamesReducer,
  slipAutoUpdateStatus: slipAutoUpdateStatusReducer,
  confirm: confirmReducer,
  wpContent: wpReducer,
  banner: bannersReducer,
  update: updateAlertReducer,
  comboboost: comboboostReducer,
  sports: sportsReducer,
  account: accountReducer,
  auth: authReducer,
});

export default persistReducer(persistConfig, rootReducer);
