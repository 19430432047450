import React from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import Close from "@mui/icons-material/Close";
import { Translate } from "react-redux-i18n";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 3, 3),
    borderRadius: "4px",
    maxWidth: "400px",
    position: "relative",
  },
  buttons_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    color: "var(--yellow-1)",
    textAlign: "center",
    padding: "0 0 10px 0",
  },
  headline: {
    color: "var(--color-contrast)",
    fontWeight: "800",
    fontSize: "1.2em",
    textAlign: "center",
  },
  close_btn_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    padding: "5px 5px 0 0",
  },
}));

function FreeSpinsAlert({ open, handleAlert, onConfirm }) {
  const classes = useStyles();
  const lang = useSelector((state) => state.i18n.locale);

  return (
    <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" className={classes.modal} open={open} onClose={handleAlert} closeAfterTransition>
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.close_btn_container}>
            <IconButton onClick={handleAlert}>
              <Close />
            </IconButton>
          </div>
          {lang === "en" && (
            <>
              <h2 className={classes.headline}>ATTENTION!</h2>
              <p className={classes.content}>You have bonus money and free spins, which will be removed from your account IF you confirm withdrawal.</p>
            </>
          )}
          {lang === "fi" && (
            <>
              <h2 className={classes.headline}>HUOM!</h2>
              <p className={classes.content}>Sinulla on bonusrahaa ja ilmaiskierroksia, jotka poistetaan pelitililtäsi noston yhteydessä mikäli vahvistat noston.</p>
            </>
          )}
          {lang === "et" && (
            <>
              <h2 className={classes.headline}>TÄHELEPANU!</h2>
              <p className={classes.content}>Teil on boonusraha ja tasuta keerutused, mis eemaldatakse teie kontolt, kui te kinnitate väljavõtmist.</p>
            </>
          )}
          <div className={classes.buttons_container}>
            <Button size="small" variant="outlined" color="error" onClick={handleAlert}>
              <Translate value="labels.deposit_cancel" />
            </Button>
            <Button size="small" color="primary" variant="contained" onClick={() => onConfirm("confirmed")}>
              <Translate value="labels.limits_confirm" />
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default FreeSpinsAlert;
