// MUI Typography overrides

export function typography(theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        h1: {
          "& span": {
            fontWeight: 900,
            fontSize: "var(--h1)",
          },
        },
        h2: {
          "& span": {
            fontWeight: 800,
            fontSize: "var(--h2)",
          },
        },
        h3: {
          "& span": {
            fontWeight: 700,
            fontSize: "var(--h3)",
          },
        },
        h4: {
          "& span": {
            fontWeight: 600,
            fontSize: "var(--h4)",
          },
        },
        h5: {
          "& span": {
            fontWeight: 700,
            fontSize: "var(--h5)",
          },
        },
        h6: {
          "& span": {
            fontWeight: 700,
            lineHeight: 28 / 18,
            fontSize: "var(--h6)",
          },
        },
        h7: {
          "& span": {
            fontWeight: 800,
            fontSize: "var(--h7)",
          },
        },
        subtitle2: {
          "& span": {
            fontWeight: 600,
            fontSize: "var(--normal)",
          },
        },
        body1: {
          "& span": {
            fontWeight: 400,
            fontSize: "var(--normal)",
            lineHeight: 1
          },
        },
        body2: {
          "& span": {
            fontSize: "var(--content)",
          },
        },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  };
}
