import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TrustlyActionsPage = () => {
  const navigate = useNavigate();
  // state selectors
  const auth = useSelector((state) => state.auth);
  const depositPayload = useSelector((state) => state.auth.deposit.payload);
  const regPayload = useSelector((state) => state.auth.trustlyRegPayloadCopy);

  useEffect(() => {
    if (auth.isSignedIn && depositPayload?.reference) {
      navigate(`/account/payments/deposit/status`);
    }
    if(regPayload?.reference){
      navigate(`/auth/registration/status`);
    }
  }, []);

  return (
    <Box>
      <Stack alignItems="center" gap={4} py={4} px={2}>
        <CircularProgress />
        <Typography variant="body1" color="text.secondary">
          Loading...
        </Typography>
      </Stack>
    </Box>
  );
};

export default TrustlyActionsPage;
