import { Box, CircularProgress, Divider, Stack, styled, Tab, TablePagination, Tabs, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTransactionStatementsAsync } from "../../../redux-store/account-reducer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { addDays, format, subDays } from "date-fns";
import TablePaginationActions from "../@components/TablePaginationActions";
import BettingStatementBox from "./BettingStatmentBox";
import { Translate } from "react-redux-i18n";
import Iconify from "../../iconify";

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: "14px",
}));

const TransactionStatements = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // router selectros
  const page = searchParams.get("page") || 1;
  const startDate = searchParams.get("start") || format(subDays(new Date(), 1), "yyyy-MM-dd");
  const endDate = searchParams.get("end") || format(addDays(new Date(), 1), "yyyy-MM-dd");
  const sortingOption = searchParams.get("sort-option") || "betting";

  // state selectors
  const list = useSelector((state) => state.account.statements.transactions.list);
  const isLoading = useSelector((state) => state.account.statements.transactions.isLoading);
  const currentRowsCount = useSelector((state) => state.account.statements.transactions.size);
  const allRowsCount = useSelector((state) => state.account.statements.transactions.count);
  const currentPage = useSelector((state) => state.account.statements.transactions.currentPage);
  const pageCount = useSelector((state) => state.account.statements.transactions.pageCount);

  const handleChangePage = (event, newPage) => {
    searchParams.set("page", Number(newPage));
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleTabChange = (e, newValue) => {
    searchParams.set("sort-option", newValue);
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    dispatch(getUserTransactionStatementsAsync({ start: startDate, end: endDate, page: Number(page), filter: sortingOption }));
  }, [page, startDate, endDate, sortingOption]);

  return (
    <Box minHeight="50vh" display="flex" flexDirection="column" justifyContent="space-between">
      <Box>
        <Stack pb={2}>
          <Tabs value={sortingOption} onChange={handleTabChange}>
            <StyledTab value="betting" label={<Translate value="menu.betting" />} />
            <StyledTab value="racing" label={<Translate value="menu.horseRacing_1" />} />
            <StyledTab value="betBuilder" label={<Translate value="marketSets.betbuilder" />} />
            <StyledTab value="casino" label={<Translate value="menu.casino" />} />
          </Tabs>
          <Divider />
        </Stack>
        {isLoading && (
          <Stack alignItems="center" justifyContent="center" gap={4} py={4} px={2} height="50vh">
            <CircularProgress />
            <Typography variant="body1" color="text.secondary">
              Loading...
            </Typography>
          </Stack>
        )}
        {!isLoading && !allRowsCount ? (
          <Stack alignItems="center" justifyContent="center" gap={2} py={4} px={2} height="50vh">
            <Iconify width={70} icon="lucide:list-x" color="text.secondary" />
            <Typography variant="body2" color="text.secondary">
              <Translate value="labels.statement_no_entries" />
            </Typography>
          </Stack>
        ) : null}
        {!isLoading && allRowsCount > 0 && list?.length === 0 ? (
          <Stack alignItems="center" justifyContent="center" gap={2} py={4} px={2} height="50vh">
            <Iconify width={70} icon="lucide:list-x" color="text.secondary" />
            <Typography variant="body2" color="text.secondary">
              There are no {sortingOption} transactions available in this page.
            </Typography>
          </Stack>
        ) : null}
        {!isLoading && (
          <Stack>
            {list?.map((transaction, key) => (
              <BettingStatementBox item={transaction} key={key} />
            ))}
          </Stack>
        )}
      </Box>
      {pageCount > 1 && (
        <Stack>
          <TablePagination
            rowsPerPageOptions={[10]}
            colSpan={3}
            count={allRowsCount}
            rowsPerPage={currentRowsCount}
            page={Number(currentPage) - 1}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </Stack>
      )}
    </Box>
  );
};

export default TransactionStatements;
