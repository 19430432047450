import { Card, IconButton, Stack, styled, Typography } from "@mui/material";
import OutrightCard from "../@cards/outright-card";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { getOutrightsBannerAsync } from "../../../redux-store/banners-reducer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import Iconify from "../../iconify";

const MuiCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.dbPaper,
  maxHeight: "90vh",
  "& .swiper": {
    maxHeight: "88vh",
  },
}));

const RacingVerticalBanner = () => {
  const dispatch = useDispatch();

  const outrightBanners = useSelector((state) => state.banner.outrights);

  const games = [];
  const gamesGroups = outrightBanners && Object.values(outrightBanners) ? Object.values(outrightBanners) : [];
  gamesGroups?.forEach((grItem) => {
    const grItemValues = Object.values(grItem);
    grItemValues?.forEach((item) => {
      games.push(item);
    });
  });

  const lang = useSelector((state) => state.i18n.locale);

  useEffect(() => {
    dispatch(getOutrightsBannerAsync(lang));
  }, []);

  const swiperRef = useRef(null);

  return (
    <MuiCard>
      <Stack borderBottom={"2px solid"} borderColor="background.paper" direction="row" alignItems="center" justifyContent="space-between" p={1}>
        <Typography variant="h5" component="p">
          Featured Events
        </Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <IconButton size="small" onClick={() => swiperRef.current?.slideNext()}>
            <Iconify color="text.secondary" icon="gg:chevron-up" />
          </IconButton>
          <IconButton size="small" onClick={() => swiperRef.current?.slidePrev()}>
            <Iconify color="text.secondary" icon="gg:chevron-down" />
          </IconButton>
        </Stack>
      </Stack>
      <Swiper
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        direction={"vertical"}
        slidesPerView={4}
        spaceBetween={1}
        loop={true}
        modules={[Pagination]}
      >
        {games?.map((game, indx) => (
          <SwiperSlide key={indx}>
            <OutrightCard key={indx} game={game} />
          </SwiperSlide>
        ))}
      </Swiper>
    </MuiCard>
  );
};

export default RacingVerticalBanner;
