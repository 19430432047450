import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootReducer from "./root-reducer";
import rootSaga from "./root-sagas";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import alertMiddleware from "./alert-reducer/_middleware";

const sagaMiddleware = createSagaMiddleware();
const extraMiddlewares = [thunk, alertMiddleware, sagaMiddleware];
const isLoggerManullyEnabled = localStorage.getItem("log-react-redux");

if (process.env.NODE_ENV === "development" || isLoggerManullyEnabled) {
  extraMiddlewares.push(logger);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
    return middlewares.concat(extraMiddlewares);
  },
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default { store, persistor };
