//Categories list and labels for news

export const newsConfigs = {
  en: [
    {
      id: 54,
      label: "All",
    },
    {
      id: 52,
      label: "Sports",
    },
    {
      id: 49,
      label: "Esports",
    },
    {
      id: 45,
      label: "Casino",
    },
    {
      id: 42,
      label: "Test",
    },
    {
      id: 39,
      label: "Roulette",
    },
    {
      id: 37,
      label: "Progressive Jackpots",
    },
    {
      id: 33,
      label: "Return rates",
    },
    {
      id: 30,
      label: "Livebetting",
    },
    {
      id: 27,
      label: "Live Roulette",
    },
    {
      id: 24,
      label: "Live Casino",
    },
    {
      id: 21,
      label: "Live Poker",
    },
    {
      id: 18,
      label: "Live Black Jack",
    },
    {
      id: 15,
      label: "Cards",
    },
    {
      id: 11,
      label: "Slots",
    },
    {
      id: 7,
      label: "Casino",
    },





  ],
  et: [
    {
      id: 55,
      label: "Kõik",
    },
    {
      id: 51,
      label: "Sport",
    },
    {
      id: 48,
      label: "Esport",
    },
    {
      id: 46,
      label: "Kasiino",
    },
    {
      id: 43,
      label: "Test",
    },
    {
      id: 40,
      label: "Rulett",
    },
    {
      id: 36,
      label: "Progressive Jackpots",
    },
    {
      id: 34,
      label: "Tagastusprotsent",
    },
    {
      id: 31,
      label: "Live",
    },
    {
      id: 28,
      label: "Live Ruletti",
    },
    {
      id: 25,
      label: "Live Kasiino",
    },
    {
      id: 22,
      label: "Live Poker",
    },
    {
      id: 19,
      label: "Live Black Jack",
    },
    {
      id: 16,
      label: "Kaardid",
    },
    {
      id: 13,
      label: "Mänguautomaadid",
    },
    {
      id: 8,
      label: "Kasiino",
    },


  ],
  fi: [
    {
      id: 53,
      label: "Kaikki",
    },
    {
      id: 50,
      label: "Urheilu",
    },
    {
      id: 47,
      label: "Esports",
    },
    {
      id: 44,
      label: "Kasino",
    },
    {
      id: 41,
      label: "Test",
    },
    {
      id: 38,
      label: "Ruletti",
    },
    {
      id: 35,
      label: "Progressive Jackpots",
    },
    {
      id: 32,
      label: "Palautusprosentti",
    },
    {
      id: 29,
      label: "Live-veto",
    },
    {
      id: 26,
      label: "Live Ruletti",
    },
    { 
      id: 23,
      label: "Live Kasino",
    },
    {
      id: 20,
      label: "Live Pokeri",
    },
    {
      id: 17,
      label: "Live Black Jack",
    },
    {
      id: 14,
      label: "Kortit",
    },
    {
      id: 12,
      label: "Peliautomaatit",
    },
    {
      id: 3,
      label: "Kasino",
    },



  ],
};
