import { useState } from "react";
import { Avatar, Box, Divider, Drawer, Grid2, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../iconify";
import AdultIcon18 from "../../media/img/18+.svg";
import AdultIcon21 from "../../media/img/21+.svg";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";
import LanguageSwitcher from "../LanguageSwitcher";
import ThemeSwitcher from "../ThemeSwitcher";
import { setLiveChatWidget } from "../../redux-store/settings-reducer";
import BalanceDropDown from "./BalanceDropDown";
import { handleUserLogoutAsync } from "../../redux-store/auth-reducer";

const ContentContainer = styled(Box)(() => ({
  width: 250,
}));

const StyledAvatar = styled(Avatar)(() => ({
  backgroundColor: "var(--color-contrast-5) !important",
  color: "var(--btn-text) !important",
  width: "2.8em !important",
  height: "2.8em !important",
  fontSize: "0.5em !important",
  lineHeight: "3em !important",
}));

const AccountPanel = () => {
  const [drawerStatus, setDrawerStatus] = useState(false);
  // action dispatcher
  const dispatch = useDispatch();

  // state selectors
  const locale = useSelector((state) => state.i18n.locale);
  const auth = useSelector((state) => state.auth);
  const host = useSelector((state) => state.settings.host);
  const translate = useSelector((state) => state.i18n.translations[locale]);

  const handleDrawerStatus = () => {
    setDrawerStatus(!drawerStatus);
  };

  const handleLoadSupportChat = () => {
    dispatch(setLiveChatWidget(true));
    setDrawerStatus(false);
  };

  const handleSignOut = () => {
    dispatch(handleUserLogoutAsync());
    setDrawerStatus(false);
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center">
        {auth.isSignedIn ? <BalanceDropDown /> : null}
        <IconButton edge="end" aria-label="open drawer" onClick={handleDrawerStatus} color={auth.isSignedIn ? "primary" : "default"} id="user_drawer_opener">
          {auth.isSignedIn ? <StyledAvatar>{auth.initials}</StyledAvatar> : <Iconify width={25} icon="mdi:account-circle" color="primary.main" />}
        </IconButton>
      </Stack>
      <Drawer anchor="right" open={drawerStatus} onClose={handleDrawerStatus}>
        <ContentContainer>
          <List>
            {auth.isSignedIn ? (
              <>
                <Link to={`/${locale}/account/payments`} onClick={handleDrawerStatus}>
                  <ListItem button>
                    <ListItemIcon>
                      <Iconify width={20} icon="ion:card-outline" />
                    </ListItemIcon>
                    <ListItemText>
                      <Translate value="labels.account_payment" />
                    </ListItemText>
                  </ListItem>
                </Link>
                <Divider />
                <Link to={`/${locale}/account/statements`} onClick={handleDrawerStatus}>
                  <ListItem button>
                    <ListItemIcon>
                      <Iconify width={21} icon="mdi:bank-outline" />
                    </ListItemIcon>
                    <ListItemText>
                      <Translate value="labels.statement_title" />
                    </ListItemText>
                  </ListItem>
                </Link>
                <Divider />
                <Link to={`/${locale}/account/settings`} onClick={handleDrawerStatus}>
                  <ListItem button>
                    <ListItemIcon>
                      <Iconify icon="mdi:settings" />
                    </ListItemIcon>
                    <ListItemText>
                      <Translate value="labels.setting_title" />
                    </ListItemText>
                  </ListItem>
                </Link>
              </>
            ) : (
              <>
                <Link to={`/${locale}/auth/login`} onClick={handleDrawerStatus}>
                  <ListItem button>
                    <ListItemIcon>
                      <Iconify width={18} icon="ooui:un-lock" />
                    </ListItemIcon>
                    <ListItemText>
                      <Translate value="labels.account_login" />
                    </ListItemText>
                  </ListItem>
                </Link>
                <Link to={`/${locale}/auth/registration`} onClick={handleDrawerStatus}>
                  <ListItem button>
                    <ListItemIcon>
                      <Iconify width={22} icon="mdi:register" />
                    </ListItemIcon>
                    <ListItemText>
                      <Translate value="labels.register" />
                    </ListItemText>
                  </ListItem>
                </Link>
              </>
            )}
            <Divider />
            <ListItem button onClick={handleLoadSupportChat} sx={{ cursor: "pointer" }}>
              <ListItemIcon>
                <Iconify width={22} icon="fluent:person-support-28-filled" />
              </ListItemIcon>
              <ListItemText primary={<Translate value="labels.customer_support" />} />
            </ListItem>
            <Divider />
            <ListItem>
              <LanguageSwitcher />
            </ListItem>
            <Divider />
            <ListItem>
              <ThemeSwitcher />
            </ListItem>
            <Divider />
            {auth.isSignedIn && (
              <div>
                <ListItem onClick={handleSignOut} sx={{ cursor: "pointer" }}>
                  <ListItemIcon>
                    <Iconify width={21} icon="uil:signout" />
                  </ListItemIcon>
                  <ListItemText>
                    <Translate value="labels.logout" />
                  </ListItemText>
                </ListItem>
                <Divider />
              </div>
            )}
          </List>
          <Box>
            <Grid2 container spacing={1}>
              <Grid2 item size={12}>
                <Stack alignItems="center">
                  {host.brand === "nubet_et" ? (
                    <img style={{ height: "3em" }} src={"https://api.nubet.com/media/icons/maksujatolliameti.png"} alt="licence-ee" />
                  ) : (
                    <img style={{ height: "3em" }} src={"https://api.nubet.com/media/icons/spelinspektionen.png"} alt="licence-se" />
                  )}
                </Stack>
              </Grid2>
              <Grid2 item size={6}>
                <Stack alignItems="center">
                  <Link to={`/${locale}/info/responsible-game`}>
                    <img style={{ height: "2em", margin: "0.5em 0 0 0.3em" }} src={AdultIcon18} alt="18+" />
                  </Link>
                </Stack>
              </Grid2>
              <Grid2 item size={6}>
                <Stack alignItems="center">
                  {host.brand === "nubet_et" ? (
                    <Link to={`/${locale}/info/responsible-game`}>
                      <img style={{ height: "2em", margin: "0.5em 0 0 0.3em" }} src={AdultIcon21} alt="21+" />
                    </Link>
                  ) : null}
                </Stack>
              </Grid2>
            </Grid2>
          </Box>
          <Divider />
          <Box padding="1em" width="15em" margin="auto">
            <Typography textAlign="center" fontSize="0.7rem" lineHeight={1.1}>
              {translate.labels.attention_gambling}
            </Typography>
          </Box>
          <Divider />
          <Box p={1}>
            <Typography className="font-release-version" component="p" align="center" fontSize={11}>
              Release v 4.111 PP6 - 23/01/25
            </Typography>
          </Box>
        </ContentContainer>
      </Drawer>
    </Box>
  );
};

export default AccountPanel;
