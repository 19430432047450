import { alpha, Box, Grid2, Stack, styled, Typography } from "@mui/material";
import RHFormProvider from "../../hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import RHFPasswordField from "../../hook-form/rhf-password-field";
import { LoadingButton } from "@mui/lab";
import { resetUserPasswordAsync } from "../../../redux-store/auth-reducer";
import { useSearchParams } from "react-router-dom";

const StyledContainer = styled(Box)(({ theme }) => ({
  // background: `linear-gradient(${alpha(theme.palette.background.paper, 0.5)}, rgba(0, 0, 0, 0.5)),  url(${require("../../../media/wallpaper.jpg")})`,
  // backgroundRepeat: "no-repeat",
  // backgroundSize: "cover",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const ResetPasswordPage = () => {
  // hooks
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  // state selectros
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const isLoading = useSelector((state) => state.auth.isResetPasswordLoading);
  // validation scheme
  const resetPasswordScheme = Yup.object().shape({
    password: Yup.string().min(8, translate.labels.invalid_pass).required(translate.labels.confirm_password),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], translate.labels.invalid_pass_confirm)
      .required(translate.labels.missing_confirm_pass),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(resetPasswordScheme),
    defaultValues: {
      password: null,
      confirmPassword: null,
    },
  });
  // Handle reset password
  const onSubmit = async (formValues) => {
    const oneTimeCode = searchParams.get("activationCode");
    dispatch(resetUserPasswordAsync({ ...formValues, code: oneTimeCode }));
  };

  return (
    <StyledContainer>
      <Stack margin="auto" width={{ xs: "100%", sm: "90%", md: "450px" }} px={2}>
        <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid2 container spacing={2}>
            <Grid2 item size={12}>
              <Typography variant="h2" color="primary" mb={1}>
                {translate.labels.reset_pass}
              </Typography>
              <Typography variant="body1">{translate.labels.reset_pass_text}</Typography>
            </Grid2>
            <Grid2 item size={12}>
              <RHFPasswordField name="password" placeholder={translate.labels.new_pass} sx={{ backgroundColor: 'var(--db-secondary-color)' }} />
            </Grid2>
            <Grid2 item size={12}>
              <RHFPasswordField name="confirmPassword" placeholder={translate.labels.confirm_pass} sx={{ backgroundColor: 'var(--db-secondary-color)' }} />
            </Grid2>
            <Grid2 item size={12}>
              <LoadingButton loading={isLoading} type="submit" variant="contained" color="primary">
                {translate.labels.change_pass}
              </LoadingButton>
            </Grid2>
          </Grid2>
        </RHFormProvider>
      </Stack>
    </StyledContainer>
  );
};

export default ResetPasswordPage;
