import React from "react";
import { connect } from "react-redux";
import PrecannedCard from "../@cards/precanned-card";
import { getBetbuilderBannersAsync } from "../../../redux-store/banners-reducer";
import SwiperSlider from "../../swiper";
import { SwiperSlide } from "swiper/react";
import featuredPrecannedSliderSettings from "../../swiper/settings/featured-precanned-slider";
import { PaperContainer } from "../../sports/@components/containers";

const shuffleBets = (precannedBetsList) => {
  for (let i = precannedBetsList.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [precannedBetsList[i], precannedBetsList[j]] = [precannedBetsList[j], precannedBetsList[i]];
  }

  return precannedBetsList;
};

class PrecannedBetsCarousel extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { lang } = this.props;
    this.props.getBetbuilderBannersAsync(lang);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.precannedBetsList.length < nextProps.precannedBetsList.length) {
      return true;
    }
    return false;
  }

  render() {
    const { precannedBetsList, layout } = this.props;
    if (precannedBetsList?.length === 0) return;
    return (
      <PaperContainer sx={{ p: 0, "& .swiper-slide": { height: "100%" } }}>
        <SwiperSlider {...featuredPrecannedSliderSettings} nextButton={layout === "mouse" && precannedBetsList?.length > 3} prevButton={layout === "mouse" && precannedBetsList?.length > 3}>
          {shuffleBets(precannedBetsList)?.map((bet, indx) => (
            <SwiperSlide key={indx}>
              <PrecannedCard game={bet.game} bet={bet} />
            </SwiperSlide>
          ))}
        </SwiperSlider>
      </PaperContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const precannedList = state.banner?.precanned && typeof state.banner.precanned === "object" ? state.banner.precanned : null;
  let precannedBetsList = [];
  if (precannedList) {
    Object.keys(precannedList).forEach((lgkey) => {
      const league = precannedList[lgkey];
      Object.values(league).forEach((lgGame) => {
        lgGame.bets?.forEach((bet) => {
          precannedBetsList.push({ ...bet, game: { ...lgGame, bets: [] } });
        });
      });
    });
  }

  return {
    slip: state.banner.slip,
    lang: state.i18n.locale,
    precannedBetsList: precannedBetsList,
    layout: state.settings.layout,
  };
};

export default connect(mapStateToProps, { getBetbuilderBannersAsync })(PrecannedBetsCarousel);
