import { Box, Typography } from "@mui/material";
import { PaperContainer } from "./containers";
import Iconify from "../../iconify";

const EmptyScreen = ({ message, displayBackground }) => {
  return (
    <PaperContainer>
      <Box
        sx={{
          width: "94%",
          height: "50vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          margin: "0 1em",
          padding: "1em",
          borderRadius: "0.5em",
          ...(displayBackground && {
            backgroundImage: "url('https://storage.googleapis.com/nubet-prod-content-news/bg_norace_2.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }),
          "@media (hover:hover)": {
            width: "98.5%",
          },
        }}
      >
        <Iconify width={60} color="text.secondary" icon="material-symbols:data-alert-rounded" />
        <Typography mt={1} color="text.secondary" mb={3} variant="norace" sx={{ textAlign: "center" }}>
          {message}
        </Typography>
      </Box>
    </PaperContainer>
  );
};

export default EmptyScreen;
