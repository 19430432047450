// Casino Game Card
// @mui ui components
import { alpha, Box, Button, Stack, styled, Typography } from "@mui/material";
// Image lazy loader
import Image from "../../image";
// router hooks
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// redux tools
import { useSelector } from "react-redux";
import LikeButton from "./LikeButton";
import { Translate } from "react-redux-i18n";

// styled components
const StyledOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: alpha(theme.palette.background.default, 0.8),
  cursor: "pointer",
  borderRadius: "10px",
  visibility: "hidden",
  zIndex: 0,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const StyledContentContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  "&:hover": {
    "& .overlay": {
      visibility: "visible",
    },
  },
}));

const GameCard = ({ game }) => {
  // router hooks
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  //select from state
  const lang = useSelector((state) => state.i18n.locale);
  const auth = useSelector((state) => state.auth);
  const layout = useSelector((state) => state.settings.layout);
  // generate url for game image
  let casinoGameImgLink = `https://storage.googleapis.com/nubet-casino-gfx/${game.name}.jpg`;
  //  load 404 placeholder
  const handleImage404Error = (e) => {
    e.target.src = `https://storage.googleapis.com/nubet-casino-gfx/coming_soon.jpg`;
  };

  //handle opening game play view
  const handleOpen = () => {
    if (auth.isSignedIn) {
      searchParams.set("dialog", "play-game");
      searchParams.set("game-id", game.gameId);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    } else {
      navigate(`/${lang}/auth/login?redirect_to=casino/dashboard?dialog=play-game&game-id=${game.gameId}`);
    }
  };

  return (
    <Box>
      <StyledContentContainer>
        <Image onError={handleImage404Error} src={casinoGameImgLink} ratio="6/4" onClick={handleOpen} />
        <StyledOverlay className="overlay">
          <Stack justifyContent="space-between" height="100%">
            <Stack alignItems="end" p={1}>
              <LikeButton gameId={game.gameId} />
            </Stack>
            <Stack alignItems="center">
              <Button onClick={handleOpen} variant="contained" color="primary">
                <Translate value="labels.slots_play" />
              </Button>
            </Stack>
            <Stack />
          </Stack>
        </StyledOverlay>
      </StyledContentContainer>
      <Stack pt={1} direction="row" alignItems="center" justifyContent="space-between">
        <Stack gap={0.5}>
          <Typography variant={layout === "mouse" ? "h4" : "h4"} align="left" color="text.primary">
            {game.translation}
          </Typography>
          <Typography variant={layout === "mouse" ? "body1" : "body2"} align="left" color="primary">
            {game?.provider_name}
          </Typography>
        </Stack>
        <Stack display={{ xs: "flex", md: "none" }}>
          <LikeButton gameId={game.gameId} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default GameCard;
