import { Box, Grid2, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import RHFormProvider from "../../hook-form/form-provider";
import { useDispatch, useSelector } from "react-redux";
import RHFPasswordField from "../../hook-form/rhf-password-field";
import { LoadingButton } from "@mui/lab";
import { updatePasswordAsync } from "../../../redux-store/account-reducer";

const ChangePasswordInterface = () => {
  const dispatch = useDispatch();
  // state selectros
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const isLoading = useSelector((state) => state.account.password?.isUpdateLoading);
  // scheme
  const changePassScheme = Yup.object().shape({
    password_current: Yup.string().min(8, translate.labels.invalid_pass).required(translate.labels.missing_confirm_pass),
    password_new: Yup.string().min(8, translate.labels.invalid_pass).required(translate.labels.missing_confirm_pass),
    password_new_confirm: Yup.string()
      .oneOf([Yup.ref("password_new"), null], translate.labels.invalid_pass_confirm)
      .required(translate.labels.missing_confirm_pass),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(changePassScheme),
    defaultValues: {
      password_current: "",
      password_new: "",
      password_new_confirm: "",
    },
  });

  const onSubmit = (formValues) => {
    dispatch(updatePasswordAsync({ params: formValues, callback: methods.reset }));
  };

  return (
    <Box py={3} px={{ xs: 0, md: 2 }}>
      <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack>
          <Grid2 container spacing={1}>
            <Grid2 item size={{ xs: 12, md: 6 }}>
              <RHFPasswordField name="password_current" placeholder={translate.labels.current_pass} />
            </Grid2>
            <Grid2 item size={{ xs: 12, md: 6 }}>
              <RHFPasswordField name="password_new" placeholder={translate.labels.new_pass} />
            </Grid2>
            <Grid2 item size={{ xs: 12, md: 6 }}>
              <RHFPasswordField name="password_new_confirm" placeholder={translate.labels.confirm_pass} />
            </Grid2>
          </Grid2>
          <Stack mt={4} alignItems="center">
            <LoadingButton loading={isLoading} type="submit" variant="contained" color="primary">
              {translate.labels.change_pass}
            </LoadingButton>
          </Stack>
        </Stack>
      </RHFormProvider>
    </Box>
  );
};

export default ChangePasswordInterface;
