import { takeLatest, put, call } from "redux-saga/effects";
import { generateDatabetToken, loginUser, refreshSession, registerUser, userAutoLogin, userLogout } from "./_saga_requests";
import {
  handleUserLoginAsync,
  setLoginSuccess,
  setLoginFail,
  handleAutoLoginAsync,
  setAutoLoginSuccess,
  setAutoLoginError,
  handleUserLogoutAsync,
  setLogoutError,
  setLogoutSuccess,
  handleSessionRefreshAsync,
  setSessionRefreshSuccess,
  setSessionRefreshError,
  handleRegisterUserAsync,
  setRegisterError,
  setRegisterSuccess,
  setDatabetError,
  generateDatabetTokenAsync,
  setDatabetSuccess,
} from "./index";
import { setTermsStatus, toggleMyTicketsPanel } from "../settings-reducer";
import { persistor } from "../store";
import { setAlert } from "../../actions";

// register user saga
function* handleRegister(action) {
  try {
    const lang = localStorage.getItem("lang");
    const regPayload = yield call(registerUser, action.payload);
    yield put(
      setRegisterSuccess({
        shouldAlert: true,
        data: regPayload?.payload,
        response: regPayload,
      })
    );
    if (regPayload?.payload?.status === "SUCCESS") {
      const user = yield call(loginUser, { email: action.payload.email, userPass: action.payload.password });
      yield put(setLoginSuccess(user?.payload));
      yield call([persistor, persistor.flush]);
      yield call(generateDatabetToken, lang);
    }
  } catch (error) {
    yield put(setRegisterError(error));
  }
}

// Saga to handle user login
function* handleLogin(action) {
  try {
    const { params } = action.payload;
    const lang = localStorage.getItem("lang");
    const user = yield call(loginUser, params);
    if (user?.payload?.jwt) {
      yield put(setLoginSuccess(user.payload));
      yield call([persistor, persistor.flush]);
      yield call(generateDatabetToken, lang);
      yield call(() => window.location.reload());
    } else {
      if (user?.payload === "TermsNotAcceptedException") {
        yield put(setTermsStatus(true));
      } else {
        yield put(
          setAlert({
            type: user.type,
            payload: user.payload,
            collection: user.collection,
          })
        );
      }
      yield put(setLoginFail(user));
    }
  } catch (error) {
    yield put(setLoginFail(error));
  }
}

function* handleAutoLogin(action) {
  try {
    const { code, lang } = action.payload;
    const user = yield call(userAutoLogin, code);
    yield put(setAutoLoginSuccess(user));
    yield call([persistor, persistor.flush]);
    if (user?.payload?.jwt) {
      yield call(generateDatabetToken, lang);
      yield call(() => window.location.reload());
    }
  } catch (error) {
    yield put(setAutoLoginError(error));
  }
}

function* handleUserLogut(action) {
  try {
    const lang = localStorage.getItem("lang");
    yield call(userLogout, null);
    yield put(setLogoutSuccess());
    yield call(generateDatabetToken, lang);
    yield put(toggleMyTicketsPanel(false));
    yield call([persistor, persistor.flush]);
    yield call(() => window.location.reload());
  } catch (error) {
    yield put(setLogoutError(error));
  }
}

function* handleRefreshSession(action) {
  try {
    const lang = localStorage.getItem("lang");
    const session = yield call(refreshSession, null);
    if (session?.payload?.jwt) {
      yield put(setSessionRefreshSuccess(session.payload));
      yield call([persistor, persistor.flush]);
      yield call(generateDatabetToken, lang);
      yield put(
        setAlert({
          type: session.type,
          payload: "reality_successful",
          collection: session.collection,
        })
      );
      yield call(() => window.location.reload());
    } else {
      yield put(
        setAlert({
          type: session?.type,
          payload: session?.payload,
          collection: session?.collection,
        })
      );
    }
  } catch (error) {
    yield put(setSessionRefreshError(error));
  }
}

function* handleGenerateDatabetToken(action) {
  try {
    const lang = action.payload;
    const res = yield call(generateDatabetToken, lang);
    yield put(setDatabetSuccess(res));
    yield call([persistor, persistor.flush]);
    yield call(() => window.location.reload());
  } catch (error) {
    yield put(setDatabetError(error));
  }
}

// Watcher saga
function* authSaga() {
  yield takeLatest(handleUserLoginAsync.type, handleLogin);
  yield takeLatest(handleAutoLoginAsync.type, handleAutoLogin);
  yield takeLatest(handleUserLogoutAsync.type, handleUserLogut);
  yield takeLatest(handleSessionRefreshAsync.type, handleRefreshSession);
  yield takeLatest(handleRegisterUserAsync.type, handleRegister);
  yield takeLatest(generateDatabetTokenAsync.type, handleGenerateDatabetToken);
}

export default authSaga;
