import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Dialog, Grid2, Stack, styled, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import RHFormProvider from "../../hook-form/form-provider";
import RHFTextField from "../../hook-form/rhf-text-field";
import { useDispatch, useSelector } from "react-redux";
import RHFPasswordField from "../../hook-form/rhf-password-field";
import RHFPhoneField from "../../hook-form/rhf-phone-input";
import { RHFCheckbox } from "../../hook-form/rhf-checkbox";
import { LoadingButton } from "@mui/lab";
import { handleRegisterUserAsync } from "../../../redux-store/auth-reducer";

const StyledContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.results,
  padding: theme.spacing(2),
}));

const UserDetailsSaver = () => {
  const dispatch = useDispatch();
  // state selectors
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const shouldAddDetails = useSelector((state) => state.auth.shouldAddDetails);
  const host = useSelector((state) => state.settings.host);
  const theme = useSelector((state) => state.settings.theme);
  const isRegLoading = useSelector((state) => state.auth.isRegLoading);
  const regStatusPaylaod = useSelector((state) => state.auth.trustlyDepositStatus);
  // validation scheme
  const userDetailsScheme = Yup.object().shape({
    email: Yup.string().required("Email is required").email(translate.labels.invalid_email),
    phoneNumber: Yup.string().required(translate.labels.invalid_phone),
    password: Yup.string().min(8, translate.labels.invalid_pass).required(translate.labels.confirm_password),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], translate.labels.invalid_pass_confirm)
      .required(translate.labels.missing_confirm_pass),
    sub_email: Yup.bool(),
    sub_sms: Yup.bool(),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(userDetailsScheme),
    defaultValues: {
      email: "",
      password: "",
      phoneNumber: "",
      sub_email: true,
      sub_sms: true,
    },
  });
  // Handle save
  const onSubmit = async (formValues) => {
    const lgcode1 = localStorage.getItem("trustly-login-code");
    const data = { ...formValues };
    data.phoneNumber = formValues.phoneNumber?.replace(/\s/g, "").slice(1, formValues["phoneNumber"]?.length);
    data.token = regStatusPaylaod?.loginCode;
    if (!regStatusPaylaod?.loginCode) {
      data.bankLogin = true;
      data.token = lgcode1;
    }
    dispatch(handleRegisterUserAsync(data));
  };

  // get form values;
  const smsEnabled = useWatch({ control: methods.control, name: "sub_sms" });
  const emailEnabled = useWatch({ control: methods.control, name: "sub_email" });
  return (
    <Dialog open={shouldAddDetails} aria-labelledby="user-details" aria-describedby="user-details-saver">
      <StyledContainer>
        <Stack mb={2}>
          <Box height="4em" component="img" alt="nubet logo" src={`https://api.nubet.com/media/logos/${host.logo}_${theme}.svg`} />
          <Typography variant="body2" align="center">
            {translate.labels.register_infotext}
          </Typography>
        </Stack>
        <RHFormProvider autoComplete="off" methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid2 container spacing={2}>
            <Grid2 item size={12}>
              <RHFTextField autoComplete="off" type="email" name="email" placeholder={translate.labels.email} />
            </Grid2>
            <Grid2 item size={12}>
              <RHFPasswordField autoComplete="new-password" name="password" placeholder={translate.labels.password} />
            </Grid2>
            <Grid2 item size={12}>
              <RHFPasswordField autoComplete="new-password" name="passwordConfirm" placeholder={translate.labels.confirm_password} />
            </Grid2>
            <Grid2 item size={12}>
              <RHFPhoneField name="phoneNumber" placeholder={translate.labels.mobile} />
            </Grid2>
            <Grid2 item size={12}>
              <RHFCheckbox label={translate.labels.email_agreement} name="sub_email" placeholder={translate.labels.mobile} />
            </Grid2>
            <Grid2 item size={12}>
              <RHFCheckbox label={translate.labels.sms_agreement} name="sub_sms" placeholder={translate.labels.mobile} />
            </Grid2>
            {!smsEnabled && !emailEnabled && (
              <Grid2 item size={12}>
                <Stack alignItems="center">
                  <Typography variant="h3" color="warning">
                    {translate.labels.attention}
                  </Typography>
                  <Typography variant="body1" align="center" color="text.secondary">
                    {translate.labels.notification_warning}
                  </Typography>
                </Stack>
              </Grid2>
            )}
          </Grid2>
          <Stack mt={2.5}>
            <LoadingButton loading={isRegLoading} type="submit" variant="contained" color="primary" fullWidth>
              {translate.labels.register}
            </LoadingButton>
          </Stack>
        </RHFormProvider>
      </StyledContainer>
    </Dialog>
  );
};

export default UserDetailsSaver;
