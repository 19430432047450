import { createAsyncThunk, createSlice, original } from "@reduxjs/toolkit";
import { getBetbuilderBet, getBetbuilderGames, getBetbuilderLeagues, getPlayerProps, getPlayerPropsCatalog, getPlayerPropsGames, getRacingGames, getRacingLeagues, getRacingResults, getSingleGame } from "./_requests";
import { calcGame, updateOutrightOdd, updateScore } from "../../helpers/gameFunctions";
import _ from "lodash";
import { format } from "date-fns";

const initialState = {
  racing: {
    isRacingLoading: false,
    list: [],
  },
  betbuilder: {
    isLoading: false,
    leagues: {},
    games: [],
    singleGame: {},
    sportCodes: [],
  },
  playerProps: {
    isLoading: false,
    isCatalogLoading: false,
    sportCodes: [],
    leagues: {},
    games: [],
  },
};

// LEAGUS REQUESTS
export const getRacingLeaguesAsync = createAsyncThunk("sports/getRacingLeaguesAsync", getRacingLeagues);
export const getBetbuilderLeaguesAsync = createAsyncThunk("sports/getBetbuilderLeaguesAsync", getBetbuilderLeagues);
export const getRacingResultsAsync = createAsyncThunk("sports/getRacingResultsAsync", getRacingResults);
export const getPlayerPropsAsync = createAsyncThunk("sports/getPlayerPropsAsync", getPlayerProps);
export const getBetbuilderBetAsync = createAsyncThunk("sports/getBetbuilderBetAsync", getBetbuilderBet);

// GAMES REQUESTS
export const getRacingGamesAsync = createAsyncThunk("sports/getRacingGamesAsync", getRacingGames);
export const getBetbuilderGamesAsync = createAsyncThunk("sports/getBetbuilderGamesAsync", getBetbuilderGames);
export const getPlayerPropsGamesAsync = createAsyncThunk("sports/getPlayerPropsGamesAsync", getPlayerPropsGames);
export const getPlayerPropsCatalogAsync = createAsyncThunk("sports/getPlayerPropsCatalogAsync", getPlayerPropsCatalog);
export const getSingleGameAsync = createAsyncThunk("sports/getSingleGameAsync", getSingleGame);

export const sportsSlice = createSlice({
  name: "slice/sports",
  initialState,
  reducers: {
    updateBetbuilderGames: (state, { payload }) => {},
    updateRacingOdds: (state, { payload }) => {
      let newOutrightOdds = [...payload];
      if (state.racing.list?.length && newOutrightOdds?.length) {
        state.racing.list.forEach((league) => {
          if (league?.games) {
            league.games.forEach((game) => {
              game.odds.forEach((odd) => {
                updateOutrightOdd(game, game.gameid, odd, newOutrightOdds);
              });
            });
          }
        });
      }
    },
    updateScoresInLeague: (state, { payload }) => {
      let newScores = payload;

      if (newScores?.length && state.racing.list?.length) {
        state.racing.list = state.racing.list.map((league) => {
          if (league?.games) {
            let leagueGames = league.games.map((game) => {
              return updateScore(game, newScores);
            });
            return {
              ...league,
              games: leagueGames,
            };
          }
          return league;
        });
      }
    },
    removeEndedGames: (state, { payload }) => {},
    updateBetbuilderGames: (state, { payload }) => {},
  },
  extraReducers: (builder) => {
    builder
      // LOAD RACING LEAGUES
      .addCase(getRacingLeaguesAsync.pending, (state, { payload }) => {
        state.racing.isRacingLoading = true;
      })
      .addCase(getRacingLeaguesAsync.rejected, (state, { payload }) => {
        state.racing.isRacingLoading = false;
      })
      .addCase(getRacingLeaguesAsync.fulfilled, (state, { payload }) => {
        state.racing.isRacingLoading = false;
        const racingLeagues = payload?.TR || [];
        state.racing.list = racingLeagues;
      })
      // GET RACING GAMES
      .addCase(getRacingGamesAsync.fulfilled, (state, { payload }) => {
        const games = payload?.games || [];
        const gamesList = games?.map((game) => {
          const calculatedGame = calcGame(game);
          return calculatedGame;
        });
        const racingLeagues = state.racing.list.map((racingLeague) => {
          if (racingLeague.leagueId === payload.leagueId) {
            return { ...racingLeague, games: gamesList };
          }
          return racingLeague;
        });
        state.racing.list = racingLeagues;
      })
      // GET RACING RESULTS
      .addCase(getRacingResultsAsync.fulfilled, (state, { payload }) => {
        const racingLeagues = state.racing.list.map((racingLeague) => {
          if (racingLeague.leagueId === payload.leagueId) {
            return { ...racingLeague, results: payload.results };
          }
          return racingLeague;
        });
        state.racing.list = racingLeagues;
      })
      // LOAD BETBUILDER LEAGUES
      .addCase(getBetbuilderLeaguesAsync.pending, (state, { payload }) => {
        state.betbuilder.isLoading = true;
      })
      .addCase(getBetbuilderLeaguesAsync.rejected, (state, { payload }) => {
        state.betbuilder.isLoading = false;
      })
      .addCase(getBetbuilderLeaguesAsync.fulfilled, (state, { payload }) => {
        state.betbuilder.isLoading = false;
        const bbLeagues = payload?.betbuilder || {};
        state.betbuilder.leagues = bbLeagues;
        state.betbuilder.sportCodes = Object.keys(bbLeagues);
      })
      // GET BETBUILDER GAMES
      .addCase(getBetbuilderGamesAsync.pending, (state) => {
        state.betbuilder.games = [];
      })
      .addCase(getBetbuilderGamesAsync.rejected, (state) => {
        state.betbuilder.games = [];
      })
      .addCase(getBetbuilderGamesAsync.fulfilled, (state, { payload }) => {
        const gamesList = payload.games.map((game) => {
          const calculatedGame = calcGame(game);
          return calculatedGame;
        });
        if (gamesList.length) {
          state.betbuilder.games = gamesList;
        } else {
          state.betbuilder.games = [];
        }
      })
      // GET PLAYER PROPS LEAGUES
      .addCase(getPlayerPropsAsync.pending, (state) => {
        state.playerProps.isLoading = true;
      })
      .addCase(getPlayerPropsAsync.rejected, (state) => {
        state.playerProps.isLoading = false;
      })
      .addCase(getPlayerPropsAsync.fulfilled, (state, { payload }) => {
        state.playerProps.isLoading = false;
        const leagues = payload && Object.keys(payload)?.length ? payload : {};
        state.playerProps.leagues = leagues;
        state.playerProps.sportCodes = Object.keys(leagues);
      })
      // GET PLAYER PROPS GAMES
      .addCase(getPlayerPropsGamesAsync.fulfilled, (state, { payload }) => {
        let games = payload?.games || [];
        games = games.map((game) => {
          return calcGame(game);
        });
        let leagues = state.playerProps.leagues[payload.sportCode] || [];
        leagues = leagues.map((league) => {
          if (league.leagueId === payload.leagueId) {
            return { ...league, games: games };
          }
          return league;
        });
        state.playerProps.leagues[payload.sportCode] = leagues;
      })
      // GET SINGLE GAMES
      .addCase(getSingleGameAsync.fulfilled, (state, { payload }) => {
        const calculatedGame = calcGame(payload.data);
        localStorage.setItem("betbuilder-game", JSON.stringify(calculatedGame));
        state[payload.type].singleGame = calculatedGame;
      })
      // getPlayerPropsCatalogAsync
      .addCase(getPlayerPropsCatalogAsync.pending, (state) => {
        state.playerProps.isCatalogLoading = true;
      })
      .addCase(getPlayerPropsCatalogAsync.rejected, (state) => {
        state.playerProps.isCatalogLoading = false;
      })
      .addCase(getPlayerPropsCatalogAsync.fulfilled, (state, { payload }) => {
        const games = payload && typeof payload?.data === "object" ? payload.data : [];
        const updatedGames =  games.map((game) => {
          const updated = {
            ...game,
            team1: game.game_title.split(" v ")[0],
            team2: game.game_title.split(" v ")[1],
            startTime: game.game_time,
            gameid: game.game_id,
            leagueID: payload?.leagueID,
            sportCode: payload?.sportCode,
          };
          const clcGame = calcGame(updated);
          return clcGame;
        });
        state.playerProps.games = updatedGames;
        state.playerProps.isCatalogLoading = false;
      });
  },
});

export const { updateRacingOdds, updateScoresInLeague, updateBetbuilderGames } = sportsSlice.actions;

export default sportsSlice.reducer;
