import authHeader from "../../apis/auth-header";
import api from "../../apis/nubet";
import { v4 as uuid } from "uuid";

// handle register
export const registerUser = async (data) => {
  try {
    const sessionId = localStorage.getItem("js_session_id");
    const affiliate_id = localStorage.getItem("affiliate_id");
    const affiliate_token = localStorage.getItem("affiliate_token");
    let authHeaders = {};
    if (affiliate_id && affiliate_token) {
      authHeaders["affiliate_token"] = affiliate_token;
      authHeaders["affiliate_id"] = affiliate_id;
    }
    const reply = await api.post(
      "/auth/signin",
      {
        params: {
          bankLogin: data.bankLogin,
          token: data.token,
          userPass: data.password,
          email: data.email,
          phoneNumber: data.phoneNumber,
          ...authHeaders,
        },
        limits: [],
        notifications: {
          sub_email: data.sub_email,
          sub_sms: data.sub_sms,
        },
      },
      {
        headers: {
          Authorization: sessionId,
        },
      }
    );
    localStorage.removeItem("js_session_id");
    return reply.data;
  } catch (error) {
    throw new Error(error.response);
  }
};

// login
export const loginUser = async (params) => {
  try {
    const reply = await api.post("auth/signin", {
      params: params,
    });
    if (reply.data?.payload?.jwt) {
      localStorage.setItem("jwt", reply.data?.payload?.jwt);
      localStorage.setItem("betworx_logged_in_user", JSON.stringify(reply.data?.payload));
    }
    return reply.data;
  } catch (error) {
    throw new Error(error.response);
  }
};

// auto login

export const userAutoLogin = async (code) => {
  try {
    const reply = await api.post("auth/autoLogin", {
      params: { token: code, policyChecked: true, privacyPolicyChecked: true },
    });
    if (reply?.data?.payload?.jwt) {
      localStorage.setItem("jwt", reply?.data?.payload?.jwt);
      localStorage.setItem("betworx_logged_in_user", JSON.stringify(reply.data.payload));
    }
    return reply.data;
  } catch (error) {
    throw new Error(error.response);
  }
};

// handle logout

export const userLogout = async () => {
  try {
    const reply = await api.post("auth/signout", {}, { headers: authHeader() });
    localStorage.removeItem("jwt");
    localStorage.removeItem("betworx_logged_in_user");
    return reply.data;
  } catch (error) {
    throw new Error(error.response);
  }
};

// handle refresh session

export const refreshSession = async () => {
  try {
    const jwt = localStorage.getItem("jwt");
    let jsSession = "";
    if (jwt) {
      jsSession = jwt.split("|")[0];
    }
    const reply = await api.get("/auth/refresh-session", {
      headers: {
        Authorization: jwt,
      },
    });

    if (reply?.data?.type === "success" && reply?.data?.payload?.success) {
      localStorage.setItem("jwt", `${jsSession}|${reply.data.payload.jwt}`);
    }
    return reply?.data;
  } catch (error) {
    throw new Error(error.response);
  }
};

// generate databet token
export const generateDatabetToken = async (lang) => {
  try {
    let unique_id = uuid();
    const saved_id = localStorage.getItem("databet_unique_id");
    if (saved_id) {
      unique_id = saved_id;
    } else {
      localStorage.setItem("databet_unique_id", unique_id);
    }
    const token_value = localStorage.getItem("jwt");
    const user = localStorage.getItem("betworx_logged_in_user") ? JSON.parse(localStorage.getItem("betworx_logged_in_user")) : null;
    const player_id = user?.user_id ? String(user.user_id) : null;
    const reply = await api.post(
      "/token/create",
      {
        locale: lang,
        currency: "EUR",
        player_id: player_id,
        params: {
          some_token: token_value || unique_id,
          internal_player_id: player_id,
          session_id: token_value || unique_id,
        },
        context: {
          bet: {
            min_stake_sum: "1.00",
          },
        },
      },
      {
        headers: authHeader(),
      }
    );
    localStorage.setItem("databet_token", reply.data?.token);
    return reply.data;
  } catch (error) {
    throw new Error(error.response);
  }
};
