/**
 * Promotions Page - Fin version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  root: {
    padding: "1.8em",
    fontSize: "var(--content)",
    "@media (hover:none)": {},
  },
  listItems: {
    color: "var(--color-contrast)",
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  promo: {
    height: "auto",
    width: "40%",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  promoGrid: {
    "@media (hover:none)": {
      padding: "1em 0 0 1em",
    },
  },
});

class PromotionsBetbuilder extends React.PureComponent {
  goTop = () => {};

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale, auth, siteTheme } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Grid className={classes.promoGrid} container spacing={2} style={{ display: "flex", alignItems: "stretch" }}>
          <Grid item xs={12} sm={12}>
            <Paper style={{ margin: "0.5em" }}>
              <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/nbt_banner_betbuilder_fi_${siteTheme}.gif`} alt="banner-betbuilder-promo" />
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Testaa Vetosekoitinta ilman riskiä!</h1>
                  <br></br>
                  Kokeile riskittömästi miten Vetosekoitin vie vedonlyönnin uudelle tasolle! <br></br>Jos veto ei osu, niin hyvitämme sen saman tien tilillesi ilman kyselyitä tai pikkupränttiä. <br></br>Tarjous on
                  voimassa ensimmäiselle Vetosekoitin -vedollesi.<br></br>
                  <br></br>
                  <br></br>
                  <h3>Näin kokeilet Vetosekotinta ilman riskiä</h3>
                  <span className={classes.listItems}>1. </span>Avaa Vetosekoitin joko ottelun sisältä tai sivun päämenun linkistä.<br></br>
                  <br></br>
                  <span className={classes.listItems}>2. </span>Laadi haluamasi Vetosekoitin -veto ja lyö se tasan 5€ panoksella.<br></br>
                  <br></br>
                  <span className={classes.listItems}>3. </span>Jos vetosi menee väärin hyvitämme pelitilillesi 5 euroa (jos vetosi voittaa, pidät luonnollisesti voittosi normaalisti). <br></br>Hyvitys maksetaan vedon
                  ratkeamista seuraavana päivänä kello 12 mennessä.<br></br>
                  <br></br>
                  <div>
                    <Link to={`/${locale}?dialogue=betBuilder&selectedBetBuilderSportCode=FB`}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          margin: "2em 0",
                          color: "var(--btn-text)",
                          padding: "1em 1em",
                          fontSize: "1em",
                          background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                        }}
                      >
                        KOKEILE VETOSEKOITINTA Ilman riskiä
                      </Button>
                    </Link>
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    locale: state.i18n.locale,
    auth: state.auth,
    siteTheme: state.settings.theme,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PromotionsBetbuilder));
