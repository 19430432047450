import authHeader from "../../apis/auth-header";
import api from "../../apis/nubet";
import apiCatalog from "../../apis/nubetCatalog";
import apiDev from "../../apis/nubet-dev";

// get racing leagues
export const getRacingLeagues = async (lang) => {
  try {
    const res = await api.get("sports/leagues/racing", { params: { lang } });
    return res.data?.payload;
  } catch (error) {
    console.log(error);
  }
};

// GET RACING GAMES

export const getRacingGames = async (data) => {
  try {
    const res = await apiCatalog.get(`/games/${data.leagueId}/${data.lang}`);
    const payload =
      res.data?.payload?.outrightGames && typeof res.data?.payload?.outrightGames === "object"
        ? { leagueId: data.leagueId, games: Object.values(res.data?.payload?.outrightGames) }
        : { leagueId: data.leagueId, games: [] };
    return payload;
  } catch (error) {
    console.log(error);
  }
};

// GET RACING RESULTS

export const getRacingResults = async (leagueId) => {
  try {
    const res = await api.get("sports/results", { params: { id: leagueId } });
    return { leagueId: leagueId, results: res.data?.payload?.gameDays };
  } catch (error) {
    console.log(error);
  }
};

// get betbuilder leagues
export const getBetbuilderLeagues = async (lang) => {
  try {
    const res = await api.get("sports/leagues", { params: { lang } });
    return res.data?.payload;
  } catch (error) {
    console.log(error);
  }
};

// get betbuilder GAMES
export const getBetbuilderGames = async ({ lang, leagueId }) => {
  try {
    const res = await apiCatalog.get(`/games/${leagueId}/${lang}`);
    const payload = res.data?.payload?.games ? { leagueId: leagueId, games: Object.values(res.data?.payload?.games) } : { leagueId: leagueId, games: [] };
    return payload;
  } catch (error) {
    console.log(error);
  }
};

// GET BET BUILDER BETS

export const getBetbuilderBet = async ({ betslipUid, callback }) => {
  try {
    const reply = await api.post("/account/betbuilder", {
      params: {
        betSlipId: betslipUid,
      },
      headers: authHeader(),
    });
    callback(reply?.data);
  } catch (error) {
    console.log(error);
  }
};

// GET PLAYER PROPS

export const getPlayerProps = async (lang) => {
  try {
    const res = await api.get("sports/leagues/playerProps", { params: { lang } });
    return res.data?.payload;
  } catch (error) {
    console.log(error);
  }
};

// GET PLAYER PROPS GAMES

export const getPlayerPropsGames = async ({ sportCode, leagueId, lang, marketSet, goalScorers }) => {
  try {
    const res = await apiCatalog.get(`/games/${leagueId}/${lang}`);
    let games = res.data?.payload?.games ? Object.values(res.data?.payload?.games) : [];
    const availableGames = [];
    games.forEach((game) => {
      const playerOdds = game.odds.filter((odd) => marketSet.includes(odd.m));
      const goalscorerOdds = game.odds.filter((odd) => goalScorers.includes(odd.m));
      if (playerOdds && playerOdds.length) {
        availableGames.push({ ...game, hasGoalscorers: goalscorerOdds.length > 0, odds: playerOdds });
      }
    });
    const payload = { leagueId: leagueId, games: availableGames, sportCode };
    return payload;
  } catch (error) {
    console.log(error);
  }
};

// get player props games catalog

export const getPlayerPropsCatalog = async (params) => {
  const reply = await api.get("/sports/playerProps", {
    params,
  });
  return {
    data: reply.data?.payload,
    ...params,
  };
};

// GET SINGLE GAME

export const getSingleGame = async ({ lang, gameID, leagueID, type }) => {
  try {
    const res = await api.get("sports/game", { params: { lang, gameID, leagueID } });
    return { type, data: res.data?.payload };
  } catch (error) {
    return { type, data: {} };
  }
};
