//BLUELIGHT theme palette
import { alpha } from "@mui/material/styles";

const NEUTRAL = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

const PRIMARY = {
  light: "#6da4ff",
  main: "#338bdd",
  dark: "#10609d",
  contrastText: "#ffffff",
  featured2: "#000000",
  menuTopActive: "#000000",
  team: "#000000",
  loginBtn: "#cfcfcf",
};

const SECONDARY = {
  light: "#6da4ff",
  main: "#338bdd",
  dark: "#10609d",
  contrastText: "#ffffff",
};

const SUCCESS = {
  main: "#14B8A6",
  light: "#43C6B7",
  dark: "#0E8074",
  contrastText: "#ffffff",
};

const ERROR = {
  light: "#f25682",
  main: "#bb1956",
  dark: "#85002e",
  contrastText: "#ffffff",
};

const INFO = {
  light: "#63cea4",
  main: "#2b9c75",
  dark: "#006d49",
  contrastText: "#ffffff",
};

const WARNING = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FFAB00",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: "#ffffff",
};

const BACKGROUND = {
  paper: "#ffffff",
  main: "#ffffff",
  drawer: "#ffffff",
  dbPaper: "#f0f0f0",
  results: "#f0f0f0",
  results1: "#dddddd",
  selectionHover: "#ffffff0b",
  featuredOdds: "#dddddd",
};

const DIVIDER = "#f0f0f0";

const GREY = {
  0: "#000000",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

export const bluelightPalette = {
  mode: "light",
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  divider: DIVIDER,
  neutral: NEUTRAL,
  background: BACKGROUND,
  success: SUCCESS,
  error: ERROR,
  grey: GREY,
  warning: WARNING,
  common: {
    white: "#ffffff",
    black: "#000000",
  },
  databet: {
    primaryColor: "#ffffff",
    primaryAccent: "#338bdd",
    secondaryColor: "#f0f0f0",
    primaryText: "#2e2e2e",
    secondaryText: "#2e2e2e",
    infoColor: "#ff00de",
    successColor: "#15a000",
    errorColor: "#e80f0f",
    warningColor: "#3da3fd",
  },
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
    odds: "var(--color-odds)",
  },
};
