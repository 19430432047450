import { Paper, styled } from "@mui/material";

export const PaperContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.dbPaper,
  padding: theme.spacing(1),
  borderRadius: 6,
  [theme.breakpoints.down("md")]: {
    borderRadius: 0,
  },
}));
