import { alpha, Box, Grid2, Stack, styled, Typography } from "@mui/material";
import RHFormProvider from "../../hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import RHFTextField from "../../hook-form/rhf-text-field";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import RHFDatePicker from "../../hook-form/rhf-date-picker";
import { LoadingButton } from "@mui/lab";
import { sendPasswordResetEmailAsync } from "../../../redux-store/auth-reducer";
import { format } from "date-fns";

const StyledContainer = styled(Box)(({ theme }) => ({
  // background: `linear-gradient(${alpha(theme.palette.background.paper, 0.5)}, rgba(0, 0, 0, 0.5)),  url(${require("../../../media/wallpaper.jpg")})`,
  // backgroundRepeat: "no-repeat",
  // backgroundSize: "cover",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const ForgotPasswordPage = () => {
  // hooks
  const dispatch = useDispatch();
  // translations
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const isLoading = useSelector((state) => state.auth.isSendPassEmailLoading);
  // validation scheme
  const signinValidationScheme = Yup.object().shape({
    email: Yup.string().required(translate.labels.field_required).email("Wrong email type"),
    birthDate: Yup.string().required(translate.labels.field_required),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(signinValidationScheme),
    defaultValues: {
      email: null,
      birthDate: null,
    },
  });

  const callback = () => {
    methods.setValue("email", "");
    methods.setValue("birthDate", null);
  };

  const onSubmit = (formValues) => {
    dispatch(sendPasswordResetEmailAsync({ params: { ...formValues, birthDate: format(new Date(formValues.birthDate), "yyyy-MM-dd") }, callback: callback }));
  };

  return (
    <StyledContainer>
      <Stack margin="auto" width={{ xs: "100%", sm: "90%", md: "480px" }}>
        <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid2 container spacing={2}>
            <Grid2 item size={12}>
              <Typography variant="h6" component="h4" color="primary" mb={1}>
                <Translate value="labels.forget_pass" />
              </Typography>
              <Typography variant="body2">
                <Translate value="labels.forget_text" />
              </Typography>
            </Grid2>
            <Grid2 item size={12}>
              <RHFTextField name="email" type="mail" placeholder={translate.labels.email} sx={{ backgroundColor: 'var(--db-secondary-color)' }} />
            </Grid2>
            <Grid2 item size={12}>
              <RHFDatePicker name="birthDate" type="date" placeholder={translate.labels.email} sx={{ backgroundColor: 'var(--db-secondary-color)' }} />
            </Grid2>
            <Grid2 item size={12}>
              <LoadingButton loading={isLoading} type="submit" variant="contained" color="primary">
                {translate.labels.forget_send}
              </LoadingButton>
            </Grid2>
          </Grid2>
        </RHFormProvider>
      </Stack>
    </StyledContainer>
  );
};

export default ForgotPasswordPage;
