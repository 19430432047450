import { Box, Button, CircularProgress, Paper, Stack, styled, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../iconify";
import { Link, useNavigate } from "react-router-dom";
import { removeTrustlyLoginStatus, handleAutoLoginAsync } from "../../../redux-store/auth-reducer";
import { Translate } from "react-redux-i18n";

const StyledContainer = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.dbPaper,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    width: "60%",
    margin: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: "45%",
    margin: "auto",
  },
}));

const AutoLoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // state selectors
  const locale = useSelector((state) => state.i18n.locale);
  const loginPayload = useSelector((state) => state.auth.trustlyLoginStatus);
  const autoLoginResponse = useSelector((state) => state.auth.autoLoginResponse);
  const responseType = autoLoginResponse?.type;
  const auth = useSelector((state) => state.auth);
  // handle back to login
  const backToLogin = () => {
    dispatch(removeTrustlyLoginStatus());
    navigate("/auth/login");
  };

  const backToRegister = () => {
    dispatch(removeTrustlyLoginStatus());
    navigate("/auth/registration");
  };
  // sign in callback

  useEffect(() => {
    if (loginPayload?.payload?.loginCode && !autoLoginResponse?.payload?.jwt && !auth.isSignedIn) {
      dispatch(handleAutoLoginAsync({ code: loginPayload?.payload?.loginCode, lang: locale }));
    }
  }, [loginPayload?.payload?.loginCode, auth.isSignedIn]);

  return (
    <Box py={3} px={1}>
      <StyledContainer>
        {(!responseType && !auth.isSignedIn) || (responseType === "pending" && !auth.isSignedIn) || (responseType === "waiting" && !auth.isSignedIn) ? (
          <Stack alignItems="center" gap={4} py={4} px={2}>
            <CircularProgress />
            <Typography variant="body1" color="text.secondary">
              Loading...
            </Typography>
          </Stack>
        ) : null}
        {auth.isSignedIn && (
          <Stack alignItems="center" gap={2} py={4} px={2}>
            <Iconify color="success.main" width={80} icon="arcticons:bitwarden-authenticator" />
            <Typography variant="body1" color="text.secondary">
              You are signed in.
            </Typography>
            <Link to="/">
              <Button variant="contained" color="primary">
                Continue betting
              </Button>
            </Link>
          </Stack>
        )}
        {responseType === "error" && autoLoginResponse?.payload === "USER_MUST_REGISTER" ? (
          <Stack alignItems="center" gap={4} py={4} px={2}>
            <Typography variant="body1" color="error">
              <Translate value="labels.register_new_user" />
            </Typography>
            <Button onClick={backToRegister} startIcon={<Iconify icon="ion:arrow-back-sharp" />} variant="contained" color="primary">
              <Translate value="labels.register" />
            </Button>
          </Stack>
        ) : null}
        {responseType === "error" && autoLoginResponse?.payload?.error === "CodeAlreadyUsedException" ? (
          <Stack alignItems="center" gap={4} py={4} px={2}>
            <Typography variant="body1" color="error">
              <Translate value="labels.login_code_already_used" />
            </Typography>
            <Button onClick={backToLogin} startIcon={<Iconify icon="ion:arrow-back-sharp" />} variant="contained" color="primary">
              <Translate value="labels.back_to_login" />
            </Button>
          </Stack>
        ) : null}
      </StyledContainer>
    </Box>
  );
};

export default AutoLoginPage;
