import { alpha, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Tab, Tabs } from "@mui/material";
import sportsIcons from "./sportsIcons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: 0,
  "& .icon-own": {
    height: "1.4em",
  },
  "&:hover": {
    borderRadius: 12,
  },
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 12,
  "&.Mui-selected": {
    background: theme.palette.primary.main,
    borderRadius: 12,
  },
  "&.Mui-selected:hover": {
    background: theme.palette.primary.main,
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MuiTabs = styled(Tabs)(({ theme }) => ({}));

const MuiTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.subtitle2,
  minHeight: 0,
  minWidth: 0,
  paddingTop: 0,
  paddingBottom: 0,
  "& .icon-own": {
    width: 25,
    height: 25,
  },
  "& div": {
    background: alpha(theme.palette.background.dbPaper, 1),
    padding: theme.spacing(1),
    borderRadius: 25,
    marginBottom: theme.spacing(0.3),
    border: "1px solid",
    borderColor: theme.palette.text.secondary,
  },
  "&.Mui-selected": {
    "& div": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const SportsSideBar = ({ sportCodes, initialSportCode, collection }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // route selectors
  const selectedSportCode = searchParams.get("selected-sport") || initialSportCode;
  const sportsLabels = useSelector((state) => state.labels.sports);
  const locale = useSelector((state) => state.i18n.locale);

  const handleSportSelect = (sportCode) => () => {
    searchParams.set("selected-sport", sportCode);
    navigate(`/${locale}/${collection}?${searchParams.toString()}`);
  };

  const handleTabChange = (e, value) => {
    searchParams.set("selected-sport", value);
    navigate(`/${locale}/${collection}?${searchParams.toString()}`);
  };

  const getSportName = (sportCode) => {
    if (sportsLabels) return sportsLabels.default[sportCode];
  };

  return (
    <Box>
      {/* <StyledList>
        {sportCodes.map((sportCode) => (
          <StyledListItem key={sportCode} disableGutters>
            <StyledListItemButton onClick={handleSportSelect(sportCode)} selected={selectedSportCode === sportCode}>
              <ListItemIcon>{sportsIcons[sportCode]}</ListItemIcon>
              <ListItemText primary={getSportName(sportCode)} />
            </StyledListItemButton>
          </StyledListItem>
        ))}
      </StyledList> */}
      <MuiTabs onChange={handleTabChange} value={selectedSportCode} indicatorColor="none">
        {sportCodes.map((spC) => (
          <MuiTab icon={sportsIcons[spC]} iconPosition="top" key={spC} value={spC} />
        ))}
      </MuiTabs>
    </Box>
  );
};

export default SportsSideBar;
