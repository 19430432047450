import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { getWPContent, getWPNewsContent } from "../../actions";
import ReactHtmlParser from "react-html-parser";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import withStyles from "@mui/styles/withStyles";
import { newsConfigs } from "../../config/newsConfigs";
import withRouter from "../../helpers/withRouter";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  root: {
    "@media (hover:hover)": {
      backgroundColor: "var(--color-main)",
    },
    "& .MuiTypography-root": {
      fontSize: "1.1em !important",
    },
    "& p": {
      margin: "1em",
      lineHeight: "1.2",
    },
    "& ol": {
      margin: "1em 1em 1em 0",
      lineHeight: "1.2",
    },
    "& li": {
      margin: "1em 0",
      lineHeight: "1.2",
    },
    "& .MuiDivider-root": {
      backgroundColor: "var(--color-contrast)",
    },
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  postDetail: {
    padding: "0.8em",
    "@media (hover:hover)": {
      padding: "1em 3em 1em",
    },
  },
  postLoadMore: {
    padding: "1em 3em 3em ",
    "@media (hover:none)": {
      textAlignLast: "center",
    },
  },
  postTitle: {
    "& h2": {
      fontSize: "1.3em",
      fontWeight: "bold",
      margin: "0.5em 0",
      color: "var(--grey)",
    },
  },
  postDate: {
    color: "var(--color-contrast)",
    fontSize: "0.9em",
  },
  postContent: {
    textAlign: "justify",
    "& table": {
      width: "100%",
      borderCollapse: "collapse",
      borderRadius: "0.5em",
      marginTop: "1em",
      marginBottom: "1em",
      backgroundColor: "var(--color-main)",
    },
    "& img": {
      maxWidth: "100%",
      height: "auto",
      margin: "1em auto",
      borderRadius: "0.5em",
      display: "block",
      "@media (hover:hover)": {
        maxWidth: "60%",
        width: "100%",
      },
    },
    "& li": {
      margin: "0.5em 1em 0 0",
      textAlign: "left",
    },
    "& ::marker": {
      color: "var(--color-contrast)",
      fontWeight: "bold",
    },
    "& summary": {
      margin: "1em",
      color: "var(--grey)",
      textAlign: "left",
      fontWeight: "bold",
    },
    "& thead": {
      color: "var(--color-contrast)",
      backgroundColor: "var(--color-main))"
    },
    "& td": {
      width: "content",
      color: "var(--grey)",
    },
    "& .wp-block-image": {
      margin: "0",
    },
    "& .wp-block-table": {
      display: "grid",
      textAlign: "center",
      // margin: "0 1em 0 0"
    },
    "& .wp-block-heading": {
      margin: "0 1em",
      color: "var(--grey)",
      fontSize: "1.1em",
      textAlign: "left",
    },
    "& .wp-block-button": {
      border: "1px solid var(--color-contrast)",
      padding: "0.5em",
      margin: "2em",
      borderRadius: "0.3em",
      backgroundColor: "var(--color-contrast)",
      fontSize: "0.9em !important",
      fontWeight: "bold",
      justifySelf: "center",
      color: "var(--btn-text)",
      textTransform: "uppercase !important",
      "&:hover": {
        backgroundColor: "var(--hover-color)",
        color: "var(--grey)",
      },
    },
    "& table": {
      width: "100%",
      borderCollapse: "collapse",
      margin: "1em 0",
      backgroundColor: "var(--color-main)",
      borderRadius: "0.5em"
    },
    "& tr": {
      borderTop: "1px solid var(--bg-promo)",
      // padding: "0.5em",
      // textAlign: "center",
    },
  },
  newsTabs: {
    backgroundColor: "var(--color-main)",
    "& .MuiTab-root": {
      minHeight: "1.95rem !important",
      padding: "0.35em 0 !important",
      color: "var(--grey)",
      "@media (hover:hover)": {
        minHeight: "2.3em !important",
        padding: "0.44em 0 !important",
        fontSize: "1.2em !important",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tabSelection: {
    "&.MuiButtonBase-root.MuiTab-root": {
      backgroundColor: "var(--color-slip-tabs)",
      borderRadius: "0.4em",
      height: "1em",
      color: "var(--btn-text)",
      fontSize: "0.9em !important",
      margin: "1em 0.8em",
    },
  },
});

class LeaderboardHockey extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { locale, search } = this.props;
    let tabActiveValue = new URLSearchParams(search).get("news-feed-index");
    if (!tabActiveValue) tabActiveValue = 0;
    this.props.getWPNewsContent(newsConfigs[locale][tabActiveValue]["id"], this.constructor.name);
  }

  goToPlay = () => {
    const { navigate, locale } = this.props;
    navigate(`/${locale}/sports/ice-hockey?sportEventStatusSlug=all`);
    window.location.reload();
  };

  render() {
    log(this.constructor.name, "render");
    const { classes, wpContent, search, locale } = this.props;

    let tabActiveValue = new URLSearchParams(search).get("news-feed-index");
    if (!tabActiveValue) tabActiveValue = 0;

    return (
      <div className={classes.root}>
        <Grid container style={{ padding: "0 0 1em 0" }}>
          {wpContent
            ?.filter((item) => item.id === 465)
            .map(() => {
              const article465 = wpContent.find((item) => item.id === 465);
              return (
                <Grid item xs={12} sm={12} md={12} key={article465.id} style={{ margin: "1em", backgroundColor: "var(--bg-promo)", borderRadius: "0.5em" }}>
                  <div className={classes.postDetail}>
                    <Typography className={classes.postTitle} component={"span"}>
                      <h2>{ReactHtmlParser(article465.title?.rendered)}</h2>
                    </Typography>
                    {/* {article268.featured_media !== 0 && (
                      <div>
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            margin: "0.5em 0 0.5em 0",
                          }}
                          src={`https://storage.googleapis.com/nubet-qa-content-news/nbt_news_${article268.id}.jpg`}
                          alt="Nubet news"
                        />
                      </div>
                    )} */}
                    <Typography className={classes.postContent} component={"span"}>
                      {ReactHtmlParser(article465.content?.rendered)}
                    </Typography>
                  </div>
                  {/* <div style={{ textAlignLast: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        margin: "2em 0",
                        color: "var(--btn-text)",
                        padding: "0.5em 0.5em",
                        fontSize: "1em",
                        padding: "0.5em 0.5em",
                      }}
                      onClick={this.goToPlay}
                    >
                      BET ON ICE HOCKEY NOW
                    </Button>
                  </div> */}
                </Grid>
              );
            })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const selectedLocale = props?.match?.params?.lang;
  return {
    locale: "en",
    wpContent: state.wpContent.news,
    host: state.settings.host,
    selectedTheme: state.settings.theme,
    search: props.location.search,
  };
};

export default withRouter(connect(mapStateToProps, { getWPContent, getWPNewsContent })(withStyles(styles)(LeaderboardHockey)));
