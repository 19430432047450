import { Collapse, Divider, Grid2, IconButton, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import Iconify from "../../iconify";
import { useState } from "react";
import { Translate } from "react-redux-i18n";

const BettingStatementBox = ({ item }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack spacing={0.5}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1">{item?.productTranslation || item?.productName}</Typography>
        <Typography variant="string" color="text.secondary">
          {format(new Date(item.createdTime), "dd MMMM, yyyy HH:mm")}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" color="text.secondary">
          <Translate value="labels.slip_stake" />
        </Typography>
        <Typography variant="body1">
          <Translate value="labels.bett_possiwin" />
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h1" component="p">
          € {item?.extraInfo?.stake ? item?.extraInfo?.stake : item?.betAmount ? item?.betAmount : 0}
        </Typography>
        <Typography variant="h1" component="div">
          € {item?.winAmount || 0}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2" color="primary">
          <Translate value="labels.bet_id" />: {item?.roundId}
        </Typography>
        <Stack direction="row" alignItems="center">
          <IconButton size="small" onClick={handleExpand}>
            <Iconify icon={expanded ? "mynaui:chevron-up-solid" : "mynaui:chevron-down-solid"} />
          </IconButton>
        </Stack>
      </Stack>
      <Stack>
        <Collapse in={expanded}>
          <Stack pt={1} pb={2}>
            <Grid2 container spacing={2}>
              <Grid2 item size={{ xs: 12, md: 2.4 }}>
                <Stack gap={1} direction={{ xs: "row", md: "column" }} justifyContent="space-between">
                  <Typography variant="body2" color="primary">
                    ID
                  </Typography>
                  <Typography style={{ overflow: "hidden", textOverflow: "ellipsis" }} variant="body2">
                    {item?.roundId}
                  </Typography>
                </Stack>
              </Grid2>
              <Grid2 item size={{ xs: 12, md: 2.4 }}>
                <Stack gap={1} direction={{ xs: "row", md: "column" }} justifyContent="space-between">
                  <Typography variant="body2" color="primary">
                    <Translate value="labels.date" />
                  </Typography>
                  <Typography variant="body2">{format(new Date(item.createdTime), "dd MMMM, yyyy HH:mm")}</Typography>
                </Stack>
              </Grid2>
              <Grid2 item size={{ xs: 12, md: 2.4 }}>
                <Stack gap={1} direction={{ xs: "row", md: "column" }} justifyContent="space-between">
                  <Typography variant="body2" color="primary">
                    <Translate value="labels.stmt_tickets" />
                  </Typography>
                  <Typography variant="body2">{item?.productTranslation || item?.productName}</Typography>
                </Stack>
              </Grid2>
              <Grid2 item size={{ xs: 12, md: 2.4 }}>
                <Stack gap={1} direction={{ xs: "row", md: "column" }} justifyContent="space-between">
                  <Typography variant="body2" color="primary">
                    <Translate value="labels.bet_type" />
                  </Typography>
                  <Typography variant="body2">{item?.gameType}</Typography>
                </Stack>
              </Grid2>
              <Grid2 item size={{ xs: 12, md: 2.4 }}>
                <Stack gap={1} direction={{ xs: "row", md: "column" }} justifyContent="space-between">
                  <Typography variant="body2" color="primary">
                    <Translate value="labels.amount" />
                  </Typography>
                  {item?.extraInfo?.stake && <Typography variant="body2">€ {item?.extraInfo?.stake}</Typography>}
                </Stack>
              </Grid2>
            </Grid2>
          </Stack>
        </Collapse>
      </Stack>
      <Stack>
        <Divider />
      </Stack>
    </Stack>
  );
};

export default BettingStatementBox;
