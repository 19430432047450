import { Box, Grid2, Stack, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import RHFormProvider from "../../../hook-form/form-provider";
import { LoadingButton } from "@mui/lab";
import RHFLimitField from "../../../hook-form/rhf-limit-field";
import { useEffect, useState } from "react";
import ConfirmLimits from "./confirm-limits";
import { saveUserLimitsAsync } from "../../../../redux-store/account-reducer";

const LossLimits = () => {
  // local state
  const [isConfirmopen, setConfirm] = useState(false);
  // action dispatcher
  const dispatch = useDispatch();
  // state selectros
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  const lossLimit = useSelector((state) => state.account.limits.loss);
  const isSaveLoading = useSelector((state) => state.account.limits.isSaveLoading);
  const dailyActive = lossLimit?.daily?.settings?.active?.limitValue;
  const weeklyActive = lossLimit?.weekly?.settings?.active?.limitValue;
  const monthlyActive = lossLimit?.monthly?.settings?.active?.limitValue;
  // cvalidation scheme
  const lossLimitsScheme = Yup.object().shape({
    daily: Yup.number().nullable().min(lossLimit?.daily?.settings?.min, `Min ${lossLimit?.daily?.settings?.min} EUR`).max(lossLimit?.daily?.settings?.max, `max ${lossLimit?.daily?.settings?.max} EUR`),
    weekly: Yup.number().nullable().min(lossLimit?.weekly?.settings?.min, `Min ${lossLimit?.weekly?.settings?.min} EUR`).max(lossLimit?.weekly?.settings?.max, `max ${lossLimit?.weekly?.settings?.max} EUR`),
    monthly: Yup.number().nullable().min(lossLimit?.monthly?.settings?.min, `Min ${lossLimit?.monthly?.settings?.min} EUR`).max(lossLimit?.monthly?.settings?.max, `max ${lossLimit?.monthly?.settings?.max} EUR`),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(lossLimitsScheme),
    defaultValues: {
      daily: null,
      weekly: null,
      monthly: null,
    },
  });

  useEffect(() => {
    methods.setValue("daily", dailyActive);
    methods.setValue("weekly", weeklyActive);
    methods.setValue("monthly", monthlyActive);
  }, [dailyActive, weeklyActive, monthlyActive]);

  const onSubmit = (formValues) => {
    if (formValues.daily && formValues.daily !== dailyActive) {
      dispatch(
        saveUserLimitsAsync({
          limitType: "loss",
          limitValue: formValues.daily,
          limitPeriod: 1,
          autoRenew: true,
        })
      );
    }
    if (formValues.weekly && formValues.weekly !== weeklyActive) {
      dispatch(
        saveUserLimitsAsync({
          limitType: "loss",
          limitValue: formValues.weekly,
          limitPeriod: 7,
          autoRenew: true,
        })
      );
    }
    if (formValues.monthly && formValues.monthly !== monthlyActive) {
      dispatch(
        saveUserLimitsAsync({
          limitType: "loss",
          limitValue: formValues.monthly,
          limitPeriod: 30,
          autoRenew: true,
        })
      );
    }
    setConfirm(false);
  };

  const dailyLimit = useWatch({ control: methods.control, name: "daily" });
  const weeklyLimit = useWatch({ control: methods.control, name: "weekly" });
  const monthlyLimit = useWatch({ control: methods.control, name: "monthly" });
  const isSaveActive = (dailyLimit && dailyLimit !== dailyActive) || (weeklyLimit && weeklyActive !== weeklyLimit) || (monthlyLimit && monthlyActive !== monthlyLimit);

  const handleConfirmAlert = () => {
    if (isSaveActive) {
      setConfirm(true);
    }
  };

  const handleClose = () => {
    setConfirm(false);
  };

  return (
    <Box py={3} px={{ xs: 0, md: 2 }}>
      <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid2 container spacing={2}>
          <Grid2 item size={12}>
            <Typography variant="body1" color="primary">
              <Translate value={`labels.limits_loss_text_1`} />
            </Typography>
          </Grid2>
          <Grid2 item size={12}>
            <Typography variant="body2">
              <Translate value={`labels.limits_loss_text_2`} />
            </Typography>
            <Typography variant="body2">
              <Translate value={`labels.limits_loss_text_3`} />
            </Typography>
            <Typography variant="body2">
              <Translate value={`labels.limits_loss_text_4`} />
            </Typography>
          </Grid2>
          <Grid2 item size={{ xs: 12, sm: 6, md: 4 }}>
            <RHFLimitField data={lossLimit?.daily?.extraData} name="daily" label={translate.labels.limits_time_day} />
          </Grid2>
          <Grid2 item size={{ xs: 12, sm: 6, md: 4 }}>
            <RHFLimitField data={lossLimit?.weekly?.extraData} name="weekly" label={translate.labels.limits_time_week} />
          </Grid2>
          <Grid2 item size={{ xs: 12, sm: 6, md: 4 }}>
            <RHFLimitField data={lossLimit?.monthly?.extraData} name="monthly" label={translate.labels.limits_time_month} />
          </Grid2>
          <Grid2 item size={12}>
            <Stack alignItems="center" py={3}>
              <LoadingButton loading={isSaveLoading} disabled={!isSaveActive} onClick={handleConfirmAlert} variant="contained" color="primary">
                {translate.labels.btn_save}
              </LoadingButton>
            </Stack>
          </Grid2>
        </Grid2>
        <ConfirmLimits isLoading={isSaveLoading} handleConfirm={methods.handleSubmit(onSubmit)} handleClose={handleClose} open={isConfirmopen} daily={dailyLimit} weekly={weeklyLimit} monthly={monthlyLimit} />
      </RHFormProvider>
    </Box>
  );
};

export default LossLimits;
