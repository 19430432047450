import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  blockUser,
  getSubscriptions,
  getUserDepositStatements,
  getUserDetails,
  getUserLimits,
  getUserTransactionStatements,
  getUserWithdrawStatements,
  getWalletBalance,
  makeWithdraw,
  saveUserLimits,
  updatePassword,
  updateSubscriptions,
  updateUserLimits,
} from "./_requests";
import limitsFormatter from "./_formatter";

const initialState = {
  balanceDetails: {},
  payments: {
    deposit: {
      isLoading: false,
      isStatusLoading: false,
      payload: {},
      statusPayload: {},
    },
    withdraw: {
      isLoading: false,
      payload: {},
    },
  },
  statements: {
    deposits: {
      isLoading: false,
      list: [],
      pageCount: 0,
      size: 0,
      currentPage: 1,
      count: 0,
    },
    withdrawals: {
      isLoading: false,
      list: [],
      pageCount: 0,
      size: 0,
      currentPage: 1,
      count: 0,
    },
    transactions: {
      isLoading: false,
      list: [],
      pageCount: 0,
      size: 0,
      currentPage: 1,
      count: 0,
    },
  },
  subscriptions: {
    sub_sms: false,
    sub_email: false,
    isSaveLoading: false,
  },
  details: {
    isLoading: false,
    isBlockUserLoading: false,
  },
  password: {
    isUpdateLoading: false,
  },
  limits: {
    loss: {},
    stake: {},
    deposit: {},
    isSaveLoading: false,
    isUpdateLoading: false,
  },
};

export const getWalletBalanceAsync = createAsyncThunk("thunk/getWalletBalance", getWalletBalance);
export const makeWithdrawAsync = createAsyncThunk("thunk/makeWithdraw", makeWithdraw);
export const getUserDepositStatementsAsync = createAsyncThunk("thunk/getUserDepositStatements", getUserDepositStatements);
export const getUserWithdrawStatementsAsync = createAsyncThunk("thunk/getUserWithdrawStatements", getUserWithdrawStatements);
export const getUserTransactionStatementsAsync = createAsyncThunk("thunk/getUserTransactionStatements", getUserTransactionStatements);
export const getSubscriptionsAsync = createAsyncThunk("thunk/getSubscriptions", getSubscriptions);
export const updateSubscriptionsAsync = createAsyncThunk("thunk/updateSubscriptions", updateSubscriptions);
export const getUserDetailsAsync = createAsyncThunk("thunk/getUserDetails", getUserDetails);
export const updatePasswordAsync = createAsyncThunk("thunk/updatePassword", updatePassword);
export const blockUserAsync = createAsyncThunk("thunk/blockUser", blockUser);
export const getUserLimitsAsync = createAsyncThunk("thunk/getUserLimits", getUserLimits);
export const saveUserLimitsAsync = createAsyncThunk("thunk/saveUserLimits", saveUserLimits);
export const updateUserLimitsAsync = createAsyncThunk("thunk/updateUserLimits", updateUserLimits);

export const accountSlice = createSlice({
  name: "slice/account",
  initialState,
  extraReducers: (builder) => {
    builder
      // GET USER BALANCE DETAILS
      .addCase(getWalletBalanceAsync.fulfilled, (state, { payload }) => {
        state.balanceDetails = payload?.payload && typeof payload?.payload === "object" ? payload?.payload : {};
      })
      // MAKE WITHDRAW REQUEST
      .addCase(makeWithdrawAsync.pending, (state) => {
        state.payments.withdraw.isLoading = true;
      })
      .addCase(makeWithdrawAsync.rejected, (state) => {
        state.payments.withdraw.isLoading = false;
      })
      .addCase(makeWithdrawAsync.fulfilled, (state, { payload }) => {
        state.payments.withdraw.isLoading = false;
        state.payments.withdraw.payload = payload.data;
      })
      // GET USER DEPOSIT STATEMENTS
      .addCase(getUserDepositStatementsAsync.pending, (state) => {
        state.statements.deposits.isLoading = true;
      })
      .addCase(getUserDepositStatementsAsync.rejected, (state) => {
        state.statements.deposits.isLoading = false;
      })
      .addCase(getUserDepositStatementsAsync.fulfilled, (state, { payload }) => {
        state.statements.deposits.isLoading = false;
        if (payload?.payload?.transactions) {
          state.statements.deposits.list = payload.payload.transactions;
          state.statements.deposits.size = payload.payload.size;
          state.statements.deposits.count = payload.payload.count;
          state.statements.deposits.pageCount = payload.payload.pageCount;
          state.statements.deposits.currentPage = payload.payload.currentPage;
        }
      })
      // GET USER WITHDRAW STATEMENTS
      .addCase(getUserWithdrawStatementsAsync.pending, (state) => {
        state.statements.withdrawals.isLoading = true;
      })
      .addCase(getUserWithdrawStatementsAsync.rejected, (state) => {
        state.statements.withdrawals.isLoading = false;
      })
      .addCase(getUserWithdrawStatementsAsync.fulfilled, (state, { payload }) => {
        state.statements.withdrawals.isLoading = false;
        if (payload?.payload?.transactions) {
          state.statements.withdrawals.list = payload.payload.transactions;
          state.statements.withdrawals.size = payload.payload.size;
          state.statements.withdrawals.count = payload.payload.count;
          state.statements.withdrawals.pageCount = payload.payload.pageCount;
          state.statements.withdrawals.currentPage = payload.payload.currentPage;
        }
      })
      // GET USER WITHDRAW STATEMENTS
      .addCase(getUserTransactionStatementsAsync.pending, (state) => {
        state.statements.transactions.isLoading = true;
      })
      .addCase(getUserTransactionStatementsAsync.rejected, (state) => {
        state.statements.transactions.isLoading = false;
      })
      .addCase(getUserTransactionStatementsAsync.fulfilled, (state, { payload }) => {
        state.statements.transactions.isLoading = false;
        if (payload?.payload?.transactions) {
          state.statements.transactions.list = Object.values(payload.payload.transactions);
          state.statements.transactions.size = payload.payload.size;
          state.statements.transactions.count = payload.payload.count;
          state.statements.transactions.pageCount = payload.payload.pageCount;
          state.statements.transactions.currentPage = payload.payload.currentPage;
        }
      })
      // GET USER SUBSCRIPTIONS
      .addCase(getSubscriptionsAsync.fulfilled, (state, { payload }) => {
        state.subscriptions.sub_email = Boolean(payload?.payload?.types?.sub_email);
        state.subscriptions.sub_sms = Boolean(payload?.payload?.types?.sub_sms);
      })
      // GET USER SUBSCRIPTIONS
      .addCase(updateSubscriptionsAsync.pending, (state) => {
        state.subscriptions.isSaveLoading = true;
      })
      .addCase(updateSubscriptionsAsync.rejected, (state) => {
        state.subscriptions.isSaveLoading = false;
      })
      .addCase(updateSubscriptionsAsync.fulfilled, (state) => {
        state.subscriptions.isSaveLoading = false;
      })
      // GET USER DETAILS
      .addCase(getUserDetailsAsync.pending, (state) => {
        state.details.isLoading = true;
      })
      .addCase(getUserDetailsAsync.rejected, (state) => {
        state.details.isLoading = false;
      })
      .addCase(getUserDetailsAsync.fulfilled, (state, { payload }) => {
        state.details.isLoading = false;
        if (payload?.payload?.userId) {
          state.details = { ...payload.payload, isLoading: false };
        }
      })
      // UPDATE USER PASSWORD
      .addCase(updatePasswordAsync.pending, (state) => {
        state.password = {};
        state.password.isUpdateLoading = true;
      })
      .addCase(updatePasswordAsync.rejected, (state) => {
        state.password.isUpdateLoading = false;
      })
      .addCase(updatePasswordAsync.fulfilled, (state) => {
        state.password.isUpdateLoading = false;
      })
      // block user
      .addCase(blockUserAsync.pending, (state) => {
        state.details.isBlockUserLoading = true;
      })
      .addCase(blockUserAsync.rejected, (state) => {
        state.details.isBlockUserLoading = false;
      })
      .addCase(blockUserAsync.fulfilled, (state) => {
        state.details.isBlockUserLoading = false;
      })
      // GET USER LIMITS
      .addCase(getUserLimitsAsync.fulfilled, (state, { payload }) => {
        payload?.types?.forEach((type) => {
          const data = limitsFormatter(payload, type);
          state.limits[type] = data;
        });
      })
      // save user limits
      .addCase(saveUserLimitsAsync.pending, (state) => {
        state.limits.isSaveLoading = true;
      })
      .addCase(saveUserLimitsAsync.rejected, (state) => {
        state.limits.isSaveLoading = false;
      })
      .addCase(saveUserLimitsAsync.fulfilled, (state) => {
        state.limits.isSaveLoading = false;
      })
      .addCase(updateUserLimitsAsync.pending, (state) => {
        state.limits.isUpdateLoading = true;
      })
      .addCase(updateUserLimitsAsync.rejected, (state) => {
        state.limits.isUpdateLoading = false;
      })
      .addCase(updateUserLimitsAsync.fulfilled, (state) => {
        state.limits.isUpdateLoading = false;
      });
  },
});

export default accountSlice.reducer;
