import { alpha, Box, Chip, Grid2, Stack, styled, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";
import { initRegisterDepositAsync } from "../../../redux-store/auth-reducer";
import RHFormProvider from "../../hook-form/form-provider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import RHFAmountField from "../../hook-form/rhf-amount-field";

const StyledContainer = styled(Box)(({ theme }) => ({
  // background: `linear-gradient(${alpha(theme.palette.background.paper, 0.5)}, rgba(0, 0, 0, 0.5)),  url(${require("../../../media/wallpaper.jpg")})`,
  // backgroundRepeat: "no-repeat",
  // backgroundSize: "cover",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(1.74),
  height: "100%",
  borderRadius: "0.5em",
  // fontSize: "1.2em",
  fontWeight: "bold",
}));

const UserRegister = () => {
  // hooks
  const dispatch = useDispatch();
  // selectors
  const locale = useSelector((state) => state.i18n.locale);
  const isDepsoitLoading = useSelector((state) => state.auth.isRegDepositLoading);
  const translate = useSelector((state) => state.i18n.translations[locale]);
  // validation scheme
  const registerScheme = Yup.object().shape({
    amount: Yup.number().min(10, translate.labels.minimum_amount).max(5000, translate.labels.minimum_amount).required(),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(registerScheme),
    defaultValues: {
      amount: 10,
    },
  });

  const handleValueChange = (value) => () => {
    methods.setValue("amount", value);
  };

  const onSubmit = (formValues) => {
    dispatch(initRegisterDepositAsync(formValues.amount));
  };

  const amount = useWatch({ control: methods.control, name: "amount" });

  return (
    <StyledContainer>
      <Stack width={{ xs: "100%", sm: "70%", md: "70%" }} margin="auto" alignItems="center" gap={5} py={3} px={2}>
        <Typography variant="h5" component="h2" align="center">
          {translate.labels.register_1}
        </Typography>
        <Typography variant="subtitle1" component="h6">
          {translate.labels.register_2}
        </Typography>
        <Typography variant="body1" color="primary">
          {translate.labels.register_3}
        </Typography>
        <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid2 container spacing={3}>
            <Grid2 item size={{ xs: 12, md: 5 }}>
              <Stack direction="row" alignItems="center" gap={2} justifyContent="center">
                <StyledChip label="50 €" variant="contained" color={Number(amount) === 50 ? "primary" : "inherit"} onClick={handleValueChange(50)} />
                <StyledChip label="100 €" variant="contained" color={Number(amount) === 100 ? "primary" : "inherit"} onClick={handleValueChange(100)} />
                <StyledChip label="200 €" variant="contained" color={Number(amount) === 200 ? "primary" : "inherit"} onClick={handleValueChange(200)} />
              </Stack>
            </Grid2>
            <Grid2 item size={{ xs: 12, md: 3.5 }}>
              <RHFAmountField size="small" type="number" name="amount" />
            </Grid2>
            <Grid2 item size={{ xs: 12, md: 3.5 }}>
              <LoadingButton type="submit" loading={isDepsoitLoading} fullWidth variant="contained" color="primary" size="large">
              {translate.labels.register_6}
              </LoadingButton>
            </Grid2>
          </Grid2>
        </RHFormProvider>
        <Stack>
          <Typography variant="body2" color="text.secondary">
            {translate.labels.register_4}
          </Typography>
        </Stack>
        <Link to={`/${locale}/auth/login`}>
          <Typography variant="h3" component="p">
            {translate.labels.register_5}
          </Typography>
        </Link>
      </Stack>
    </StyledContainer>
  );
};

export default UserRegister;
