import React, { useEffect } from "react";
import { Grid2, Stack, Typography, Accordion, AccordionSummary, AccordionDetails, styled, Paper, useMediaQuery, useTheme, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PaperContainer } from "../@components/containers";
import PlayerPropsSelectionButton from "../@components/PlayerPropsSelectionButton";
import { useLocation } from "react-router-dom";

const MuiAccardion = styled(Accordion)(({ theme }) => ({
  padding: 0,
  background: "transparent",
  margin: 0,
  "& .MuiAccordionSummary-content": {
    margin: 0,
    padding: 0,
    "&.Mui-expanded": {
      padding: 0,
    },
  },
  "& .MuiAccordionSummary-root": {
    minHeight: 0,
    padding: 0,
    "&.Mui-expanded": {
      minHeight: 0,
    },
  },
  "& .MuiAccordionDetails-root": {
    padding: 0,
    "&.Mui-expanded": {
      minHeight: 0,
    },
  },
}));

const getPlayersLayout = (contractsKeys, contractsValues, contracts, sortKey) => {
  let players = {};
  contractsValues?.forEach((arr) => {
    arr.forEach((player) => {
      if (!players[player.player_id]) {
        const markets = {};
        contractsKeys.forEach((contKey) => {
          const market = contracts[contKey];
          const detailsInMarket = market.find((pl) => pl.player_id === player?.player_id) || {};
          markets[contKey] = detailsInMarket;
        });
        players[player.player_id] = { ...player, markets: markets };
      }
    });
  });
  players = Object.values(players);
  if (players.length && sortKey) {
    players = players.sort((a, b) => {
      const priceA = a.markets[sortKey]?.price;
      const priceB = b.markets[sortKey]?.price;
      return priceA - priceB;
    });
  }
  return players;
};

const PlayerPropsMarketGroup = ({ name, contracts, game, id }) => {
  const [expanded, setExpanded] = React.useState(false);
  const contractsKeys = contracts && typeof contracts === "object" ? Object.keys(contracts) : [];
  const contractsValues = contracts && typeof contracts === "object" ? Object.values(contracts) : [];
  const players = getPlayersLayout(contractsKeys, contractsValues, contracts, contractsKeys[0]);
  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    if (game?.sportCode === "FB" || game?.sportCode === "IH") {
      setExpanded(true);
    }
  }, [game?.sportCode]);

  return (
    <PaperContainer sx={{ p: 0 }}>
      <MuiAccardion expanded={expanded} onChange={handleExpansion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row" alignItems="center" p={1.5}>
            <Typography variant="h5" component="p">
              {name}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            {mobileMatches ? (
              <Stack pb={1}>
                {players.map((player) => {
                  return (
                    <Box key={player?.player_id} px={1}>
                      <Paper sx={{ my: 1, py: 1 }}>
                        <Typography variant="subtitle2" component="h6" align="center">
                          {player?.player_name}
                        </Typography>
                      </Paper>
                      <Box>
                        <Grid2 container spacing={1}>
                          {contractsKeys?.map((contKey) => {
                            const selection = player?.markets[contKey] || {};
                            return (
                              <Grid2 item size={6} key={contKey}>
                                <PlayerPropsSelectionButton marketGroupName={name} mobile marketName={contKey} marketId={id} game={game} price={selection.price} {...selection} playerName={selection.player_name} />
                              </Grid2>
                            );
                          })}
                        </Grid2>
                      </Box>
                    </Box>
                  );
                })}
              </Stack>
            ) : (
              <Grid2 container>
                <Grid2 item size={12} px={1}>
                  <Paper>
                    <Grid2 container px={2} py={1} spacing={0.2}>
                      <Grid2 item size={{ xs: 12, md: 2 }} />
                      {contractsKeys.map((conKey) => (
                        <Grid2 size={{ xs: 12 / contractsKeys.length, md: 10 / contractsKeys.length }} display="flex" justifyContent={"center"} key={conKey}>
                          <Typography variant="body2" component="p" color="text.secondary">
                            {conKey}
                          </Typography>
                        </Grid2>
                      ))}
                    </Grid2>
                  </Paper>
                </Grid2>
                {players.map((player) => {
                  return (
                    <Grid2 borderBottom="2px solid" borderColor="background.paper" item size={12} container key={player.player_id} px={1} py={1} spacing={0.2}>
                      <Grid2 size={{ xs: 12, md: 2 }}>
                        <Typography variant="body2">{player.player_name}</Typography>
                      </Grid2>
                      {contractsKeys.map((contKey) => {
                        const selection = player?.markets[contKey] || {};
                        return (
                          <Grid2 size={{ xs: 12 / contractsKeys.length, md: 10 / contractsKeys.length }} display="flex" justifyContent="center" key={contKey}>
                            <PlayerPropsSelectionButton marketGroupName={name} marketName={contKey} marketId={id} game={game} price={selection.price} {...selection} playerName={selection.player_name} />
                          </Grid2>
                        );
                      })}
                    </Grid2>
                  );
                })}
              </Grid2>
            )}
          </Stack>
        </AccordionDetails>
      </MuiAccardion>
    </PaperContainer>
  );
};

export default PlayerPropsMarketGroup;
