import { Box, Button, Card, Drawer, Grid2, Paper, Stack, styled, Typography } from "@mui/material";
import Iconify from "../../iconify";
import Slip from "../slip";
import TicketsList from "../../user/tickets/TicketsList";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import BetMarketLabel from "../../BetMarketLabel";

const MuiCard = styled(Card)(({ theme }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  bottom: "66px",
  zIndex: 1200,
  background: theme.palette.primary.main,
  padding: theme.spacing(0.7),
  cursor: "pointer",
}));

const DrawerContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.dbPaper,
  width: "100vw",
  height: "100dvh",
  overflow: "auto",
}));

const DrawerHeader = styled(Paper)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  position: "sticky",
  top: 0,
  zIndex: 2500,
}));

const IconContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.3),
  borderRight: "1px solid",
  borderColor: theme.palette.divider,
  background: "transparent",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 0,
}));

const MobileSlip = () => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("slip");

  const layout = useSelector((state) => state.settings.layout);
  const auth = useSelector((state) => state.auth);
  const slip = useSelector((state) => state.slips[0]);
  const bets = Object.values(slip.settings.bets);

  return (
    <Box display={layout === "mouse" ? "none" : "block"}>
      <MuiCard onClick={() => setOpen(true)}>
        <Grid2 container>
          <Grid2 item size={1}>
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Iconify width={23} icon="mdi:chevron-up" />
            </Stack>
          </Grid2>
          <Grid2 item size={bets.length ? 3 : 11}>
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Typography textTransform="uppercase" variant="body2" align="center">
                {bets.length < 2 ? <Translate value="slip.slip" /> : slip.betType}
              </Typography>
            </Stack>
          </Grid2>
          {bets.length >= 1 && (
            <Grid2 item size={8}>
              {slip.betType === "single" ? (
                <Stack direction="column" alignItems="flex-end">
                  <Typography variant="string" component="span">
                    {bets[0]?.game.game_title ? bets[0]?.game?.game_title : `${bets[0]?.game?.team1} v ${bets[0]?.game?.team2}`}
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Stack direction="row" alignItems="center" gap={0.5} color="text.secondary">
                      <Typography variant="caption">
                        <BetMarketLabel bet={bets[0]} />
                      </Typography>
                      {bets[0]?.isPlayerProps && (
                        <Typography variant="caption" textTransform="lowercase">
                          {bets[0]?.marketGroupName}
                        </Typography>
                      )}
                    </Stack>
                    <Typography variant="subtitle2">{bets[0].price}</Typography>
                  </Stack>
                </Stack>
              ) : (
                <Stack alignItems="flex-end">
                  <Typography variant="body2" component="p">
                    <Translate value="labels.bett_odd" />
                  </Typography>
                  <Typography variant="caption">{slip.settings.odds?.toFixed(2)}</Typography>
                </Stack>
              )}
            </Grid2>
          )}
        </Grid2>
      </MuiCard>
      <Drawer ModalProps={{ sx: { zIndex: 2000 } }} anchor={"bottom"} open={open}>
        <DrawerContainer>
          <DrawerHeader>
            <Grid2 container>
              <Grid2 item size={3}>
                <IconContainer onClick={() => setOpen(false)}>
                  <Iconify icon="mdi:chevron-down" />
                </IconContainer>
              </Grid2>
              <Grid2 item size={auth.isSignedIn ? 4.5 : 9} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button onClick={() => setTab("slip")} sx={{ color: tab === "slip" ? "text.primary" : "text.secondary" }} fullWidth>
                  <Translate value="menu.slip" />
                </Button>
              </Grid2>
              {auth.isSignedIn && (
                <Grid2 item size={4.5} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Button onClick={() => setTab("tickets")} sx={{ color: tab === "tickets" ? "text.primary" : "text.secondary" }} fullWidth>
                    <Translate value="menu.myBets" />
                  </Button>
                </Grid2>
              )}
            </Grid2>
          </DrawerHeader>
          {auth.isSignedIn && tab === "tickets" ? <TicketsList /> : <Slip />}
        </DrawerContainer>
      </Drawer>
    </Box>
  );
};

export default MobileSlip;
