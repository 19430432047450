import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import SportsLayout from "../@components/SportsLayout";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getPlayerPropsAsync, getPlayerPropsCatalogAsync } from "../../../redux-store/sports-reducer";
import React from "react";
import SportsSideBar from "../@components/SportsSideBar";
import { PaperContainer } from "../@components/containers";
import PrecannedBetsCarousel from "../../banners/precanned-bets";
import Iconify from "../../iconify";
import SportsHeader from "../@components/SportsHeader";
import { Localize } from "react-redux-i18n";

const PlayerPropsGamesPage = () => {
  // redux action dispatcher
  const dispatch = useDispatch();
  // router hooks
  const [searchParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // selectors
  const sportCodes = useSelector((state) => state.sports.playerProps.sportCodes);
  const selectedSportCode = searchParams.get("selected-sport") || sportCodes[0];
  const leagues = useSelector((state) => state.sports.playerProps.leagues);
  const games = useSelector((state) => state.sports.playerProps.games);
  const locale = useSelector((state) => state.i18n.locale);
  const selectedLeague = leagues[selectedSportCode]?.find((lg) => lg.leagueId === Number(params.leagueId));

  const handleNavigation = (gameId) => () => {
    searchParams.set("league", params.leagueId);
    navigate(`/${locale}/player-props/game/${gameId}?${searchParams?.toString()}`);
  };

  React.useEffect(() => {
    if (params.leagueId && selectedSportCode) {
      dispatch(
        getPlayerPropsCatalogAsync({
          lang: locale,
          leagueID: params.leagueId,
          sportCode: selectedSportCode,
        })
      );
    }
    if (!leagues || !Object.keys(leagues).length) {
      dispatch(getPlayerPropsAsync(locale));
    }
  }, [location.pathname, params.leagueId, selectedSportCode]);

  return (
    <SportsLayout>
      <Box pb={{ xs: 0, md: 2 }}>
        <Stack gap={1}>
          <Box display={{ xs: "none", md: "block" }}>
            <PrecannedBetsCarousel />
          </Box>
          <SportsSideBar collection="player-props" sportCodes={sportCodes} initialSportCode={sportCodes[0]} />
          <SportsHeader collection="player-props" league={selectedLeague} sportCode={selectedSportCode} />
          <PaperContainer>
            {games.map((ev, indx) => {
              return (
                <Stack key={ev?.game_id}>
                  <Stack py={2} sx={{ cursor: "pointer" }} direction="row" alignItems="center" justifyContent="space-between" onClick={handleNavigation(ev?.game_id)}>
                    <Stack gap={0.5}>
                      <Typography variant="teamnamebb">{ev.game_title}</Typography>
                      <Typography variant="string" color="text.secondary">
                        <Localize value={ev.game_time} dateFormat="date.game1" />
                      </Typography>
                    </Stack>
                    <IconButton size="small">
                      <Iconify icon="material-symbols:chevron-right-rounded" />
                    </IconButton>
                  </Stack>
                  {games?.length !== indx + 1 && <Divider />}
                </Stack>
              );
            })}
          </PaperContainer>
        </Stack>
      </Box>
    </SportsLayout>
  );
};

export default PlayerPropsGamesPage;
