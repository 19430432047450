/**
 * Promotions Page - English version
 */

import { log } from "../../../config/log";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const styles = (theme) => ({
  root: {
    // padding: "1.8em",
    fontSize: "var(--content)",
    "@media (hover:none)": {},
  },
  listItems: {
    color: "var(--color-contrast)",
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  promo: {
    height: "auto",
    width: "30%",
    "@media only screen and (max-width: 767px)": {
      width: "100%",
    },
  },
  promoGrid: {
    "@media (hover:none)": {
      padding: "1em 0 0 1em",
    },
  },
});

class PromotionsHockeyWeekendBonus extends React.PureComponent {
  goTop = () => { };

  render() {
    log(this.constructor.name, "render");
    const { host, classes, locale, auth, siteTheme } = this.props;

    if (!host) return null;

    return (
      <Container style={{ backgroundColor: "var(--color-main)", height: "100%" }} maxWidth={false}>
        <Grid className={classes.promoGrid} container spacing={0}>
          <Grid item xs={12} sm={12}>
            <Paper style={{ margin: "0.5em" }}>
              <Typography component="span">
                <div id="legalcontent" className={classes.root}>
                  <h1>Hockey Weekend Bonus</h1>
                  <br></br>
                  <img className={classes.promo} src={`https://storage.googleapis.com/nubet-prod-content-news/freebetdeposit_bonus.png`} alt="banner-casino-promo" />
                  <br></br><br></br>
                
                  <span className={classes.listItems}>- </span>Deposit 50 € and you will automatically get 3 freebets of 10 € each
                  <br></br>
                  <br></br>
                  <span className={classes.listItems}>- </span>Freebets are available on single bets on Finnish Liiga, NHL or U20 World Championship matches
                  <br></br>
                  <br></br>
                  <span className={classes.listItems}>- </span>Deposit has 1x rollover requirement with odds 1.50 or greater
                  <br></br>
                  <br></br>
                  <span className={classes.listItems}>- </span>Freebets are valid until matches played on 5.1.2025.
                  <br></br>
                  <br></br>
                  <span className={classes.listItems}>- </span>Hockey Weekend Bonus is open for first deposits on 20.-22.12.
                  <br></br>
                  <br></br>
                  <span className={classes.listItems}>- </span>Nubet reserves the right to alter, cancel or withdraw this bonus at any time. Any changes will be published in these rules.
                  <br></br>
                  <br></br>
                  <div>
                    {!auth.isSignedIn ? (
                      <Link to={`/${locale}/auth/registration`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "1em 1em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                          }}
                        >
                          DEPOSIT AND OPT-IN FOR FREE BETS
                        </Button>
                      </Link>
                    ) : (
                      <Link to={`/${locale}/account/payments`}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            margin: "2em 0",
                            color: "var(--btn-text)",
                            padding: "1em 1em",
                            fontSize: "1em",
                            background: "linear-gradient(0deg, rgba(29,88,154,1) 0%, rgba(61,163,253,1) 50%, rgba(29,88,154,1) 100%)",
                          }}
                        >
                          DEPOSIT AND OPT-IN FOR FREE BETS
                        </Button>
                      </Link>
                    )}
                  </div>
                </div>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    host: state.settings.host,
    locale: state.i18n.locale,
    auth: state.auth,
    siteTheme: state.settings.theme,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PromotionsHockeyWeekendBonus));
