import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Stack, styled, Tab, Tabs } from "@mui/material";
import DepositStatements from "./deposits";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import WithdrawStatements from "./withdraws";
import { useSelector } from "react-redux";
import { addDays, format, subDays } from "date-fns";
import CustomDatePicker from "../../Inputs/DatePicker";
import TransactionStatements from "./transactions";

const StyledContainer = styled(Paper)(({ theme }) => ({
  background: theme.palette.background.dbPaper,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up("md")]: {
    width: "70%",
    margin: "auto",
  },
  [theme.breakpoints.up("lg")]: {
    width: "60%",
    margin: "auto",
  },
  "& .MuiTabPanel-root": {
    padding: 0,
  },
  "& .MuiTableCell-root": {
    borderBottom: "2px solid",
    borderColor: theme.palette.divider,
    padding: theme.spacing(1),
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  "&.MuiButtonBase-root.MuiTab-root": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0.4em",
    height: "2.5em",
    color: "var(--btn-text)",
    fontSize: "0.9em !important",
    margin: "1em 0.8em",
    width: "120px",
  },
}));

const StatementsPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const loocation = useLocation();

  // state selectors
  const locale = useSelector((state) => state.i18n.locale);
  const translate = useSelector((state) => state.i18n.translations[locale]);

  // router selectors
  const selectedTab = searchParams.get("type") || "transactions";
  const period = searchParams.get("period") || "1";
  const startDate = searchParams.get("start") || format(subDays(new Date(), 1), "yyyy-MM-dd");
  const endDate = searchParams.get("end") || format(addDays(new Date(), 1), "yyyy-MM-dd");

  // handle tab change

  const handleChange = (e, newValue) => {
    const startDate = format(subDays(new Date(), 1), "yyyy-MM-dd");
    const endDate = format(addDays(new Date(), 1), "yyyy-MM-dd");
    searchParams.set("type", newValue);
    searchParams.set("period", 1);
    searchParams.set("page", 1);
    searchParams.set("start", startDate);
    searchParams.set("end", endDate);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  // change start date
  const handleStartDateChange = (newValue) => {
    const startDate = format(subDays(new Date(newValue), 1), "yyyy-MM-dd");
    searchParams.set("start", startDate);
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  // change end date
  const handleEndDateChange = (newValue) => {
    const endDate = format(addDays(new Date(newValue), 1), "yyyy-MM-dd");
    searchParams.set("end", endDate);
    searchParams.set("page", 1);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  // handle period change

  const handlePeriodChange = (e, newValue) => {
    searchParams.set("period", newValue);
    if (newValue !== "other") {
      const startDate = format(subDays(new Date(), Number(newValue)), "yyyy-MM-dd");
      const endDate = format(addDays(new Date(), 1), "yyyy-MM-dd");
      searchParams.set("start", startDate);
      searchParams.set("end", endDate);
      searchParams.set("page", 1);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  return (
    <Box px={1} py={2}>
      <StyledContainer>
        <TabContext value={selectedTab}>
          <TabList indicatorColor="none" onChange={handleChange} aria-label="tabs-payments">
            <Tab label={translate.labels.stmt_tickets?.toUpperCase()} value="transactions" />
            <Tab label={translate.labels.stmt_payin?.toUpperCase()} value="deposits" />
            <Tab label={translate.labels.stmt_payout?.toUpperCase()} value="withdrawals" />
          </TabList>
          <Box>
            <Tabs indicatorColor="none" value={period} onChange={handlePeriodChange}>
              <StyledTab value="1" label="24h" />
              <StyledTab value="7" label="7 days" />
              <StyledTab value="30" label="30 days" />
              <StyledTab value="other" label="Other" />
            </Tabs>
          </Box>
          {period === "other" && (
            <Stack direction="row" alignItems="center" gap={2} py={1}>
              <CustomDatePicker label="from" input={{ value: new Date(startDate), onChange: handleStartDateChange }} />
              <CustomDatePicker label="to" input={{ value: new Date(endDate), onChange: handleEndDateChange }} />
            </Stack>
          )}
          <TabPanel value="transactions">
            <TransactionStatements />
          </TabPanel>
          <TabPanel value="deposits">
            <DepositStatements />
          </TabPanel>
          <TabPanel value="withdrawals">
            <WithdrawStatements />
          </TabPanel>
        </TabContext>
      </StyledContainer>
    </Box>
  );
};

export default StatementsPage;
