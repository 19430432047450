//BLUEDARK theme palette
import { alpha } from "@mui/material/styles";

const NEUTRAL = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

const PRIMARY = {
  light: "#6ec0ff",
  main: "#3da3fd",
  dark: "#3180c6",
  contrastText: "#ffffff",
  featured2: "#999b9d",
  menuTopActive: "#ffffff",
  team: "#ffffff",
  loginBtn: "#fdfdfd",
};

const SECONDARY = {
  light: "#6ec0ff",
  main: "#3da3fd",
  dark: "#10609d",
  contrastText: "#ffffff",
};

const SUCCESS = {
  main: "#14B8A6",
  light: "#43C6B7",
  dark: "#0E8074",
  contrastText: "#ffffff",
};

const ERROR = {
  light: "#f25682",
  main: "#bb1956",
  dark: "#85002e",
  contrastText: "#ffffff",
};

const INFO = {
  light: "#63cea4",
  main: "#2b9c75",
  dark: "#006d49",
  contrastText: "#ffffff",
};

const WARNING = {
  lighter: "#FFF5CC",
  light: "#FFD666",
  main: "#FFAB00",
  dark: "#B76E00",
  darker: "#7A4100",
  contrastText: "#ffffff",
};

const DIVIDER = "#272e45";

const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#ffffffb3",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
};

const BACKGROUND = {
  paper: "#191e2d",
  main: "#0d0f14",
  drawer: "#191e2d",
  dbPaper: "#21283c",
  header: GREY[700],
  results: "#21283c",
  results1: "#2e3445",
  selectionHover: "#ffffff0b",
  featuredOdds: "#2e3445"
};

export const bluedarkPalette = {
  mode: "dark",
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  divider: DIVIDER,
  neutral: NEUTRAL,
  background: BACKGROUND,
  success: SUCCESS,
  error: ERROR,
  grey: GREY,
  warning: WARNING,
  common: {
    white: "#ffffff",
    black: "#000000",
  },
  databet: {
    primaryColor: "#191e2d",
    primaryAccent: "#3da3fd",
    secondaryColor: "#21283c",
    primaryText: "#ffffff",
    secondaryText: "#999b9d",
    infoColor: "#ff00de",
    successColor: "#15a000",
    errorColor: "#e80f0f",
    warningColor: "#3da3fd",
  },
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  text: {
    primary: GREY[0],
    secondary: GREY[600],
    disabled: GREY[500],
    odds: "var(--color-odds)",
  },
};
