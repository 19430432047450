import { Box, Stack, Typography } from "@mui/material";
import { RHFCheckbox } from "../../hook-form/rhf-checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import RHFormProvider from "../../hook-form/form-provider";
import { Translate } from "react-redux-i18n";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getSubscriptionsAsync, updateSubscriptionsAsync } from "../../../redux-store/account-reducer";

const NotificationsInterface = () => {
  const dispatch = useDispatch();
  // state selectors
  const notifySettings = useSelector((state) => state.account.subscriptions);
  // scheme
  const notificationsScheme = Yup.object().shape({
    sub_email: Yup.bool(),
    sub_sms: Yup.bool(),
  });
  // RHF methods
  const methods = useForm({
    resolver: yupResolver(notificationsScheme),
    defaultValues: {
      sub_email: false,
      sub_sms: false,
    },
  });

  const getSubscriptions = () => {
    dispatch(getSubscriptionsAsync());
  };

  const onSubmit = (formValues) => {
    dispatch(updateSubscriptionsAsync({ params: formValues, callback: getSubscriptions }));
  };

  useEffect(getSubscriptions, []);

  useEffect(() => {
    methods.setValue("sub_email", notifySettings.sub_email);
    methods.setValue("sub_sms", notifySettings.sub_sms);
  }, [notifySettings.sub_email, notifySettings.sub_sms]);

  return (
    <Box py={3} px={{ xs: 0, md: 2 }}>
      <Stack pb={2}>
        <Typography variant="body2">
          <Translate value="labels.notifications_text" />
        </Typography>
      </Stack>
      <RHFormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack>
          <RHFCheckbox label="Email" name="sub_email" />
          <RHFCheckbox label="SMS" name="sub_sms" />
        </Stack>
        <Stack direction="row" mt={2}>
          <LoadingButton type="submit" loading={notifySettings.isSaveLoading} variant="contained" color="primary">
            <Translate value="labels.btn_save" />
          </LoadingButton>
        </Stack>
      </RHFormProvider>
    </Box>
  );
};

export default NotificationsInterface;
