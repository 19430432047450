import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl, FormHelperText, InputBase, styled } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { MobileDatePicker } from "@mui/x-date-pickers";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  height: 44,
  fontSize: 14,
  width: "100%",
  fontWeight: 500,
  padding: "0 0 0 1rem",
  borderRadius: "5px",
  background: theme.palette.background.paper,
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
  "&::placeholder": {
    color: theme.palette.grey[200],
  },
}));

const RHFDatePicker = ({ name, helperText, type, textField, ...other }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth>
          <MobileDatePicker slots={{ textField: (props) => <StyledInputBase {...props} /> }} {...field} {...other} label="Basic date picker" />
          <FormHelperText error={!!error} id="rhf-text-field">
            {error ? error?.message : helperText}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default RHFDatePicker;
