import { CircularProgress, Paper, styled, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleMyTicketsPanel } from "../../../redux-store/settings-reducer";
import { emptySlipTemplate, saveBet } from "../../../actions";
import api from "../../../apis/nubet";
import { useState } from "react";

const StyledContainer = styled(Paper)(({ theme, price, isSelected }) => ({
  border: "1px solid",
  backgroundColor: theme.palette.background.results1,
  borderColor: theme.palette.background.results,
  padding: theme.spacing(1.4),
  width: "100%",
  maxWidth: 200,
  height: "100%",
  borderRadius: 8,
  cursor: "pointer",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...(isSelected && {
    borderColor: theme.palette.primary.main,
  }),
  "&:hover": {
    background: theme.palette.background.selectionHover,
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    maxWidth: "100%",
    padding: theme.spacing(0.5),
  },
  ...(!price && {
    background: theme.palette.background.selectionHover,
    cursor: "default",
    height: 45,
  }),
}));

const PlayerPropsSelectionButton = ({ marketName, marketGroupName, price, playerName, identifier, game, player_id, mobile, selection }) => {
  const [isGenerateLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const addBet = (slipId, fixtureId, description, details, translations) => {
    if (!price) return;
    let payload = {
      gameId: game.game_id,
      isLive: false,
    };
    const isActive = true;

    payload.marketId = selection;
    payload.marketName = marketName;
    payload.selectionId = player_id;
    payload.price = Number(price).toFixed(2);
    payload.sn = playerName;
    payload.identifier = identifier;
    payload.hc = null;
    payload.shc = null;
    payload.isActive = isActive;
    payload.isPlayerProps = true;
    payload.fixtureId = "sr:match:" + game?.game_id;
    payload.betslipUid = slipId;
    payload.description = description;
    payload.marketDescription = details;
    payload.marketGroupName = marketGroupName;
    payload.translations = translations;
    payload.isOutright = false;
    payload.game = game;
    payload.isMultibet = 0;
    payload.isBank = -1;
    dispatch(toggleMyTicketsPanel(false));
    dispatch(saveBet(payload, 0, "PlayerPropsSelectionButton"));
  };

  const generateBet = async () => {
    setLoading(true);
    try {
      const req = {
        params: { gameId: game.game_id, sportCode: game.sportCode, selectionId: selection },
      };
      const res = await api.post("/account/requestBetPriceById", req);
      if (res?.data?.payload?.Identifier) {
        const data = res.data.payload;
        addBet(data.BetSlipUid, data.ConsumerFixtureId, data.Description, data.LegDetails, data.Translations);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const bets = useSelector((state) => state.slips[0].settings?.bets) || {};
  const isSelected = Object.values(bets).find((bt) => bt.gameId === game.game_id && bt.selectionId === player_id && bt.identifier === identifier);

  return (
    <StyledContainer isSelected={isSelected} price={price} onClick={generateBet}>
      {mobile && !isGenerateLoading && (
        <Typography sx={{ opacity: price ? 1 : 0.2 }} color={price ? "text.primary" : "text.secondary"} textTransform="capitalize" variant="caption" component="span" mb={0.5} align="center">
          {marketName}
        </Typography>
      )}
      {isGenerateLoading ? (
        <CircularProgress size={15} />
      ) : (
        <Typography variant="subtitle2" component="p" color={{ xs: "primary.main", sm: "text.secondary" }}>
          {price?.toFixed(2)}
        </Typography>
      )}
    </StyledContainer>
  );
};

export default PlayerPropsSelectionButton;
