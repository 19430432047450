import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { checkDepositStatusAsync } from "../../../redux-store/auth-reducer";
import { removeTrustlyDepositPayload, removeTrustlyDepositStatus } from "../../../redux-store/auth-reducer";
import Iconify from "../../iconify";
import { StyledContainer } from "../@components/containers";

const DepositStatusPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // state selectors
  const locale = useSelector((state) => state.i18n.locale);
  const depositPayload = useSelector((state) => state.auth.deposit.payload);
  const depositStatus = useSelector((state) => state.auth.deposit.status);
  const isLoading = useSelector((state) => state.auth.deposit.isStatusLoading);
  const status = depositStatus?.status;

  const handleDepositStatusCheck = () => {
    dispatch(checkDepositStatusAsync({ token: depositPayload.token, reference: depositPayload.reference }));
  };

  const handleBacktoHome = () => {
    dispatch(removeTrustlyDepositPayload());
    navigate(`/sports`);
  };

  const handleContinueDeposit = () => {
    dispatch(removeTrustlyDepositStatus());
    window.location.href = depositPayload?.redirectUrl;
  };

  useEffect(() => {
    let intervalId;
    if (status === "init" || status === "pending") {
      navigate("/sports");
    }
    if (depositPayload?.reference && status !== "pending" && status !== "ok") {
      intervalId = setInterval(handleDepositStatusCheck, 2000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [depositPayload?.reference, status]);

  return (
    <Box py={3}>
      <StyledContainer>
        {!status || status === "waiting" || isLoading ? (
          <Stack alignItems="center" gap={4} py={4} px={2}>
            <CircularProgress />
            <Typography variant="body1" color="text.secondary">
              Loading...
            </Typography>
          </Stack>
        ) : null}
        {status === "init" || status === "pending" ? (
          <Stack alignItems="center" gap={3} py={3} px={2}>
            <Typography variant="body2" color="warning">
              <Translate value="trustly.deposit_not_completed" />
            </Typography>
            <Stack direction="row" alignItems="center" gap={2}>
              <Link to={`/${locale}/sports?sportEventStatusSlug=all`}>
                <Button variant="contained" color="primary" onClick={handleBacktoHome}>
                  <Translate value="labels.cancel_operation" />
                </Button>
              </Link>
              <Button variant="contained" color="success" onClick={handleContinueDeposit}>
                <Translate value="trustly.complete_deposit" />
              </Button>
            </Stack>
          </Stack>
        ) : null}
        {status === "voided" || status === "cancelled" || status === "rejected" || status === "failed" ? (
          <Stack alignItems="center" gap={4} py={4} px={2}>
            <Iconify color="error.main" width={100} icon="si:error-duotone" />
            <Typography variant="body2" align="center">
              <Translate value="trustly.deposit_canceled" />
            </Typography>
            <Link to="/sports">
              <Button variant="contained" color="primary">
                <Translate value="labels.go_to_play" />
              </Button>
            </Link>
          </Stack>
        ) : null}
        {status === "ok" || status === "success" ? (
          <Stack alignItems="center" gap={4} py={4} px={2}>
            <Iconify color="success.main" width={100} icon="icon-park-twotone:success" />
            <Typography variant="body2" align="center">
              Deposit successful
            </Typography>

            <Button onClick={handleBacktoHome} variant="contained" color="primary">
              <Translate value="labels.go_to_play" />
            </Button>
          </Stack>
        ) : null}
      </StyledContainer>
    </Box>
  );
};

export default DepositStatusPage;
