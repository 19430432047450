import { Box } from "@mui/material";
import LossLimits from "./loss-limits";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserLimitsAsync } from "../../../../redux-store/account-reducer";

const LimitsInterface = () => {
  const dispatch = useDispatch();
  const isSaveLoading = useSelector((state) => state.account.limits.isSaveLoading);

  useEffect(() => {
    dispatch(getUserLimitsAsync());
  }, [isSaveLoading]);

  return (
    <Box>
      <LossLimits />
    </Box>
  );
};

export default LimitsInterface;
