import { Box, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import { getUserDepositStatementsAsync } from "../../../redux-store/account-reducer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { addDays, format, subDays } from "date-fns";
import { getStatusColor } from "../../../helpers/utilities";
import TablePaginationActions from "../@components/TablePaginationActions";
import Iconify from "../../iconify";

const DepositStatements = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // router selectros
  const page = searchParams.get("page") || 1;
  const startDate = searchParams.get("start") || format(subDays(new Date(), 1), "yyyy-MM-dd");
  const endDate = searchParams.get("end") || format(addDays(new Date(), 1), "yyyy-MM-dd");

  // state selectors
  const list = useSelector((state) => state.account.statements.deposits.list);
  const isLoading = useSelector((state) => state.account.statements.deposits.isLoading);
  const currentRowsCount = useSelector((state) => state.account.statements.deposits.size);
  const allRowsCount = useSelector((state) => state.account.statements.deposits.count);
  const currentPage = useSelector((state) => state.account.statements.deposits.currentPage);
  const pageCount = useSelector((state) => state.account.statements.deposits.pageCount);

  const handleChangePage = (event, newPage) => {
    searchParams.set("page", Number(newPage));
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    dispatch(getUserDepositStatementsAsync({ start: startDate, end: endDate, page: Number(page) }));
  }, [page, startDate, endDate]);

  return (
    <Box minHeight="50vh" display="flex" flexDirection="column" justifyContent="space-between">
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" gap={4} py={4} px={2} height="50vh">
          <CircularProgress />
          <Typography variant="body1" color="text.secondary">
            Loading...
          </Typography>
        </Stack>
      )}
      {!isLoading && !allRowsCount ? (
        <Stack alignItems="center" justifyContent="center" gap={2} py={4} px={2} height="50vh">
          <Iconify width={70} icon="lucide:list-x" color="text.secondary" />
          <Typography variant="body2" color="text.secondary">
            <Translate value="labels.statement_no_entries" />
          </Typography>
        </Stack>
      ) : null}
      {!isLoading && currentRowsCount > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" color="primary">
                    <Translate value="labels.statement_header_ts" />
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1" color="primary">
                    <Translate value="labels.statement_header_amount" />
                  </Typography>
                </TableCell>
                <TableCell align="right" variant="body1" color="primary">
                  <Typography variant="body1" color="primary">
                    <Translate value="labels.statement_header_trtype" />
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((deposit) => (
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">{deposit?.createdTime ? format(new Date(deposit.createdTime), "dd.MM.yyyy, HH:mm") : null}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={1}>
                      <Typography variant="body1">{deposit.amount?.toFixed(2)}</Typography>
                      <Typography variant="body1">{deposit.currency}</Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1" color={getStatusColor(deposit.status)}>
                      {deposit.status}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {pageCount > 1 && (
        <Stack>
          <TablePagination
            rowsPerPageOptions={[10]}
            colSpan={3}
            count={allRowsCount}
            rowsPerPage={currentRowsCount}
            page={Number(currentPage) - 1}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        </Stack>
      )}
    </Box>
  );
};

export default DepositStatements;
