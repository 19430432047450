// Betbuilder Main Page
import { Box, Divider, Grid2, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
// redux utils
import { useDispatch, useSelector } from "react-redux";
import { getBetbuilderLeaguesAsync } from "../../../redux-store/sports-reducer";
import LeagueRow from "../@components/LeagueRow";
import { useLocation, useSearchParams } from "react-router-dom";
import PrecannedBetsCarousel from "../../banners/precanned-bets";
import EmptyScreen from "../@components/EmptyScreen";
import { Translate } from "react-redux-i18n";
import PageLoader from "../../PageLoader";
import SportsLayout from "../@components/SportsLayout";
import SportsSideBar from "../@components/SportsSideBar";
import { PaperContainer } from "../@components/containers";

const BetbuilderPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  // Selectors
  const isPageLoading = useSelector((state) => state.sports.betbuilder.isLoading);
  const lang = useSelector((state) => state.i18n.locale);
  const betbuilderLeagues = useSelector((state) => state.sports.betbuilder.leagues);
  const allSportsCodes = useSelector((state) => state.sports.betbuilder.sportCodes);
  const sportCode = searchParams.get("selected-sport") || allSportsCodes[0];
  const initialSportCode = sportCode || allSportsCodes[0];

  useEffect(() => {
    if (!betbuilderLeagues[initialSportCode]?.length) {
      dispatch(getBetbuilderLeaguesAsync(lang));
    }
  }, [location.pathname]);

  return (
    <SportsLayout>
      <Box pb={{ xs: 0.7, md: 2 }}>
        {!betbuilderLeagues[initialSportCode]?.length && isPageLoading ? (
          <PageLoader />
        ) : !isPageLoading && !betbuilderLeagues[initialSportCode]?.length ? (
          <EmptyScreen message={<Translate value="labels.empty_screen_message" />} />
        ) : (
          <Stack gap={2}>
            <Box display={{ xs: "none", md: "block" }}>
              <PrecannedBetsCarousel />
            </Box>
            <SportsSideBar collection="betbuilder" sportCodes={allSportsCodes} initialSportCode={initialSportCode} />
            <PaperContainer>
              <Box>
                <Stack pb={2} pt={1}>
                  <Typography variant="h5" component="p">
                    <Translate value="labels.all_competetions" />
                  </Typography>
                </Stack>
                <Divider />
                {betbuilderLeagues[initialSportCode]?.map((league, indx) => (
                  <LeagueRow key={league.leagueId} rowIndex={indx + 1} leaguesCount={betbuilderLeagues[initialSportCode]?.length} {...league} />
                ))}
              </Box>
            </PaperContainer>
          </Stack>
        )}
      </Box>
    </SportsLayout>
  );
};

export default BetbuilderPage;
