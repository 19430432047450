// betbuilder loader
// REACT hooks
import { useEffect } from "react";
// @MUI components
import { Box, Grid2, Stack, styled } from "@mui/material";
// REDUX utils
import { useDispatch, useSelector } from "react-redux";
import { emptySlipTemplate, saveBet } from "../../../actions";
import { getBetbuilderBetAsync, getBetbuilderLeaguesAsync, getSingleGameAsync } from "../../../redux-store/sports-reducer";
// CONFIGs
import sportCastConfig from "../../../config/sportcast";
// router utils
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// components
import { toggleMyTicketsPanel } from "../../../redux-store/settings-reducer";
import SportsLayout from "../@components/SportsLayout";
import SportsSideBar from "../@components/SportsSideBar";
import SportsHeader from "../@components/SportsHeader";

const StyledIFrame = styled(Box)(({ theme }) => ({
  border: 0,
  width: "100%",
  height: "63dvh",
  [theme.breakpoints.down("sm")]: {
    height: "69dvh",
  },
  "& .content-container": {
    background: "transparent",
  },
}));

const BetBuilderLoaderPage = () => {
  // url params
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // action dispatcher
  const dispatch = useDispatch();

  // state selectors
  const sportCasturl = useSelector((state) => state.settings.host.sportcast_url);
  const brand = useSelector((state) => state.settings.theme);
  const locale = useSelector((state) => state.i18n.locale);
  const slipTemplate = useSelector((state) => state.selected.slipTemplate);
  const bets = useSelector((state) => state.slips[slipTemplate]["settings"]["bets"]);
  const selectedSlipTemplate = useSelector((state) => state.selected.slipTemplate);
  const allSportsCodes = useSelector((state) => state.sports.betbuilder.sportCodes);
  const sportCode = searchParams.get("sport") || allSportsCodes[0];
  const initialSportCode = sportCode || allSportsCodes[0];
  const game = useSelector((state) => state.sports.betbuilder.singleGame);
  const leagues = useSelector((state) => state.sports.betbuilder.leagues);
  // constants
  const gameId = params.gameId;
  const leagueId = searchParams.get("league");
  const selectedLeague = leagues && leagues[sportCode] ? leagues[sportCode].find((lg) => lg.leagueId === Number(leagueId)) : {};

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handleAddBet = (res) => {
    const selectedGame = JSON.parse(localStorage.getItem("betbuilder-game"));
    const data = res?.payload ? res.payload : {};

    let fixtureId = data?.FixtureId;
    
    if (Number(selectedGame?.gameid) !== Number(fixtureId)) return;
    let payload = {
      game: selectedGame,
      gameId: selectedGame?.gameid,
      consumerFixtureId: data?.FixtureId || data?.ConsumerFixtureId,
      isLive: false,
      isBetBuilder: true,
      marketId: undefined,
      betslipUid: data?.BetSlipUid,
      price: data?.PriceDetails?.DecimalPriceRounded,
      isActive: true,
      hc: "",
      selectionId: data?.BetSlipUid,
      description: data?.Description,
      translations: data?.Translations,
      identifier: data?.Identifier,
      marketDescription: data?.LegDetails,
      isMultiBet: 0,
      sn: data?.Description?.toString(),
    };
    dispatch(emptySlipTemplate(0));
    dispatch(toggleMyTicketsPanel(false));
    dispatch(saveBet(payload, selectedSlipTemplate, "BetbuilderView"));
    return Object.keys(payload)?.length === 0 ? false : true;
  };

  const handleBuilderResponse = (e) => {
    if (!isJson(e?.data)) return;
    const betData = e?.data ? JSON.parse(e.data) : {};
    const betValues = bets ? Object.values(bets) : [];
    const isBetExist = betValues.find((bet) => bet.betslipUid === betData?.betslipUid);
    if (betData?.command === "addToBetslip" && !isBetExist) {
      const callback = (res) => handleAddBet(res);
      dispatch(getBetbuilderBetAsync({ betslipUid: betData?.betslipUid, callback }));
    }
  };

  const goBack = () => {
    navigate(`/${locale}/betbuilder/league/${leagueId}?selected-sport=${game?.sport_code}`);
  };

  useEffect(() => {
    dispatch(getBetbuilderLeaguesAsync(locale));
    if (leagueId && gameId) {
      dispatch(getSingleGameAsync({ leagueID: leagueId, gameID: gameId, type: "betbuilder" }));
    }
  }, [leagueId, gameId]);

  useEffect(() => {
    window.addEventListener("message", handleBuilderResponse);
    return () => {
      window.removeEventListener("message", handleBuilderResponse);
    };
  }, [game?.sport_code]);

  return (
    <SportsLayout>
      <Box pb={{ xs: 0, md: 2 }}>
        <Grid2 container spacing={2}>
          <Grid2 item size={12}>
            <SportsSideBar collection="betbuilder" sportCodes={allSportsCodes} initialSportCode={initialSportCode} />
          </Grid2>
          <Grid2 item size={12}>
            <Stack gap={1}>
              <SportsHeader collection="betbuilder" sportCode={sportCode} league={selectedLeague} game={game} />
              <StyledIFrame
                component="iframe"
                title="Betbuilder"
                src={`${sportCasturl}/markets?key=${sportCastConfig.WIDGET_KEY}&fixtureId=${params.gameId}&isConsumerId=false&brand=nubet-${brand}${
                  locale !== "en" ? `&culture=${locale}-${locale === "et" ? "EE" : "FI"}` : ""
                }`}
              />
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </SportsLayout>
  );
};

export default BetBuilderLoaderPage;
