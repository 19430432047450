/**
 * Balance dropdown component
 */
import { log } from "../../config/log";
import React from "react";
import { connect } from "react-redux";
import { withStyles, styled } from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Iconify from "../iconify";
import withRouter from "../../helpers/withRouter";

const MenuCaret = styled("div")(() => ({
  width: 20,
  height: 20,
  position: "absolute",
  right: 60,
  top: -10,
  transform: "rotate(45deg)",
  background: "var(--bg-app)",
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
    width: 100,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const styles = (theme) => ({
  progress_container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingLeft: 20,
  },
  progress_text: {
    margin: 0,
  },
});

class BalanceDropDown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  numberWithCommas = (x) => {
    // Format numbers with comma
    // E.g 1000 will be 1,000
    if (x) {
      return x
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "0.00";
  };

  render() {
    log(this.constructor.name, "render");
    const {
      host,
      layout,
      cash,
      promo,
      classes,
      earnedTurnover,
      turnoverRate,
      totalTurnover,
      translate,
      balance,
      path,
      lang,
      gameId,
    } = this.props;

    if (!host) return null;

    return (
      <div style={{ marginLeft: "10px", fontSize: "1em" }}>
        <Button
          onClick={this.handleClick}
          variant="outlined"
          color="primary"
          endIcon={<Iconify icon={Boolean(this.state.anchorEl)? "fluent:chevron-up-20-filled" : "fluent:chevron-down-20-filled"} />}
          size={layout === "touch" ? "medium" : "small"}
          style={{
            padding: layout === "mouse" ? "0.4em" : "0.4em",
          }}
        >
          {path === `/${lang}/casino/${gameId}` ? (
            <Typography variant="string">Gaming</Typography>
          ) : (
            <Typography
              variant="body2"
              style={{
                color: "var(--color-balance)",
                margin: "0 0 0 0.8em",
                fontSize: layout === "mouse" ? "1em" : "1em",
              }}
            >
              {this.numberWithCommas(balance)}{" "}
              {/* {host.brand === "nubet_se" ? "SEK" : "EUR"} */}
            </Typography>
          )}
        </Button>
        <Menu
          id="customized-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          slotProps={{
            paper: {
              sx: {
                border: "none",
                borderRadius: "6px",
                width: "24.5dvw",
                height: "30dvh",
                top: "60px !important",
                "@media only screen and (max-width: 600px)": {
                  width: "100dvw !important",
                  top: "60px !important",
                  height: "280px",
                },
                "& .MuiListItemIcon-root": {
                  minWidth: "2em",
                },
                boxShadow: "0px 1px 8px 6px rgba(34, 60, 80, 0.39)",
                background: "var(--bg-app)",
                overflow: "unset",
              },
            },
          }}
        >
          <MenuCaret />
          <MenuItem>
            <ListItemIcon>
              <Iconify icon="material-symbols:euro" />
            </ListItemIcon>
            <ListItemText primary={`${translate.menu.balance_cash}:`} />
            <ListItemText
              style={{ textAlignLast: "right" }}
              primary={this.numberWithCommas(cash)}
            />
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Iconify icon="simple-icons:bitdefender" width={15} />
            </ListItemIcon>
            <ListItemText primary={`${translate.menu.balance_promo}:`} />
            <ListItemText
              style={{ textAlignLast: "right" }}
              primary={this.numberWithCommas(promo)}
            />
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Iconify icon="mdi:restore" />
            </ListItemIcon>
            <ListItemText primary={`${translate.menu.bonus_wr}:`} />
            <div className={classes.progress_container}>
              <h5 className={classes.progress_text}>
                {this.numberWithCommas(earnedTurnover)} {" / "}
                {this.numberWithCommas(totalTurnover)}
              </h5>
              <BorderLinearProgress
                variant="determinate"
                value={turnoverRate}
              />
            </div>
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const totalTurnover = state.account.balanceDetails.totalTurnover
    ? state.account.balanceDetails.totalTurnover
    : 0;
  const currentTurnover = state.account.balanceDetails.currentTurnover
    ? state.account.balanceDetails.currentTurnover
    : 0;
  const earnedTurnover = totalTurnover - currentTurnover;
  const turnoverRate =
    totalTurnover > 0 ? (earnedTurnover * 100) / totalTurnover : 0;

  const transactions = state.casino.bonuses?.transactions
    ? state.casino.bonuses?.transactions
    : [];
  let spinsCount = 0;

  transactions?.forEach((transaction) => {
    if (transaction?.freeSpins) spinsCount += transaction?.freeSpins;
  });

  const selectedGame =
    state.selected.casino && Object.values(state.selected.casino)?.length
      ? Object.values(state.selected.casino)[0]
      : null;

  return {
    host: state.settings.host,
    lang: state.i18n.locale,
    balance: state.account.balanceDetails.balance,
    cash: state.account.balanceDetails.availableWithdrawal,
    promo: state.account.balanceDetails.promo,
    layout: state.settings.layout,
    totalTurnover: totalTurnover,
    turnoverRate: turnoverRate,
    earnedTurnover: earnedTurnover,
    translate: state.i18n.translations[state.i18n.locale],
    spinsCount: spinsCount,
    locale: state.i18n.locale,
    path: ownProps.location.pathname,
    gameId: selectedGame?.gameId,
  };
};

export default withRouter(
  connect(mapStateToProps, {})(withStyles(styles)(BalanceDropDown))
);
