const limitsFormatter = (data, limitType) => {
  const payload = {
    daily: {
      settings: {},
      pending: null,
      extraData: {},
    },
    weekly: { settings: {}, pending: null },
    monthly: { settings: {}, pending: null },
  };
  const settings = data.settings[limitType];
  const pending = data.pending[limitType];
  payload.daily.settings = settings.daily;
  payload.weekly.settings = settings.weekly;
  payload.monthly.settings = settings.monthly;
  payload.daily.pending = pending[1].limitValue;
  payload.weekly.pending = pending[7].limitValue;
  payload.monthly.pending = pending[30].limitValue;
  if (limitType === "loss") {
    const dailyLossData = data.extraData.find((item) => item.dailyLoss);
    const weeklyLossData = data.extraData.find((item) => item.weeklyLoss);
    const monthlyLossData = data.extraData.find((item) => item.monthlyLoss);
    payload.daily.extraData = dailyLossData || {};
    payload.weekly.extraData = weeklyLossData || {};
    payload.monthly.extraData = monthlyLossData || {};
  }
  if (limitType === "stake") {
    const dailyWagerData = data.extraData.find((item) => item.dailyWager);
    const weeklyWagerData = data.extraData.find((item) => item.weeklyWager);
    const monthlyWagerData = data.extraData.find((item) => item.monthlyWager);
    payload.daily.extraData = dailyWagerData || {};
    payload.weekly.extraData = weeklyWagerData || {};
    payload.monthly.extraData = monthlyWagerData || {};
  }
  if (limitType === "deposit") {
    const dailyDepositData = data.extraData.find((item) => item.dailyDeposit);
    const weeklyDepositData = data.extraData.find((item) => item.weeklyDeposit);
    const monthlyDepositData = data.extraData.find((item) => item.monthlyDeposit);
    payload.daily.extraData = dailyDepositData || {};
    payload.weekly.extraData = weeklyDepositData || {};
    payload.monthly.extraData = monthlyDepositData || {};
  }

  return payload;
};

export default limitsFormatter;
