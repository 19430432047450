// Like/Dislike button
// @react
import { useState } from "react";
// router hooks
import { useNavigate } from "react-router-dom";
// @redux tools
import { useDispatch, useSelector } from "react-redux";
// action handlers
import { getUserFavGamesIdsAsync, saveFavoriteCasinoGamesAsync } from "../../../redux-store/casino-reducer";
import { IconButton } from "@mui/material";
import Iconify from "../../iconify";

const LikeButton = ({ gameId }) => {
  //   state
  const [isLiked, setLiked] = useState(false);
  // redux action dispatcher
  const dispatch = useDispatch();
  // router hooks
  const navigate = useNavigate();
  //select from state
  const favGamesIds = useSelector((state) => state.casino.favGames.ids);
  const lang = useSelector((state) => state.i18n.locale);
  const auth = useSelector((state) => state.auth);

  // handle like/dislike
  const toggleFavoriteCasinoGame = () => {
    if (auth.isSignedIn) {
      if (!favGamesIds.includes(gameId)) {
        setLiked(true);
      } else if (isLiked) {
        setLiked(false);
      }
      let formData = new FormData();
      formData.append("api", favGamesIds.includes(gameId) ? "remove" : "add");
      formData.append("gameId", gameId);
      dispatch(saveFavoriteCasinoGamesAsync({ data: formData, callback: () => dispatch(getUserFavGamesIdsAsync()) }));
    } else {
      navigate(`/${lang}/auth/login?redirect_to=casino`);
    }
  };

  return favGamesIds.includes(gameId) || isLiked ? (
    <IconButton color="error" onClick={toggleFavoriteCasinoGame}>
      <Iconify icon="line-md:heart-filled" />
    </IconButton>
  ) : (
    <IconButton onClick={toggleFavoriteCasinoGame}>
      <Iconify icon="solar:heart-linear" />
    </IconButton>
  );
};

export default LikeButton;
